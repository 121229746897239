import React, { useState, useEffect, useRef } from 'react';
import { Table, Label, InputGroup, InputGroupAddon } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import {
  fetchKaiGroups,
  fetchKaiUsers,
} from '../../../store/sliceHelpers/kaiSliceHelper';
import {
  setKaiOrderBy,
  setKaiOrderByDir,
  setKaiPlatform,
  setKaiStatus,
  setKaiGroup,
  setKaiUser,
  setKaiNotUsers,
  setKaiSubmissionTag,
} from '../../../store/slices/kaiSlice';
import AnalyzedAppInfo from '../../AnalyzedApps/AnalyzedAppInfo';
import { ThreatScore } from '../../ThreatScore';
import AsyncTablePagination from '../../AsyncTablePagination';
import PlatformSelect from '../../PlatformSelect';
import Loading from '../../Loading';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QBadge } from '../../Q-Components/QBadge';
import { QInput } from '../../Q-Components/QInput';
import { QInputIconWrapper } from '../../Q-Components/QInputIconWrapper';
import { QAutocomplete } from '../../Q-Components/QAutocomplete';
import { QSelect } from '../../Q-Components/QSelect';
import { QSearchBar } from '../../Q-Components/QSearchBar';

import { KaiAppActions } from './KaiAppActions';

function KaiAnalyzedApps() {
  const { mastV2 } = useFlags();
  const groups = useSelector(state => state.kai.kaiGroups);
  const users = useSelector(state => state.kai.kaiUsers);
  const dispatch = useDispatch();

  const groupsOptions = groups.map(group => ({
    value: group.id,
    label: group.name,
    ...group,
  }));

  const userOptions = users.map(user => ({
    value: user.id,
    label: user.email,
    ...user,
  }));

  const statusOptions = [
    { value: 0, label: 'Not submitted' },
    { value: 1, label: 'Processing' },
    { value: 2, label: 'Complete' },
    { value: 10, label: 'Waiting for PDF' },
    { value: 55, label: 'Ignored' },
    { value: 58, label: 'Analysis failed' },
  ];

  const orderOptions = [
    { value: 'submittedAt', label: 'Submitted at' },
    { value: 'threatScore', label: 'Threat Score' },
    { value: 'analysisEnd', label: 'Analysis End' },
    { value: 'lastModified', label: 'Last Modified' },
  ];

  const submissionTagOptions = [
    { value: 'any', label: 'Any' },
    { value: 'App Watchlist Submission', label: 'App Watchlist Submission' },
    {
      value: 'Manual - Binary Submission',
      label: 'Manual - Binary Submission',
    },
    {
      value: 'Manual - App Store Submission',
      label: 'Manual - App Store Submission',
    },
    { value: 'API - Binary Submission', label: 'API - Binary Submission' },
    {
      value: 'API - App Store Submission',
      label: 'API - App Store Submission',
    },
  ];

  const orderDirOptions = [
    { value: 'ASC', label: 'Ascending' },
    { value: 'DESC', label: 'Descending' },
  ];
  const {
    orderBy,
    orderByDir,
    platform,
    status,
    group,
    user,
    notUsers,
    submissionTag,
  } = useSelector(state => state.kai);

  const [searchValue, setSearchValue] = useState('');
  const [groupInputValue, setGroupInputValue] = useState('');
  const [userInputValue, setUserInputValue] = useState('');

  const setOrderBy = orderBy => {
    dispatch(setKaiOrderBy(orderBy));
  };

  const setOrderByDir = orderByDir => {
    dispatch(setKaiOrderByDir(orderByDir));
  };

  const setPlatform = platform => {
    dispatch(setKaiPlatform(platform));
  };

  const setStatus = status => {
    dispatch(setKaiStatus(status));
  };

  const setGroup = group => {
    dispatch(setKaiGroup(group));
  };

  const setUser = user => {
    dispatch(setKaiUser(user));
  };

  const setNotUsers = notUsers => {
    dispatch(setKaiNotUsers(notUsers));
  };

  const setSubmissionTag = submissionTag => {
    dispatch(setKaiSubmissionTag(submissionTag));
  };

  const [analyzedApps, setAnalyzedApps] = useState([]);
  const [totalApps, setTotalApps] = useState(0);
  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const getAnalyzedApps = async query => {
    setIsLoading(true);
    let response;
    try {
      response = await axios.get('adminV2/analyzed-apps', {
        params: {
          limit: 10,
          offset: (page - 1) * 10,
          groupId: group?.id,
          userId: user?.id,
          query,
          status: status?.map(status => status.value),
          order: orderBy?.value,
          sortDir: orderByDir.value.toUpperCase(),
          platform: mastV2
            ? platform === 'both'
              ? null
              : platform
            : platform.value === 'both'
            ? null
            : platform.value,
          notUsersFilter: notUsers?.map(notUser => notUser.id),
          submissionTag:
            submissionTag.value === 'any' ? null : submissionTag.value,
        },
      });

      setAnalyzedApps(response.data.apps || []);
      setTotalApps(response.data.count || 0);
    } catch (err) {
      console.log('Error getting analyzed apps:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const changePage = async (page, e) => {
    e.preventDefault();

    setPage(page);
  };

  const handleGroupChange = (event, newGroup) => {
    if (mastV2) {
      setGroup(newGroup);
    } else {
      setGroup(event);
    }
  };

  const handleUserChange = (event, newUser) => {
    if (mastV2) {
      setUser(newUser);
    } else {
      setUser(event);
    }
  };

  const handleStatusChange = (event, newStatus) => {
    if (mastV2) {
      setStatus(newStatus);
    } else {
      setStatus(event);
    }
  };

  const handleOrderByChange = (event, newOrderBy) => {
    if (mastV2) {
      const newOption = orderOptions.find(
        option => option.value === newOrderBy.props.value,
      );
      setOrderBy(newOption);
    } else {
      setOrderBy(event);
    }
  };

  const handleOrderByDirChange = (event, newOrderByDir) => {
    if (mastV2) {
      const newOption = orderDirOptions.find(
        option => option.value === newOrderByDir.props.value,
      );
      setOrderByDir(newOption);
    } else {
      setOrderByDir(event);
    }
  };

  const handlePlatformChange = (event, newPlatform) => {
    if (mastV2) {
      setPlatform(newPlatform.props.value);
    } else {
      setPlatform(event);
    }
  };

  const handleSubmissionTag = (event, newSubmissionTag) => {
    if (mastV2) {
      const newOption = submissionTagOptions.find(
        option => option.value === newSubmissionTag.props.value,
      );
      setSubmissionTag(newOption);
    } else {
      setSubmissionTag(event);
    }
  };

  const handleNotUsersChange = (event, newNotUsers) => {
    if (mastV2) {
      setNotUsers(newNotUsers);
    } else {
      setNotUsers(event);
    }
  };

  const handleKeyPress = event => {
    /* istanbul ignore else */
    if (event.charCode === 13) {
      event.preventDefault();
      getAnalyzedApps(searchValue);
    }
  };

  const resetSearch = () => {
    setSearchValue('');
    getAnalyzedApps('');
  };

  const KaiMultiAutocompleteWrapper = ({ ...props }) => (
    <QAutocomplete
      popupIcon={<ExpandMoreIcon fontSize="medium" />}
      clearIcon={<ClearIcon fontSize="medium" />}
      getOptionLabel={option => option.label}
      isClearable
      isMulti
      multiple
      multiline
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => option.value === value.value}
      className="z-index-20"
      renderTags={items => {
        const displayList = items.map(item => item.label).join(', ');
        // Render <span> elements instead of <Chip> components.
        return <>{displayList}</>;
      }}
      renderInput={params => (
        <QInput
          {...params}
          outlinedWithTitle
          label={props.label}
          placeholder={props.placeholder}
          variant="outlined"
          style={{ textOverflow: 'ellipsis' }}
          size="small"
        />
      )}
      {...props}
    />
  );

  useEffect(() => {
    dispatch(fetchKaiGroups());
    dispatch(fetchKaiUsers());
  }, []);

  useEffect(() => {
    getAnalyzedApps(searchValue);
  }, [
    page,
    group,
    user,
    status,
    orderBy,
    orderByDir,
    platform,
    notUsers,
    submissionTag,
  ]);

  return (
    <>
      <QCard className="card-accent-primary" style={{ padding: '0px' }}>
        <QCardHeader
          className="flex justify-between align-center"
          title="Analyzed Apps"
        >
          <div>
            <span>
              <strong>Analyzed Apps</strong>
            </span>
          </div>
        </QCardHeader>

        <div
          className="d-flex width-100"
          style={{ padding: '16px', gap: '24px' }}
        >
          <div style={{ width: '25%' }}>
            {!mastV2 && <Label for="kai-aa-group-filter">Group</Label>}
            {mastV2 ? (
              <QAutocomplete
                className="z-index-20"
                value={group}
                onChange={handleGroupChange}
                inputValue={groupInputValue}
                onInputChange={(event, newInputValue) => {
                  setGroupInputValue(newInputValue);
                }}
                getOptionLabel={option => option.label}
                options={groupsOptions}
                isClearable
                styles={{
                  container: base => ({
                    ...base,
                    width: '100%',
                  }),
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      wordWrap: 'break-word',
                      display: 'block',
                    }}
                  >
                    {option.label}
                  </li>
                )}
                popupIcon={<ExpandMoreIcon fontSize="medium" />}
                clearIcon={<ClearIcon fontSize="medium" />}
                renderInput={params => (
                  <QInput
                    {...params}
                    outlinedWithTitle
                    label="Group"
                    placeholder="Select Group"
                    variant="outlined"
                    style={{ textOverflow: 'ellipsis' }}
                    size="small"
                  />
                )}
              />
            ) : (
              <QAutocomplete
                id="kai-aa-group-filter"
                placeholder="Select Group"
                options={groupsOptions}
                value={group}
                onChange={handleGroupChange}
                isClearable
              />
            )}
          </div>
          <div style={{ width: '25%' }}>
            {!mastV2 && <Label for="kai-aa-user-filter">User</Label>}
            {mastV2 ? (
              <QAutocomplete
                className="z-index-20"
                value={user}
                inputValue={userInputValue}
                onInputChange={(event, newInputValue) => {
                  setUserInputValue(newInputValue);
                }}
                getOptionLabel={option => option.label}
                id="kai-aa-user-filter"
                options={userOptions}
                onChange={handleUserChange}
                isClearable
                styles={{
                  container: base => ({
                    ...base,
                    width: '100%',
                  }),
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      wordWrap: 'break-word',
                      display: 'block',
                    }}
                  >
                    {option.label}
                  </li>
                )}
                popupIcon={<ExpandMoreIcon fontSize="medium" />}
                clearIcon={<ClearIcon fontSize="medium" />}
                renderInput={params => (
                  <QInput
                    {...params}
                    outlinedWithTitle
                    label="User"
                    placeholder="Select User"
                    variant="outlined"
                    style={{ textOverflow: 'ellipsis' }}
                    size="small"
                  />
                )}
              />
            ) : (
              <QAutocomplete
                id="kai-aa-user-filter"
                placeholder="Select User"
                options={userOptions}
                value={user}
                onChange={handleUserChange}
                isClearable
              />
            )}
          </div>
          <div style={{ width: '25%' }}>
            {!mastV2 && <Label for="kai-aa-status-filter">Status</Label>}
            <KaiMultiAutocompleteWrapper
              label="Status"
              id="kai-aa-status-filter"
              options={statusOptions}
              onChange={handleStatusChange}
              value={status ?? []}
              placeholder="Select Status"
            />
          </div>
          <div style={{ width: '25%' }}>
            {!mastV2 && <Label for="kai-aa-not-users-filter">Not users</Label>}
            <KaiMultiAutocompleteWrapper
              label="Not users"
              id="kai-aa-not-users-filter"
              options={userOptions}
              onChange={handleNotUsersChange}
              value={notUsers ?? []}
              placeholder="Select Not Users"
            />
          </div>
        </div>
        <div
          className="d-flex width-100"
          style={{ padding: '16px', gap: '24px' }}
        >
          <div style={{ width: '25%' }}>
            {!mastV2 && <Label for="kai-aa-order">Sort by</Label>}
            <QSelect
              size="small"
              options={orderOptions}
              value={mastV2 ? orderBy.value : orderBy}
              onChange={handleOrderByChange}
              label="Sort by"
              selectIconProps={{ fontSize: 'medium' }}
              formControlProps={{ sx: { width: '100%' } }}
            />
          </div>
          <div style={{ width: '25%' }}>
            {!mastV2 && <Label for="kai-aa-orderd-dir">Sort dir</Label>}
            <QSelect
              size="small"
              options={orderDirOptions}
              value={mastV2 ? orderByDir.value : orderByDir}
              onChange={handleOrderByDirChange}
              label="Sort dir"
              selectIconProps={{ fontSize: 'medium' }}
              formControlProps={{ sx: { width: '100%' } }}
            />
          </div>

          <div style={{ width: '25%' }}>
            {!mastV2 && <Label for="kai-aa-platform-filter">Platform</Label>}
            <PlatformSelect
              hideLabel
              platformFilter={platform}
              handlePlatformChange={handlePlatformChange}
              mastV2={mastV2}
            />
          </div>

          <div style={{ width: '25%' }}>
            {!mastV2 && (
              <Label for="kai-aa-submission-tag-filter">Submission Tag</Label>
            )}
            <QSelect
              size="small"
              options={submissionTagOptions}
              value={mastV2 ? submissionTag.value : submissionTag}
              onChange={handleSubmissionTag}
              label="Submission Tag"
              selectIconProps={{ fontSize: 'medium' }}
              formControlProps={{ sx: { width: '100%' } }}
            />
          </div>
        </div>
        {mastV2 ? (
          <div style={{ padding: '16px' }}>
            <QSearchBar
              searchValue={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              onRemove={resetSearch}
              onKeyPress={e => handleKeyPress(e)}
            />
          </div>
        ) : (
          <div
            className="d-flex width-100"
            style={{ gap: '12px', marginTop: '20px' }}
          >
            <InputGroup>
              <QInput
                type="text"
                id="app-query-input"
                onKeyPress={e => handleKeyPress(e)}
                onChange={e => setSearchValue(e.target.value)}
                value={searchValue}
                variant="filled"
                placeholder="Search apps"
                style={{ width: '100%', height: '48px', zIndex: 'unset' }}
                InputProps={{
                  startAdornment: (
                    <QInputIconWrapper
                      position="start"
                      sx={{ padding: '12px 14px 12px 5px', margin: 0 }}
                    >
                      <i
                        className="far fa-search"
                        style={{
                          color: 'black',
                          fontSize: '19px',
                        }}
                      />
                    </QInputIconWrapper>
                  ),
                }}
              />
              {searchValue && (
                <InputGroupAddon
                  className="pointer input-group-warning"
                  onClick={resetSearch}
                  addonType="append"
                >
                  <span className="input-group-text">
                    <i className="fas fa-times" />
                  </span>
                </InputGroupAddon>
              )}
            </InputGroup>
          </div>
        )}
        <div style={mastV2 ? { paddingTop: '16px' } : {}}>
          <AsyncTablePagination
            pagination={{
              lastPage: Math.ceil(totalApps / 10),
              totalData: totalApps,
              dataPerPage: 10,
            }}
            getPage={changePage}
            page={page}
          />
        </div>

        <QCardBody>
          {!isLoading ? (
            <Table>
              <thead>
                <tr>
                  <th className="text-left">Name</th>
                  <th className="text-left">Times</th>
                  <th className="text-left">User</th>
                  <th className="text-left">Threat Score</th>
                  <th className="text-left">Complete</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {analyzedApps.map(analyzedApp => (
                  <tr key={analyzedApp.id}>
                    <td>
                      <AnalyzedAppInfo
                        app={{
                          ...analyzedApp,
                          farm_app: analyzedApp.farmApp,
                        }}
                        kai
                      />
                    </td>

                    <td>
                      <div>
                        <div>
                          <b>Submitted at:</b>{' '}
                          {moment(analyzedApp.submittedAt).format('L - LTS')}
                        </div>
                        <div>
                          <b>Analysis start:</b>{' '}
                          {moment(analyzedApp.analysisStart).format('L - LTS')}
                        </div>
                        <div>
                          <b>Analysis end:</b>{' '}
                          {moment(analyzedApp.analysisEnd).format('L - LTS')}
                        </div>
                      </div>
                    </td>

                    <td>{analyzedApp.user.email}</td>

                    <td>
                      <ThreatScore
                        threatScore={analyzedApp.threatScore}
                        status={analyzedApp.farmApp.status}
                      />
                    </td>

                    <td>
                      <QBadge
                        color={
                          analyzedApp.farmApp.status === 2
                            ? 'success'
                            : 'danger'
                        }
                        pill
                        label={analyzedApp.farmApp.status === 2 ? 'YES' : 'NO'}
                        variant={
                          analyzedApp.farmApp.status === 2 ? 'green' : 'red'
                        }
                      />
                    </td>

                    <td>
                      <KaiAppActions farmAppId={analyzedApp.farmApp.id} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>
              <Loading />
            </div>
          )}
        </QCardBody>
      </QCard>
    </>
  );
}

export default withLDConsumer()(KaiAnalyzedApps);
