import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import NProgress from 'nprogress';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import AppSubmitConfirmModal from '../Modals/AppSubmitConfirmModal';
import HelpButton from '../HelpButton';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import { clearAppCredentials } from '../../store/slices/appSubmitSlice';
import { store } from '../../store/store';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import { invalidateAnalyticsCache } from '../../store/slices/cache/analyticsCacheSlice';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QInputGroup } from '../Q-Components/QInputGroup';
import { QInput } from '../Q-Components/QInput';
import { QInputGroupAddon } from '../Q-Components/QInputGroupAddon';
import { QTypography } from '../Q-Components/QTypography';

import { QSubmitButton } from './QSubmitButton';

const StoreUrlSubmit = props => {
  const [platform] = useState(props.platform || '');
  const [isLoading, setIsLoading] = useState(false);
  const [storeUrl, setStoreUrl] = useState('');
  const [submitButtonClass, setSubmitButtonClass] = useState('fa-upload');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { mastV2 } = useFlags();

  const dispatch = useDispatch();

  const userAccess = useSelector(state => state.emmApp.userAccess);

  const getURLPlatform = str => {
    if (str.includes('play.google.com')) {
      return 'android';
    }

    if (str.includes('itunes.apple.com') || str.includes('apps.apple.com')) {
      return 'ios';
    }

    return null;
  };

  const isURL = str => {
    console.log('checking url:', str);
    const pattern =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/;
    return pattern.test(str);
  };

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const userHasAccessToPlatform = platform => {
    if (platform === 'android' && userAccess.android_analysis) {
      return true;
    }

    if (platform === 'ios' && userAccess.ios_analysis) {
      return true;
    }

    return false;
  };

  /* istanbul ignore next */
  const submitStoreUrl = async (addToWatchlist, externalId, subgroupIds, _) => {
    toggleConfirmModal();
    // Check to see if the URL is valid
    if (!isURL(storeUrl)) {
      toastrHelper.showWarningToast(
        i18n.t('Please submit a valid URL'),
        i18n.t('Warning'),
        mastV2,
      );
      return;
    }

    const platform = getURLPlatform(storeUrl);

    // Check to see if the URL is from the Google or Apple stores
    if (!platform) {
      toastrHelper.showWarningToast(
        i18n.t(
          'Please submit a valid URL from Google Play or the iOS App Store',
        ),
        i18n.t('Warning'),
        mastV2,
      );
      return;
    }
    // Check to see if the user has access to the given store
    if (!userHasAccessToPlatform(platform)) {
      toastrHelper.showWarningToast(
        i18n.t('You do not have access to the provided platform'),
        i18n.t('Warning'),
        mastV2,
      );
      return;
    }

    console.log(
      'Submitting store URL:',
      storeUrl,
      'watchlist:',
      addToWatchlist,
    );

    NProgress.start();
    setIsLoading(true);
    setSubmitButtonClass('fa-spin fa-spinner');
    toastrHelper.showInfoToast(
      i18n.t('Submitting application URL...'),
      i18n.t('Info'),
      mastV2,
    );

    const { appUsername, appPassword, appCustomCredential } =
      store.getState().appSubmit;

    let response;
    try {
      response = await axios.post('api/submit', {
        platform,
        storeUrl,
        addToWatchlist,
        customUsername: appUsername,
        customPassword: appPassword,
        customCredential: appCustomCredential,
        externalId,
        subgroupIds,
      });

      dispatch(invalidateAnalyzedAppsCache());
      dispatch(invalidateAnalyticsCache());
      toastrHelper.showSuccessToast(
        `Application submitted. ${response.data.msg}`,
        i18n.t('Success'),
        mastV2,
      );
    } catch (err) {
      toastrHelper.showErrorToast(
        `${i18n.t('Error submitting request')}: ${err.response.data.msg}`,
        i18n.t('Error'),
        mastV2,
      );
      console.error('Error submitting store URL:', err);
    } finally {
      NProgress.done();
      setStoreUrl('');
      setIsLoading(false);
      setSubmitButtonClass('fa-upload');

      // Clear the custom credentials
      dispatch(clearAppCredentials());
    }
  };

  return (
    <div>
      {showConfirmModal && (
        <AppSubmitConfirmModal
          isOpen={showConfirmModal}
          canWatchlist
          toggle={toggleConfirmModal}
          confirm={submitStoreUrl}
        />
      )}
      <QCard className="card-accent-warning">
        <QCardHeader
          title={
            <QTypography variant="h4Bold" color="#000000">
              {i18n.t('Store Link')}
            </QTypography>
          }
          action={<HelpButton helpItem="storeUrlSubmission" />}
        >
          <strong>{i18n.t('Store Link')}</strong>
          <HelpButton helpItem="storeUrlSubmission" />
        </QCardHeader>
        <QCardBody style={{ display: 'flex', columnGap: '8px' }}>
          <QInputGroup>
            <QInput
              type="text"
              value={storeUrl}
              onChange={e => setStoreUrl(e.target.value)}
              id={`${platform}-store-url-submission`}
              style={{ flex: 4 }}
              size="small"
            />
            <QInputGroupAddon addonType="append">
              <QSubmitButton
                variant="filled"
                type="button"
                color="primary"
                className="btn-submit-store-url"
                disabled={isLoading}
                onClick={toggleConfirmModal}
                startIcon={<i className={`fas ${submitButtonClass}`} />}
                content={i18n.t('Submit URL')}
                data-testid="submit-url-button"
              />
            </QInputGroupAddon>
          </QInputGroup>
        </QCardBody>
      </QCard>
    </div>
  );
};

export default withRouter(StoreUrlSubmit);
