import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QLabel } from '../../Q-Components/QLabel';
import i18n from '../../../localization/i18n';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QButton } from '../../Q-Components/QButton';
import { QButtonGroup } from '../../Q-Components/QButtonGroup';
import { QTypography } from '../../Q-Components/QTypography';
import QDataTable from '../../DataTable/QDataTable';
import { QSelect } from '../../Q-Components/QSelect';
import { QCol } from '../../Q-Components/QCol';
import { QRow } from '../../Q-Components/QRow';

import { LicenseProfile } from './LicenseProfile';

export function Licenses() {
  const [isOpenLicenseProfile, setIsOpenLicenseProfile] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState(null);
  const [type, setType] = useState(null);

  const [licenseTypeData, setLicenseTypeData] = useState([]);
  const [licensesData, setLicenseData] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [selectedLicenseType, setSelectedLicenseType] = useState();
  const [selectedStatus, setSelectedStatus] = useState({
    label: 'All',
    value: 'all',
  });

  const { mastV2 } = useFlags();

  async function fetchLicenseTypeData() {
    try {
      const { data } = await axios.get('/adminV2/license/types');
      const allOption = { label: 'All', value: 'all' };
      const fetchedData = data.map(entry => ({
        label: entry.name,
        value: entry.id,
      }));
      setLicenseTypeData([allOption, ...fetchedData]);
      setSelectedLicenseType(allOption);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchLicensesData() {
    try {
      const { data } = await axios.get('/adminV2/licenses');
      setLicenseData(data);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchGroupsData() {
    try {
      const { data } = await axios.get('/adminV2/groups');

      setGroupsData(
        data.map(entry => ({ label: entry.name, value: entry.id })),
      );
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchLicenseTypeData();
    fetchLicensesData();
    fetchGroupsData();
  }, []);

  const toggle = () => {
    setIsOpenLicenseProfile(!isOpenLicenseProfile);
  };

  const Title = () => (
    <QTypography variant="h4Bold" color="#000000">
      Licenses Manager
    </QTypography>
  );

  const Action = () => (
    <QButtonGroup>
      <QButton
        size="sm"
        color="link"
        className="pointer black-icon"
        variant="outlined"
        onClick={() => {
          setType('CREATE');
          toggle();
        }}
        startIcon={<i className="fas fa-file-certificate" />}
      >
        {!mastV2 && <i className="fas fa-file-certificate mr-2" />}
        Create License
      </QButton>
    </QButtonGroup>
  );

  // Status options
  const statusOptions = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: 1 },
    { label: 'Expired', value: 2 },
  ];

  // Filter licensesData based on selectedLicenseType and selectedStatus
  const filteredLicensesData = licensesData.filter(license => {
    if (
      selectedLicenseType &&
      selectedLicenseType.value !== 'all' &&
      license.licenseType?.id !== selectedLicenseType.value
    ) {
      return false;
    }

    if (selectedStatus) {
      const now = moment();

      const startDate = moment(license.startDate);

      const endDate = moment(license.endDate);

      if (
        selectedStatus.value === 1 &&
        !now.isBetween(startDate, endDate, null, '[]')
      ) {
        return false;
      }
      if (selectedStatus.value === 2 && !now.isAfter(endDate)) {
        return false;
      }
    }

    return true;
  });

  const handleSelectStatus = (event, newStatus) => {
    if (mastV2) {
      const newOption = statusOptions.find(
        option => option.value === newStatus.props.value,
      );

      setSelectedStatus(newOption);
    } else {
      setSelectedStatus(event);
    }
  };

  const handleSelectLicenseType = (event, newLicenseType) => {
    if (mastV2) {
      const newOption = licenseTypeData.find(
        option => option.value === newLicenseType.props.value,
      );

      setSelectedLicenseType(newOption);
    } else {
      setSelectedLicenseType(event);
    }
  };

  return (
    <>
      <LicenseProfile
        isOpen={isOpenLicenseProfile}
        data={selectedLicense}
        toggle={toggle}
        type={type}
        groupsData={groupsData}
        licenseTypeData={licenseTypeData}
        fetchData={() => fetchLicensesData()}
      />
      <QCard className="card-accent-primary">
        <QCardHeader disableTypography title={<Title />} action={<Action />}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <strong>
              <Title />
            </strong>
            <Action />
          </div>
        </QCardHeader>
        <QCardBody>
          {mastV2 ? (
            <div style={{ display: 'flex', gap: '16px' }}>
              <QSelect
                size="small"
                options={licenseTypeData}
                value={
                  mastV2 ? selectedLicenseType?.value : selectedLicenseType
                }
                onChange={handleSelectLicenseType}
                label="License Type"
                selectIconProps={{ fontSize: 'medium' }}
                formControlProps={{ sx: { width: '20%' } }}
              />

              <QSelect
                size="small"
                options={statusOptions}
                value={mastV2 ? selectedStatus?.value : selectedStatus}
                onChange={handleSelectStatus}
                label="Status"
                selectIconProps={{ fontSize: 'medium' }}
                formControlProps={{ sx: { width: '20%' } }}
              />
            </div>
          ) : (
            <QRow>
              <QCol xs="2">
                <QLabel style={{ paddingTop: '5px' }}>
                  <strong>{i18n.t('License Type')}</strong>
                </QLabel>
                <QSelect
                  size="small"
                  options={licenseTypeData}
                  value={
                    mastV2 ? selectedLicenseType?.value : selectedLicenseType
                  }
                  onChange={handleSelectLicenseType}
                  label="License Type"
                  selectIconProps={{ fontSize: 'medium' }}
                  formControlProps={{ sx: { width: '100%' } }}
                />
              </QCol>
              <QCol xs="2">
                <QLabel style={{ paddingTop: '5px' }}>
                  <strong>{i18n.t('Status')}</strong>
                </QLabel>
                <QSelect
                  size="small"
                  options={statusOptions}
                  value={mastV2 ? selectedStatus?.value : selectedStatus}
                  onChange={handleSelectStatus}
                  label="Status"
                  selectIconProps={{ fontSize: 'medium' }}
                  formControlProps={{ sx: { width: '100%' } }}
                />
              </QCol>
            </QRow>
          )}
          <QDataTable
            striped
            showPageSize
            data={filteredLicensesData}
            onRowClick={row => {
              setType('UPDATE');
              setSelectedLicense(row);
              toggle();
            }}
            columns={[
              {
                label: 'License ID',
                keyName: 'id',
                filterable: true,
                sortable: true,
              },
              {
                label: 'Group',
                keyName: 'userGroup',
                component: ({ value }) => value.name,
                filterable: true,
                filterFunc: (filterStr, { userGroup }) =>
                  userGroup.name
                    .toLowerCase()
                    .includes(filterStr.toLowerCase()),
              },
              {
                label: 'License Type',
                keyName: 'licenseType',
                component: ({ value }) => {
                  if (value) {
                    return value.name;
                  }

                  return null;
                },
              },
              {
                label: 'Max Unique Apps',
                keyName: 'maxUniqueApps',
              },
              {
                label: 'Number of Top Apps',
                keyName: 'numOfTopApps',
              },
              {
                label: 'Max Number of Scans',
                keyName: 'maxScans',
              },
              {
                label: 'Max Group Users',
                keyName: 'maxGroupUsers',
              },
              {
                label: 'Start Date',
                keyName: 'startDate',
                component: ({ value }) => {
                  if (value) {
                    return moment(value).format('L LTS');
                  }

                  return null;
                },
              },
              {
                label: 'End Date',
                keyName: 'endDate',
                component: ({ value }) => {
                  if (value) {
                    return moment(value).format('L LTS');
                  }

                  return null;
                },
              },
            ]}
          />
        </QCardBody>
      </QCard>
    </>
  );
}
