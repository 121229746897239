import CisaMainLogo from './cisa_logo_verbose.png';
import CisaLoginLogo from './MAV.png';
import CisaSideLogo from './CISA Logo Color PNG.png';

export const cisaAsset = {
  logo: {
    main: CisaMainLogo,
    side: CisaSideLogo,
    login: CisaLoginLogo,
    fav: CisaSideLogo,
    banner: CisaMainLogo,
  },
  fonts: [],
  theme: {
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover, &.hover': {
              backgroundColor: '#EEEEEE',
            },
            '&:focus': {
              backgroundColor: '#EEEEEE',
            },
            '&.Mui-selected': {
              backgroundColor: '#EEEEEE',
            },
          },
        },
      },
    },
  },
  palette: {
    primary: { main: '#003e67' },
    accent: { main: '#003e67' },
    text: { secondary: '#003e67' },
    greyOverride: { 100: '#f5f8fa' },
  },
};
