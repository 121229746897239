import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ExpandMore } from '@mui/icons-material';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';
import _ from 'lodash';

import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';
import { QButton } from '../Q-Components/QButton';
import { QUncontrolledButtonDropdown } from '../Q-Components/QUncontrolledButtonDropdown';
import { QDropdownToggle } from '../Q-Components/QDropdownToggle';
import { QDropdownMenu } from '../Q-Components/QDropdownMenu';
import { QDropdownSelectItem } from '../Q-Components/QDropdownSelectItem';
import { QMenuButtonWithIcon } from '../Q-Components/QMenuButtonWithIcon';
import { QActionSelect } from '../Q-Components/QActionSelect';

import EditAppDetails from './EditAppDetails';
import { ModifyEidModal } from './ModifyEidModal';
import { ChangeOwnershipModal } from './ChangeOwnershipModal';
import { AddToWatchlistConfirmationModal } from './AddToWatchlistConfirmationModal';

const AppActions = ({
  app,
  removeApp,
  getApps,
  resubmitApp,
  labelOptions,
  pageNum,
}) => {
  const { userAccess } = useSelector(state => state.emmApp);
  const customizedEid = useSelector(
    state => state.emmApp.userConfig.customized_eid,
  );
  const [ownerOpen, setOwnerOpen] = useState(false);
  const [eidOpen, setEidOpen] = useState(false);
  const [showChangeOwnershipModal, setShowChangeOwnershipModal] =
    useState(false);

  const [showAddToWatchlistModal, setShowAddToWatchlistModal] = useState(false);

  const { mastV2 } = useFlags();

  const handleFocus = event => {
    event.target.style.background = 'none';
  };

  const regeneratePdfReport = async () => {
    try {
      await axios.post(`analyzed-apps/${app.id}/regenerate-pdf`);
      toastrHelper.showSuccessToast(
        'Successfully issued request to regenerate PDF report. You will receive an email once the report is ready.',
        null,
        mastV2,
      );
    } catch (err) {
      console.log('Error regenerating PDF report:', err);
      toastrHelper.showErrorToast(
        'Error sending request to regenerate PDF report',
        null,
        mastV2,
      );
    }
  };
  const appActionOptions = [
    ...(userAccess.change_sub_group_ownership_of_apps
      ? [
          {
            label: i18n.t('Change App Ownership'),
            value: 'change-ownership',
            icon: <i className="far fa-user purple-icon" />,
            onClick: () => setShowChangeOwnershipModal(true),
          },
        ]
      : []),
    {
      label: app.pdf_report_generated
        ? i18n.t('Regenerate PDF report')
        : i18n.t('Generate PDF report'),
      value: 'regenerate-pdf',
      icon: <i className="fas fa-file-pdf fa-lg" />,
      onClick: regeneratePdfReport,
    },
    {
      label: i18n.t('Edit App Details'),
      value: 'edit-app-details',
      icon: <i className="far fa-arrow-square-right fa-lg primary-icon" />,
      onClick: () => setOwnerOpen(true),
    },
    ...(app.belongsToUser || userAccess.group_admin
      ? [
          {
            label: i18n.t('Resubmit'),
            value: 'resubmit',
            icon: <i className="fas fa-sync-alt primary-icon" />,
            onClick: () => resubmitApp(app.id),
            id: `resubmit-btn-app-${app.id}`,
          },
        ]
      : []),
    {
      label: i18n.t('All Versions'),
      value: 'all-versions',
      icon: <i className="far fa-code-branch black-icon" />,
      link: `/analyzed-app/${app.id}/all-versions`,
    },
    ...((app.belongsToUser || userAccess.group_admin) &&
    app.submissionTag === 'Manual - App Store Submission'
      ? [
          {
            label: i18n.t('Add To Watchlist'),
            value: 'add-to-watchlist',
            icon: <i className="far fa-file-plus success-icon" />,
            onClick: () => setShowAddToWatchlistModal(true),
          },
        ]
      : []),
    ...(userAccess.change_app_external_id
      ? [
          {
            label: `Modify ${customizedEid || 'EID'}`,
            value: 'modify-eid',
            icon: <i className="far fa-id-card success-icon" />,
            onClick: () => setEidOpen(true),
          },
        ]
      : []),
    ...(userAccess.group_admin || userAccess.can_delete_apps
      ? [
          {
            label: i18n.t('Delete App'),
            value: 'delete-app',
            icon: <i className="far fa-times fa-lg text-error danger-icon" />,
            onClick: () => removeApp(app.id),
          },
        ]
      : []),
  ];

  return (
    <>
      <EditAppDetails
        app={app}
        analyzedAppId={app.id}
        reviewStatus={app.review_status}
        note={app.app_note}
        appLabels={app.labels?.map(item => {
          const label = { value: item.label, label: _.startCase(item.label) };
          return label;
        })}
        labelOptions={labelOptions}
        pageNum={pageNum}
        getApps={getApps}
        toggle={() => setOwnerOpen(!ownerOpen)}
        open={ownerOpen}
      />

      <ModifyEidModal
        isOpen={eidOpen}
        toggle={() => setEidOpen(!eidOpen)}
        initEid={app.external_id}
        getApps={getApps}
        analyzedAppId={app.id}
      />

      <ChangeOwnershipModal
        isOpen={showChangeOwnershipModal}
        toggle={() => setShowChangeOwnershipModal(!showChangeOwnershipModal)}
        analyzedAppId={app.id}
        analyzedAppUserId={app.user_id}
        getApps={getApps}
      />

      <AddToWatchlistConfirmationModal
        isOpen={showAddToWatchlistModal}
        toggle={() => setShowAddToWatchlistModal(!showAddToWatchlistModal)}
        app={app}
      />

      {mastV2 ? (
        <QActionSelect actionOptions={appActionOptions} />
      ) : (
        <QUncontrolledButtonDropdown
          variant="filled"
          style={{
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
          className="mx-1"
          title="Actions"
          dropdown
          sx={{ width: '140px' }}
        >
          <QDropdownToggle
            caret
            className="toggledown-primary"
            size="sm"
            disableAnimation
            shrink={false}
            style={
              mastV2
                ? {
                    transform: 'none',
                    overflow: 'visible',
                  }
                : {}
            }
          >
            <QButton
              variant="outlined"
              endIcon={<ExpandMore />}
              className="button-toggledown-primary"
            >
              Actions
            </QButton>
          </QDropdownToggle>
          <QDropdownMenu
            caret
            variant="filled"
            IconComponent={null}
            value=""
            displayEmpty
            style={
              mastV2
                ? {
                    background: 'none',
                    paddingTop: '0px',
                    minWidth: 'unset',
                  }
                : {}
            }
            renderValue={() => {}}
            onFocus={handleFocus}
          >
            {/* First item is always selected in MUI by default so this is to get rid of that behavior. */}
            <QDropdownSelectItem value="" style={{ display: 'none' }} />

            {userAccess.change_sub_group_ownership_of_apps && (
              <QDropdownSelectItem
                onClick={() => setShowChangeOwnershipModal(true)}
              >
                <QMenuButtonWithIcon
                  icon={<i className="far fa-user purple-icon" />}
                  label={i18n.t('Change App Ownership')}
                />
              </QDropdownSelectItem>
            )}

            <QDropdownSelectItem
              title="Regenerate PDF report"
              onClick={regeneratePdfReport}
            >
              <QMenuButtonWithIcon
                icon={<i className="fas fa-file-pdf fa-lg" />}
                label={
                  app.pdf_report_generated
                    ? i18n.t('Regenerate PDF report')
                    : i18n.t('Generate PDF report')
                }
              />
            </QDropdownSelectItem>

            <QDropdownSelectItem
              title="Change app status"
              onClick={() => setOwnerOpen(true)}
            >
              <QMenuButtonWithIcon
                icon={
                  <i className="far fa-arrow-square-right fa-lg primary-icon" />
                }
                label={i18n.t('Edit App Details')}
              />
            </QDropdownSelectItem>

            {(app.belongsToUser || userAccess.group_admin) && (
              <>
                <QDropdownSelectItem
                  color="primary"
                  onClick={() => resubmitApp(app.id)}
                  id={`resubmit-btn-app-${app.id}`}
                  title="Resubmit app for analysis"
                >
                  <QMenuButtonWithIcon
                    icon={<i className="fas fa-sync-alt primary-icon" />}
                    label={i18n.t('Resubmit')}
                  />
                </QDropdownSelectItem>
              </>
            )}

            {(app.belongsToUser || userAccess.group_admin) &&
              app.submissionTag === 'Manual - App Store Submission' && (
                <>
                  <QDropdownSelectItem
                    color="primary"
                    onClick={() => setShowAddToWatchlistModal(true)}
                    id={`addToWatchlist-btn-app-${app.id}`}
                    title="Add App To Watchlist"
                  >
                    <QMenuButtonWithIcon
                      icon={<i className="far fa-file-plus success-icon" />}
                      label={i18n.t('Add To Watchlist')}
                    />
                  </QDropdownSelectItem>
                </>
              )}

            <Link
              to={`/analyzed-app/${app.id}/all-versions`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <QDropdownSelectItem>
                <QMenuButtonWithIcon
                  icon={<i className="far fa-code-branch black-icon" />}
                  label={i18n.t('All Versions')}
                />
              </QDropdownSelectItem>
            </Link>

            {userAccess.change_app_external_id && (
              <QDropdownSelectItem
                title="Modify EID"
                onClick={() => setEidOpen(true)}
              >
                <QMenuButtonWithIcon
                  icon={<i className="far fa-id-card success-icon" />}
                  label={<>Modify {customizedEid || 'EID'}</>}
                />
              </QDropdownSelectItem>
            )}

            {(userAccess.group_admin || userAccess.can_delete_apps) && (
              <QDropdownSelectItem onClick={() => removeApp(app.id)}>
                <QMenuButtonWithIcon
                  icon={
                    <i className="far fa-times fa-lg text-error danger-icon" />
                  }
                  label={i18n.t('Delete App')}
                />
              </QDropdownSelectItem>
            )}
          </QDropdownMenu>
        </QUncontrolledButtonDropdown>
      )}
    </>
  );
};

export default withLDConsumer()(AppActions);
