import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

import { RiskBadge } from '../../RiskBadge';

export function DopplerDetailsModal({ isOpen, toggle, selectedItem }) {
  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      {selectedItem && (
        <>
          <ModalHeader>Code Path Analysis Item Details</ModalHeader>
          <ModalBody>
            <p className="bold">Type</p>
            <p>
              <RiskBadge risk={selectedItem.vulnerability.risk} />{' '}
              {selectedItem.vulnerability.impact}
            </p>
            <hr />

            <p>{selectedItem.vulnerability.description}</p>
            <hr />

            {/* CWEs */}
            <p>
              <strong>CWEs</strong>
            </p>
            <ul>
              {selectedItem.vulnerability.CWEs.map((cwe, index) => (
                <li key={index}>
                  <a href={cwe} target="_blank">
                    {cwe}
                  </a>
                </li>
              ))}
            </ul>
            <hr />

            {selectedItem.vulnerability.source && (
              <>
                <h4>Source</h4>
                <p className="dont-break-out">
                  {selectedItem.vulnerability.source}
                </p>
              </>
            )}

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <i className="fal fa-arrow-down fa-3x" />
            </div>

            {selectedItem.vulnerability.sink && (
              <>
                <h4>Sink</h4>
                <p className="dont-break-out">
                  {selectedItem.vulnerability.sink}
                </p>
              </>
            )}
          </ModalBody>
        </>
      )}
    </Modal>
  );
}
