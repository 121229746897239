import React from 'react';

import { QListGroup } from '../Q-Components/QListGroup';
import { QListGroupItem } from '../Q-Components/QListGroupItem';

const getFunctionalityIcon = functionality => {
  if (functionality === 'Microphone') {
    return 'fa fa-microphone';
  }
  if (functionality === 'Camera') {
    return 'fa fa-camera';
  }
  if (functionality === 'Bluetooth') {
    return 'fab fa-bluetooth';
  }
  if (functionality === 'Photos') {
    return 'fa fa-picture-o';
  }
  if (functionality === 'Location Services') {
    return 'fa fa-location-arrow';
  }
  if (functionality === 'Calendar') {
    return 'fa fa-calendar';
  }
  if (functionality === 'Face ID') {
    return 'fa fa-smile-o';
  }
  if (functionality === 'Contacts') {
    return 'fa fa-address-card';
  }
  if (functionality === 'Siri') {
    return 'fab fa-apple';
  }
  return 'fa fa-asterisk';
};

const VersionPermissions = ({
  platform,
  permissionsSelf,
  permissionsOther,
}) => {
  if (!permissionsSelf || !permissionsOther) {
    return <>Not available</>;
  }
  return (
    <div>
      <QListGroup
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxHeight: '200px',
          overflow: 'scroll',
        }}
      >
        {Object.entries(permissionsSelf).map(permission => {
          let found = false;
          for (let i = 0; i < Object.entries(permissionsOther).length; i++) {
            if (Object.entries(permissionsOther)[i][0] === permission[0]) {
              found = true;
              break;
            }
          }
          return (
            <QListGroupItem
              color={found ? '' : 'warning'}
              sx={found ? {} : { backgroundColor: '#FFB822' }}
            >
              <div>
                <div>
                  {platform === 'android' && (
                    <a className="no-link" href={permission[1]} target="_blank">
                      {permission[0]}
                    </a>
                  )}
                  {platform === 'ios' && (
                    <span>
                      <i className={getFunctionalityIcon(permission[0])} />{' '}
                      {permission[0]}
                      {' - '}
                      {typeof permission[1][0].description === 'object' &&
                        permission[1][1].description}
                      {typeof permission[1][0].description === 'string' &&
                        permission[1][0].description}
                    </span>
                  )}
                </div>
              </div>
            </QListGroupItem>
          );
        })}
      </QListGroup>
    </div>
  );
};

export default VersionPermissions;
