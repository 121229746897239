import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFlags } from 'launchdarkly-react-client-sdk';
import axios from 'axios';

import i18n from '../localization/i18n';
import { QModal } from '../components/Q-Components/QModal';
import { QModalTitle } from '../components/Q-Components/QModalTitle';
import { QModalContent } from '../components/Q-Components/QModalContent';
import { QModalActions } from '../components/Q-Components/QModalActions';
import { QTypography } from '../components/Q-Components/QTypography';
import { QButton } from '../components/Q-Components/QButton';
import { QInputGroup } from '../components/Q-Components/QInputGroup';
import { QInput } from '../components/Q-Components/QInput';
import { QInputGroupAddon } from '../components/Q-Components/QInputGroupAddon';
import { toastrHelper } from '../logic/toastrHelper';

export const ForgotPasswordModal = ({ open, toggle }) => {
  const { mastV2 } = useFlags();
  const [resetEmail, setResetEmail] = useState('');

  const submitPasswordResetRequest = async () => {
    try {
      const response = await axios.post('emm/forgot-password', {
        email: resetEmail,
      });
      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          i18n.t(
            'Password reset request received. Please check your email inbox',
          ),
          null,
          mastV2,
        );
      } else
        toastrHelper.showErrorToast(
          i18n.t('Error submitting password reset request'),
          i18n.t('Error'),
          mastV2,
        );
    } catch (err) {
      toastrHelper.showErrorToast(
        i18n.t('Error submitting password reset request'),
        i18n.t('Error'),
        mastV2,
      );
    } finally {
      toggle(false);
    }
  };

  return (
    <QModal
      open={open}
      isOpen={open}
      toggle={toggle}
      title={
        <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
          <QTypography variant="h3Medium">
            {i18n.t('Forgot your password?')}
          </QTypography>
        </QModalTitle>
      }
      content={
        <QModalContent>
          <div style={{ paddingBottom: '20px', paddingTop: '20px' }}>
            <QTypography
              variant="h5Regular"
              style={{
                color: '#000000',
              }}
            >
              {i18n.t(
                'Enter the email address associated with your account and we’ll email you a link to reset your password.',
              )}
            </QTypography>
          </div>
          <div>
            <QTypography variant="h5Medium">{i18n.t('Email')}</QTypography>
          </div>

          <QInputGroup>
            <QInput
              type="text"
              placeholder={i18n.t('name@email.com')}
              value={resetEmail}
              onChange={e => setResetEmail(e.target.value)}
              variant="outlined"
              data-testid="forgot-password-modal-email"
            />
          </QInputGroup>
        </QModalContent>
      }
      actions={
        <QModalActions>
          <QButton
            variant="outlined"
            onClick={toggle}
            sx={{ minWidth: '100px', marginRight: '24px' }}
            data-testid="forgot-password-modal-cancel"
          >
            {i18n.t('Cancel')}
          </QButton>
          <QButton
            color="warning"
            onClick={() => {
              submitPasswordResetRequest();
            }}
            variant="filled"
            data-testid="forgot-password-modal-send-link"
          >
            {i18n.t('Send link')}
          </QButton>
        </QModalActions>
      }
    >
      {!mastV2 && (
        <>
          <QModalTitle>
            <QTypography variant="h5Regular">
              {i18n.t('Forgotten Password')}
            </QTypography>
          </QModalTitle>
          <QModalContent>
            <div>{i18n.t('Forgot your password?')}</div>
            <br />
            <div>{i18n.t('Enter in your email below')}:</div>
            <hr />
            <QInputGroup>
              <QInputGroupAddon addonType="prepend">
                <span className="input-group-text">
                  <i className="icon-user" />
                </span>
              </QInputGroupAddon>
              <QInput
                type="text"
                placeholder={i18n.t('Email')}
                value={resetEmail}
                onChange={e => setResetEmail(e.target.value)}
              />
            </QInputGroup>
          </QModalContent>
          <QModalActions>
            <QButton
              color="warning"
              onClick={() => {
                submitPasswordResetRequest();
              }}
            >
              <i className="fas fa-share" /> {i18n.t('Submit Request')}
            </QButton>
          </QModalActions>
        </>
      )}
    </QModal>
  );
};
