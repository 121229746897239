const helpHtml = {
  analyzedApps: `
          <div class="test">
              <span>The Analyzed Applications table displays all applications that have been submitted and are done being analyzed.</span>
          </div>
      `,
  reportColumn: `
      <div class="test">
          <span>Here you can find the different types of analysis reports provided by Quokka's application analysis:</span>
          <br>
          <br>
          <p><strong>Quokka</strong></p>
          <span>The standard Quokka analysis report in both HTML and PDF formats. These reports represent our standard view of the application's analysis which show and highlighting a standard criteria of analysis issues found within the application.</span>
          <br>
          <br>
          <p><strong>NIAP</strong></p>
          <span>These reports show a mapping of our analysis to the <a href="http://common-criteria.rhcloud.com/application/output/application-release.html">NIAP mobile application protection profile</a>. </span>
          <br>
          <br>
          <p><strong>Other</strong></p>
          <ul>
              <li>Our analysis results exported as JSON</li>
              <li>The ability to remove the application from your analysis portal. If you are part of a group, you will only be able to remove applications that you have submitted yourself.</li>
          </ul>
      </div>
      `,
  appColumn: `
          <div class="test">
              <span>The App column displays information regarding the applications name, package name, application platform, as well as the app icon.</span>
          </div>
      `,
  appPopularity: `
    <div>
        <span>Shows the popularity of the application. For Android, this is represented by number of downloads. For iOS, since they do not provide download numbers, we show the number of reviews for the application.
    </div>
  `,
  analysisTime: `
    <div class="test"><span>The Analysis Time column displays the time of the app analysis performed</span></div>`,
  dateSubmitted: `
          <div class="test">
              <span>The Date Submitted column displays the time and date at which an application was submitted for analysis.</span>
          </div>
      `,
  threatScore: `
          <div class="test">
              <span>The Threat Score is a comprehensive value assigned to an application based on the vulnerabilities and flaws found during analysis.</span>
          </div>
      `,
  summaryResults: `
          <div class="test">
              <span>The Summary Results provide a quick overview of the issues found within an application during analysis.</span>
          </div>
      `,

  threatScoreChart: `
          <div class="test">
              <span>The Threat Score is a comprehensive value assigned to an application based on the vulnerabilities and flaws found during analysis. The graph shows threat scores that were calculated for the last ten app analyses.</span>
          </div>
      `,
  summaryResultsChart: `
      <div class="test">
          <span>The Summary Results provide a quick overview of the issues found within an application during analysis. The graph shows the issues found for the last ten app analyses.</span>
      </div>
      `,
  appSearch: `
          <div class="test">
              <span>Use this search box to lookup applications based on the application name, package name, or platform</span>
          </div>
      `,
  sortBy: `
          <div class="test">
              <span>Select a field by which the analyzed apps table will be sorted</span>
          </div>
      `,
  appPlatforms: `
          <div class="test">
              <span><Trans>Select an option to display both iOS and Android apps, Android apps only, or iOS apps only.</Trans></span>
          </div>
      `,
  binaryUpload: `
          <div class="test">
              <span>Choose a locally stored binary or application package file to submit for analysis.</span>
          </div>
      `,
  storeUrlSubmission: `
          <div class="test">
              <span>Submit a specific URL that points to an official mobile app store page for a particular application.</span>
          </div>
      `,
  bulkStoreSubmit: `
          <div class="test">
              <span>Use this option to input direct links to App store or Google Play store applications. Each new application must be 
              placed on its own line. When the bulk submit button is clicked, all listed applications will be submitted for analysis. </span>
          </div>
      `,
  storeSearch: `
          <div class="test">
              <span>Search the App store or Google Play store for an application you wish to submit for analysis.</span>
          </div>
      `,
  latestVersion: `
      <div class="test">
          <span>The version number for the latest release of the Watchlist App you are tracking.</span>
      </div>
      `,
  trackingSince: `
      <div class="test">
          <span>The time and day an application was added to your App Watchlist</span>
      </div>
      `,
  latestThreatScore: `
      <div class="test">
          <span>This column displays the threat score that was calculated when the latest version of the app was analyzed</span>
      </div>
      `,
  lastSubmitted: `
      <div class="test">
          <span>The time and date at which an application was last submitted for analysis.</span>
      </div>
      `,
  numVersions: `
      <div class="test">
          <span>The number of versions of this application that have been scanned while the application has been on your watchlist</span>
      </div>
      `,
  watchlistActions: `
      <div class="test">
          <span>Shows any available actions you can take on your watchlist app. The Red X will remove the application from your watchlist</span>
      </div>
      `,
  watchlistApps: `
      <div class="test">
          <span>The Watchlist Applications table displays all applications that you have added to your watchlist. These applications will be monitored,
          and automatically scanned and updated as new versions of them are released.</span>
      </div>
      `,
  customUserCredentials: `
    <div>
    These credentials will be sent along with the next application you submit for analysis. They will be used during the exercising of the application as credentials for any login screen encountered. 
    </div>
  `,
  threatScoreThreshold: `
  <p>This setting will set a Threat Score threshold for applications analyzed by Quokka. If an application exceeds this threshold, you will be notified via email.</p><p>The email address used in the "Alert Email" field will be used for sending out the alert when an analyzed app exceeds the threshold. This is useful for setting up email groups or lists to notify multiple people of the issue.</p>
`,
  sideLoadedAppNotifications: `
  <div>This setting will enable/disable email notifications for when a side loaded application is found on a synced MDM device.</div>
`,
  foreignConnection: `
    <div>This setting allows you to customize what is considered your home country. Any connections the applications makes during analysis to countries aside from this one will be marked as "Foreign Connections" in the analysis results.</div>
`,
  'android-has_in_app_purchases': `
      <p><strong>Issue</strong></p>
      <p>Has in-app purchases</p>
      <p><strong>Description</strong></p>
      <p>The application supports in-app purchases through the Google Play Store. The application gains access to in-app purchases through the Google Play Billing Library. This library is added as a dependency in the build.grade for the application and results in the com.android.vending.BILLING permission being added to the manifest on build.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Purchases can be made with the user's Google account. In-app purchases can be used to trick users into payments for superfluous reasons.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>The Google Play Billing library can be removed from the application's dependencies.</p>
      `,
  'android-accepts_all_certs': `
      <p><strong>Issue</strong></p>
      <p>Accepts all SSL certificates</p>
      <p><strong>Description</strong></p>
      <p>The application does not securely verify SSL certificates provided by server endpoints.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Communications over the network are insecure. Any sensitive data transmitted to or from this application could be intercepted, altered, or stolen. </p>
      <p><strong>Evidence</strong></p>
      <p>The application has one of two possible faults. The first is usage of a custom X509TrustManager that does not validate SSL certificates correctly. The location in the decompiled code can be found in the "Analysis Details" tab of the Quokka HTML report under "Coding Issues". The second possible fault is using the "ALLOW_ALL" hostname verifier. This can be found by searching the "Dynamic Analysis" table for "ALLOW_ALL".</p>
      <p><strong>Remediation</strong></p>
      <p>The platform provided TrustManager should be used when setting up the SSLContext. This can be done by leaving the parameter for the TrustManager set to null during initialization. More info can be found here https://developer.android.com/reference/javax/net/ssl/SSLContext. 

Usage of the "ALLOW_ALL" hostname verifier should be removed from the application. The default HostnameVerifier can be grabbed by using getDefaultHostnameVerifier. More info can be found here https://developer.android.com/reference/javax/net/ssl/HttpsURLConnection.html#getDefaultHostnameVerifier().

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-can_download_without_notification': `
      <p><strong>Issue</strong></p>
      <p>Can download without notification</p>
      <p><strong>Description</strong></p>
      <p>The application can download files without a notification being generated for the user to see. This functionality is allowed by the android.permission.DOWNLOAD_WITHOUT_NOTIFICATION permission.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Unknown and potentially unsafe files can be saved on the user's device without their knowledge. </p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.DOWNLOAD_WITHOUT_NOTIFICATION can be removed from the application's AndroidManifest.xml file to remove access.</p>
      `,
  'android-can_pair_bluetooth': `
      <p><strong>Issue</strong></p>
      <p>Can pair bluetooth devices</p>
      <p><strong>Description</strong></p>
      <p>The application can use bluetooth to connect to paired devices. This functionality is allowed by the android.permission.BLUETOOTH permission. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the user's paired bluetooth devices is granted with this permission. The application can see which devices are paired and connect to them. </p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.BLUETOOTH can be removed from the application's AndroidManifest.xml file to remove access.</p>
      `,
  'android-can_pair_bluetooth_special': `
      <p><strong>Issue</strong></p>
      <p>Can pair bluetooth without user</p>
      <p><strong>Description</strong></p>
      <p>The application can use bluetooth to pair and connect to new devices without user interaction. This functionality is allowed by the android.permission.BLUETOOTH_PRIVILEGED permission. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to all nearby bluetooth devices is granted with this permission. The application can search for nearby devices and initiate connection without user interaction. </p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.BLUETOOTH_PRIVILEGED can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-checks_root': `
      <p><strong>Issue</strong></p>
      <p>Checks for root access</p>
      <p><strong>Description</strong></p>
      <p>The application performs checks for root access on the device. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Checking for root access on a device is a good practice for any application looking to ensure it is running on a trusted platform.</p>
      <p><strong>Evidence</strong></p>
      <p>The checks can be viewed in the Quokka HTML Report under the "Analysis Details" tab. They are found in the "Dynamic Analysis" table and most often are command line checks for the SuperUser (SU) binary. E.g. "which su" or "type su".</p>
      <p><strong>Remediation</strong></p>
      <p>The checks for root access can be removed from the application code. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-debuggable': `
      <p><strong>Issue</strong></p>
      <p>Left as debuggable</p>
      <p><strong>Description</strong></p>
      <p>The application was left as debuggable. Debugging functionality is controlled by a flag in the application's AndroidManifest.xml file. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Debugging functionality gives access to application logs and other privileged details. This can expose sensitive information and allow for insight on an application's internal functions.</p>
      <p><strong>Evidence</strong></p>
      <p>To determine this finding Quokka scans the AndroidManifest.xml file for the flag "android:debuggable". If it is set to "true" then the app is debuggable.</p>
      <p><strong>Remediation</strong></p>
      <p>android:debuggable can be removed or explicitly set to "false" in the AndroidManifest.xml file.</p>
      `,
  'android-dynamically_loads_class': `
      <p><strong>Issue</strong></p>
      <p>Dynamically loads java classes</p>
      <p><strong>Description</strong></p>
      <p>The application loads java class(es) during runtime. Most often this is done to supplement code that is written by the developer with third party code. </p>
      <p><strong>Threat Profile</strong></p>
      <p>If the developer does not verify the security and validity of the dynamically loaded code it is possible it could introduce vulnerabilities, flaws, or unintended functionality in the application.</p>
      <p><strong>Evidence</strong></p>
      <p>The dynamically loaded classes can be found in the Quokka HTML report under the "Analysis Details" tab. They are located in the Dynamic Analysis table under the "Classes Loaded" category.</p>
      <p><strong>Remediation</strong></p>
      <p>The classes loaded can be verified before loading. This can be done through usage of hashes such as SHA-256 to confirm the class hasn't been modified. 

In some cases the functionality from the class can be included in the application's own code, if it is desired to remove the loading of the classes the calls can be removed from the application's code.

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-hard_coded_credentials': `
      <p><strong>Issue</strong></p>
      <p>Contains hard-coded credentials</p>
      <p><strong>Description</strong></p>
      <p>The application contains hard-coded cryptographic key(s). Cryptographic keys should be generated securely during runtime and not hard-coded in the application.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Data encrypted or decrypted with hard-coded cryptographic keys is vulnerable to exposure. Attackers could gain access to the key(s) by extracting them from the application.</p>
      <p><strong>Evidence</strong></p>
      <p>The hard-coded cryptographic keys can be found in the Quokka HTML report under the "Analysis Details" tab. They are located in the "Coding Issues" section. If they are observed during runtime a separate finding "Uses hard-coded credentials" will be flagged.</p>
      <p><strong>Remediation</strong></p>
      <p>The hard-coded credentials should be removed from the application and replaced with securely generated cryptographic keys. More information can be found here https://developer.android.com/reference/javax/crypto/KeyGenerator.

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-has_extra_permissions': `
      <p><strong>Issue</strong></p>
      <p>Has extra permissions</p>
      <p><strong>Description</strong></p>
      <p>The application has access to more permissions than it likely needs. This is determined by mapping the requested permissions in the AndroidManifest.xml file to the constants and methods seen inside the application.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Extra permissions increase the attack surface on the device if an application is exploited via another attack vector. Additionally it is a poor development practice to request functionality that is not needed for execution.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>The permissions can be reviewed to confirm which ones are needed for the core functionality of the application. Extra permissions can be removed from the AndroidManifest.xml file to remove access.</p>
      `,
  'android-loads_library': `
      <p><strong>Issue</strong></p>
      <p>Loads third party library</p>
      <p><strong>Description</strong></p>
      <p>The application loads third party library(s) during runtime. Most often this is done to supplement code that is written by the developer with third party code. </p>
      <p><strong>Threat Profile</strong></p>
      <p>If the developer does not verify the security and validity of the dynamically loaded library it is possible it could introduce vulnerabilities, flaws, or unintended functionality in the application.</p>
      <p><strong>Evidence</strong></p>
      <p>The dynamically loaded libraries can be found in the Quokka HTML report under the "Analysis Details" tab. They are located in the Dynamic Analysis table under the "Libraries Loaded" category.</p>
      <p><strong>Remediation</strong></p>
      <p>The libraries loaded can be verified before loading. This can be done through usage of hashes such as SHA-256 to confirm the class hasn't been modified. 

In some cases the functionality from the library can be included in the application's own code, if it is desired to remove the loading of the libraries the calls can be removed from the application's code.

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-may_accept_all_certs': `
      <p><strong>Issue</strong></p>
      <p>Contains code to bypass SSL</p>
      <p><strong>Description</strong></p>
      <p>The application contains code to bypass SSL certificate verification. </p>
      <p><strong>Threat Profile</strong></p>
      <p>If this functionality is used communications over the network are insecure. Any sensitive data transmitted to or from this application could be intercepted, altered, or stolen.</p>
      <p><strong>Evidence</strong></p>
      <p>The application contains a custom X509TrustManager that does not validate SSL certificates correctly. The location in the decompiled code can be found in the "Analysis Details" tab of the Quokka HTML report under "Coding Issues". If this functionality is observed during runtime a separate issue "Accepts all SSL certificates" will be flagged.</p>
      <p><strong>Remediation</strong></p>
      <p>The platform provided TrustManager should be used when setting up the SSLContext. This can be done by leaving the parameter for the TrustManager set to null during initialization. More info can be found here https://developer.android.com/reference/javax/net/ssl/SSLContext. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-non_private_mode': `
      <p><strong>Issue</strong></p>
      <p>Stores files insecurely</p>
      <p><strong>Description</strong></p>
      <p>The application stores files using insecure parameters. The parameter "MODE_WORLD_READABLE" or "MODE_WORLD_WRITEABLE" is used instead of "MODE_PRIVATE". Files created with these flags allow other applications and users on the device to access them.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Any data stored in files saved with these flags can be read or written by other applications or users on the device without special privileges. If sensitive data is stored in these files it is exposed to any other software running on the device and is not protected by the default privacy provided by application containers.</p>
      <p><strong>Evidence</strong></p>
      <p>The insecure file writes are located in the Quokka HTML report under the "Analysis Details" tab. They are located in the "Coding Issues" table with a reference to the line of code the problem was observed. 

Evidence can also be located in the "Dynamic Analysis" table with usage of the functions "setReadable", "setWriteable", or "setExecutable".</p>
      <p><strong>Remediation</strong></p>
      <p>The MODE_PRIVATE flag should be used instead of MODE_WORLD_READABLE or MODE_WORLD_WRITABLE. The correct integer value for the flag is 0 (MODE_PRIVATE) instead of 1(MODE_WORLD_READABLE) or 2(MODE_WORLD_WRITEABLE). 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-non_standard_sql': `
      <p><strong>Issue</strong></p>
      <p>Vulnerable to SQL injection</p>
      <p><strong>Description</strong></p>
      <p>The application is vulnerable to a SQL injection attack on a local database because there is a raw string parameter included with a SQL query. This does not apply to CREATE or PRAGMA queries as it is standard to issue raw string queries for these operations.</p>
      <p><strong>Threat Profile</strong></p>
      <p>SQL injection allows for attackers to modify the intended query to the database. Typical attacks include truncating the intended query and adding a secondary query to pull, taint, or modify data that is usually inaccessible. These type of attacks can expose sensitive data, change the behavior of the app, allow for easier reverse engineering, and more.</p>
      <p><strong>Evidence</strong></p>
      <p>The potential SQL Injection methods can be found in the Quokka HTML report under the "Analysis Details" tab. They are located in the "Dynamic Analysis" table and can be easily found by searching for "SQL" and/or "USER_INPUT" which denotes a raw user input string in the SQL query.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should pre-process the user input string in order to strip out any truncating characters (e.g. ';') and ensure there are no additional SQL commands or query additions that are unwanted.

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-password_exposed': `
      <p><strong>Issue</strong></p>
      <p>User password exposed</p>
      <p><strong>Description</strong></p>
      <p>The application possibly exposes a user password through the network, local device storage, or logs. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Exposed passwords can be used to access secure accounts of the application and/or network server. If the password is re-used across other sensitive accounts an attacker could access additional sensitive functionality and data. </p>
      <p><strong>Evidence</strong></p>
      <p>The password exposed can be found in one of two main places, Network Info & Analysis Details tabs of the Quokka HTML report. 

In the Network Info tab scroll down to the "Network Connections" table showing all of the captured network packets. Perform a search for "pass" & "password" to identify if the information was exposed over the network.

In the Analysis Details tab scroll down to the "Dynamic Analysis" table. Perform a search for "pass" & "password" to identify if the password is seen inside any secure storage or logging method calls.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should not send raw password strings over the network or to device storage/logs. Network connections should be secured by TLS v1.2 over HTTPS at a minimum to ensure the password cannot be intercepted in plain text. The developer can also utilize hashing or encryption to provide an extra layer of protection to passwords. </p>
      `,
  'android-uses_hard_coded_credentials': `
      <p><strong>Issue</strong></p>
      <p>Uses hard-coded credentials</p>
      <p><strong>Description</strong></p>
      <p>The application uses hard-coded encryption keys when encrypting data. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Encryption should always be performed with keys that are random or as close to random as possible. Randomly generated keys make reversing encryption operations much more difficult. Hard-coded encryption keys allow for easy reversing of encryption operations as an attacker already has the credentials. Attackers that are able to locate the hard-coded key can use it to reverse any operation and gain access to the previously encrypted data. </p>
      <p><strong>Evidence</strong></p>
      <p>The hard-coded cryptographic keys can be found in the Quokka HTML report under the "Analysis Details" tab. They are located in the "Coding Issues" section. The keys used during runtime can be found in the "Dynamic Analysis" table. To locate the calls simply search for the encryption key found in the "Coding Issues" section in the Dynamic Analysis table. </p>
      <p><strong>Remediation</strong></p>
      <p>The hard-coded credentials should be removed from the application and replaced with securely generated cryptographic keys. More information can be found here https://developer.android.com/reference/javax/crypto/KeyGenerator.

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-takes_picture': `
      <p><strong>Issue</strong></p>
      <p>Can access Camera</p>
      <p><strong>Description</strong></p>
      <p>The application can access all camera features on the device. This functionality is allowed by the android.permission.CAMERA permission.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the camera on a device allows an application to take pictures, record video, and view a live output of the camera view. Applications should be reviewed carefully to determine if they need access to these features to support their core functionality. Application developer and country of origin should also be taken into account when reviewing if the application should be run with this access.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.CAMERA can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_access_internet': `
      <p><strong>Issue</strong></p>
      <p>Can access Internet</p>
      <p><strong>Description</strong></p>
      <p>The application can access open network sockets on the device. This functionality is allowed by the android.permission.INTERNET permission.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the internet should be considered normal for any application that has a backend infrastructure. This should only be considered a risk if the application purports to only save data locally on the device.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.INTERNET can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-links_with_social_network': `
      <p><strong>Issue</strong></p>
      <p>Integrates Social Network</p>
      <p><strong>Description</strong></p>
      <p>The application connects to at least one social networking service.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Integration with social networks is common in consumer applications and should be considered normal. For business applications that may contain sensitive data connections to social networks should be reviewed for the data shared and purpose.</p>
      <p><strong>Evidence</strong></p>
      <p>The connections can be reviewed in the Quokka HTML report under the Network Info tab. The Network Connections table shows all captured network packets and can be searched for common social networking connections such as Facebook, Twitter, Instagram, LinkedIn, etc. Data sent to these locations are in the "Parameters" and "Headers" of the network packet.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer may remove any social network related SDKs from their application and perform string searches for social network URLs that may be in their own code base. </p>
      `,
  'android-pii_exposure': `
      <p><strong>Issue</strong></p>
      <p>Exposes Sensitive Information</p>
      <p><strong>Description</strong></p>
      <p>The application sends sensitive information over the network. Sensitive information is any data that can be used to identify the device and/or user.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Sensitive information should be carefully reviewed to ensure it is only shared when absolutely necessary. Quokka highlights ALL sensitive information sent, including those to Google, & the app developer to ensure the user is aware of all connections. Sharing of device metadata and unique user IDs (advertising IDs, generic user identifiers, etc) can be used in conjunction with other collected data to track users and identify them based off what seems to be innocuous information. </p>
      <p><strong>Evidence</strong></p>
      <p>Sensitive information exposed is shown in the Quokka HTML report under the "Analysis Details" tab. If seen the table is at the top of the tab under the heading "Sensitive Data Exposure". Users can also perform searches for any strings they are interested in under the "Network Info" tab at the "Network Connections" table to see the full packet information.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should review the URL of the social network connection to identify which domain the data is sent to. Using the URL and domain information they can then identify the entity/sdk the connection comes from and search their codebase for the origin of the network connection. The exact location in the code where the connection is initiated can also be found in the "Dynamic Analysis" table in the "Network events" category. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-possible_foreign_connection': `
      <p><strong>Issue</strong></p>
      <p>Connects to Servers Worldwide</p>
      <p><strong>Description</strong></p>
      <p>The application connects to servers located outside of your designated home country. By default this is set to the United States and can be modified in the "Settings" menu for your user account under "Analysis" settings.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Connections to other countries can be important for many regulations and application standards, one prominent example is GDPR in the EU. Evaluators should consider what level of access and information the app has and look at which countries are connected to in order to determine the threat profile. </p>
      <p><strong>Evidence</strong></p>
      <p>The list of countries and geo location information can be found in the Quokka HTML report in the "Network Info" tab. There is a map containing the geolocation information for the domain/IPs as well as a high level list of countries that are contacted.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should review the URL of the foreign connections to identify which domain the data is sent to. Using the URL and domain information they can then identify the entity/sdk the connection comes from and search their codebase for the origin of the network connection. The exact location in the code where the connection is initiated can also be found in the "Dynamic Analysis" table in the "Network events" category. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-uses_ad_network': `
      <p><strong>Issue</strong></p>
      <p>Integrates Ad Network</p>
      <p><strong>Description</strong></p>
      <p>The application integrates with an advertising network.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Integration with advertising networks allows for tracking the user based off advertising identifiers and device metadata. This information can be combined with other datasets in the backend to identify user behavior, preferences, locations, and more. Special attention should be given to advertisers based in un-trusted countries.  </p>
      <p><strong>Evidence</strong></p>
      <p>The connections can be reviewed in the Quokka HTML report under the Network Info tab. The Network Connections table shows all captured network packets and can be searched for common advertising network connections such as Facebook, Admob, DUApps, DoubleClick, etc. Data sent to these locations are in the "Parameters" and "Headers" of the network packet.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should review the URL of the advertising connection to identify which domain the data is sent to. Using the URL and domain information they can then identify the entity/sdk the connection comes from and search their codebase for the origin of the network connection. The exact location in the code where the connection is initiated can also be found in the "Dynamic Analysis" table in the "Network events" category. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-can_access_bluetooth': `
      <p><strong>Issue</strong></p>
      <p>Can access bluetooth</p>
      <p><strong>Description</strong></p>
      <p>The application can discover and pair bluetooth devices. This functionality is allowed by the android.permission.BLUETOOTH_ADMIN permission.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to all nearby bluetooth devices is granted with this permission. The application can search for nearby devices and initiate connection.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.BLUETOOTH_ADMIN can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_access_coarse_location': `
      <p><strong>Issue</strong></p>
      <p>Can access coarse location</p>
      <p><strong>Description</strong></p>
      <p>The application can access the approximate location of the device based on WiFi and Network data. This functionality is allowed by the android.permission.ACCESS_COARSE_LOCATION permission.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the approximate location of the user will give the application a general idea of where the device is located. The precision accuracy is approximately 2km, typically this allows the app to see where the user is down to a city and/or city district level. </p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.ACCESS_COARSE_LOCATION can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_access_fine_location': `
      <p><strong>Issue</strong></p>
      <p>Can access fine location</p>
      <p><strong>Description</strong></p>
      <p>The application can access the exact location of the device based on GPS data. This functionality is allowed by the android.permission.ACCESS_FINE_LOCATION permission.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the fine location of the user will give the application exact details of where the device is located. The precision accuracy is approximately 3-5m, typically this allows the app to see where the user is down to a specific address or building. </p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.ACCESS_FINE_LOCATION can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-nfc_access': `
      <p><strong>Issue</strong></p>
      <p>Can access NFC</p>
      <p><strong>Description</strong></p>
      <p>The application can perform I/O operations over NFC. This functionality is allowed by the android.permission.NFC permission.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the NFC functionality allows the app to communicate with any NFC device that is nearby to the device. This can include transmission of information to other NFC enabled mobile phones or NFC storage chips. The range for NFC data transfer is approximately 4cm.  </p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.NFC can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_call_number': `
      <p><strong>Issue</strong></p>
      <p>Can call outside numbers</p>
      <p><strong>Description</strong></p>
      <p>The application can initiate a phone call without going through the Dialer user interface for the user to confirm the call. This functionality is allowed by the android.permission.CALL_PHONE permission.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to this functionality should be carefully vetted for need in the application's context. This permission allows calling without the user confirming the call. This could be used to spoof phone calls, initiate calls to paid calling services to fraudulently charge the user, and more. </p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.CALL_PHONE can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_get_accounts': `
      <p><strong>Issue</strong></p>
      <p>Can get user accounts</p>
      <p><strong>Description</strong></p>
      <p>The application can access the list of accounts in the Accounts Service. This functionality is allowed by the permission GET_ACCOUNTS_PRIVILEGED.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to user accounts on the device can give an application information about which other apps are installed on the device. The app gains access to an array of the accounts registered with the device including the creator, name, and type of account. </p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.GET_ACCOUNTS_PRIVILEGED can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_read_calendar': `
      <p><strong>Issue</strong></p>
      <p>Can read calendar</p>
      <p><strong>Description</strong></p>
      <p>The application can read the user's calendar data. This functionality is allowed by the permission.READ_CALENDAR.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the user's calendar data should be considered very dangerous. The application has no restrictions for the type of events it can view data for and could gather contact details, locations, and more based off this access. </p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.READ_CALENDAR can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_read_contacts': `
      <p><strong>Issue</strong></p>
      <p>Can read contacts</p>
      <p><strong>Description</strong></p>
      <p>The application can read the user's contacts data. This functionality is allowed by the permission.READ_CONTACTS.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the user's contacts data should be considered very dangerous. The application has no restrictions for the type of contacts it can view data for and could gather contact details, locations, and more based off this access. </p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.READ_CONTACTS can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_read_external_storage': `
      <p><strong>Issue</strong></p>
      <p>Can read external storage</p>
      <p><strong>Description</strong></p>
      <p>The application can read the external storage on the device. This functionality is allowed by the permission.READ_EXTERNAL_STORAGE.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the device's external storage allows the application to view any public files the user has saved, downloaded, or that were created by other applications. Application should be vetted to ensure it can be trusted with access to the user's downloads directory and various application files.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.READ_EXTERNAL_STORAGE can be removed from the application's AndroidManifest.xml file to remove access. Note that any application that has the related permission android.permission.WRITE_EXTERNAL_STORAGE will be given access to this permission by default and this must be removed from the AndroidManifest.xml as well.</p>
      `,
  'android-can_read_sms': `
      <p><strong>Issue</strong></p>
      <p>Can read SMS</p>
      <p><strong>Description</strong></p>
      <p>The application can read the user's SMS messages. This functionality is allowed by the permission.READ_SMS.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to read the user's SMS messages should be considered dangerous. There is no restriction for the SMS threads the app has access to enabling the application to get the full text of every SMS message on the device.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.READ_SMS can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_receive_mms': `
      <p><strong>Issue</strong></p>
      <p>Can receive MMS</p>
      <p><strong>Description</strong></p>
      <p>The application can monitor incoming MMS messages. This functionality is allowed by the permission.RECEIVE_MMS.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to receive the user's MMS messages should be considered dangerous. There is no restriction for the MMS threads the app has access to enabling the application to get the full text of every MMS message received on the device.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.RECEIVE_MMS can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_receive_sms': `
      <p><strong>Issue</strong></p>
      <p>Can receive SMS</p>
      <p><strong>Description</strong></p>
      <p>The application can monitor incoming SMS messages. This functionality is allowed by the permission.RECEIVE_SMS.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to receive the user's SMS messages should be considered dangerous. There is no restriction for the SMS threads the app has access to enabling the application to get the full text of every SMS message received on the device.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.RECEIVE_SMS can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_record_audio': `
      <p><strong>Issue</strong></p>
      <p>Can record audio</p>
      <p><strong>Description</strong></p>
      <p>The application can record audio on the device. This functionality is allowed by the permission.RECORD_AUDIO.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to record audio on the device should be considered dangerous. The application has the added capability to record audio in the background prior to Android 9. Applications should be reviewed carefully to determine if they need access to these features to support their core functionality. Application developer and country of origin should also be taken into account when reviewing if the application should be run with this access.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.RECORD_AUDIO can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_send_sms': `
      <p><strong>Issue</strong></p>
      <p>Can send SMS</p>
      <p><strong>Description</strong></p>
      <p>The application can send SMS messages. This functionality is allowed by the permission.SEND_SMS.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to send SMS messages should be considered dangerous. The application can send content of the apps choosing to any SMS enabled number. This could be used for fraudulent communication and paid SMS services.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.SEND_SMS can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_write_calendar': `
      <p><strong>Issue</strong></p>
      <p>Can write to calendar</p>
      <p><strong>Description</strong></p>
      <p>The application can write to the user's calendar. This functionality is allowed by the permission.WRITE_CALENDAR.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to write user's calendar data should be considered dangerous. The application has no restrictions for the type of events it can create and could trick the user with fake calendar events or modifications to existing calendar events.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.WRITE_CALENDAR can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_write_contacts': `
      <p><strong>Issue</strong></p>
      <p>Can write to contacts</p>
      <p><strong>Description</strong></p>
      <p>The application can write to the user's contacts. This functionality is allowed by the permission.WRITE_CONTACTS.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to write user's contacts data should be considered dangerous. The application has no restrictions for the type of contacts it can create and could create fake contacts to trick the user or modify existing contact details.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.WRITE_CONTACTS can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_write_external_storage': `
      <p><strong>Issue</strong></p>
      <p>Can write to external storage</p>
      <p><strong>Description</strong></p>
      <p>The application can write to the external storage on the device. This functionality is allowed by the permission.WRITE_EXTERNAL_STORAGE and this permission implicitly gives access to android.permission.READ_EXTERNAL_STORAGE.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the device's external storage allows the application to view and modify any public files the user has saved, downloaded, or that were created by other applications. Application should be vetted to ensure it can be trusted with access to the user's downloads directory and various application files.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.WRITE_EXTERNAL_STORAGE can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-gets_device_id': `
      <p><strong>Issue</strong></p>
      <p>Gets device ID</p>
      <p><strong>Description</strong></p>
      <p>The application reads the IMEI (International Mobile Equipment Identity) of the device. This functionality is detected by usage of the getImei() method in the TelephonyManager class.</p>
      <p><strong>Threat Profile</strong></p>
      <p>The IMEI of the device is a unique ID that remains unchanged throughout the device's life. This ID allows identification and tracking of the specific hardware the application is installed on.</p>
      <p><strong>Evidence</strong></p>
      <p>The calls to getImei() can be found in the "Analysis Details" tab of the Quokka HTML report in the "Dynamic Analysis" table. These calls can be specifically searched for or found in the "Telephony" category.</p>
      <p><strong>Remediation</strong></p>
      <p>The calls to getImei() can be removed from the application's code. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-gets_phone_number': `
      <p><strong>Issue</strong></p>
      <p>Gets phone number</p>
      <p><strong>Description</strong></p>
      <p>The application reads the phone number of the device. This functionality is detected by usage of the getLine1Number() method in the TelephonyManager class.</p>
      <p><strong>Threat Profile</strong></p>
      <p>The phone number of the device allows identification and tracking of the user across services and can be used for spam, phishing attacks, or 2FA attacks.</p>
      <p><strong>Evidence</strong></p>
      <p>The calls to getLine1Number() can be found in the "Analysis Details" tab of the Quokka HTML report in the "Dynamic Analysis" table. These calls can be specifically searched for or found in the "Telephony" category.</p>
      <p><strong>Remediation</strong></p>
      <p>The calls to getLine1Number() can be removed from the application's code. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-gets_sim_serial_number': `
      <p><strong>Issue</strong></p>
      <p>Gets SIM serial number</p>
      <p><strong>Description</strong></p>
      <p>The application reads the serial number of the SIM card on the device. This functionality is detected by usage of the getSimSerialNumber() method in the TelephonyManager class.</p>
      <p><strong>Threat Profile</strong></p>
      <p>The SIM serial number allows identification and tracking of the user across services and identification of the user's cellular provider.</p>
      <p><strong>Evidence</strong></p>
      <p>The calls to getSimSerialNumber() can be found in the "Analysis Details" tab of the Quokka HTML report in the "Dynamic Analysis" table. These calls can be specifically searched for or found in the "Telephony" category.</p>
      <p><strong>Remediation</strong></p>
      <p>The calls to getSimSerialNumber() can be removed from the application's code. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-gets_subscriber_id': `
      <p><strong>Issue</strong></p>
      <p>Gets subscriber ID</p>
      <p><strong>Description</strong></p>
      <p>The application reads the subscriber ID of the device, typically the IMSI (International Mobile Subscriber Identity). The IMSI is a number that uniquely identifies every user of a cellular network.  This functionality is detected by usage of the getSubscriberId() method in the TelephonyManager class.</p>
      <p><strong>Threat Profile</strong></p>
      <p>The subscriber ID allows the identification and tracking of the user and identification of the user's cellular provider.</p>
      <p><strong>Evidence</strong></p>
      <p>The calls to getSubscriberId() can be found in the "Analysis Details" tab of the Quokka HTML report in the "Dynamic Analysis" table. These calls can be specifically searched for or found in the "Telephony" category.</p>
      <p><strong>Remediation</strong></p>
      <p>The calls to getSubscriberId() can be removed from the application's code. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-records_audio': `
      <p><strong>Issue</strong></p>
      <p>Records audio</p>
      <p><strong>Description</strong></p>
      <p>The application records audio on the device. This functionality is allowed by the permission.RECORD_AUDIO and is seen during dynamic analysis through use of the MediaRecorder class.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to record audio on the device should be considered dangerous. The application has the added capability to record audio in the background prior to Android 9.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Search for usage of the MediaRecorder class to see where audio recording is configured and initiated. </p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.RECORD_AUDIO can be removed from the application's AndroidManifest.xml file to remove access. Alternatively the calls to the MediaRecorder class can be removed from the application's code. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-records_video': `
      <p><strong>Issue</strong></p>
      <p>Records video</p>
      <p><strong>Description</strong></p>
      <p>The application records video on the device. This functionality is allowed by the android.permission.CAMERA permission and is seen during dynamic analysis through the use of the MediaRecorder class.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the camera on a device allows an application to take pictures, record video, and view a live output of the camera view. Applications should be reviewed carefully to determine if they need access to these features to support their core functionality. Application developer and country of origin should also be taken into account when reviewing if the application should be run with this access.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Search for usage of the MediaRecorder class to see where video recording is configured and initiated. </p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.CAMERA can be removed from the application's AndroidManifest.xml file to remove access. Alternatively the calls to the MediaRecorder class can be removed from the application's code. </p>
      `,
  'android-uses_cloud_service': `
      <p><strong>Issue</strong></p>
      <p>Integrates cloud service</p>
      <p><strong>Description</strong></p>
      <p>The application integrates with a cloud storage service.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Integration with cloud storage services enables storage of user files on the cloud. The type of data accessed by the app should be considered when assessing the risk of this capability. If there is sensitive data access cloud storage with third parties could be dangerous.</p>
      <p><strong>Evidence</strong></p>
      <p>The connections can be reviewed in the Quokka HTML report under the Network Info tab. The Network Connections table shows all captured network packets and can be searched for common cloud storage provider connections such as Microsoft, DropBox, Google, etc. Data sent to these locations are in the "Parameters" and "Headers" of the network packet.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should review the URL of the cloud storage connection to identify which domain the data is sent to. Using the URL and domain information they can then identify the entity/sdk the connection comes from and search their codebase for the origin of the network connection. The exact location in the code where the connection is initiated can also be found in the "Dynamic Analysis" table in the "Network events" category. 

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-admin_privs': `
      <p><strong>Issue</strong></p>
      <p>Has device admin privileges</p>
      <p><strong>Description</strong></p>
      <p>The application requests device administrator access. This functionality is allowed by either the android.permission.ACCESS_SUPERUSER permission or an intent sent with a parameter of android.app.action.ADD_DEVICE_ADMIN.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Device administrator access should only be allowed on trusted applications used to manage the deployment of mobile devices in an organization. This permission should not be given to any third-party or in-house application for any other reason.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Kryptowire HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.
 
      Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.
     
      The intents can be viewed in the "Analysis Details" tab on the "Dynamic Analysis" table.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.ACCESS_SUPERUSER can be removed from the application's AndroidManifest.xml file and the parameter android.app.action.ADD_DEVICE_ADMIN can be removed from any intents.</p>
      `,
  'android-can_kill_background': `
      <p><strong>Issue</strong></p>
      <p>Can kill background processes</p>
      <p><strong>Description</strong></p>
      <p>The application can kill background processes on the device. This functionality is allowed by the android.permission.KILL_BACKGROUND_PROCESSES permission.</p>
      <p><strong>Threat Profile</strong></p>
      <p>The application can use this permission to kill background processes of other applications on the device. This could be used as a denial of service against other installed applications.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.KILL_BACKGROUND_PROCESSES can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-can_show_alert': `
      <p><strong>Issue</strong></p>
      <p>Can show windows over UI</p>
      <p><strong>Description</strong></p>
      <p>The application can show windows on top of all other applications. This functionality is allowed by the android.permission.SYSTEM_ALERT_WINDOW permission. </p>
      <p><strong>Threat Profile</strong></p>
      <p>This functionality is often used to spam and advertise to the user outside of the application's context. Ads can be shown over any other application and homescreen of the app. This functionality should be closely vetted for need before approval.</p>
      <p><strong>Evidence</strong></p>
      <p>The permissions for the application can be viewed in the Quokka HTML report under the "Permission Usage" tab. The mappings for constants and methods can be seen on the same tab underneath the listed permissions.

Usage of these permissions during dynamic analysis can be found in the "Analysis Details" tab under the "Dynamic Analysis" table. Searching for applicable API calls listed in the mapping table or browsing through the listed categories can be helpful to locate calls.</p>
      <p><strong>Remediation</strong></p>
      <p>android.permission.SYSTEM_ALERT_WINDOW can be removed from the application's AndroidManifest.xml file to remove access. </p>
      `,
  'android-malware_detected': `
      <p><strong>Issue</strong></p>
      <p>Malware detected</p>
      <p><strong>Description</strong></p>
      <p>The application or a contained file matches a known malware signature. </p>
      <p><strong>Threat Profile</strong></p>
      <p>This application should not be installed on any device for any reason. Malware is present in the app.</p>
      <p><strong>Evidence</strong></p>
      <p>Please contact Quokka for the specific malware signature that was flagged in the application. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer should contact Quokka directly to determine how to remediate the application.</p>
      `,
  'android-privilege_escalation': `
      <p><strong>Issue</strong></p>
      <p>Attempts to gain root access</p>
      <p><strong>Description</strong></p>
      <p>The application attempts to gain root access on the device. It attempts to run the substitute user (su) binary and/or to gain a root shell. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Root access allows the application to gain full control of a rooted device. Applications do not need root access for any legitimate functions and should not be installed if they attempt to access it.</p>
      <p><strong>Evidence</strong></p>
      <p>The calls can be viewed in the Quokka HTML Report under the "Analysis Details" tab. They are found in the "Dynamic Analysis" table and most often are command line attempted uses of the substitute user (su) binary. E.g. "su" or "sudo [command]".</p>
      <p><strong>Remediation</strong></p>
      <p>The attempts to root access can be removed from the application code. </p>

The class path can be inferred from the "Smali file" heading for the related call. E.g. com/example/files would relate to the com.example.files class.</p>
      `,
  'android-data_at_rest_encryption': `
      <p><strong>Issue</strong></p>
      <p>Data at rest encryption</p>
      <p><strong>Description</strong></p>
      <p>The application encrypts data on the device. We monitor for encryption operations during the Dynamic Analysis of the application.</p>
      <p><strong>Threat Profile</strong></p>
      <p>If any sensitive data is handled by the application it is good practice to encrypt this data when stored locally. If the encryption operations are not seen it may be easier for attackers to exploit the information handled by this app.</p>
      <p><strong>Evidence</strong></p>
      <p>The encryption operations can be found in the Quokka HTML Report under the "Analysis Details" tab. They are found in the "Dynamic Analysis" table in the "Cryptography" category.</p>
      <p><strong>Remediation</strong></p>
      <p>The attempts to root access can be removed from the application code.</p>
      `,
  'android-data_in_transit_encryption': `
      <p><strong>Issue</strong></p>
      <p>Data in transit encryption</p>
      <p><strong>Description</strong></p>
      <p>The application uses encrypted network connections. We monitor for encrypted network connections during the Dynamic Analysis of the application.</p>
      <p><strong>Threat Profile</strong></p>
      <p>If any sensitive data is exchanged over the network through the application it is essential to use encrypted network communications. If the application does not use encrypted connections the app should not have access to any sensitive functionality or data.  </p>
      <p><strong>Evidence</strong></p>
      <p>The connections can be reviewed in the Quokka HTML report under the Network Info tab. The Network Connections table shows all captured network packets and the associated security protocol used by the connection. HTTP for plain text (un-encrypted) connections, and most often TLS 1.2 for encrypted connections.</p>
      <p><strong>Remediation</strong></p>
      <p>"The developer should use encryption for any network connections. This is most often achieved through HTTPS using TLS 1.2 and SSL certificates. More information can be found here, https://developer.android.com/training/articles/security-ssl."</p>
      `,
  'ios-can_use_bluetooth': `
      <p><strong>Issue</strong></p>
      <p>Can use bluetooth</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application requires the Core Bluetooth framework for accessing the Bluetooth functionality of the device. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to all nearby bluetooth devices is granted with this permission. The application can search for nearby devices and initiate connection. </p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-executes_external_library': `
      <p><strong>Issue</strong></p>
      <p>Executes third party library</p>
      <p><strong>Description</strong></p>
      <p>All execution is monitored during Dynamic Analysis for loading of an external library. </p>
      <p><strong>Threat Profile</strong></p>
      <p>If the developer does not verify the security and validity of the dynamically loaded library it is possible it could introduce vulnerabilities, flaws, or unintended functionality in the application.</p>
      <p><strong>Evidence</strong></p>
      <p>The third party libraries can be found in the Quokka HTML report under the "Analysis Details" tab in the "Dynamically Loaded Libraries & Frameworks" table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the code referencing the third party library from the application. This can be located by searching the code base for the name of the third party library and reviewing any libraries packaged with the app.  </p>
      `,
  'ios-hard_coded_iv': `
      <p><strong>Issue</strong></p>
      <p>Hard-Coded IV</p>
      <p><strong>Description</strong></p>
      <p>All execution is monitored during Dynamic Analysis for cryptographic operations. All operations are logged with provided Initialization Vectors (IV)s and hard-coded IVs are flagged.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Encryption should always be performed with IVs that are random or as close to random as possible. Randomly generated IVs make reversing encryption operations much more difficult. Hard-coded IVs allow for easy reversing of encryption operations, attackers that are able to locate the hard-coded IV can use it to help reverse any operation and gain access to the previously encrypted data. </p>
      <p><strong>Evidence</strong></p>
      <p>The cryptographic operations with hard-coded IVs can be found in the Quokka HTML report under the "Analysis Details" tab in the "Cryptographic Operations" table. Hard-coded values are highlighted in red for easy identification.</p>
      <p><strong>Remediation</strong></p>
      <p>The hard-coded IVs should be removed from the application and replaced with securely generated IVs. 

More information can be found here https://developer.apple.com/documentation/security/randomization_services.</p>
      `,
  'ios-hard_coded_key': `
      <p><strong>Issue</strong></p>
      <p>Hard-Coded Encryption Key</p>
      <p><strong>Description</strong></p>
      <p>All execution is monitored during Dynamic Analysis for cryptographic operations. All operations are logged with provided encryption keys and hard-coded keys are flagged.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Encryption should always be performed with keys that are random or as close to random as possible. Randomly generated keys make reversing encryption operations much more difficult. Hard-coded encryption keys allow for easy reversing of encryption operations as an attacker already has the credentials. Attackers that are able to locate the hard-coded key can use it to reverse any operation and gain access to the previously encrypted data. </p>
      <p><strong>Evidence</strong></p>
      <p>The cryptographic operations with hard-coded keys can be found in the Quokka HTML report under the "Analysis Details" tab in the "Cryptographic Operations" table. Hard-coded values are highlighted in red for easy identification.</p>
      <p><strong>Remediation</strong></p>
      <p>The hard-coded keys should be removed from the application and replaced with securely generated cryptographic keys. More information can be found here https://developer.apple.com/documentation/security/certificate_key_and_trust_services/keys/generating_new_cryptographic_keys. </p>
      `,
  'ios-has_in_app_purchases': `
      <p><strong>Issue</strong></p>
      <p>Has in-app purchases</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application requires the StoreKit framework for accessing in-app purchases functionality.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Purchases can be made through the user's Apple account. In-app purchases can be used to trick users into payments for superfluous reasons. </p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-offers_apple_watch_app_iphone': `
      <p><strong>Issue</strong></p>
      <p>Integrates Apple Watch</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application requires the WatchKit or WatchConnectivity frameworks for integrating with the Apple Watch.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Apple Watch integration allows communication and display of data on the watch. The transmission of data occurs through a Bluetooth connection to the watch and any data transferred is subject to the security of the Bluetooth protocol.</p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-can_access_address_book': `
      <p><strong>Issue</strong></p>
      <p>Can access address book</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application uses the AddressBook or Contacts frameworks for accessing the Address Book or Contacts on the device. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the user's contacts data should be considered very dangerous. The application has no restrictions for the type of contacts it can view data for and could gather contact details, locations, and more based off this access. </p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-can_access_calendar': `
      <p><strong>Issue</strong></p>
      <p>Can access calendar</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application uses the EventKit framework for accessing the calendar and reminders on the device. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the user's calendar data should be considered very dangerous. The application has no restrictions for the type of events it can view data for and could gather contact details, locations, and more based off this access. </p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-can_access_camera': `
      <p><strong>Issue</strong></p>
      <p>Can access camera</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application uses the AVFoundation framework for accessing the camera on the device. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the camera on a device allows an application to take pictures, record video, and view a live output of the camera view. Applications should be reviewed carefully to determine if they need access to these features to support their core functionality. Application developer and country of origin should also be taken into account when reviewing if the application should be run with this access.</p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-can_access_internet': `
      <p><strong>Issue</strong></p>
      <p>Can access internet</p>
      <p><strong>Description</strong></p>
      <p>All execution is monitored during Dynamic Analysis for network traffic. If any network traffic is seen, this issue is flagged.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the internet should be considered normal for any application that has a backend infrastructure. This should only be considered a risk if the application purports to not have any internet connectivity.</p>
      <p><strong>Evidence</strong></p>
      <p>The network traffic can be found in the Quokka HTML report under the "Network Info" tab. Full network capture details can also be seen in the "Network Traffic" table.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove any network calls initiated by the application by searching for methods like NSURLConnection.</p>
      `,
  'ios-can_access_location': `
      <p><strong>Issue</strong></p>
      <p>Can access location</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application uses the Core Location framework for accessing the user's location from the GPS on the device. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the location of the user will give the application exact details of where the device is located. The app has the choice of getting the most accurate measurements possible (0-3m) or updates when the user changes location based on 10m, 100m, 1km, or 3km deltas. Additionally the framework will allow estimates for general location information, e.g. Home, Work, Gym and estimates of the floor of a building the user is in. </p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-can_access_microphone': `
      <p><strong>Issue</strong></p>
      <p>Can access microphone</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application uses the AVFoundation, AudioToolbox or ReplayKit frameworks for accessing the microphone on the device. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to record audio on the device should be considered dangerous. Applications should be reviewed carefully to determine if they need access to these features to support their core functionality. Application developer and country of origin should also be taken into account when reviewing if the application should be run with this access.</p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-can_access_shared_libraries': `
      <p><strong>Issue</strong></p>
      <p>Can access shared libraries</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application uses the AssetsLibrary or PhotoKit frameworks for accessing the photos and videos on the device. </p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the user's photo and video library should be considered sensitive. Any and all user photos stored in this library can be accessed by the application.</p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-can_interact_email_client': `
      <p><strong>Issue</strong></p>
      <p>Can interact with email client</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application uses the MessageUI framework for sending email messages through the app.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to this framework allows the app to pre-populate an email message with a destination address, subject, content, and attachments. This functionality could be used to trick the user into sending messages without reviewing content but does require user interaction to finalize. </p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-can_interact_sms_mms': `
      <p><strong>Issue</strong></p>
      <p>Can interact with SMS/MMS</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application requires the MessageUI framework for sending SMS messages through the app.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to this framework allows the app to pre-populate an SMS message with a destination number and message contents. This functionality could be used to trick the user into sending messages without reviewing content but does require user interaction to finalize. </p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-can_modify_files': `
      <p><strong>Issue</strong></p>
      <p>Can modify files</p>
      <p><strong>Description</strong></p>
      <p>All execution is monitored during Dynamic Analysis for NSFileManager operations. All fwrite operations are logged with the file name and path.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to modify files is limited to files in the application's private container and shared containers from the same developer. This functionality could be used to share information collected to another application with less privileged access.</p>
      <p><strong>Evidence</strong></p>
      <p>The files written can be found in the Quokka HTML report under the "Analysis Details" tab in the "File Access" table. Files read and write operations are displayed with the corresponding file path on the device.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer can look for NSFileManager operations, specifically fwrite operations inside their codebase. These can be trimmed from the application as needed.</p>
      `,
  'ios-can_read_files': `
      <p><strong>Issue</strong></p>
      <p>Can read files</p>
      <p><strong>Description</strong></p>
      <p>All execution is monitored during Dynamic Analysis for NSFileManager operations. All fopen operations are logged with the file name and path.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to read files is limited to files in the application's private container and shared containers from the same developer. This functionality could be used to pull information collected from another application with more privileged access.</p>
      <p><strong>Evidence</strong></p>
      <p>The files read can be found in the Quokka HTML report under the "Analysis Details" tab in the "File Access" table. Files read and write operations are displayed with the corresponding file path on the device.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer can look for NSFileManager operations, specifically fopen operations inside their codebase. These can be trimmed from the application as needed.</p>
      `,
  'ios-cloud_storage_integration': `
      <p><strong>Issue</strong></p>
      <p>Integrates cloud storage</p>
      <p><strong>Description</strong></p>
      <p>The application integrates with a cloud storage service.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Integration with cloud storage services enables storage of user files on the cloud. The type of data accessed by the app should be considered when assessing the risk of this capability. If there is sensitive data access cloud storage with third parties could be dangerous.</p>
      <p><strong>Evidence</strong></p>
      <p>The connections can be reviewed in the Quokka HTML report under the Network Info tab. The Network Connections table shows all captured network packets and can be searched for common cloud storage provider connections such as Microsoft, DropBox, Google, etc. Data sent to these locations are in the "Parameters" and "Headers" of the network packet.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should review the URL of the cloud storage connection to identify which domain the data is sent to. Using the URL and domain information they can then identify the entity/sdk the connection comes from and search their codebase for the origin of the network connection.</p>
      `,
  'ios-links_with_social_network': `
      <p><strong>Issue</strong></p>
      <p>Integrates social network</p>
      <p><strong>Description</strong></p>
      <p>The application connects to at least one social networking service.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Integration with social networks is common in consumer applications and should be considered normal. For business applications that may contain sensitive data connections to social networks should be reviewed for the data shared and purpose.</p>
      <p><strong>Evidence</strong></p>
      <p>The connections can be reviewed in the Quokka HTML report under the Network Info tab. The Network Connections table shows all captured network packets and can be searched for common social networking connections such as Facebook, Twitter, Instagram, LinkedIn, etc. Data sent to these locations are in the "Parameters" and "Headers" of the network packet.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer may remove any social network related SDKs from their application and perform string searches for social network URLs that may be in their own code base. </p>
      `,
  'ios-pii_exposure': `
      <p><strong>Issue</strong></p>
      <p>Exposes sensitive information</p>
      <p><strong>Description</strong></p>
      <p>The application sends sensitive information over the network. Sensitive information is any data that can be used to identify the device and/or user.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Sensitive information should be carefully reviewed to ensure it is only shared when absolutely necessary. Quokka highlights ALL sensitive information sent, including those to Apple, & the app developer to ensure the user is aware of all connections. Sharing of device metadata and unique user IDs (advertising IDs, generic user identifiers, etc) can be used in conjunction with other collected data to track users and identify them based off what seems to be innocuous information. </p>
      <p><strong>Evidence</strong></p>
      <p>Sensitive information exposed is shown in the Quokka HTML report under the "Analysis Details" tab. If seen the table is at the top of the tab under the heading "Sensitive Data Exposure". Users can also perform searches for any strings they are interested in under the "Network Info" tab at the "Network Connections" table to see the full packet information.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should review the URL of the social network connection to identify which domain the data is sent to. Using the URL and domain information they can then identify the entity/sdk the connection comes from and search their codebase for the origin of the network connection.</p>
      `,
  'ios-possible_foreign_connection': `
      <p><strong>Issue</strong></p>
      <p>Connects to servers worldwide</p>
      <p><strong>Description</strong></p>
      <p>The application connects to servers located outside of your designated home country. By default this is set to the United States and can be modified in the "Settings" menu for your user account under "Analysis" settings.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Connections to other countries can be important for many regulations and application standards, one prominent example is GDPR in the EU. Evaluators should consider what level of access and information the app has and look at which countries are connected to in order to determine the threat profile. </p>
      <p><strong>Evidence</strong></p>
      <p>The list of countries and geo location information can be found in the Quokka HTML report in the "Network Info" tab. There is a map containing the geo-location information for the domain/IPs as well as a high level list of countries that are contacted.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should review the URL of the foreign connections to identify which domain the data is sent to. Using the URL and domain information they can then identify the entity/sdk the connection comes from and search their codebase for the origin of the network connection.</p>
      `,
  'ios-supports_passbook': `
      <p><strong>Issue</strong></p>
      <p>Supports passbook</p>
      <p><strong>Description</strong></p>
      <p>We determine if the application requires the PassKit framework for interacting with the Apple Wallet.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Access to the Apple Wallet allows the app to request and process Apple Pay payments and create, update, and distribute passes for the Wallet app. Access to Apple Pay and Wallet should be considered privileged. Applications should be reviewed carefully to determine if they need access to these features to support their core functionality. Application developer and country of origin should also be taken into account when reviewing if the application should be run with this access.</p>
      <p><strong>Evidence</strong></p>
      <p>The System Frameworks packaged in the application can be found in the Quokka HTML report under the 'Metadata' tab in the 'System Frameworks Used' table. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer can remove the framework from the application binary. If the developer did not explicitly add the framework, they are often added during the build process from third party development tools or libraries.</p>
      `,
  'ios-uses_ad_network': `
      <p><strong>Issue</strong></p>
      <p>Integrates ad network</p>
      <p><strong>Description</strong></p>
      <p>The application integrates with an advertising network.</p>
      <p><strong>Threat Profile</strong></p>
      <p>Integration with advertising networks allows for tracking the user based off advertising identifiers and device metadata. This information can be combined with other datasets in the backend to identify user behavior, preferences, locations, and more. Special attention should be given to advertisers based in un-trusted countries.  </p>
      <p><strong>Evidence</strong></p>
      <p>The connections can be reviewed in the Quokka HTML report under the Network Info tab. The Network Connections table shows all captured network packets and can be searched for common advertising network connections such as Facebook, Admob, DUApps, DoubleClick, etc. Data sent to these locations are in the "Parameters" and "Headers" of the network packet.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should review the URL of the advertising connection to identify which domain the data is sent to. Using the URL and domain information they can then identify the entity/sdk the connection comes from and search their codebase for the origin of the network connection.</p>
      `,
  'ios-malware_detected': `
      <p><strong>Issue</strong></p>
      <p>Malware Detected</p>
      <p><strong>Description</strong></p>
      <p>The application or a contained file matches a known malware signature. </p>
      <p><strong>Threat Profile</strong></p>
      <p>This application should not be installed on any device for any reason. Malware is present in the app.</p>
      <p><strong>Evidence</strong></p>
      <p>Please contact Quokka for the specific malware signature that was flagged in the application. </p>
      <p><strong>Remediation</strong></p>
      <p>The developer should contact Quokka directly to determine how to remediate the application.</p>
      `,
  'ios-data_at_rest_encryption': `
      <p><strong>Issue</strong></p>
      <p>Data at rest encryption</p>
      <p><strong>Description</strong></p>
      <p>The application encrypts data on the device. We monitor for encryption operations during the Dynamic Analysis of the application and search the binary for cryptographic functions during static analysis.</p>
      <p><strong>Threat Profile</strong></p>
      <p>If any sensitive data is handled by the application it is good practice to encrypt this data when stored locally. If the encryption operations are not seen it may be easier for attackers to exploit the information handled by this app.</p>
      <p><strong>Evidence</strong></p>
      <p>The encryption operations can be found in the Quokka HTML Report under the "Analysis Details" tab. They are found in the "Cryptographic Operations" table. Any statically detected cryptographic functions will show in the "Cryptographic Static Analysis Functions" table.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should use encryption for storing sensitive data. 

More information can be found here, https://developer.apple.com/documentation/uikit/core_app/protecting_the_user_s_privacy/encrypting_your_app_s_files.</p>
      `,
  'ios-data_in_transit_encryption': `
      <p><strong>Issue</strong></p>
      <p>Data in transit encryption</p>
      <p><strong>Description</strong></p>
      <p>The application uses encrypted network connections. We monitor for encrypted network connections during the Dynamic Analysis of the application.</p>
      <p><strong>Threat Profile</strong></p>
      <p>If any sensitive data is exchanged over the network through the application it is essential to use encrypted network communications. If the application does not use encrypted connections the app should not have access to any sensitive functionality or data.  </p>
      <p><strong>Evidence</strong></p>
      <p>The connections can be reviewed in the Quokka HTML report under the Network Info tab. The Network Connections table shows all captured network packets and the associated security protocol used by the connection. HTTP for plain text (un-encrypted) connections, and most often TLS 1.2 for encrypted connections.</p>
      <p><strong>Remediation</strong></p>
      <p>The developer should use encryption for any network connections. This is most often achieved through HTTPS using TLS 1.2 and SSL certificates. 

More information can be found here, https://developer.apple.com/documentation/network.</p>
      `,
};
export default helpHtml;
