import auth from './auth';

// Various utility function used across components
export default {
  gtagReportConversion(url) {
    const callback = () => {
      if (typeof url !== 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      send_to: 'AW-796559322/QX3jCNzg4vYBENqP6vsC',
      event_callback: callback,
    });
    return false;
  },

  formatAppIssue(issue, mastV2 = false) {
    const exclamationIcon = mastV2 ? 'fa-exclamation-circle' : 'fa-exclamation';

    if (issue === 'can_read_sms') {
      return {
        iconClass: 'far fa-comment-alt issue-icon',
      };
    }
    if (issue === 'can_receive_sms') {
      return {
        iconClass: 'fas fa-comment-alt issue-icon',
      };
    }
    if (issue === 'debuggable') {
      return {
        iconClass: 'fas fa-bug issue-icon',
      };
    }
    if (issue === 'can_write_external_storage') {
      return {
        iconClass: 'far fa-folder issue-icon',
      };
    }
    if (issue === 'privilege_escalation') {
      return {
        iconClass: 'fas fa-cogs issue-icon',
      };
    }
    if (issue === 'tracking_analytics') {
      return {
        iconClass: 'fas fa-eye issue-icon',
      };
    }
    if (issue === 'can_read_contacts' || issue === 'can_access_address_book') {
      return {
        iconClass: 'fas fa-phone issue-icon',
      };
    }
    if (issue === 'has_extra_permissions') {
      return {
        iconClass: `fas ${exclamationIcon} issue-icon`,
      };
    }
    if (issue === 'can_download_without_notification') {
      return {
        iconClass: 'fas fa-download issue-icon',
      };
    }
    if (issue === 'can_get_fine_location' || issue === 'can_access_location') {
      return {
        iconClass: 'fas fa-location-arrow issue-icon',
      };
    }
    if (issue === 'loads_dynamic_code' || issue === 'dynamically_loads_class') {
      return {
        iconClass: 'fas fa-code issue-icon',
      };
    }
    if (issue === 'integrates_with_cloud_service') {
      return {
        iconClass: 'fas fa-cloud issue-icon',
      };
    }
    if (
      issue === 'integrates_with_social_network' ||
      issue === 'links_with_social_network'
    ) {
      return {
        iconClass: 'fas fa-group issue-icon',
      };
    }
    if (issue === 'pii_exposure') {
      return {
        iconClass: 'fas fa-user-secret issue-icon',
      };
    }
    if (issue === 'can_access_camera') {
      return {
        iconClass: 'fas fa-camera issue-icon',
      };
    }
    if (issue === 'gets_user_info' || issue === 'gets_device_id') {
      return {
        iconClass: 'fas fa-mobile issue-icon',
      };
    }
    if (issue === 'possible_foreign_connection') {
      return {
        iconClass: 'fas fa-globe issue-icon',
      };
    }
    if (issue === 'has_in_app_purchases') {
      return {
        iconClass: 'fas fa-usd-circle issue-icon',
      };
    }
    if (issue === 'can_access_shared_libraries') {
      return {
        iconClass: 'fas fa-camera issue-icon',
      };
    }
    if (issue === 'uses_ad_network') {
      return {
        iconClass: 'fas fa-chart-pie issue-icon',
      };
    }
    if (issue === 'can_interact_sms_mms' || issue === 'can_send_sms') {
      return {
        iconClass: 'fas fa-comment-dots issue-icon',
      };
    }
    if (issue === 'can_access_microphone' || issue === 'can_record_audio') {
      return {
        iconClass: 'fas fa-microphone issue-icon',
      };
    }
    if (
      issue === 'can_read_calendar' ||
      issue === 'can_write_calendar' ||
      issue === 'can_access_calendar'
    ) {
      return {
        iconClass: 'fas fa-calendar issue-icon',
      };
    }
    if (issue === 'admin_privs') {
      return {
        iconClass: `fas ${exclamationIcon} issue-icon`,
      };
    }
    if (issue === 'privilege_escalation') {
      return {
        iconClass: `fas ${exclamationIcon} issue-icon`,
      };
    }
    if (issue === 'malware_detected') {
      return {
        iconClass: 'fas fa-exclamation-triangle issue-icon',
      };
    }
    if (issue === 'accepts_all_certs') {
      return {
        iconClass: 'fas fa-certificate issue-icon',
      };
    }
    if (issue === 'non_standard_sql') {
      return {
        iconClass: 'fas fa-database issue-icon',
      };
    }
    if (issue === 'hard_coded_key') {
      return {
        iconClass: 'fas fa-key issue-icon',
      };
    }
    if (issue === 'can_access_bluetooth' || issue === 'can_pair_bluetooth') {
      return { iconClass: 'fab fa-bluetooth' };
    }
    if (issue === 'can_access_internet') {
      return { iconClass: 'far fa-wifi' };
    }
    if (issue === 'can_call_number') {
      return { iconClass: 'far fa-phone' };
    }
    if (issue === 'loads_library') {
      return { iconClass: 'far fa-books' };
    }
    if (issue === 'data_in_transit_encryption') {
      return { iconClass: 'far fa-lock' };
    }
    if (issue === 'can_interact_email_client') {
      return { iconClass: 'far fa-envelope' };
    }
    if (issue === 'can_modify_files') {
      return { iconClass: 'far fa-pencil' };
    }
    if (issue === 'can_read_files') {
      return { iconClass: 'far fa-search' };
    }
    return { iconClass: null };
  },

  // Return just the path to the smali file
  parseSmaliFile(smaliFile) {
    if (smaliFile.includes('/smali/')) return smaliFile.split('/smali/')[1];
    return smaliFile;
  },

  // Check if a string is JSON or not
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (err) {
      return false;
    }

    return true;
  },

  // Check to see if a string is a valid email address
  isValidEmail(str) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase());
  },

  // Capitalize the string
  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  // get substring if string is longer than a given length
  getSubstringIfNeeded(str, maxLength) {
    if (str.length < maxLength) {
      return str;
    }
    return `${str.substring(0, maxLength)}...`;
  },

  /**
   * Get the results for the given app
   * @param {*} farmApp
   * @param {*} analysisType
   */
  getResults(farmApp, analysisType, regeneratePDF = false) {
    let resultsUrl;
    if (farmApp.uuid) {
      resultsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/results/${analysisType}?auth=basic&uuid=${farmApp.uuid}&regeneratePDF=${regeneratePDF}`;
    } else {
      resultsUrl = `${process.env.REACT_APP_API_BASE_URL}/api/results/${farmApp.id}/${analysisType}?auth=basic`;
    }

    if (analysisType === 'pdf') {
      const locale =
        window.navigator.language || window.navigator.userLanguage || 'en-US';
      resultsUrl += `&locale=${locale}`;
    }

    window.open(resultsUrl, '_blank');
  },

  getQueryVariable(variable, queryString) {
    const query = queryString.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return false;
  },

  validateIp(ipStr) {
    const parts = ipStr.split('.');

    if (parts.length !== 4) {
      console.log('IP address does not have 4 parts');
      return { valid: false, msg: 'IP address does not have 4 octets' };
    }

    for (const part of parts) {
      const partNum = Number(part);
      if (Number.isNaN(partNum)) {
        console.log('Part of the ip is not a number:', part);
        return { valid: false, msg: `"${part}" is not a number` };
      }

      if (partNum < 0 || partNum > 255) {
        console.log('Part of the ip is not a valid number:', partNum);
        return { valid: false, msg: `"${partNum}" is not between 0 and 255` };
      }
    }

    return { valid: true };
  },

  validateSubnetMask(subnetMaskStr) {
    // 255, 254, 252, 248, 240, 224, 192, and 128.
    const parts = subnetMaskStr.split('.');

    if (parts.length !== 4) {
      console.log('Subnet mask does not have 4 parts');
      return { valid: false, msg: 'Subnet mask does not have 4 octets' };
    }

    for (const part of parts) {
      const partNum = Number(part);
      if (Number.isNaN(partNum)) {
        console.log('Part of the ip is not a number:', part);
        return { valid: false, msg: `"${part}" is not a number` };
      }
      const validNums = [255, 254, 252, 248, 240, 224, 192, 128, 0];

      if (!validNums.includes(partNum)) {
        console.log('Part of the subnet mask is not a valid number:', partNum);
        return {
          valid: false,
          msg: `"${partNum}" is not a valid octet for a subnet mask`,
        };
      }
    }

    return { valid: true };
  },
  paramsToUrl: (base, params) => {
    const queryParams = new URLSearchParams(params);
    queryParams.sort();
    return `${base}?${queryParams}`;
  },

  getUrlHost(url) {
    try {
      return new URL(url).host;
    } catch (err) {
      return url;
    }
  },

  validateEmail(email) {
    const emailRegex = /\S+@\S+\.\S+/;

    return email.match(emailRegex);
  },

  getLanguageFromFileName(fileName) {
    const ext = fileName.split('.').pop();
    if (ext === 'java' || ext === 'xml' || ext === 'json') {
      return ext;
    }
    if (ext === 'ts' || ext === 'tsx') {
      return 'typescript';
    }
    if (ext === '.js' || ext === 'jsx') {
      return 'javascript';
    }
    return '';
  },

  zendeskUrls: {
    default: 'https://kryptowire.zendesk.com/hc/en-us/requests',
    cisa: 'https://cisa-mav.zendesk.com/hc/en-us/requests',
  },

  documentationUrls: {
    default:
      'https://kryptowire.zendesk.com/hc/en-us/sections/360007786134-EMM-Documentation',
    cisa: 'https://cisa-mav.zendesk.com/hc/en-us',
  },

  brands: {
    default: 'Quokka',
    cisa: 'CISA',
  },
};
