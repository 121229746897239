import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { QTabContext } from '../Q-Components/QTabContext';
import { QTabLink } from '../Q-Components/QTabLink';
import { QTabList } from '../Q-Components/QTabList';
import { QTabContent } from '../Q-Components/QTabContent';
import { QTabPanel } from '../Q-Components/QTabPanel';
import { QTab } from '../Q-Components/QTab';
import { setPageTitle } from '../../store/slices/emmAppSlice';

import AllAppsTab from './AllAppsTab';
import PendingApprovalAppsTab from './PendingAppsTab';
import ApprovedAppsTab from './ApprovedAppsTab';
import DeniedAppsTab from './DeniedAppsTab';

export default function AppApprovals({ appApprovalEnabled, group }) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    dispatch(setPageTitle('App Analysis Approvals'));
  }, []);

  const handleChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  if (appApprovalEnabled) {
    return (
      <QTabContext value={activeTab}>
        <QTabList tabs onChange={handleChange} style={{ paddingTop: '50px' }}>
          <QTab label="Pending" value="1">
            <QTabLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => setActiveTab('1')}
            >
              Pending
            </QTabLink>
          </QTab>
          <QTab label="Approved" value="2">
            <QTabLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => setActiveTab('2')}
            >
              Approved
            </QTabLink>
          </QTab>
          <QTab label="Denied" value="3">
            <QTabLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => setActiveTab('3')}
            >
              Denied
            </QTabLink>
          </QTab>
          <QTab label="All" value="4">
            <QTabLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => setActiveTab('4')}
            >
              All
            </QTabLink>
          </QTab>
        </QTabList>
        <QTabContent activeTab={activeTab}>
          <QTabPanel tabId="1" value="1" sx={{ padding: '0px' }}>
            <PendingApprovalAppsTab
              isActive={activeTab === '1'}
              group={group}
            />
          </QTabPanel>
          <QTabPanel tabId="2" value="2" sx={{ padding: '0px' }}>
            <ApprovedAppsTab isActive={activeTab === '2'} group={group} />
          </QTabPanel>
          <QTabPanel tabId="3" value="3" sx={{ padding: '0px' }}>
            <DeniedAppsTab isActive={activeTab === '3'} group={group} />
          </QTabPanel>
          <QTabPanel tabId="4" value="4" sx={{ padding: '0px' }}>
            <AllAppsTab isActive={activeTab === '4'} group={group} />
          </QTabPanel>
        </QTabContent>
      </QTabContext>
    );
  }
  return <div> Not Authorized to view this page!</div>;
}
