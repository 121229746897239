import React, { useState, useEffect } from 'react';
import axios from 'axios';

import util from '../../logic/util';

import AppApprovalTab from './AppApprovalTab';

export default function AllAppsTab({ isActive, group }) {
  const [appsList, setAppsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchAllApps() {
    try {
      setIsLoading(true);
      const allAppsUrl = group
        ? `/adminV2/app-approval/apps/${group.id}`
        : `/app-approval/apps`;
      const response = await axios.get(allAppsUrl);
      setAppsList(
        response.data.map(app => ({
          developer: app.storeInfo?.developer ?? app.farmApp?.developer,
          createdAt: app.createdAt,
          requestedBy: app.requestedBy,
          status:
            app.status === 'preApproved'
              ? 'Pre-Approved'
              : util.capitalize(app.status),
          platform: app.platform,
          package: app.package,
          name: app.storeInfo?.name ?? app.farmApp?.name,
          iconUrl: app.storeInfo?.iconUrl ?? app.farmApp?.iconSmallUrl,
        })),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isActive) {
      fetchAllApps();
    }
  }, [isActive, group]);

  const fields = ['developer', 'createdAt', 'requestedBy', 'status'];
  const fieldNames = {
    developer: 'Developer',
    createdAt: 'Submitted On',
    requestedBy: 'Submitted By',
    status: 'Status',
  };

  return (
    <AppApprovalTab
      tabName="All Apps"
      fields={fields}
      data={appsList}
      columnDisplayNamesMapping={fieldNames}
      isLoading={isLoading}
    />
  );
}
