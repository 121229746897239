import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';

import DataTable from '../DataTable/DataTable';

export default function AvScanResults() {
  const avScanResults = useSelector(({ appResults }) => appResults.results.av);

  return (
    <Card className="card-accent-danger">
      <CardHeader>AV Scan Results</CardHeader>
      <CardBody>
        {!avScanResults || avScanResults.length === 0 ? (
          <span style={{ fontSize: '1rem' }}>
            <i className="fal fa-check-circle" /> Clean
          </span>
        ) : (
          <div>
            <DataTable
              data={avScanResults}
              columns={[
                {
                  label: 'Virus',
                  component: ({ value }) => (
                    <span>
                      <i className="fas fa-bug mr-2" />
                      {value.virus || 'N/A'}
                    </span>
                  ),
                },
                {
                  label: 'File',
                  component: ({ value }) => <span>{value.file || 'N/A'}</span>,
                },
              ]}
              showFilter={false}
            />
          </div>
        )}
      </CardBody>
    </Card>
  );
}
