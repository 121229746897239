import React from 'react';
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
} from 'reactstrap';

import { PasswordMeter, PasswordHint } from './PasswordMeter';

export const PasswordField = ({ label, id, innerRef, passwordHook }) => (
  <FormGroup>
    <div className="new-user-password__form__label-group">
      <Label hidden htmlFor={id} className="new-user-password__form__label">
        {label}
      </Label>
    </div>
    <PasswordHint {...passwordHook} />

    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <span className="input-group-text">
          <i className="fas fa-key" />
        </span>
      </InputGroupAddon>
      <Input
        type="password"
        innerRef={innerRef}
        id={id}
        placeholder={label}
        name={id}
        className="new-user-password__form__input"
      />
    </InputGroup>
    <PasswordMeter {...passwordHook} />
  </FormGroup>
);
