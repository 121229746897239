import React, { useEffect, useState } from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { QPopover } from '../../Q-Components/QPopover';
import { QPopoverBody } from '../../Q-Components/QPopoverBody';
import { QPopoverHeader } from '../../Q-Components/QPopoverHeader';
import { QLabel } from '../../Q-Components/QLabel';
import { QInput } from '../../Q-Components/QInput';
import { QButton } from '../../Q-Components/QButton';
import { QAutocomplete } from '../../Q-Components/QAutocomplete';
import { QTypography } from '../../Q-Components/QTypography';
import { QCol } from '../../Q-Components/QCol';
import { QRow } from '../../Q-Components/QRow';
import { QModal } from '../../Q-Components/QModal';
import { QModalTitle } from '../../Q-Components/QModalTitle';
import { QModalContent } from '../../Q-Components/QModalContent';
import { QModalActions } from '../../Q-Components/QModalActions';

const Title = () => (
  <QTypography variant="h3Medium">Create Sub Group</QTypography>
);

const Body = ({
  name,
  setName,
  kaiAccess,
  groupsOptions,
  group,
  handleGroupSelect,
}) => (
  <QCol>
    <QRow sx={{ width: '100%', mt: 1 }}>
      <QLabel htmlFor="new-sub-group-name">
        <QTypography variant="h5Medium">Name</QTypography>
      </QLabel>
    </QRow>
    <QRow sx={{ width: '100%', mt: 1 }}>
      <QInput
        value={name}
        onChange={e => setName(e.target.value)}
        placeholder="Name"
        id="new-sub-group-name"
        fullWidth
      />
    </QRow>
    <QRow sx={{ width: '100%', mt: 1 }}>
      <QLabel htmlFor="group-name">
        <QTypography variant="h5Medium">Group</QTypography>
      </QLabel>
    </QRow>
    <QRow sx={{ width: '100%', mt: 1 }}>
      <div style={{ width: '100%', margin: '0 auto' }}>
        <QAutocomplete
          sx={{ width: '100%' }}
          freeSolo
          disabled={!kaiAccess}
          isDisabled={!kaiAccess}
          options={groupsOptions}
          value={group ?? ''}
          disableClearable
          onChange={handleGroupSelect}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={option => option?.label}
          popupIcon={<ExpandMoreIcon htmlColor="#000000" />}
          renderOption={(props, option) => (
            <li {...props} style={{ wordWrap: 'break-word', display: 'block' }}>
              {option.label}
            </li>
          )}
          renderInput={params => (
            <QInput
              {...params}
              outlinedWithTitle
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: params.InputProps.endAdornment,
                startAdornment: params.InputProps.startAdornment,
                sx: {
                  '.MuiAutocomplete-endAdornment': {
                    position: 'absolute',
                  },
                },
                inputProps: params.inputProps,
                ref: params.InputProps.ref,
              }}
              style={{
                textOverflow: 'ellipsis',
                backgroundColor: '#FFFFFF',
              }}
            />
          )}
        />
      </div>
    </QRow>
  </QCol>
);

const Actions = ({
  mastV2,
  name,
  group,
  setName,
  setEditing,
  createSubGroup,
  onClose,
}) => (
  <>
    {mastV2 && (
      <QButton variant="outlined" color="secondary" onClick={onClose}>
        Cancel
      </QButton>
    )}
    <QButton
      className="mt-2"
      block
      onClick={() => {
        setName('');
        setEditing(false);
        createSubGroup(name, group);
      }}
      color="primary"
      disabled={!name}
      variant="filled"
    >
      {!mastV2 && <i className="far fa-save mr-1" />}Create
    </QButton>
  </>
);

const LDNewSubGroup = ({
  createSubGroup,
  kaiAccess = false,
  groupsOptions = [],
  currentGroup,
}) => {
  const { mastV2 } = useFlags();

  const [group, setGroup] = useState();
  const [name, setName] = useState('');
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (currentGroup) {
      setGroup(currentGroup);
    } else if (groupsOptions.length > 0) {
      setGroup(groupsOptions[0]);
    }
  }, [groupsOptions, currentGroup]);

  const handleGroupSelect = (event, newGroup) => {
    if (mastV2) {
      setGroup(newGroup);
    } else {
      setGroup(event);
    }
  };

  const toggle = () => setEditing(!editing);

  return (
    <div>
      {mastV2 ? (
        <>
          <div style={{ width: '100px' }}>
            <QModal
              isOpen={editing}
              open={editing}
              toggle={toggle}
              title={
                <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
                  <Title />
                </QModalTitle>
              }
              content={
                <QModalContent sx={{ padding: '16px 29px 29px 16px' }}>
                  <Body
                    name={name}
                    setName={setName}
                    kaiAccess={kaiAccess}
                    groupsOptions={groupsOptions}
                    group={group}
                    handleGroupSelect={handleGroupSelect}
                  />
                </QModalContent>
              }
              actions={
                <QModalActions sx={{ padding: '0px 32px 16px 0px' }}>
                  <Actions
                    name={name}
                    group={group}
                    setName={setName}
                    setEditing={setEditing}
                    createSubGroup={createSubGroup}
                    onClose={toggle}
                    mastV2={mastV2}
                  />
                </QModalActions>
              }
            />
            <i
              id="new-sub-group"
              data-testid="new-sub-group"
              className="fa-lg far fa-plus pointer d-flex justify-content-end"
              style={{ color: '#487F87' }}
              onClick={toggle}
            />
          </div>
        </>
      ) : (
        <div>
          <QPopover
            placement="left"
            isOpen={editing}
            target="new-sub-group"
            toggle={toggle}
          >
            <QPopoverHeader>
              <Title />
            </QPopoverHeader>
            <QPopoverBody>
              <div>
                <Body
                  name={name}
                  setName={setName}
                  kaiAccess={kaiAccess}
                  groupsOptions={groupsOptions}
                  group={group}
                  handleGroupSelect={handleGroupSelect}
                />
              </div>
              <div className="mt-2">
                <Actions
                  name={name}
                  group={group}
                  setName={setName}
                  setEditing={setEditing}
                  createSubGroup={createSubGroup}
                  onClose={toggle}
                  mastV2={mastV2}
                />
              </div>
            </QPopoverBody>
          </QPopover>
          <i
            id="new-sub-group"
            data-testid="new-sub-group"
            className="far fa-plus-circle pointer"
            onClick={toggle}
          />
        </div>
      )}
    </div>
  );
};

export const NewSubGroup = withLDConsumer()(LDNewSubGroup);
