import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import _ from 'lodash';
import { toCamel } from 'snake-camel';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QButton } from '../../Q-Components/QButton';
import { QSwitch } from '../../Q-Components/QSwitch';
import { QSwitchControlLabel } from '../../Q-Components/QSwitchControlLabel';
import { QCol } from '../../Q-Components/QCol';
import { QRow } from '../../Q-Components/QRow';
import { QInput } from '../../Q-Components/QInput';
import { EditableInput } from '../../EditableInput';
import { QAutocomplete } from '../../Q-Components/QAutocomplete';
import { QLabel } from '../../Q-Components/QLabel';
import Switch from '../../Switch';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { fetchGroupAdminGroup } from '../../../store/sliceHelpers/emmAppSliceHelper';
import { fetchKaiGroups } from '../../../store/sliceHelpers/kaiSliceHelper';
import { toastrHelper } from '../../../logic/toastrHelper';

import { NewSubGroup } from './NewSubGroup';
import { DefaultWhitelistIps } from './EditSubGroup/DefaultWhitelistIps';
import {
  getPermissionsConfig,
  checkSpecialPermissionVisibility,
  convertSubgroupPermissionToUserPermission,
  checkFeatureFlagPermissionVisibility,
} from './PermissionsConfig';

// eslint-disable-next-line no-unused-vars
const LDEditSubGroups = ({ kaiAccess = false }) => {
  const flags = useFlags();
  const { mastV2 } = flags;

  const [groupInputValue, setGroupInputValue] = useState('');
  const [group, setGroup] = useState();
  const [selectedSubGroup, setSelectedSubGroup] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showPermissionConfirm, setShowPermissionConfirm] = useState(false);
  const [attributes, setAttributes] = useState(null);

  const general = getPermissionsConfig().defaultGeneral;
  const analysis = getPermissionsConfig().defaultAnalysis;
  const reports = getPermissionsConfig().defaultReports;

  const dispatch = useDispatch();

  const groupAdminGroups = useSelector(state =>
    kaiAccess ? state.kai.kaiGroups : state.emmApp.groupAdminGroup,
  );

  const groupsOptions = groupAdminGroups.map(group => ({
    value: group.id,
    label: group.name,
  }));

  const groupAdminSubGroups = _.flatten(
    groupAdminGroups
      .filter(adminGroup => adminGroup.id === group?.value)
      .map(adminGroup => adminGroup.subGroups),
  );

  const adminUserAccess = toCamel(
    useSelector(({ emmApp }) => emmApp.userAccess),
  );

  useEffect(() => {
    setGroup(groupsOptions[0]);
  }, [groupsOptions.length > 0]);

  const getSubGroups = async () => {
    try {
      const groups = kaiAccess
        ? await dispatch(fetchKaiGroups())
        : await dispatch(fetchGroupAdminGroup());

      if (selectedSubGroup) {
        setSelectedSubGroup(
          groups
            .find(adminGroup => adminGroup.id === group?.value)
            .subGroups.find(subGroup => subGroup.id === selectedSubGroup.id),
        );
      }
    } catch (err) {
      console.log('Error getting sub groups:', err);
    }
  };

  useEffect(() => {
    getSubGroups();
  }, []);

  const createSubGroup = async (name, group) => {
    try {
      if (kaiAccess) {
        await axios.post('adminV2/sub-group', { name, groupId: group.value });
      } else {
        await axios.post('group-admin/sub-group', { name });
      }

      toastrHelper.showSuccessToast(
        `Successfully created sub group for group ${group.label}`,
        'Success',
        mastV2,
      );
    } catch (err) {
      console.log('Error creating sub group:', err);
      toastrHelper.showErrorToast(
        `Error creating sub group for group ${group.label}`,
        'Error',
        mastV2,
      );
    }

    getSubGroups();
  };

  const updateSubGroup = async attributes => {
    try {
      await axios.put(
        `group-admin/sub-group/${selectedSubGroup.id}`,
        attributes,
      );

      toastrHelper.showSuccessToast(
        `Successfully updated sub group for group ${group.label}`,
        'Success',
        mastV2,
      );
    } catch (err) {
      console.log('Error updating sub group:', err);
      toastrHelper.showErrorToast(
        `Error updating sub group for group ${group.label}`,
        'Error',
        mastV2,
      );
    }

    getSubGroups();
  };

  const deleteSubGroup = async () => {
    setConfirmOpen(false);
    try {
      await axios.delete(`group-admin/sub-group/${selectedSubGroup.id}`);

      toastrHelper.showSuccessToast(
        `Successfully deleted sub group for group ${group.label}`,
        'Success',
        mastV2,
      );
    } catch (err) {
      console.log('Error updating sub group:', err);
      toastrHelper.showErrorToast(
        `Error deleting sub group for group ${group.label}`,
        'Error',
        mastV2,
      );
    }

    getSubGroups();
  };

  const permissionConfirmation = async attributes => {
    setShowPermissionConfirm(true);
    setAttributes(attributes);
  };

  return (
    <div>
      <ConfirmationModal
        isOpen={confirmOpen}
        toggle={() => setConfirmOpen(!setConfirmOpen)}
        confirmHeader="Delete Sub Group"
        confirmBody="Are you sure you want to delete this sub group?"
        confirm={() => deleteSubGroup()}
      />
      {attributes && (
        <ConfirmationModal
          isOpen={showPermissionConfirm}
          toggle={() => setShowPermissionConfirm(!showPermissionConfirm)}
          confirmHeader="Update Permission"
          confirmBody={`Are you sure to ${
            attributes.value ? 'enable' : 'disable'
          } ${attributes.label} permission for sub group ${attributes.name}?`}
          confirm={() => {
            setShowPermissionConfirm(false);

            const filteredAttributes = _.omit(attributes, ['label', 'value']);
            updateSubGroup(filteredAttributes);
          }}
        />
      )}
      <QRow columnGap="30px">
        <QCol xs="3">
          {mastV2 ? (
            <QCard style={{ width: '100%' }}>
              <QCardHeader
                title="Sub Groups"
                action={
                  <NewSubGroup
                    createSubGroup={createSubGroup}
                    kaiAccess={kaiAccess}
                    groupsOptions={groupsOptions}
                    currentGroup={group}
                  />
                }
              />
              <QCardBody>
                {kaiAccess && (
                  <>
                    <QLabel for="group-select">
                      <strong style={{ color: 'black' }}>Group</strong>
                    </QLabel>
                    <QAutocomplete
                      id="group-select"
                      options={groupsOptions}
                      value={group || null}
                      inputValue={groupInputValue}
                      onChange={(event, value) => {
                        setGroup(value);
                      }}
                      onInputChange={(event, value) => {
                        setGroupInputValue(value);
                      }}
                      disableClearable
                      popupIcon={<ExpandMoreIcon htmlColor="#000000" />}
                      renderInput={params => (
                        <QInput
                          {...params}
                          outlinedWithTitle
                          size="small"
                          variant="outlined"
                          InputProps={{
                            endAdornment: params.InputProps.endAdornment,
                            startAdornment: params.InputProps.startAdornment,
                            sx: {
                              '.MuiAutocomplete-endAdornment': {
                                position: 'absolute',
                              },
                            },
                            inputProps: params.inputProps,
                            ref: params.InputProps.ref,
                          }}
                          style={{
                            textOverflow: 'ellipsis',
                            backgroundColor: '#FFFFFF',
                          }}
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      style={{
                        width: '200px',
                      }}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{ wordWrap: 'break-word', display: 'block' }}
                        >
                          {option.label}
                        </li>
                      )}
                    />
                  </>
                )}
                <List>
                  {groupAdminSubGroups.map(subGroup => (
                    <div key={subGroup.id}>
                      <Divider />
                      <ListItemButton
                        data-testid={`select-subgroup-${subGroup.id}`}
                        selected={selectedSubGroup?.id === subGroup.id}
                        disableRipple
                        onClick={() =>
                          setSelectedSubGroup(
                            groupAdminSubGroups.find(g => g.id === subGroup.id),
                          )
                        }
                      >
                        <span>{subGroup.name}</span>
                      </ListItemButton>
                    </div>
                  ))}
                </List>
              </QCardBody>
            </QCard>
          ) : (
            <ListGroup>
              <ListGroupItem
                color="info"
                className="flex justify-between align-center"
              >
                <ListGroupItemHeading>Sub Groups</ListGroupItemHeading>
                <NewSubGroup
                  createSubGroup={createSubGroup}
                  kaiAccess={kaiAccess}
                  groupsOptions={groupsOptions}
                  currentGroup={group}
                />
              </ListGroupItem>
              {kaiAccess && (
                <ListGroupItem>
                  <ListGroupItemText>Group</ListGroupItemText>
                  <Select
                    options={groupsOptions}
                    value={group}
                    onChange={newGroup => {
                      setGroup(newGroup);
                    }}
                  />
                </ListGroupItem>
              )}
              {groupAdminSubGroups.map(subGroup => (
                <ListGroupItem
                  data-testid={`select-subgroup-${subGroup.id}`}
                  key={subGroup.id}
                  className="pointer"
                  action
                  onClick={() =>
                    setSelectedSubGroup(
                      groupAdminSubGroups.find(g => g.id === subGroup.id),
                    )
                  }
                >
                  {subGroup.name}
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
        </QCol>

        {selectedSubGroup && (
          <QCol xs="9" flex="9">
            <QCard className="card-accent-primary">
              <QCardHeader
                className="flex justify-between align-center"
                title={`Editing Sub Group: ${selectedSubGroup.name}`}
                action={
                  <QButton
                    variant="outlined"
                    onClick={() => setConfirmOpen(true)}
                  >
                    <span>
                      <i className="far fa-trash-alt" /> Delete
                    </span>
                  </QButton>
                }
              >
                <strong>Editing Sub Group: {selectedSubGroup.name}</strong>
                <span
                  className="text-danger pointer"
                  onClick={() => setConfirmOpen(true)}
                >
                  <i className="far fa-minus-circle" /> Delete
                </span>
              </QCardHeader>
              <QCardBody>
                <QRow columnGap="30px">
                  <QCol xs="2">
                    <strong>ID:</strong>
                    <QInput
                      className="text-center"
                      value={selectedSubGroup.id}
                      disabled
                    />
                  </QCol>
                  <QCol xs="6">
                    <strong>Name:</strong>
                    <EditableInput
                      initValue={selectedSubGroup.name}
                      onSave={newName =>
                        updateSubGroup({ ...selectedSubGroup, name: newName })
                      }
                    />
                  </QCol>
                </QRow>

                <hr />

                <QRow columnGap="30px">
                  <QCol xs="6">
                    <div className="mb-2">
                      <strong>Default Access:</strong>
                    </div>

                    <small className="text-muted text-uppercase font-weight-bold">
                      General
                    </small>
                    {mastV2 ? <br /> : <></>}
                    {general.map(([label, defaultPrivilege]) => {
                      const { hidden } = checkSpecialPermissionVisibility({
                        permissionName: defaultPrivilege,
                        doesCurrentAdminHasPermission:
                          adminUserAccess[
                            convertSubgroupPermissionToUserPermission(
                              defaultPrivilege,
                            )
                          ],
                        kaiAccess,
                      });

                      if (hidden) {
                        return null;
                      }

                      const value = selectedSubGroup[defaultPrivilege] || false;
                      return mastV2 ? (
                        <div key={label}>
                          <QSwitchControlLabel
                            label={<strong>{label}</strong>}
                            control={
                              <QSwitch
                                key={label}
                                value={value}
                                label={label}
                                checked={value}
                                onChange={() =>
                                  permissionConfirmation({
                                    label,
                                    value: !value,
                                    ...selectedSubGroup,
                                    [defaultPrivilege]:
                                      !selectedSubGroup[defaultPrivilege],
                                  })
                                }
                              />
                            }
                          />
                          {mastV2 ? <br /> : <></>}
                        </div>
                      ) : (
                        <QSwitch
                          key={label}
                          value={value}
                          label={label}
                          onChange={() =>
                            permissionConfirmation({
                              label,
                              value: !value,
                              ...selectedSubGroup,
                              [defaultPrivilege]:
                                !selectedSubGroup[defaultPrivilege],
                            })
                          }
                        />
                      );
                    })}

                    <small className="text-muted text-uppercase font-weight-bold">
                      Analysis
                    </small>
                    {mastV2 ? <br /> : <></>}
                    {analysis.map(([label, defaultPrivilege]) => {
                      const value = selectedSubGroup[defaultPrivilege] || false;
                      return mastV2 ? (
                        <div key={label}>
                          <QSwitchControlLabel
                            label={<strong>{label}</strong>}
                            control={
                              <QSwitch
                                datatestid={label}
                                value={value}
                                label={label}
                                checked={value}
                                onChange={() =>
                                  permissionConfirmation({
                                    label,
                                    value: !value,
                                    ...selectedSubGroup,
                                    [defaultPrivilege]:
                                      !selectedSubGroup[defaultPrivilege],
                                  })
                                }
                              />
                            }
                          />
                          <br />
                        </div>
                      ) : (
                        <Switch
                          key={label}
                          datatestid={label}
                          value={value}
                          label={label}
                          onChange={() =>
                            permissionConfirmation({
                              label,
                              value: !value,
                              ...selectedSubGroup,
                              [defaultPrivilege]:
                                !selectedSubGroup[defaultPrivilege],
                            })
                          }
                        />
                      );
                    })}

                    <small className="text-muted text-uppercase font-weight-bold">
                      Reports
                    </small>
                    {mastV2 ? <br /> : <></>}
                    {reports.map(([label, defaultPrivilege]) => {
                      const isVisible = checkFeatureFlagPermissionVisibility({
                        permissionName: defaultPrivilege,
                        flags,
                      });

                      if (!isVisible) {
                        return null;
                      }

                      const value = selectedSubGroup[defaultPrivilege] || false;
                      return mastV2 ? (
                        <div key={label}>
                          <QSwitchControlLabel
                            label={<strong>{label}</strong>}
                            control={
                              <QSwitch
                                key={label}
                                value={value}
                                label={label}
                                checked={value}
                                onChange={() =>
                                  permissionConfirmation({
                                    label,
                                    value: !value,
                                    ...selectedSubGroup,
                                    [defaultPrivilege]:
                                      !selectedSubGroup[defaultPrivilege],
                                  })
                                }
                              />
                            }
                          />
                          <br />
                        </div>
                      ) : (
                        <Switch
                          key={label}
                          value={value}
                          label={label}
                          onChange={() =>
                            permissionConfirmation({
                              label,
                              value: !value,
                              ...selectedSubGroup,
                              [defaultPrivilege]:
                                !selectedSubGroup[defaultPrivilege],
                            })
                          }
                        />
                      );
                    })}
                  </QCol>

                  <QCol>
                    <DefaultWhitelistIps
                      defaultWhitelistIps={selectedSubGroup.defaultWhitelistIps}
                      updateSubGroup={updateSubGroup}
                    />
                  </QCol>
                </QRow>
              </QCardBody>
            </QCard>
          </QCol>
        )}
      </QRow>
    </div>
  );
};

export const EditSubGroups = withLDConsumer()(LDEditSubGroups);
