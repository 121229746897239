import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { useFlags } from 'launchdarkly-react-client-sdk';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import HelpButton from '../../HelpButton';
import i18n from '../../../localization/i18n';
import util from '../../../logic/util';
import { toastrHelper } from '../../../logic/toastrHelper';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QLabel } from '../../Q-Components/QLabel';
import { QInput } from '../../Q-Components/QInput';
import { QButton } from '../../Q-Components/QButton';
import { QBadge } from '../../Q-Components/QBadge';
import { QTypography } from '../../Q-Components/QTypography';
import { QRow } from '../../Q-Components/QRow';
import { QListGroup } from '../../Q-Components/QListGroup';
import { QListGroupItem } from '../../Q-Components/QListGroupItem';
import { QIconButton } from '../../Q-Components/QIconButton';

const MdmThreatScoreLimit = () => {
  const [score, setScore] = useState(0);
  const [alertEmails, setAlertEmails] = useState([]);
  const [inputEmails, setInputEmails] = useState('');
  const { mastV2 } = useFlags();

  useEffect(() => {
    const getThresholdScore = async () => {
      const response = await axios.get('emm/threshold-score');
      setScore(parseInt(response.data.score, 10));
      setAlertEmails(
        response.data.emails ? response.data.emails.split(',') : [],
      );
    };
    getThresholdScore();
  }, []);

  const handleSliderChange = value => {
    setScore(value);
  };

  const addAlertEmail = () => {
    const emails = inputEmails.replace(/\s+/g, '').split(',');
    const isValid = emails.map(email => util.isValidEmail(email));

    if (isValid.includes(false)) {
      toastrHelper.showErrorToast('Invalid Email', 'Error', mastV2);
      return;
    }
    setAlertEmails([
      ...new Set(alertEmails.concat(emails.filter(item => item))),
    ]);
  };

  const deleteAlertEmail = email => {
    setAlertEmails(alertEmails.filter(item => item !== email));
  };

  const saveSettings = async () => {
    console.log('Setting score to:', score);
    console.log('Email to:', alertEmails);

    if (alertEmails.length === 0) {
      toastrHelper.showWarningToast(
        'Please add at least one email address',
        'Warning',
        mastV2,
      );
      return;
    }

    try {
      const response = await axios.patch('emm/threshold-score', {
        score,
        emails: alertEmails.join(','),
      });

      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          i18n.t('Successfully set score threshold'),
          null,
          mastV2,
        );
      }
    } catch (err) {
      console.log('Error setting score:', err);
      toastrHelper.showErrorToast(
        i18n.t('Error setting score threshold'),
        null,
        mastV2,
      );
    }
  };

  const disableScoreThreshold = async () => {
    setScore(-1);
    try {
      const response = await axios.patch('emm/threshold-score', {
        score: -1,
      });

      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          i18n.t('Successfully disabled score threshold'),
          null,
          mastV2,
        );
      }
    } catch (err) {
      console.log('Error disabling score threshold:', err);
      toastrHelper.showErrorToast(
        i18n.t('Error disabling score threshold'),
        null,
        mastV2,
      );
    }
  };

  return (
    <QCard className="card-accent-warning-2">
      <QCardHeader
        title={
          <strong>
            {i18n.t('Threat Score Threshold')}{' '}
            <HelpButton helpItem="threatScoreThreshold" />
          </strong>
        }
      >
        <strong>
          {i18n.t('Threat Score Threshold')}{' '}
          <HelpButton helpItem="threatScoreThreshold" />
        </strong>
      </QCardHeader>
      <QCardBody>
        {score === -1 || score === 0 || Number.isNaN(score) ? (
          mastV2 ? (
            <QRow alignItems="center" columnGap="8px" marginBottom="20px">
              <QTypography variant="h5Regular" color="#000000">
                {i18n.t('Enabled')}:{' '}
              </QTypography>
              <QBadge
                color="warning"
                label={
                  <QTypography variant="tinyMedium" color="#000000">
                    {i18n.t('NO')}
                  </QTypography>
                }
              />
            </QRow>
          ) : (
            <h4>{i18n.t('Threshold Disabled')}</h4>
          )
        ) : mastV2 ? (
          <QRow alignItems="center" columnGap="8px" marginBottom="20px">
            <QTypography variant="h5Regular" color="#000000">
              {i18n.t('Enabled')}: {score}
            </QTypography>
          </QRow>
        ) : (
          <h2>{score}</h2>
        )}
        <Slider onChange={handleSliderChange} value={score} />
        {mastV2 ? <br /> : <hr />}
        <QLabel for="threat-score-threshold-email-input">
          {i18n.t('Alert Emails')}
        </QLabel>
        <QInput
          type="text"
          id="threat-score-threshold-email-input"
          onChange={e => setInputEmails(e.target.value)}
          value={inputEmails}
          sx={{ marginBottom: '8px' }}
        />
        <QButton
          variant="filled"
          className="btn-primary mt-2 "
          onClick={() => {
            addAlertEmail();
            setInputEmails('');
          }}
          startIcon={<AddIcon />}
        >
          {!mastV2 && <i className="far fa-plus-circle" />} Add
        </QButton>

        <hr />

        {alertEmails && alertEmails.length > 0 && (
          <>
            <QListGroup>
              {alertEmails.map(email => (
                <QListGroupItem
                  key={email}
                  sx={{
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderRadius: '4px',
                    backgroundColor: 'white',
                    margin: '8px',
                    borderColor: '#BDBDBD',
                  }}
                  secondaryAction={
                    <QIconButton
                      style={{
                        color: 'unset',
                      }}
                    >
                      <IndeterminateCheckBoxOutlinedIcon
                        fontSize="large"
                        onClick={() => deleteAlertEmail(email)}
                      />
                    </QIconButton>
                  }
                >
                  {email}{' '}
                  {!mastV2 && (
                    <i
                      className="fal fa-minus-circle pointer ml-2"
                      onClick={() => deleteAlertEmail(email)}
                    />
                  )}
                </QListGroupItem>
              ))}
            </QListGroup>
            <hr />{' '}
          </>
        )}

        <div
          style={
            !mastV2
              ? { display: 'flex', justifyContent: 'space-between' }
              : { textAlign: 'center' }
          }
        >
          <QRow columnGap="5px">
            <QButton
              variant="filled"
              color="primary"
              onClick={saveSettings}
              sx={{ marginRight: '16px' }}
              style={{ whiteSpace: 'nowrap' }}
            >
              {i18n.t('Save Settings')}
            </QButton>
            <QButton
              variant="outlined"
              color="warning"
              onClick={disableScoreThreshold}
              style={{ whiteSpace: 'nowrap' }}
              data-testId="disable-threat-score-threshold"
            >
              {i18n.t('Disable Threshold')}
            </QButton>
          </QRow>
        </div>
      </QCardBody>
    </QCard>
  );
};

export default MdmThreatScoreLimit;
