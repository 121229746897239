import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QTableRow } from '../Q-Components/QTableRow';
import { QTableCell } from '../Q-Components/QTableCell';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QButton } from '../Q-Components/QButton';
import util from '../../logic/util';
import { QLink } from '../Q-Components/QLink';

const PlayStoreApp = props => {
  const id = `app-result-${props.index}`;
  const { mastV2 } = useFlags();
  return (
    <QTableRow>
      {/* App */}
      <QTableCell>
        <QRow id={id}>
          <QCol flex="1 1" xs="3">
            <a href={props.app.market_url} target="_blank">
              <img
                src={props.app.icon}
                className="store-search-app-icon"
                alt="Icon"
              />
            </a>
          </QCol>
          <QCol flex="3 3" xs="9">
            <QRow>
              <strong>{props.app.title}</strong>
            </QRow>
            <QRow className="dont-break-out" class="dont-break-out">
              <i>{props.app.package_name}</i>
            </QRow>
          </QCol>
        </QRow>
      </QTableCell>

      {/* Developer */}
      <QTableCell>
        <QLink href={props.app.website} target="_blank">
          {props.app.developer}
        </QLink>
      </QTableCell>

      {/* Description */}
      <QTableCell className="dont-break-out" class="dont-break-out">
        {util.getSubstringIfNeeded(props.app.description, 515)}
      </QTableCell>

      {/* Actions */}
      <QTableCell>
        <QButton
          variant="outlined"
          className="btn-success"
          onClick={() =>
            props.submitApp(
              props.app.title,
              props.app.package_name,
              props.app.icon,
              'android',
            )
          }
          startIcon={<i className="fas fa-upload" />}
        >
          {mastV2 ? 'Submit' : <i className="far fa-cloud-download fa-lg" />}
        </QButton>
      </QTableCell>
    </QTableRow>
  );
};

export default PlayStoreApp;
