import _ from 'lodash';
import produce from 'immer';

import { store } from '../store';

// set to only 20 mins to avoid stale data across different users
const onemin = 60000;
const CACHE_INTERVAL = 20 * onemin;

export const isValid = ({ time, setInvalid } = {}) => {
  // timed-cache algo reference https://dev.to/nosyminotaur/caching-network-requests-on-the-frontend-dmh
  const isNumber = typeof time === 'number';
  const dateTime = new Date(time);
  const isWithinCacheInterval =
    Date.now() - dateTime.getTime() < CACHE_INTERVAL;
  const isValidDate = dateTime.toString() !== 'Invalid Date';

  return !setInvalid && isNumber && isWithinCacheInterval && isValidDate;
};

export const getCacheValue = (key, prop) => {
  const cacheStore = store.getState().cache[prop][key];
  if (isValid(cacheStore)) {
    return cacheStore.value;
  }
  return undefined;
};

export const setCacheInvalid = cache => ({ ...cache, setInvalid: true });

export const createCacheValue = value => ({
  value,
  time: Date.now(),
});

// immutable json store, not using method based cache since methods disappear on when put in localStorage
export const jsonCache = {
  // sizeLimit is the max number of valid and invalid cache entries
  create({ sizeLimit }) {
    return {
      __limit: sizeLimit,
      __keys: [],
    };
  },
  add(state, key, value) {
    if (typeof key !== 'string' && typeof key !== 'number') {
      throw new Error(`invalid type for key "${JSON.stringify(key)}"`);
    }
    return produce(state, draft => {
      if (draft.__keys.length >= draft.__limit) {
        delete draft[draft.__keys[0]];
        draft.__keys.shift();
      }

      draft[key] = createCacheValue(value);
      draft.__keys.push(key);
    });
  },
  setAllInvalid: ({ __keys, __limit, ...state }) => ({
    ..._.mapValues(state, setCacheInvalid),
    __limit,
    __keys,
  }),
};
