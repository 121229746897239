import defaultMainLogo from './Quokka_V2.svg';
import defaultSideLogo from './Q_V2.svg';
import defaultBannerLogo from './Quokka_Banner_V2.svg';
import defaultFavIcon from './Q_Favicon_V2.svg';

export const defaultAsset = {
  logo: {
    main: defaultMainLogo,
    side: defaultSideLogo,
    banner: defaultBannerLogo,
    fav: defaultFavIcon,
  },
  fonts: ['"Nunito Sans"', 'NunitoSans', 'sans-serif'],
};
