import axios from 'axios';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useParams } from 'react-router';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { QCard } from '../../../Q-Components/QCard';
import { QCardHeader } from '../../../Q-Components/QCardHeader';
import { QCardBody } from '../../../Q-Components/QCardBody';
import { QContainer } from '../../../Q-Components/QContainer';
import { QRow } from '../../../Q-Components/QRow';
import { QCol } from '../../../Q-Components/QCol';
import { QTypography } from '../../../Q-Components/QTypography';
import { QBadge } from '../../../Q-Components/QBadge';
import { QActionSelect } from '../../../Q-Components/QActionSelect';
import { QInput } from '../../../Q-Components/QInput';
import { QAutocomplete } from '../../../Q-Components/QAutocomplete';
import { QButton } from '../../../Q-Components/QButton';
import { QTable } from '../../../Q-Components/QTable';
import { QTableHead } from '../../../Q-Components/QTableHead';
import { QTableRow } from '../../../Q-Components/QTableRow';
import { QTableCell } from '../../../Q-Components/QTableCell';
import { QTableBody } from '../../../Q-Components/QTableBody';
import auth from '../../../../logic/auth';
import { toastrHelper } from '../../../../logic/toastrHelper';
import AnalyzedAppInfo from '../../../AnalyzedApps/AnalyzedAppInfo';
import { ThreatScore } from '../../../ThreatScore';
import { fetchKaiUsers } from '../../../../store/sliceHelpers/kaiSliceHelper';
import { Spinner } from '../../../Spinner';

import { AnalysisSteps } from './AnalysisSteps';

export function KaiFarmApp() {
  const { farmAppId } = useParams();
  const { mastV2 } = useFlags();
  const dispatch = useDispatch();
  const users = useSelector(state => state.kai.kaiUsers);

  const [loadingAddAppToUser, setLoadingAddAppToUser] = useState(false);
  const [details, setDetails] = useState(null);
  const [user, setUser] = useState(null);
  const [userValue, setUserValue] = useState();

  const userOptions = users.map(user => ({
    value: user.id,
    label: user.email,
    ...user,
  }));

  const fetchData = async () => {
    const response = await axios.get(`adminV2/farm-apps/${farmAppId}`);
    setDetails(response.data);
  };

  const handleUserChange = (event, newUser) => {
    if (mastV2) {
      setUser(newUser);
      setUserValue(newUser?.label);
    } else {
      setUser(event);
      setUserValue(event);
    }
  };

  const regeneratePdfReport = async analyzedAppId => {
    try {
      await axios.post(`analyzed-apps/${analyzedAppId}/regenerate-pdf`);
      toastrHelper.showSuccessToast('Sent regeneration request', null, mastV2);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error sending regeneration request',
        null,
        mastV2,
      );
    }
  };

  const resubmitApp = async analyzedAppId => {
    try {
      toastrHelper.showInfoToast('Resubmitting app...', null, mastV2);
      await axios.put(`analyzed-apps/${analyzedAppId}/resubmit`);
      toastrHelper.showSuccessToast(
        'Resubmitted app for analysis',
        null,
        mastV2,
      );
      fetchData();
    } catch (err) {
      toastrHelper.showErrorToast('Error resubmitting app', null, mastV2);
    }
  };

  const reimportApp = async () => {
    try {
      await axios.post(`adminV2/farm-apps/${details.id}/reimport`);
      toastrHelper.showSuccessToast(
        'Reimported app successfully',
        null,
        mastV2,
      );
      fetchData();
    } catch (err) {
      toastrHelper.showErrorToast('Error resubmitting app', null, mastV2);
    }
  };

  const addAppToUser = async () => {
    setLoadingAddAppToUser(true);

    try {
      await axios.post(`adminV2/farm-apps/${details.id}/copy`, {
        userId: user.id,
      });
      toastrHelper.showSuccessToast('Copied app to user', null, mastV2);
      fetchData();
    } catch (err) {
      toastrHelper.showErrorToast('Error copying app to user', null, mastV2);
    } finally {
      setLoadingAddAppToUser(false);
    }
  };

  const deleteAnalyzedApp = async analyzedAppId => {
    try {
      await axios.delete(`emm/app/${analyzedAppId}`);
      toastrHelper.showSuccessToast(
        'Successfully deleted analyzed app',
        null,
        mastV2,
      );
      fetchData();
    } catch (err) {
      console.log('Error deleting analyzed app:', err);
      toastrHelper.showErrorToast('Error deleting analyzed app', null, mastV2);
    }
  };

  const getStatusString = status => {
    switch (status) {
      case 0:
        return 'Not submitted';
      case 1:
        return 'Processing';
      case 2:
        return 'Complete';
      case 5:
        return 'Resubmitted';
      case 10:
        return 'Waiting for PDF';
      case 55:
        return 'Analysis issue';
      case 56:
        return 'Failed to submit';
      case 58:
        return 'Analysis failed';
      default:
        return 'Unknown';
    }
  };

  const KaiAutocomplete = ({ children, ...props }) => (
    <QAutocomplete
      {...props}
      popupIcon={<ExpandMoreIcon fontSize="medium" />}
      clearIcon={<ClearIcon fontSize="medium" />}
      renderInput={params => (
        <QInput
          {...params}
          outlinedWithTitle
          label={props.label}
          placeholder={props.placeholder}
          variant="outlined"
          style={{ textOverflow: 'ellipsis' }}
          size="small"
          InputProps={{
            endAdornment: params.InputProps.endAdornment,
            startAdornment: params.InputProps.startAdornment,
            sx: {
              '.MuiAutocomplete-endAdornment': {
                position: 'absolute',
              },
            },
            inputProps: params.inputProps,
            ref: params.InputProps.ref,
            style: {
              fontSize: '14px',
              fontWeight: '400',
            },
          }}
        />
      )}
    >
      {children}
    </QAutocomplete>
  );

  useEffect(() => {
    fetchData();
  }, [farmAppId]);

  useEffect(() => {
    dispatch(fetchKaiUsers());
  }, []);

  if (!details) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <QContainer
        fluid
        sx={{ padding: '0px', backgroundColor: 'unset', minWidth: '100%' }}
      >
        <QRow columnGap="20px">
          <QCol flex="1" xs="6">
            <QCard className="card-accent-primary">
              <QCardHeader
                title={
                  <QTypography variant="h4Medium" color="#000000">
                    App
                  </QTypography>
                }
              >
                <b> App </b>
              </QCardHeader>
              <QCardBody>
                <QRow>
                  <QCol flex="1" xs="6">
                    <AnalyzedAppInfo
                      app={{ ...details.analyzedApps[0], farm_app: details }}
                    />
                  </QCol>
                  <QCol flex="1" xs="6">
                    <div>
                      <b>Complete? </b>
                      {details.status === 2 ? (
                        <QBadge
                          color="success"
                          pill
                          label="YES"
                          variant="green"
                        />
                      ) : (
                        <QBadge color="danger" pill label="No" variant="red" />
                      )}
                    </div>
                    <div>
                      <b>Status: </b>
                      {getStatusString(details.status)}
                    </div>

                    {mastV2 ? (
                      <QActionSelect
                        actionOptions={[
                          {
                            value: 'downloadBinary',
                            label: 'Download Binary',
                            icon: (
                              <i className="primary-icon fas fa-download" />
                            ),
                            onClick: () =>
                              window.open(
                                `${process.env.REACT_APP_API_BASE_URL}/adminV2/farm-apps/${details.id}/binary?auth=basic`,
                                '_blank',
                              ),
                          },
                          {
                            value: 'reimport',
                            label: 'Reimport',
                            icon: (
                              <i className="primary-icon fas fa-file-import" />
                            ),
                            onClick: () => reimportApp(),
                          },
                        ]}
                      />
                    ) : (
                      <UncontrolledButtonDropdown title="Actions">
                        <DropdownToggle caret className="toggledown-primary">
                          Actions
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem title="Regenerate PDF">
                            <a
                              href={`${process.env.REACT_APP_API_BASE_URL}/adminV2/farm-apps/${details.id}/binary?auth=basic`}
                              target="_blank"
                            >
                              <i className="primary-icon fas fa-download" />{' '}
                              Download Binary
                            </a>
                          </DropdownItem>
                          <DropdownItem
                            title="Regenerate PDF"
                            onClick={reimportApp}
                          >
                            <i className="primary-icon fas fa-file-import" />{' '}
                            Reimport
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    )}
                  </QCol>
                </QRow>

                <hr />
                <QRow>
                  <QCol>
                    <div>
                      <b>Farm app ID:</b> {details.id}
                      <CopyToClipboard
                        text={details.id}
                        onCopy={() =>
                          toastrHelper.showSuccessToast(
                            'Copied ID to clipboard',
                            null,
                            mastV2,
                          )
                        }
                      >
                        <i className="fas fa-paste pointer ml-2" />
                      </CopyToClipboard>
                    </div>
                    <div>
                      <b>Farm app UUID:</b> {details.uuid}
                      <CopyToClipboard
                        text={details.uuid}
                        onCopy={() =>
                          toastrHelper.showSuccessToast(
                            'Copied UUID to clipboard',
                            null,
                            mastV2,
                          )
                        }
                      >
                        <i className="fas fa-paste pointer ml-2" />
                      </CopyToClipboard>
                    </div>
                  </QCol>
                </QRow>
              </QCardBody>
            </QCard>
          </QCol>
          <QCol flex="1">
            <QCard className="card-accent-primary">
              <QCardHeader
                title={
                  <QTypography variant="h4Medium" color="#000000">
                    Analysis Status
                  </QTypography>
                }
              >
                Analysis Status
              </QCardHeader>
              <QCardBody>
                <AnalysisSteps
                  analysisTracking={details.analysisTracking}
                  platform={details.platform}
                />
              </QCardBody>
            </QCard>
          </QCol>
        </QRow>

        <QRow sx={{ paddingTop: '20px' }}>
          <QCol flex="1">
            <QCard className="card-accent-primary mt-2">
              <QCardHeader
                title={
                  <QTypography variant="h4Medium" color="#000000">
                    Users who submitted app
                  </QTypography>
                }
              >
                <b>Users who submitted app</b>
              </QCardHeader>
              <QCardBody>
                <QRow columnGap="20px" style={{ paddingBottom: '10px' }}>
                  <QCol xs="4">
                    <KaiAutocomplete
                      className="z-index-20"
                      label="User"
                      value={userValue}
                      id="kai-aa-user-filter"
                      options={userOptions}
                      onChange={handleUserChange}
                      isClearable
                      styles={{
                        container: base => ({
                          ...base,
                          width: '100%',
                        }),
                      }}
                      renderOption={(props, option) => (
                        <li
                          {...props}
                          style={{
                            wordWrap: 'break-word',
                            display: 'block',
                          }}
                        >
                          {option.label}
                        </li>
                      )}
                      placeholder="Select User"
                    />
                  </QCol>
                  <QCol>
                    <QButton
                      color="primary"
                      variant="filled"
                      onClick={addAppToUser}
                      disabled={!user || loadingAddAppToUser}
                      startIcon={<i className="fas fa-plus-circle" />}
                    >
                      {loadingAddAppToUser ? (
                        <>
                          <Spinner /> Loading...
                        </>
                      ) : (
                        <>
                          {!mastV2 && <i className="fas fa-plus-circle" />}Add
                          app to user
                        </>
                      )}
                    </QButton>
                  </QCol>
                </QRow>
                <QTable>
                  <QTableHead>
                    <QTableRow>
                      <QTableCell>ID</QTableCell>
                      <QTableCell>User</QTableCell>
                      <QTableCell>Submitted at</QTableCell>
                      <QTableCell>Threat Score</QTableCell>
                      <QTableCell>Deleted?</QTableCell>
                      <QTableCell>PDF Generated?</QTableCell>
                      <QTableCell>Actions</QTableCell>
                    </QTableRow>
                  </QTableHead>
                  <QTableBody>
                    {details.analyzedApps.map(analyzedApp => (
                      <QTableRow key={analyzedApp.id}>
                        <>
                          <QTableCell>{analyzedApp.id}</QTableCell>
                          <QTableCell>{analyzedApp.user.email}</QTableCell>
                          <QTableCell>
                            {moment(analyzedApp.submittedAt).format('L - LTS')}
                          </QTableCell>
                          <QTableCell>
                            <ThreatScore
                              threatScore={analyzedApp.threatScore}
                              status={details.status}
                            />
                          </QTableCell>
                          <QTableCell>
                            {analyzedApp.isDeleted ? (
                              <QBadge
                                color="danger"
                                pill
                                label="Yes"
                                variant="red"
                              >
                                YES
                              </QBadge>
                            ) : (
                              <QBadge
                                color="success"
                                pill
                                label="No"
                                variant="green"
                              >
                                NO
                              </QBadge>
                            )}
                          </QTableCell>
                          <QTableCell>
                            {analyzedApp.pdfReportGenerated ? (
                              <QBadge
                                color="success"
                                variant="green"
                                pill
                                label="Yes"
                              >
                                YES
                              </QBadge>
                            ) : (
                              <QBadge
                                color="danger"
                                pill
                                label="no"
                                variant="red"
                              >
                                NO
                              </QBadge>
                            )}
                          </QTableCell>
                          <QTableCell>
                            {(details.status === 2 ||
                              analyzedApp.pdfReportGenerated) &&
                              (mastV2 ? (
                                <QActionSelect
                                  selectProps={{
                                    'data-testid': 'dropdown-menu-button',
                                  }}
                                  actionOptions={[
                                    {
                                      value: 'regeneratePDF',
                                      label: 'Regenerate PDF',
                                      'data-testid': 'regenerate-pdf-button',
                                      icon: (
                                        <i className="fas fa-file-pdf fa-lg" />
                                      ),
                                      onClick: () =>
                                        regeneratePdfReport(analyzedApp.id),
                                      id: 'regenerate-pdf-button',
                                    },
                                    {
                                      value: 'resubmit',
                                      label: 'Resubmit',
                                      icon: (
                                        <i className="fas fa-sync-alt primary-icon" />
                                      ),
                                      onClick: () =>
                                        resubmitApp(analyzedApp.id),
                                    },
                                    {
                                      value: 'pdfReport',
                                      label: 'PDF Report',
                                      icon: (
                                        <i className="far fa-file-pdf fa-lg" />
                                      ),
                                      onClick: () =>
                                        window.open(
                                          `${process.env.REACT_APP_API_BASE_URL}/adminV2/pdf-report?uuid=${details.uuid}&userId=${analyzedApp.userId}&auth=basic`,
                                          '_blank',
                                        ),
                                    },
                                    {
                                      value: 'delete',
                                      label: 'Delete',
                                      icon: (
                                        <i className="fas fa-times danger-icon" />
                                      ),
                                      onClick: () =>
                                        deleteAnalyzedApp(analyzedApp.id),
                                    },
                                  ]}
                                />
                              ) : (
                                <UncontrolledButtonDropdown title="Actions">
                                  <DropdownToggle
                                    caret
                                    className="toggledown-primary"
                                  >
                                    Actions{' '}
                                  </DropdownToggle>
                                  <DropdownMenu data-testid="dropdown-menu-button">
                                    <DropdownItem
                                      title="Regenerate PDF"
                                      data-testid="regenerate-pdf-button"
                                      onClick={() =>
                                        regeneratePdfReport(analyzedApp.id)
                                      }
                                    >
                                      <i className="fas fa-file-pdf fa-lg" />
                                      <span>Regenerate PDF</span>
                                    </DropdownItem>
                                    <DropdownItem
                                      title="Resubmit"
                                      onClick={() =>
                                        resubmitApp(analyzedApp.id)
                                      }
                                    >
                                      <i className="fas fa-sync-alt primary-icon" />
                                      <span>Resubmit</span>
                                    </DropdownItem>
                                    <a
                                      target="_blank"
                                      href={`${process.env.REACT_APP_API_BASE_URL}/adminV2/pdf-report?uuid=${details.uuid}&userId=${analyzedApp.userId}&auth=basic`}
                                    >
                                      <DropdownItem title="PDF Report">
                                        <i className="far fa-file-pdf fa-lg" />
                                        <span>PDF Report</span>
                                      </DropdownItem>
                                    </a>
                                    <DropdownItem
                                      title="Delete"
                                      onClick={() =>
                                        deleteAnalyzedApp(analyzedApp.id)
                                      }
                                    >
                                      <i className="fas fa-times danger-icon" />
                                      <span>Delete</span>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledButtonDropdown>
                              ))}
                          </QTableCell>
                        </>
                      </QTableRow>
                    ))}
                  </QTableBody>
                </QTable>
              </QCardBody>
            </QCard>
          </QCol>
        </QRow>
      </QContainer>
    </>
  );
}
