import React from 'react';
import _ from 'lodash';

import i18n from '../../localization/i18n';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';

import AndroidMarketInfoTable from './Android/MarketInfoTable';
import IOSMarketInfoTable from './iOS/MarketInfoTable';

export function MarketInfo({ results, platform }) {
  return (
    <div style={{ height: '100%' }}>
      <QCard
        className="card-accent-warning market-info-section"
        style={{ height: '100%' }}
      >
        <QCardHeader
          title={
            <QTypography variant="h5Medium">
              {i18n.t('Market Information')}
            </QTypography>
          }
        >
          {i18n.t('Market Information')}
        </QCardHeader>
        <QCardBody>
          {_.isEmpty(results) ? (
            <i className="fas fa-spinner fa-spin" />
          ) : platform === 'android' ? (
            <AndroidMarketInfoTable results={results} />
          ) : (
            <IOSMarketInfoTable />
          )}
        </QCardBody>
      </QCard>
    </div>
  );
}
