import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { connect } from 'react-redux';

const getServiceIcon = service => {
  if (service === 'Google Drive') {
    return 'fab fa-google';
  }
  if (service === 'Dropbox') {
    return 'fab fa-dropbox';
  }
  if (service === 'Windows Live OneDrive') {
    return 'fab fa-windows';
  }
  if (service === 'Box') {
    return 'fas fa-stop';
  }
  return null;
};

class CloudServicesTable extends Component {
  render() {
    const { results } = this.props;
    const cloudServices = results.analysis.high_level_issues.cloud_services;

    return (
      <div>
        {cloudServices.length === 0 ? (
          <span>No cloud services detected</span>
        ) : (
          <Table>
            <tbody>
              {cloudServices.map((service, index) => {
                const iconClass = getServiceIcon(service);
                return (
                  <tr key={index}>
                    <td>
                      {iconClass && <i className={iconClass} />} {service}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  results: appResults.results,
});

export default connect(mapStateToProps)(CloudServicesTable);
