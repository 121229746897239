import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { toastrHelper } from '../../logic/toastrHelper';

import AppApprovalTab from './AppApprovalTab';

export default function DeniedAppsTab({ isActive, group }) {
  const [appsList, setAppsList] = useState([]);
  const appApprovalRequestUrl = group
    ? `/adminV2/app-approval/request/${group.id}`
    : '/app-approval/request';
  const { mastV2 } = useFlags();
  const [isLoading, setIsLoading] = useState(false);

  async function fetchDeniedApps() {
    try {
      setIsLoading(true);
      const deniedAppsUrl = group
        ? `/adminV2/app-approval/apps/${group.id}`
        : `/app-approval/apps`;
      const response = await axios.get(`${deniedAppsUrl}?status=denied`);

      setAppsList(
        response.data.map(app => ({
          id: app.id,
          developer: app.storeInfo?.developer ?? app.farmApp?.developer,
          createdAt: app.createdAt,
          requestedBy: app.requestedBy,
          status: app.status,
          platform: app.platform,
          package: app.package,
          name: app.storeInfo?.name ?? app.farmApp?.name,
          iconUrl: app.storeInfo?.iconUrl ?? app.farmApp?.iconSmallUrl,
          updatedAt: app.updatedAt,
          lastUpdatedBy: app.lastUpdatedBy,
        })),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function approveApp(appId) {
    try {
      await axios.put(appApprovalRequestUrl, {
        requestId: appId,
        status: 'approved',
      });

      toastrHelper.showSuccessToast(
        `App was approved successfully`,
        'Success',
        mastV2,
      );
    } catch (err) {
      toastrHelper.showErrorToast(
        `Error approving app: ${err.response.data.message}`,
        'Error',
        mastV2,
      );
    } finally {
      fetchDeniedApps();
    }
  }

  useEffect(() => {
    if (isActive) {
      fetchDeniedApps();
    }
  }, [isActive, group]);

  const fields = [
    'developer',
    'createdAt',
    'requestedBy',
    'updatedAt',
    'lastUpdatedBy',
  ];
  const fieldNames = {
    developer: 'Developer',
    createdAt: 'Submitted On',
    requestedBy: 'Submitted By',
    lastUpdatedBy: 'Denied By',
    updatedAt: 'Denied On',
  };
  const actions = { approve: approveApp };
  return (
    <AppApprovalTab
      tabName="Denied Apps"
      fields={fields}
      data={appsList}
      columnDisplayNamesMapping={fieldNames}
      actions={actions}
      isLoading={isLoading}
    />
  );
}
