import React from 'react';
import { CardText } from 'reactstrap';

import DataTable from '../../../DataTable/DataTable';
import MissingCompileProtectionEvidence from '../../SimpleReport/ReportFindingCard/IosEvidence/MissingCompileProtectionEvidence';

import { Issues, IsDetected } from './Issues';

export const V7_9 = ({ platform, evidence }) => (
  <>
    <Issues
      evidence={evidence}
      issues={['MISSING_COMPILE_PROTECTION', 'NO_RASP_DETECTED', 'ALLOWBACKUP']}
    />
    <hr />

    <CardText>
      <strong>App Protection: </strong>
      <IsDetected detected={evidence.appProtection} />
    </CardText>
    <hr />

    <CardText>
      <strong>Allow Backup: </strong>
      <IsDetected detected={evidence.allowBackup} />
    </CardText>
    <hr />

    {platform === 'android' ? (
      <>
        <CardText>
          <strong>Stack Protection</strong>
        </CardText>
        {evidence.stackProtection && evidence.stackProtection.length > 0 ? (
          <DataTable
            data={evidence.stackProtection}
            columns={[
              {
                label: 'Binary',
                component: ({ rowData }) => <p>{rowData}</p>,
              },
            ]}
          />
        ) : (
          <CardText>
            All libraries packaged with the app have sufficient stack protection
            flags.
          </CardText>
        )}
      </>
    ) : (
      <>
        <CardText>
          <strong>Compile Protection</strong>
        </CardText>
        <MissingCompileProtectionEvidence />
      </>
    )}
  </>
);
