import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QAlert } from './Q-Components/QAlert';
import { QRow } from './Q-Components/QRow';
import { QCol } from './Q-Components/QCol';
import { QTypography } from './Q-Components/QTypography';

export const GroupNotifications = () => {
  const { mastV2 } = useFlags();
  const groupNotifications = useSelector(
    state => state.emmApp.groupNotifications,
  );

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setNotifications(groupNotifications);
  }, [groupNotifications]);

  const hideNotifications = id => {
    setNotifications(
      notifications.filter(notification => notification.id !== id),
    );
  };

  const getAlertColor = severity => severity?.toLowerCase() || 'info';
  const getAlertIcon = {
    info: 'fa-info-circle',
    warning: 'fa-exclamation-circle',
    success: 'fa-check-circle',
    error: 'fa-ban-circle',
  };

  return (
    <>
      {notifications.length > 0 && (
        <div
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
            overflowX: 'hidden',
            marginBottom: '1rem',
          }}
        >
          {notifications.map(({ id, severity, title, notification }) => (
            <QRow sx={{ mb: 2 }}>
              <QCol sx={{ width: '100%' }}>
                <QAlert
                  variant="outlined"
                  severity={getAlertColor(severity)}
                  color={getAlertColor(severity)}
                  onClose={() => hideNotifications(id)}
                  isOpen={notifications.find(
                    notification => notification.id === id,
                  )}
                  toggle={() => hideNotifications(id)}
                >
                  <QTypography component="h6" variant="h6Bold">
                    {!mastV2 ? (
                      <span>
                        <h6>
                          <i
                            className={`fas ${
                              getAlertIcon[getAlertColor(severity)]
                            } mr-2`}
                          />
                          <strong>{title}</strong>
                        </h6>
                      </span>
                    ) : (
                      title
                    )}
                  </QTypography>
                  <QTypography component="p" variant="h6">
                    <p>{notification}</p>
                  </QTypography>
                </QAlert>
              </QCol>
            </QRow>
          ))}
        </div>
      )}
      <hr />
    </>
  );
};
