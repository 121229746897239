import React from 'react';

import { QDoughnut } from '../../Q-Components/QDoughnut';
import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';
import { QTypography } from '../../Q-Components/QTypography';

export const MaliciousScore = ({ score = 0 }) => {
  const maliciousScoreFloat = parseFloat(score);

  let color = '#ddd';
  if (maliciousScoreFloat >= 90.0) {
    color = '#800000';
  } else if (maliciousScoreFloat >= 75.0) {
    color = '#F44336';
  } else if (maliciousScoreFloat >= 35.0) {
    color = '#FF9800';
  } else {
    color = '#2196F3';
  }

  return (
    <QRow>
      <QCol style={{ textAlign: 'center' }}>
        <h6>
          <QTypography variant="h6" component="h6">
            <strong>Malicious Score</strong>
          </QTypography>
        </h6>
        <QDoughnut
          value={maliciousScoreFloat}
          data={{
            datasets: [
              {
                data: [maliciousScoreFloat, 100 - maliciousScoreFloat],
                backgroundColor: [color, '#9E9E9E'],
                borderWidth: 0,
              },
            ],
          }}
          options={{
            circumference: 180,
            rotation: -90,
            animation: {
              animateRotate: false,
            },
            responsive: true,
            cutout: '80%',
            plugins: {
              tooltip: {
                enabled: false,
              },
            },
          }}
          noLegend
        />
      </QCol>
    </QRow>
  );
};
