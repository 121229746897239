import React from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QConditionalCard } from '../Q-Components/QConditionalCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QContainer } from '../Q-Components/QContainer';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QInput } from '../Q-Components/QInput';
import { QButton } from '../Q-Components/QButton';
import { changeReviewStatusFilter } from '../../store/slices/summarizedAppsSlice';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import PlatformSelect from '../PlatformSelect';
import HelpButton from '../HelpButton';
import Loading from '../Loading';
import ConfirmationModal from '../Modals/ConfirmationModal';
import AsyncTablePagination from '../AsyncTablePagination';
import i18n from '../../localization/i18n';
import { QTable } from '../Q-Components/QTable';
import { QTableHead } from '../Q-Components/QTableHead';
import { QTableRow } from '../Q-Components/QTableRow';
import { QTableCell } from '../Q-Components/QTableCell';
import { QTableBody } from '../Q-Components/QTableBody';
import { QSelect } from '../Q-Components/QSelect';
import { QInputGroup } from '../Q-Components/QInputGroup';
import { QLabel } from '../Q-Components/QLabel';
import { QInputGroupAddon } from '../Q-Components/QInputGroupAddon';
import { QSearchBar } from '../Q-Components/QSearchBar';

import SummarizedApp from './SummarizedApp';

export const SummarizedAppsTable = ({
  getApps,
  removeApp,
  showConfirmModal,
  toggleConfirmModal,
  handleKeyPress,
  handleTextChange,
  removeSearchQuery,
  changeSortOption,
  handlePageSizeChange,
  handlePlatformChange,
  pagination,
  loading,
  data,
  filters,
  resubmitApp,
  showResubmitConfirmModal,
  toggleResubmitConfirmModal,
  toggleSettingsModal,
  columnSettings,
  downloadCsv,
}) => {
  const {
    page,
    pageSize,
    platformFilter,
    sortBy,
    currentSearchQuery,
    searchQuery,
  } = filters;

  const { userSubGroups } = useSelector(state => state.emmApp);
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();

  const tempSortByV2 = sortBy?.value || null;
  const tempSortBy = { ...sortBy };
  tempSortBy.label = i18n.t(tempSortBy.label);

  const tempPlatformFilterV2 = platformFilter?.value || null;
  const tempPlatformFilter = { ...platformFilter };
  tempPlatformFilter.label = i18n.t(tempPlatformFilter.label);

  const orderByOptions = [
    {
      value: 'dateSubmitted',
      label: i18n.t('Date Submitted'),
      clearableValue: false,
    },
    {
      value: 'appPopularity',
      label: i18n.t('App Popularity'),
      clearableValue: false,
    },
  ];

  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  const platformOptions = [
    { value: 'both', label: i18n.t('Both'), clearableValue: false },
    { value: 'android', label: 'Android', clearableValue: false },
    { value: 'ios', label: 'iOS', clearableValue: false },
  ];

  // Hide Non associated options if user is not part of a sub group
  const subGroupOptionsSelect =
    Array.isArray(userSubGroups) && userSubGroups.length > 0
      ? [{ value: 'none', label: 'Non Associated' }]
      : [];

  userSubGroups.forEach(subGroup => {
    subGroupOptionsSelect.push({ value: subGroup.id, label: subGroup.name });
  });

  const changePlatform = (event, newPlatform) => {
    if (mastV2) {
      const newOption =
        typeof newPlatform.props.value === 'string'
          ? platformOptions.find(
              option => option.value === newPlatform.props.value,
            )
          : newPlatform.props.value;
      handlePlatformChange(newOption);
    } else {
      handlePlatformChange(event);
    }
  };

  const changePageSize = (event, newPageSize) => {
    if (mastV2) {
      handlePageSizeChange(newPageSize.props.value);
    } else {
      handlePageSizeChange(event);
    }
  };

  const changeSortBy = (event, newOrderBy) => {
    if (mastV2) {
      const newOption = orderByOptions.find(
        option => option.value === newOrderBy.props.value,
      );
      changeSortOption(newOption);
    } else {
      changeSortOption(event);
    }
  };

  return (
    <div className="animated fadeIn">
      <ConfirmationModal
        isOpen={showConfirmModal}
        toggle={toggleConfirmModal}
        confirm={removeApp}
        confirmHeader={i18n.t('Application Removal')}
        confirmBody={i18n.t(
          'Are you sure you want to remove this application? If this application is on your watchlist and is the most recent version of that app, it will also be removed from your watchlist.',
        )}
      />

      <ConfirmationModal
        isOpen={showResubmitConfirmModal}
        toggle={toggleResubmitConfirmModal}
        confirm={resubmitApp}
        confirmHeader={i18n.t('Application Resubmission')}
        confirmBody={i18n.t(
          'Are you sure you want to resubmit this application for analysis?',
        )}
      />
      <QConditionalCard className="card-accent-info" id="analyzed-apps-card">
        {!mastV2 && (
          <QCardHeader className="analyzed-apps-card-header">
            <div className="d-flex justify-between">
              <div>
                <strong>{i18n.t('Analyzed Applications')}</strong>{' '}
                <HelpButton helpItem="analyzedApps" />
              </div>
              <div className="d-flex justify-between">
                <QButton
                  color="link"
                  size="sm"
                  className="fas fa-cog fa-lg pointer black-icon"
                  onClick={() => toggleSettingsModal()}
                  data-testid="toggle-settings-modal"
                />
                <QButton
                  color="link"
                  size="sm"
                  className="fas fa-sync-alt pointer black-icon"
                  onClick={() => {
                    dispatch(invalidateAnalyzedAppsCache());
                    getApps(1);
                  }}
                  data-testid="refresh-analyzed-apps-summary"
                />
                <QButton
                  color="link"
                  size="sm"
                  onClick={async () => downloadCsv(false)}
                  data-testid="download-csv-summarized-page"
                >
                  <i className="fas fa-download black-icon" />
                </QButton>
              </div>
            </div>
          </QCardHeader>
        )}
        <QContainer
          fluid
          style={
            mastV2
              ? { padding: '0px', background: 'none', minWidth: '100%' }
              : {}
          }
        >
          <QRow
            className="analyzed-apps-filter-options"
            columnGap="12px"
            sx={{ mb: '12px', padding: '12px' }}
          >
            {/* Filtering options */}
            {/* Search query */}
            {!mastV2 && (
              <QCol xs="3">
                <QLabel for="app-query-input" style={{ paddingTop: '5px' }}>
                  <strong>Search </strong>
                  <HelpButton helpItem="appSearch" />
                </QLabel>
                <QInputGroup>
                  <QInput
                    type="text"
                    id="app-query-input"
                    onKeyPress={e => handleKeyPress(e)}
                    onChange={handleTextChange}
                    placeholder={i18n.t('Search Apps')}
                    value={searchQuery}
                  />
                  {currentSearchQuery && (
                    <QInputGroupAddon
                      className="pointer input-group-warning"
                      onClick={removeSearchQuery}
                      addonType="append"
                    >
                      <span className="input-group-text">
                        <i className="fa fa-times" />
                      </span>
                    </QInputGroupAddon>
                  )}
                </QInputGroup>
              </QCol>
            )}
            {/* Column to sort by */}
            <QCol xs="2" sx={{ width: '15%' }}>
              {!mastV2 && (
                <QLabel for="column-sort-select" style={{ paddingTop: '5px' }}>
                  <strong>Sort by:</strong>
                </QLabel>
              )}
              <QSelect
                size="small"
                options={orderByOptions}
                value={mastV2 ? tempSortByV2 : tempSortBy}
                onChange={changeSortBy}
                label="Sort by"
                selectIconProps={{ fontSize: 'medium' }}
                formControlProps={{ sx: { width: '100%' } }}
              />
            </QCol>

            {/* Apps to show per page */}
            {!mastV2 && (
              <QCol xs="2">
                <QLabel
                  for="apps-per-page-select"
                  style={{ paddingTop: '5px' }}
                >
                  <strong>Apps per page:</strong>
                </QLabel>
                <QSelect
                  options={pageSizeOptions}
                  value={pageSize}
                  onChange={changePageSize}
                  clearable={false}
                  isSearchable={false}
                  className="z-index-10"
                />
              </QCol>
            )}

            {/* Platform options */}
            <QCol xs="2" id="platform-filter-options" sx={{ width: '15%' }}>
              <PlatformSelect
                handlePlatformChange={changePlatform}
                platformFilter={
                  mastV2 ? tempPlatformFilterV2 : tempPlatformFilter
                }
                mastV2={mastV2}
              />
            </QCol>
            {mastV2 && <QCol sx={{ width: '60%' }} />}
            {mastV2 && (
              <QCol xs="2" sx={{ width: '7%' }}>
                <div className="d-flex justify-between">
                  <QButton
                    variant="outlined"
                    color="link"
                    size="sm"
                    startIcon={
                      <i
                        className="fas fa-cog fa-lg pointer"
                        style={{ fontSize: '20px', fontWeight: '400' }}
                      />
                    }
                    onClick={() => toggleSettingsModal()}
                    data-testid="toggle-settings-modal"
                    style={{
                      border: 'none',
                      background: 'none',
                      padding: '0px',
                      minWidth: 'unset',
                    }}
                  />
                  <QButton
                    variant="outlined"
                    color="link"
                    size="sm"
                    startIcon={
                      <i
                        className="fas fa-sync-alt pointer"
                        style={{ fontSize: '20px', fontWeight: '400' }}
                      />
                    }
                    onClick={() => {
                      dispatch(invalidateAnalyzedAppsCache());
                      getApps(1);
                    }}
                    data-testid="refresh-analyzed-apps-summary"
                    style={{
                      border: 'none',
                      background: 'none',
                      padding: '0px',
                      minWidth: 'unset',
                    }}
                  />
                  <QButton
                    variant="outlined"
                    color="link"
                    size="sm"
                    startIcon={
                      <i
                        className="fas fa-download"
                        style={{ fontSize: '20px', fontWeight: '400' }}
                      />
                    }
                    onClick={async () => downloadCsv(false)}
                    data-testid="download-csv-summarized-page"
                    style={{
                      border: 'none',
                      background: 'none',
                      padding: '0px',
                      minWidth: 'unset',
                    }}
                  />
                </div>
              </QCol>
            )}
          </QRow>
          {mastV2 && (
            <QRow sx={{ mb: '12px', ml: '12px', mr: '12px' }}>
              <QCol sx={{ width: '100%' }}>
                <QSearchBar
                  searchValue={searchQuery}
                  onChange={handleTextChange}
                  onRemove={removeSearchQuery}
                  onKeyPress={handleKeyPress}
                />
              </QCol>
            </QRow>
          )}
        </QContainer>
        <QCardBody style={mastV2 ? { width: '100%' } : {}}>
          <QRow>
            {/* Table pagination */}
            <QCol
              style={mastV2 ? { width: '100%' } : {}}
              className="text-right analyzed-apps-table-pagination"
            >
              <AsyncTablePagination
                pagination={pagination}
                page={page}
                getPage={getApps}
                showRowsPerPage
                currentRows={data.length}
                handlePageSizeChange={handlePageSizeChange}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
              />
            </QCol>
          </QRow>
          {/* If we're loading, display the table */}
          {loading ? (
            <Loading />
          ) : (
            <div>
              <QTable responsive style={{ tableLayout: 'fixed' }}>
                <QTableHead>
                  <QTableRow>
                    <QTableCell width="49%" className="text-left">
                      {i18n.t('App')}&nbsp;
                      <HelpButton helpItem="appColumn" />
                    </QTableCell>

                    {columnSettings.dateCol && (
                      <QTableCell width="16%" className="text-left">
                        {i18n.t('Last Submission')}&nbsp;
                        <HelpButton helpItem="dateSubmitted" />
                      </QTableCell>
                    )}
                    {columnSettings.scoreCol && (
                      <QTableCell width="13%" className="text-left">
                        {i18n.t('Threat Score(s)')}&nbsp;
                        <HelpButton helpItem="threatScoreChart" />
                      </QTableCell>
                    )}
                    {columnSettings.findingsCol && (
                      <QTableCell width="18%" className="text-left">
                        {i18n.t('Number of Issues')}&nbsp;
                        <HelpButton helpItem="summaryResultsChart" />
                      </QTableCell>
                    )}
                    <QTableCell width="15%" className="text-center">
                      {i18n.t('Actions')}&nbsp;
                      <HelpButton helpItem="reportColumn" />
                    </QTableCell>
                  </QTableRow>
                </QTableHead>
                <QTableBody>
                  {data.map((app, index) => (
                    <SummarizedApp
                      app={app}
                      key={index}
                      getApps={getApps}
                      resubmitApp={toggleResubmitConfirmModal}
                      removeApp={toggleConfirmModal}
                      userHasSubGroup={subGroupOptionsSelect.length > 0}
                    />
                  ))}
                </QTableBody>
              </QTable>
              <QRow>
                <QCol className="text-right">
                  <AsyncTablePagination
                    pagination={pagination}
                    getPage={getApps}
                    page={page}
                  />
                </QCol>
              </QRow>
            </div>
          )}
        </QCardBody>
      </QConditionalCard>
    </div>
  );
};

const mapStateToProps = ({ summarizedApps, emmApp }) => ({
  filters: summarizedApps,
  lang: emmApp.portalConfig.lang,
  columnSettings: emmApp.columnSettings,
});

const mapDispatchToProps = {
  changeReviewStatusFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(SummarizedAppsTable));
