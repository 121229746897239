import React, { useRef } from 'react';
import { Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { usePassword, PasswordHint } from '../NewUserPassword/PasswordMeter';
import i18n from '../../localization/i18n';
import { setPasswordChangeOpen } from '../../store/slices/emmAppSlice';
import { ErrorMessage } from '../NewUserPassword/ErrorMessage';
import { QModal } from '../Q-Components/QModal';
import { QModalContent } from '../Q-Components/QModalContent';
import { QTypography } from '../Q-Components/QTypography';
import { QModalActions } from '../Q-Components/QModalActions';
import { QButton } from '../Q-Components/QButton';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QRow } from '../Q-Components/QRow';
import { QLabel } from '../Q-Components/QLabel';
import { QInput } from '../Q-Components/QInput';
import { QForm } from '../Q-Components/QForm';
import { QFormGroup } from '../Q-Components/QFormGroup';

const PasswordChangeForm = ({
  methods,
  changePasswordValidation,
  errorMessage,
}) => {
  const { mastV2 } = useFlags();

  const getRef = field => {
    const reg = methods.register(changePasswordValidation[field]);
    return mastV2 ? { inputRef: reg } : { innerRef: reg };
  };

  const getLabel = field =>
    i18n.t(
      field === 'currentPassword'
        ? 'Current Password'
        : field === 'newPassword'
        ? 'New Password'
        : 'Confirm New Password',
    );

  return (
    <FormProvider {...methods}>
      <QForm>
        {['currentPassword', 'newPassword', 'confirmPassword'].map(field => (
          <QFormGroup key={field}>
            <QRow
              sx={{
                width: '100%',
                mt: 1,
              }}
            >
              <QLabel htmlFor={field}>
                <QTypography variant="h5Medium">{getLabel(field)}</QTypography>
              </QLabel>
            </QRow>
            <QRow sx={{ width: '100%', mt: 1 }}>
              <QInput
                name={field}
                id={field}
                type="password"
                placeholder={getLabel(field)}
                {...getRef(field)}
                fullWidth
              />
            </QRow>
            <QRow sx={{ width: '100%', mt: 1 }}>
              <ErrorMessage>{errorMessage(field)}</ErrorMessage>
            </QRow>
          </QFormGroup>
        ))}
      </QForm>
    </FormProvider>
  );
};

export const PasswordChangeModal = ({ confirm }) => {
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();

  const changeModalOpen = useSelector(
    ({ emmApp }) => emmApp.passwordChangeOpen,
  );

  const toggle = () => {
    dispatch(setPasswordChangeOpen(!changeModalOpen));
  };

  const methods = useForm({
    mode: 'onTouched',
  });

  const { handleSubmit, watch, errors, formState } = methods;

  const { isValid, isDirty } = formState;

  const newPassword = useRef({});
  newPassword.current = watch('newPassword');

  const passwordHook = usePassword(newPassword.current);

  const changePasswordValidation = {
    currentPassword: {
      required: 'Current password is required',
    },

    newPassword: {
      required: 'New password is required',
      validate: () =>
        passwordHook.isStrong || <PasswordHint {...passwordHook} />,
    },

    confirmPassword: {
      required: 'Confirm password is required',
      validate: value =>
        value === newPassword.current ||
        'New password and confirm password do not match',
    },
  };

  const errorMessage = field => {
    const hasError = !!(errors && errors[field]);

    return (
      hasError && (
        <span>
          <i className="far fa-exclamation-circle mr-1" />
          {errors[field].message}
        </span>
      )
    );
  };

  const Title = () => (
    <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
      <QTypography variant="h3Medium">
        {!mastV2 && <i className="fas fa-lock" />} {i18n.t('Change Password')}
      </QTypography>
    </QModalTitle>
  );

  const Footer = () => (
    <QModalActions sx={{ padding: '0px 32px 16px 0px' }}>
      <QButton variant="outlined" color="secondary" onClick={() => toggle()}>
        {!mastV2 && <i className="far fa-times" />}
        &nbsp;{i18n.t('Cancel')}
      </QButton>
      <QButton
        id="change-password-btn"
        variant="filled"
        color="primary"
        disabled={!isDirty || !isValid}
        onClick={handleSubmit(confirm)}
      >
        {!mastV2 && <i className="far fa-check-circle" />}
        &nbsp;{i18n.t('Submit')}
      </QButton>
    </QModalActions>
  );

  return (
    <QModal
      isOpen={changeModalOpen}
      open={changeModalOpen}
      toggle={() => toggle()}
      title={<Title />}
      content={
        <QModalContent sx={{ padding: '16px 29px 29px 16px' }}>
          <PasswordChangeForm
            methods={methods}
            changePasswordValidation={changePasswordValidation}
            errorMessage={errorMessage}
          />
        </QModalContent>
      }
      actions={<Footer />}
    >
      {!mastV2 && (
        <>
          <Title />
          <Container fluid>
            <QModalContent sx={{ padding: '16px 29px 29px 16px' }}>
              <PasswordChangeForm
                methods={methods}
                changePasswordValidation={changePasswordValidation}
                errorMessage={errorMessage}
              />
            </QModalContent>
          </Container>
          <Footer />
        </>
      )}
    </QModal>
  );
};
