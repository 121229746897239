import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Collapse } from 'reactstrap';

import MarketInfoTable from '../MarketInfoTable';
import UrlConstantsDatatable from '../UrlConstantsDatatable';
import CryptoOperationsDatatable from '../CryptoOperationsDatatable';
import TabsView from '../../../TabsView';

export default class M7ios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      collapseButton: 'up',
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    this.setState({
      isOpen: !this.state.isOpen,
      collapseButton: this.state.collapseButton === 'up' ? 'down' : 'up',
    });
  }

  render() {
    const { owasp, owaspIssues } = this.props;
    const { isOpen, collapseButton } = this.state;

    const tabs = [
      {
        title: 'Market Information',
        tabContent: () => (
          <Row>
            <Col className="card-accent-warning market-info-section">
              <MarketInfoTable />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Url Constants',
        tabContent: () => (
          <Row>
            <Col>
              <UrlConstantsDatatable platform="ios" />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Crypto Operations',
        tabContent: () => (
          <Row>
            <Col>
              <CryptoOperationsDatatable />
            </Col>
          </Row>
        ),
      },
    ];

    return (
      <div>
        <Card
          className={
            owaspIssues.length === 0
              ? 'card-accent-success'
              : 'card-accent-danger'
          }
        >
          <CardHeader onClick={this.toggleCollapse} className="pointer">
            <Row>
              <Col className="text-left">{owasp}</Col>
              <Col className="text-right">
                <i className={`fas fa-lg fa-angle-${collapseButton}`} />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody>
              <Row>
                <Col xs="6">
                  <div>
                    Threat Agents include entities that can pass untrusted
                    inputs to method calls made within mobile code. These types
                    of issues are not necessarily security issues in and of
                    themselves but lead to security vulnerabilities. For
                    example, buffer overflows within older versions of Safari (a
                    poor code quality vulnerability) led to high risk drive-by
                    Jailbreak attacks. Poor code-quality issues are typically
                    exploited via malware or phishing scams.
                  </div>
                  <div>
                    <a
                      href="https://owasp.org/www-project-mobile-top-10/2016-risks/m7-client-code-quality"
                      style={{ display: 'table-cell' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      <em>More Info</em>{' '}
                    </a>
                  </div>
                </Col>
                {owaspIssues.length !== 0 ? (
                  <Col>
                    <strong>High level issues found: </strong>
                    <div>
                      {owaspIssues.map(issue => (
                        <div>
                          <i className="fas fa-exclamation-triangle warning-icon" />{' '}
                          {issue}
                        </div>
                      ))}
                    </div>
                  </Col>
                ) : null}
              </Row>
              <hr />
              <strong>Additional Analysis Information:</strong>
              <TabsView tabs={tabs} />
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}
