import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTypography } from '@kw/quokka-ui';

import { QContainer } from '../Q-Components/QContainer';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import util from '../../logic/util';
import i18n from '../../localization/i18n';
import { getAssets } from '../../assets/assets';

/**
 * Format the app issues to the proper HTML
 * @param {*} issues
 */
const QAnalyzedAppFindings = ({ parsedIssues }) => {
  const { mastV2 } = useFlags();
  // Get a list of high risk items as configured by the user since we display
  // these by default in the summary section
  const issuesHtml = parsedIssues
    .filter(
      item =>
        item.found &&
        item.review_status !== 'passed' &&
        (item.risk === 'high' ||
          item.risk === 'critical' ||
          item.risk === 'medium'),
    )
    .map((issue, index) => {
      const issueInfo = util.formatAppIssue(issue.name[0], mastV2);
      // Check to see if an icon was found for the item
      // The util function will return just the issue string if no icon was found
      return mastV2 ? (
        <QContainer style={{ padding: '0px', background: 'none' }}>
          <QRow columnGap="4px">
            <QCol>
              <i
                style={{
                  color:
                    getAssets[process.env.REACT_APP_APPLIANCE ?? 'default']
                      .palette?.text?.secondary ?? '#244C5A',
                  height: '12px',
                }}
                className={`${
                  issueInfo.iconClass || 'fas fa-exclamation-circle issue-icon'
                } fa-fw`}
              />
            </QCol>
            <QCol>
              {' '}
              <QTypography
                variant="h6Medium"
                color="#757575"
                className="wrap-text"
              >
                {i18n.t(issue.positive_finding_text)}
              </QTypography>
            </QCol>
          </QRow>
        </QContainer>
      ) : (
        <div key={index} className="float-left ml-2">
          <i
            className={issueInfo.iconClass || 'fas fa-exclamation issue-icon'}
          />{' '}
          {i18n.t(issue.positive_finding_text)}
        </div>
      );
    })
    .filter(item => item);

  if (issuesHtml.length === 0) {
    return <span>{i18n.t('No high risk issues found')}</span>;
  }

  // Filter out any items that are blank in the array and then join then by ', '
  return issuesHtml;
};

export const AnalyzedAppFindings = withLDConsumer()(QAnalyzedAppFindings);
