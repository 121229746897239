import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Collapse } from 'reactstrap';

import DynamicAnalysisDatatable from '../DynamicAnalysisDatatable';
import SensitiveDataExposureDatatable from '../../SensitiveDataExposureDatatable';
import CountriesContactedList from '../../CountriesContactedList';
import { Harviewer } from '../../../Harviewer/Harviewer';
import TabsView from '../../../TabsView';

export default class M3android extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      collapseButton: 'up',
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    this.setState({
      isOpen: !this.state.isOpen,
      collapseButton: this.state.collapseButton === 'up' ? 'down' : 'up',
    });
  }

  render() {
    const {
      owasp,
      owaspIssues,
      showMap,
      latLongData,
      foreignConnections,
      platform,
    } = this.props;
    const { isOpen, collapseButton } = this.state;
    if (!showMap) return null;

    const tabs = [
      {
        title: 'Network Traffic',
        tabContent: () => (
          <Row>
            <Col>
              <Harviewer />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Countries Contacted',
        tabContent: () => (
          <Row>
            <Col>
              <CountriesContactedList
                latLongData={latLongData}
                foreignConnections={foreignConnections}
                platform={platform}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Dynamic Analysis Network events',
        tabContent: () => (
          <Row>
            <Col>
              <DynamicAnalysisDatatable onlySelectedCategory="Network events" />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Sensitive Data Exposure',
        tabContent: () => (
          <Row>
            <Col>
              <SensitiveDataExposureDatatable platform="android" />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Dynamic Analysis Telephony',
        tabContent: () => (
          <Row>
            <Col>
              <DynamicAnalysisDatatable onlySelectedCategory="Telephony" />
            </Col>
          </Row>
        ),
      },
    ];

    return (
      <div>
        <Card
          className={
            owaspIssues.length === 0
              ? 'card-accent-success'
              : 'card-accent-danger'
          }
        >
          <CardHeader onClick={this.toggleCollapse} className="pointer">
            <Row>
              <Col className="text-left">{owasp}</Col>
              <Col className="text-right">
                <i className={`fas fa-lg fa-angle-${collapseButton}`} />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody>
              <Row>
                <Col xs="6">
                  <div>
                    When designing a mobile application, data is commonly
                    exchanged in a client-server fashion. When the solution
                    transmits its data, it must traverse the mobile
                    device&apos;s carrier network and the internet. Threat
                    agents might exploit vulnerabilities to intercept sensitive
                    data while it&apos;s traveling across the wire. The
                    following threat agents exist:
                    <ul>
                      <li>
                        An adversary that shares your local network (compromised
                        or monitored Wi-Fi)
                      </li>
                      <li>
                        Carrier or network devices (routers, cell towers,
                        proxy&apos;s, etc)
                      </li>
                      <li>Malware on your mobile device</li>
                    </ul>
                  </div>
                  <div>
                    <a
                      href="https://owasp.org/www-project-mobile-top-10/2016-risks/m3-insecure-communication"
                      style={{ display: 'table-cell' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      <em>More Info</em>{' '}
                    </a>
                  </div>
                </Col>
                {owaspIssues.length !== 0 ? (
                  <Col>
                    <strong>High level issues found: </strong>
                    <div>
                      {owaspIssues.map(issue => (
                        <div>
                          <i className="fas fa-exclamation-triangle warning-icon" />{' '}
                          {issue}
                        </div>
                      ))}
                    </div>
                  </Col>
                ) : null}
              </Row>
              <hr />
              <strong>Additional Analysis Information:</strong>
              <TabsView tabs={tabs} />
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}
