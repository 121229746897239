import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QForm } from '../Q-Components/QForm';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QLabel } from '../Q-Components/QLabel';
import { QInput } from '../Q-Components/QInput';
import { QButton } from '../Q-Components/QButton';
import { QTypography } from '../Q-Components/QTypography';
import { QInputIconWrapper } from '../Q-Components/QInputIconWrapper';
import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';

import CurrentIntegrationDetails from './MdmIntegrationCredentials/CurrentIntegrationDetails';

const Title = ({ isUpdated }) => (
  <QTypography variant="h4Bold" color="#000000">
    {i18n.t(`MDM Integration Credentials ${isUpdated ? 'Update' : ''}`)}
  </QTypography>
);

class MdmIntegrationCredentials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updateMdmTab: 0,
      passwordHidden: true,
      integration: '',
      host: '',
      username: '',
      password: '',
      tenantCode: '',
      billingId: '',
      appId: '',
      appVersion: '',
      platformId: '',
      appAccessKey: '',
      updatedAt: '',
      createdAt: '',
    };

    this.updateMdmCredentials = this.updateMdmCredentials.bind(this);
  }

  async componentDidMount() {
    const credentialsResponse = await axios.get(
      'mdm-integration/mdm-integration-credentials',
    );

    if (credentialsResponse.data.credentials !== null) {
      console.log(
        'Found existing credentials:',
        credentialsResponse.data.credentials,
      );

      const { credentials } = credentialsResponse.data;

      this.setState({
        integration: credentials.type,
        updatedAt: credentials.updatedAt,
        createdAt: credentials.createdAt,
        username: credentials.username,
        host: credentials.host,
        tenantCode: credentials.tenantCode,
        billingId: credentials.billingId,
        appId: credentials.appId,
        appVersion: credentials.appVersion,
        platformId: credentials.platformId,
        appAccessKey: credentials.appAccessKey,
      });
    }
  }

  async updateMdmCredentials() {
    // Delete the credentials for the user first
    await axios.delete('mdm-integration/mdm-integration-credentials');

    if (this.state.integration === 'airwatch') {
      try {
        const patchResponse = await axios.patch(
          'mdm-integration/mdm-integration-credentials/airwatch',
          {
            host: this.state.host,
            username: this.state.username,
            password: this.state.password,
            tenantCode: this.state.tenantCode,
          },
        );
        if (patchResponse.status === 200) {
          toastrHelper.showSuccessToast(
            i18n.t('Successfully updated MDM credentials'),
            null,
            this.props.flags?.mastV2,
          );
        } else if (patchResponse.status === 400) {
          toastrHelper.showErrorToast(
            i18n.t('Error: Data missing'),
            null,
            this.props.flags?.mastV2,
          );
        }
      } catch (err) {
        console.log('Error updating MDM Integration credentials:', err);
        toastrHelper.showErrorToast(
          i18n.t('Error updating MDM credentials'),
          null,
          this.props.flags?.mastV2,
        );
      }
    }

    if (this.state.integration === 'maas360') {
      try {
        const patchResponse = await axios.patch(
          'mdm-integration/mdm-integration-credentials/maas360',
          {
            host: this.state.host,
            username: this.state.username,
            password: this.state.password,
            billingId: this.state.billingId,
            appId: this.state.appId,
            appVersion: this.state.appVersion,
            platformId: this.state.platformId,
            appAccessKey: this.state.appAccessKey,
          },
        );
        if (patchResponse.status === 200) {
          toastrHelper.showSuccessToast(
            i18n.t('Successfully updated MDM credentials'),
            null,
            this.props.flags?.mastV2,
          );
        } else if (patchResponse.status === 400) {
          toastrHelper.showErrorToast(
            i18n.t('Error: Data missing'),
            null,
            this.props.flags?.mastV2,
          );
        }
      } catch (err) {
        console.log('Error updating MDM Integration credentials:', err);
        toastrHelper.showErrorToast(
          i18n.t('Error updating MDM credentials'),
          null,
          this.props.flags?.mastV2,
        );
      }
    }

    if (this.state.integration === 'mobileiron') {
      try {
        const patchResponse = await axios.patch(
          'mdm-integration/mdm-integration-credentials/mobileiron',
          {
            host: this.state.host,
            username: this.state.username,
            password: this.state.password,
          },
        );
        if (patchResponse.status === 200) {
          toastrHelper.showSuccessToast(
            i18n.t('Successfully updated MDM credentials'),
            null,
            this.props.flags?.mastV2,
          );
        } else if (patchResponse.status === 400) {
          toastrHelper.showErrorToast(
            i18n.t('Error: Data missing'),
            null,
            this.props.flags?.mastV2,
          );
        }
      } catch (err) {
        console.log('Error updating MDM Integration credentials:', err);
        toastrHelper.showErrorToast(
          i18n.t('Error updating MDM credentials'),
          null,
          this.props.flags?.mastV2,
        );
      }
    }

    this.setState({ updateMdmTab: 0 });
  }

  render() {
    const { updateMdmTab, updatedAt, createdAt } = this.state;
    let setDate = updatedAt === null ? createdAt : updatedAt;
    setDate = moment(setDate).format('L - LTS');

    return (
      <div>
        {updateMdmTab === 0 ? (
          <QCard className="card-accent-primary">
            <QCardHeader title={<Title />}>
              <strong>
                <Title />
              </strong>
            </QCardHeader>
            <QCardBody>
              <QRow width="100%">
                <QCol width="100%">
                  <CurrentIntegrationDetails
                    setDate={setDate}
                    credentials={this.state}
                  />
                </QCol>
              </QRow>
              <QRow justifyContent="flex-end">
                <QCol className="text-right">
                  <QButton
                    variant="filled"
                    color="warning"
                    onClick={() => {
                      this.setState({ updateMdmTab: 1 });
                    }}
                  >
                    {i18n.t('Update')}
                  </QButton>
                </QCol>
              </QRow>
            </QCardBody>
          </QCard>
        ) : null}
        {updateMdmTab === 1 ? (
          <QCard className="card-accent-primary">
            <QCardHeader title={<Title isUpdated />}>
              <strong>
                <Title isUpdated />
              </strong>
            </QCardHeader>
            <QCardBody>
              <div className="text-center">
                <strong>
                  <QTypography variant="label">
                    {i18n.t('MDM Integration Options')}:
                  </QTypography>
                </strong>
                <hr className="no-padding" />
                <QRow width="100%" mt={1}>
                  {['Airwatch', 'MaaS360', ' MobileIron'].map(type => (
                    <QCol width="100%">
                      <QLabel className="radio-inline">
                        <QInput
                          type="radio"
                          className="cursor"
                          name="mdm-radio"
                          checked={
                            this.state.integration === type.toLowerCase()
                          }
                          value={type.toLowerCase()}
                          onChange={e => {
                            this.setState({
                              integration: e.target.value,
                            });
                          }}
                          InputProps={{
                            style: {
                              border: 'none',
                            },
                          }}
                        />
                        {type}
                      </QLabel>
                    </QCol>
                  ))}
                </QRow>
              </div>

              <QForm>
                <QRow width="100%" mt={1}>
                  <QCol xs="12" width="100%" p={1}>
                    <QLabel>
                      <strong>
                        <QTypography variant="label">
                          {i18n.t('Host')}
                        </QTypography>
                      </strong>
                    </QLabel>
                    <QInput
                      id="mdm-host-input"
                      sx={{ mt: 1 }}
                      value={this.state.host}
                      onChange={e => {
                        this.setState({ host: e.target.value });
                      }}
                    />
                  </QCol>
                </QRow>
                <QRow width="100%" mt={1}>
                  <QCol xs="6" width="100%" p={1}>
                    <QLabel>
                      <strong>
                        <QTypography variant="label">
                          {i18n.t('Username')}
                        </QTypography>
                      </strong>
                    </QLabel>
                    <QInput
                      id="mdm-username-input"
                      sx={{ mt: 1 }}
                      value={this.state.username}
                      onChange={e => {
                        this.setState({ username: e.target.value });
                      }}
                    />
                  </QCol>
                  <QCol xs="6" width="100%" p={1}>
                    <QLabel>
                      <strong>
                        <QTypography variant="label">
                          {i18n.t('Password')}
                        </QTypography>
                      </strong>
                    </QLabel>
                    <div style={{ position: 'relative' }}>
                      <QInput
                        id="mdm-password-input"
                        sx={{ mt: 1 }}
                        type={this.state.passwordHidden ? 'password' : 'text'}
                        value={this.state.password}
                        onChange={e => {
                          this.setState({ password: e.target.value });
                        }}
                        style={{ width: '100%' }}
                        InputProps={{
                          endAdornment: (
                            <QInputIconWrapper position="end">
                              <QButton
                                variant="ghost"
                                addonType="append"
                                onClick={() => {
                                  this.setState(prevState => ({
                                    passwordHidden: !prevState.passwordHidden,
                                  }));
                                }}
                              >
                                <i
                                  className={
                                    this.state.passwordHidden
                                      ? 'fas fa-eye-slash pointer'
                                      : 'fas fa-eye pointer'
                                  }
                                />
                              </QButton>
                            </QInputIconWrapper>
                          ),
                        }}
                      />

                      {!this.props.flags.mastV2 && (
                        <span
                          style={{
                            position: 'absolute',
                            top: '7px',
                            right: '20px',
                          }}
                        >
                          <i
                            className={
                              this.state.passwordHidden
                                ? 'far fa-eye-slash'
                                : 'far fa-eye'
                            }
                            onClick={() => {
                              this.setState(prevState => ({
                                passwordHidden: !prevState.passwordHidden,
                              }));
                            }}
                          />
                        </span>
                      )}
                    </div>
                  </QCol>
                </QRow>
                {this.state.integration === 'airwatch' ? (
                  <QRow width="100%" mt={1}>
                    <QCol xs="12" width="100%" p={1}>
                      <QLabel>
                        <strong>
                          <QTypography variant="label">
                            {i18n.t('Tenant Code')}
                          </QTypography>
                        </strong>
                      </QLabel>
                      <QInput
                        id="mdm-tenantcode-input"
                        sx={{ mt: 1 }}
                        value={this.state.tenantCode}
                        onChange={e => {
                          this.setState({ tenantCode: e.target.value });
                        }}
                      />
                    </QCol>
                  </QRow>
                ) : null}
                {this.state.integration === 'maas360' ? (
                  <QRow width="100%" mt={1}>
                    <QCol xs="4" width="100%" p={1}>
                      <QLabel>
                        <strong>
                          <QTypography variant="label">
                            {i18n.t('Billing ID')}
                          </QTypography>
                        </strong>
                      </QLabel>
                      <QInput
                        id="mdm-billingid-input"
                        sx={{ mt: 1 }}
                        value={this.state.billingId}
                        onChange={e => {
                          this.setState({ billingId: e.target.value });
                        }}
                      />
                    </QCol>
                    <QCol xs="8" width="100%" p={1}>
                      <QLabel>
                        <strong>
                          <QTypography variant="label">
                            {i18n.t('App ID')}
                          </QTypography>
                        </strong>
                      </QLabel>
                      <QInput
                        id="mdm-appid-input"
                        sx={{ mt: 1 }}
                        value={this.state.appId}
                        onChange={e => {
                          this.setState({ appId: e.target.value });
                        }}
                      />
                    </QCol>
                  </QRow>
                ) : null}
                {this.state.integration === 'maas360' ? (
                  <QRow width="100%" mt={1}>
                    <QCol xs="3" width="100%" p={1}>
                      <QLabel>
                        <strong>
                          <QTypography variant="label">
                            {i18n.t('App Version')}
                          </QTypography>
                        </strong>
                      </QLabel>
                      <QInput
                        id="mdm-appversion-input"
                        sx={{ mt: 1 }}
                        value={this.state.appVersion}
                        onChange={e => {
                          this.setState({ appVersion: e.target.value });
                        }}
                      />
                    </QCol>
                    <QCol xs="3" width="100%" p={1}>
                      <QLabel>
                        <strong>
                          <QTypography variant="label">
                            {i18n.t('Platform ID')}
                          </QTypography>
                        </strong>
                      </QLabel>
                      <QInput
                        id="mdm-platformid-input"
                        sx={{ mt: 1 }}
                        value={this.state.platformId}
                        onChange={e => {
                          this.setState({ platformId: e.target.value });
                        }}
                      />
                    </QCol>
                    <QCol xs="6" width="100%" p={1}>
                      <QLabel>
                        <strong>
                          <QTypography variant="label">
                            {i18n.t('App Access Key')}
                          </QTypography>
                        </strong>
                      </QLabel>
                      <QInput
                        id="mdm-appaccesskey-input"
                        sx={{ mt: 1 }}
                        value={this.state.appAccessKey}
                        onChange={e => {
                          this.setState({ appAccessKey: e.target.value });
                        }}
                      />
                    </QCol>
                  </QRow>
                ) : null}
              </QForm>
              <QRow
                className="mt-3 text-center"
                width="100%"
                mt={3}
                justifyContent="center"
                alignItems="center"
              >
                <QCol xs="4" />
                <QCol xs="4">
                  <QButton
                    variant="filled"
                    color="warning"
                    block
                    onClick={this.updateMdmCredentials}
                  >
                    {i18n.t('Update')}
                  </QButton>
                </QCol>
              </QRow>
            </QCardBody>
          </QCard>
        ) : null}
      </div>
    );
  }
}

export default withLDConsumer()(MdmIntegrationCredentials);
