import React from 'react';
import { Line } from 'react-chartjs-2';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';

const LDAppsByDay = ({ isLoading, appsByDate }) => {
  const { mastV2 } = useFlags();

  const chartData = {
    labels: appsByDate.map(item => item.day),
    datasets: [
      {
        borderColor: 'rgba(255, 163, 139)',
        fill: true,
        backgroundColor: context => {
          if (!mastV2) {
            return 'rgba(255, 163, 139, 0.4)';
          }

          const { ctx } = context.chart;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, 'rgba(255,163,139,0.3)');
          gradient.addColorStop(1, 'rgba(255,163,139,0.04)');
          return gradient;
        },
        ...(mastV2 && {
          lineTension: 0,
          pointBackgroundColor: '#FFA38B',
          pointBorderColor: '#FFA38B66',
          pointBorderWidth: 10,
          pointRadius: 5,
          pointHitRadius: 2,
          borderWidth: 2,
        }),
        label: 'Apps Submitted',
        data: appsByDate.map(item => item.count),
      },
    ],
  };
  return (
    <QCard className="card-accent-secondary">
      <QCardHeader
        title={i18n.t('Apps Submitted by Day')}
        action={
          <span>{isLoading && <i className="fas fa-spinner fa-spin" />}</span>
        }
      >
        <div className="d-flex justify-between">
          <strong>{i18n.t('Apps Submitted by Day')}</strong>
          <span>{isLoading && <i className="fas fa-spinner fa-spin" />}</span>
        </div>
      </QCardHeader>
      <QCardBody>
        <Line
          data={chartData}
          height={300}
          options={{
            maintainAspectRatio: false,
            plugins: {
              datalabels: {
                display: false,
              },
              legend: {
                display: false,
              },
              title: {
                display: true,
                text: 'Apps that have been submitted and completed over time time period',
                position: 'bottom',

                font: {
                  weight: 'normal',
                  style: 'italic',
                },
              },
            },
          }}
        />
      </QCardBody>
    </QCard>
  );
};

const AppsByDay = withLDConsumer()(LDAppsByDay);

export default AppsByDay;
