import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import Switch, { Case, Default } from 'react-switch-case';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';

import DynamicAnalysisDatatable from '../Android/DynamicAnalysisDatatable';
import { Harviewer } from '../../Harviewer/Harviewer';
import SmaliAnalysisDatatable from '../Android/SmaliAnalysisDatatable';
import SSLPinning from '../SSLPinning';
import FilesWrittenDataTable from '../iOS/FilesWrittenDatatable';
import SystemFrameworksDatatable from '../iOS/SystemFrameworksDatatable';
import CryptoOperationsDatatable from '../iOS/CryptoOperationsDatatable';
import DynamicallyLoadedLibrariesDatatable from '../iOS/DynamicallyLoadedLibrariesDatatable';
import SensitiveDataExposureDatatable from '../SensitiveDataExposureDatatable';
import PermissionsRequestedTable from '../Android/PermissionsRequestedTable';
import i18n from '../../../localization/i18n';
import ConstantsDatatable from '../Android/ConstantsDatatable';
import CertInfoTable from '../Android/CertInfoTable';
import UrlConstantsDatatable from '../iOS/UrlConstantsDatatable';
import WorldAccessibleFiles from '../Android/WorldAccessibleFiles';
import AvScanResults from '../AvScanResults';
import SbomVulnerabilitiesDataTable from '../Android/SbomVulnerabilitiesDataTable';
import HighRiskCountriesContactedDatatable from '../HighRiskCountriesContactedDatatable';
import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';
import { QTypography } from '../../Q-Components/QTypography';
import { QBadge } from '../../Q-Components/QBadge';
import { QButton } from '../../Q-Components/QButton';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QDivider } from '../../Q-Components/QDivider';

import RiskLevel from './ReportFindingCard/RiskLevel';
import HasExtraPermissions from './ReportFindingCard/AndroidEvidence/HasExtraPermissions';
import CanDownloadWithoutNotification from './ReportFindingCard/AndroidEvidence/CanDownloadWithoutNotification';
import AcceptsAllCerts from './ReportFindingCard/AndroidEvidence/AcceptsAllCerts';
import AdminPrivs from './ReportFindingCard/AndroidEvidence/AdminPrivs';
import CanKillBackground from './ReportFindingCard/AndroidEvidence/CanKillBackground';
import CanReadContacts from './ReportFindingCard/AndroidEvidence/CanReadContacts';
import HasInAppPurchases from './ReportFindingCard/AndroidEvidence/HasInAppPurchases';
import PasswordExposed from './ReportFindingCard/AndroidEvidence/PasswordExposed';
import PiiExposure from './ReportFindingCard/AndroidEvidence/PiiExposure';
import NonPrivateMode from './ReportFindingCard/AndroidEvidence/NonPrivateMode';
import CanReadCalendar from './ReportFindingCard/AndroidEvidence/CanReadCalendar';
import TakesPicture from './ReportFindingCard/AndroidEvidence/TakesPicture';
import CanWriteExternalStorage from './ReportFindingCard/AndroidEvidence/CanWriteExternalStorage';
import CanReadExternalStorage from './ReportFindingCard/AndroidEvidence/CanReadExternalStorage';
import CAnAccessBluetooth from './ReportFindingCard/AndroidEvidence/CanAccessBluetooth';
import CanAccessFineLocation from './ReportFindingCard/AndroidEvidence/CanAccessFineLocation';
import CanSendSms from './ReportFindingCard/AndroidEvidence/CanSendSms';
import CanReadSms from './ReportFindingCard/AndroidEvidence/CanReadSms';
import CanCallNumber from './ReportFindingCard/AndroidEvidence/CanCallNumber';
import RecordsAudio from './ReportFindingCard/AndroidEvidence/RecordsAudio';
import CanReceiveMms from './ReportFindingCard/AndroidEvidence/CanReceiveMms';
import AdNetworkEvidence from './ReportFindingCard/SharedEvidence/AdNetworkEvidence';
import CloudStorageEvidence from './ReportFindingCard/IosEvidence/CloudStorageEvidence';
import SocialNetworkEvidence from './ReportFindingCard/IosEvidence/SocialNetworkEvidence';
import AccessesCalendarEvidence from './ReportFindingCard/IosEvidence/AccessesCalendarEvidence';
import AccessMicrophoneEvidence from './ReportFindingCard/IosEvidence/AccessMicrophoneEvidence';
import AccessCameraEvidence from './ReportFindingCard/IosEvidence/AccessCameraEvidence';
import AccessPhotosEvidence from './ReportFindingCard/IosEvidence/AccessPhotosEvidence';
import AccessContactsEvidence from './ReportFindingCard/IosEvidence/AccessContactEvidence';
import AccessLocationEvidence from './ReportFindingCard/IosEvidence/AccessLocationEvidence';
import MissingCompileProtectionEvidence from './ReportFindingCard/IosEvidence/MissingCompileProtectionEvidence';
import KeychainSecurityAccessibleEvidence from './ReportFindingCard/IosEvidence/KeychainSecurityAccessibleEvidence';
import { Regulations } from './ReportFindingCard/Regulations';
import { DopplerResult } from './ReportFindingCard/AndroidEvidence/DopplerResult';
import { EvidenceV2 } from './ReportFindingCard/EvidenceV2/EvidenceV2';

class QReportFindingCard extends Component {
  constructor() {
    super();

    this.filterOutWhitelistUrls = this.filterOutWhitelistUrls.bind(this);
  }

  filterOutWhitelistUrls(url) {
    // Allow a whitelist of URLs that are commonly found in applications that are
    // not an issue

    const WHITELIST_REGEXES = [
      /http:\/\/schemas\.android\.com\/apk\/res\/android/g,
      /http:\/\/\*\/\*/g,
      /http:\/\/captive\.apple\.com\/hotspot-detect\.html/g,
      /http:\/\/connectivitycheck\.gstatic\.com\/generate_204/g,
      /http:\/\/www\.google\.com\/gen_204/g,
      /xmlns(:.*?){0,1}=(".*?")/g,
      /http:\/\/schemas\.android\.com\/.*/g,
      /http:\/\/ocsp\.apple\.com/g,
    ];

    for (const whitelistRegex of WHITELIST_REGEXES) {
      if (url.match(whitelistRegex)) {
        return false;
      }
    }

    if (!url.startsWith('http://')) {
      return false;
    }

    return true;
  }

  isNotEmpty(regulations) {
    return regulations && !_.isEmpty(regulations);
  }

  render() {
    const { issue, platform, v2 } = this.props;
    const issueKey = `${platform}-${issue.name[0]}`;

    return (
      <Accordion
        style={{
          backgroundColor: '#FAFAFA',
          boxShadow: 'none',
          borderRadius: '0px',
        }}
        data-testid="rule-finding"
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{ marginTop: '12px', marginBottom: '12px' }}
            />
          }
          style={{
            flexDirection: 'row-reverse',
            alignItems: 'start',
          }}
        >
          <QCol
            style={{
              marginLeft: '16px',
              marginTop: '3px',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '8px',
            }}
          >
            <QRow>
              <QTypography variant="h5Regular">{issue.name}</QTypography>
            </QRow>

            <QRow columnGap="8px">
              <RiskLevel risk={issue.risk} />
              {issue.exploitable && (
                <QBadge
                  variant="basic"
                  style={{ backgroundColor: '#3B3E40', height: '24px' }}
                  label="Exploitable"
                />
              )}
              {issue.cvss_score && (
                <QButton
                  variant="outlined"
                  endIcon={<LaunchIcon />}
                  href={issue.cvss_link}
                  style={{
                    fontSize: '12px',
                    height: '24px',
                  }}
                >
                  CVSS: {issue.cvss_score}
                </QButton>
              )}
              {issue.cwe && (
                <QButton
                  variant="outlined"
                  endIcon={<LaunchIcon />}
                  href={issue.cwe_link}
                  style={{
                    fontSize: '12px',
                    height: '24px',
                  }}
                >
                  {issue.cwe.split(':')[0]}
                </QButton>
              )}
            </QRow>
            <QRow>
              <QTypography
                variant="h6Medium"
                style={{
                  color: '#757575',
                }}
              >
                {i18n.t(issue.description)}
              </QTypography>{' '}
            </QRow>
          </QCol>
        </AccordionSummary>
        <AccordionDetails>
          <QRow
            columnGap="16px"
            style={{
              width: '100%',
              paddingLeft: '40px',
              marginBottom: '16px',
            }}
          >
            {(issue.threat_profile || issue.remediation || issue.impact) && (
              <QCard
                style={{
                  padding: '8px',
                  backgroundColor: '#FFFFFF',
                  width: '100%',
                }}
              >
                <QCardBody
                  style={{
                    padding: '30px',
                  }}
                >
                  {issue.threat_profile && (
                    <QCol
                      style={{ display: 'flex', flexDirection: 'column' }}
                      rowGap="12px"
                    >
                      <QTypography
                        variant="h5Bold"
                        style={{
                          color: '#000000',
                        }}
                      >
                        Threat Details
                      </QTypography>
                      <p className="font-lg break-word">
                        {i18n.t(issue.threat_profile)}
                      </p>
                    </QCol>
                  )}
                  {issue.threat_profile && issue.remediation && (
                    <QDivider
                      style={{
                        borderColor: '#E0E0E0',
                        marginTop: '14px',
                        marginBottom: '14px',
                      }}
                    />
                  )}
                  {issue.remediation && (
                    <QCol
                      style={{ display: 'flex', flexDirection: 'column' }}
                      rowGap="12px"
                    >
                      <QTypography
                        variant="h5Bold"
                        style={{
                          color: '#000000',
                        }}
                      >
                        Remediation
                      </QTypography>
                      <p className="font-lg break-word">
                        {i18n.t(issue.remediation)}
                      </p>
                    </QCol>
                  )}
                  {issue.impact &&
                    (issue.remediation || issue.threat_profile) && (
                      <QDivider
                        style={{
                          borderColor: '#E0E0E0',
                          marginTop: '14px',
                          marginBottom: '14px',
                        }}
                      />
                    )}
                  {issue.impact && (
                    <QCol
                      style={{ display: 'flex', flexDirection: 'column' }}
                      rowGap="12px"
                    >
                      <QTypography
                        variant="h5Bold"
                        style={{
                          color: '#000000',
                        }}
                      >
                        Finding Impact
                      </QTypography>
                      <p className="font-lg break-word">
                        {i18n.t(issue.impact)}
                      </p>
                    </QCol>
                  )}
                </QCardBody>
              </QCard>
            )}
            {(this.isNotEmpty(issue.owasp) ||
              this.isNotEmpty(issue.niap) ||
              this.isNotEmpty(issue.gdpr)) && (
              <QCard
                style={{
                  padding: '8px',
                  backgroundColor: '#FFFFFF',
                  width: '100%',
                }}
              >
                <QCardHeader
                  disableTypography
                  title={
                    <QTypography
                      variant="h5Bold"
                      style={{
                        color: '#000000',
                      }}
                    >
                      Compliance
                    </QTypography>
                  }
                />
                <QCardBody>
                  <Regulations issue={issue} isSarifReport={v2} />
                </QCardBody>
              </QCard>
            )}
          </QRow>

          {this.isNotEmpty(issue.evidences) && (
            <QCard
              style={{
                padding: '0',
                marginLeft: '40px',
                backgroundColor: '#FFFFFF',
              }}
            >
              <QCardHeader disableTypography title="Evidence" />
              <QDivider />
              {console.log(issueKey)}
              <QCardBody>
                {v2 ? (
                  <EvidenceV2 issue={issue} />
                ) : (
                  <Switch condition={issueKey}>
                    {/* Android */}
                    <Case value="android-loads_library">
                      <DynamicAnalysisDatatable onlySelectedCategory="Libraries loaded" />
                    </Case>
                    <Case value="android-app_exposes_file">
                      <WorldAccessibleFiles />
                    </Case>
                    <Case value="android-does_not_securely_generates_keys">
                      <p />
                    </Case>
                    <Case value="android-has_extra_permissions">
                      <HasExtraPermissions />
                    </Case>
                    <Case value="android-can_download_without_notification">
                      <CanDownloadWithoutNotification />
                    </Case>
                    <Case value="android-accepts_all_certs">
                      <AcceptsAllCerts />
                    </Case>
                    <Case value="android-admin_privs">
                      <AdminPrivs />
                    </Case>
                    <Case value="android-malware_detected">
                      <AvScanResults />
                    </Case>
                    <Case value="android-executable_file_download">
                      <Harviewer onlyExecutableMimeTypes />
                    </Case>
                    <Case value="android-vulnerable_min_os_version">
                      <p />
                    </Case>
                    <Case value="android-privilege_escalation">
                      <DynamicAnalysisDatatable
                        onlySelectedCategory="Commands"
                        paramFilter="su"
                      />
                    </Case>
                    <Case value="android-non_standard_sql">
                      <DynamicAnalysisDatatable filter="sql" />
                    </Case>
                    <Case value="android-debuggable">
                      {/* TODO add Manifest here */}
                      <p />
                    </Case>
                    <Case value="android-hard_coded_credentials">
                      <SmaliAnalysisDatatable filter="crypto_key" />
                    </Case>
                    <Case value="android-has_invalid_certificate">
                      <CertInfoTable />
                    </Case>
                    <Case value="android-dynamically_loads_class">
                      <DynamicAnalysisDatatable onlySelectedCategory="Classes Loaded" />
                    </Case>
                    <Case value="android-no_data_at_rest_encryption">
                      <DynamicAnalysisDatatable
                        onlySelectedCategory={[
                          'Files Created',
                          'File Io',
                          'Files Accessed',
                        ]}
                        methodFilter="OutputStream"
                      />
                    </Case>
                    <Case value="android-can_be_persistent">
                      <p>
                        <strong>{i18n.t('Permissions Requested')}:</strong>
                      </p>
                      <PermissionsRequestedTable filter="PERSISTENT" />
                    </Case>
                    <Case value="android-can_kill_background">
                      <CanKillBackground />
                    </Case>
                    <Case value="android-no_ssl_pinning">
                      <SSLPinning detectFilter="no" />
                    </Case>
                    {/* Privacy & Information Access */}
                    <Case value="android-links_with_social_network">
                      <Harviewer />
                    </Case>
                    <Case value="android-manage_accounts">
                      <p>
                        <strong>{i18n.t('Permissions Requested')}:</strong>
                      </p>
                      <PermissionsRequestedTable filter="ACCOUNT" />
                    </Case>
                    <Case value="android-gets_device_id">
                      <DynamicAnalysisDatatable
                        onlySelectedCategory="Telephony Events"
                        methodFilter="getImei"
                      />
                    </Case>
                    <Case value="android-leftover_files">
                      <DynamicAnalysisDatatable
                        onlySelectedCategory={[
                          'Files Created',
                          'Files Accessed',
                        ]}
                        paramFilter="/sdcard"
                      />
                    </Case>
                    <Case value="android-can_read_contacts">
                      <CanReadContacts />
                    </Case>
                    <Case value="android-has_in_app_purchases">
                      <HasInAppPurchases />
                    </Case>
                    <Case value="android-password_exposed">
                      <PasswordExposed />
                    </Case>
                    <Case value="android-pii_exposure">
                      <PiiExposure />
                    </Case>
                    <Case value="android-non_private_mode">
                      <NonPrivateMode />
                    </Case>
                    <Case value="android-executes_native_code">
                      <DynamicAnalysisDatatable onlySelectedCategory="Native Methods" />
                    </Case>
                    <Case value="android-pii_exposure_low_risk">
                      {/* TODO */}
                      <p />
                    </Case>
                    <Case value="android-uses_ad_network">
                      <AdNetworkEvidence platform="android" />
                    </Case>
                    <Case value="android-uses_cloud_service">
                      <Harviewer />
                    </Case>
                    <Case value="android-gets_subscriber_id">
                      <DynamicAnalysisDatatable
                        onlySelectedCategory="Telephony Events"
                        methodFilter="getSubscriberId"
                      />
                    </Case>
                    <Case value="android-gets_sim_serial_number">
                      <DynamicAnalysisDatatable
                        onlySelectedCategory="Telephony Events"
                        methodFilter="getSimSerial"
                      />
                    </Case>
                    <Case value="android-gets_phone_number">
                      <DynamicAnalysisDatatable
                        onlySelectedCategory="Telephony Events"
                        methodFilter="getLine1"
                      />
                    </Case>
                    <Case value="android-can_read_calendar">
                      <CanReadCalendar />
                    </Case>
                    {/* Device Access */}
                    <Case value="android-can_access_internet">
                      <p>
                        <strong>{i18n.t('Permissions Requested')}:</strong>
                      </p>
                      <PermissionsRequestedTable filter="INTERNET" />
                    </Case>
                    <Case value="android-takes_picture">
                      <TakesPicture />
                    </Case>
                    <Case value="android-can_write_external_storage">
                      <CanWriteExternalStorage />
                    </Case>
                    <Case value="android-can_read_external_storage">
                      <CanReadExternalStorage />
                    </Case>
                    <Case value="android-can_access_bluetooth">
                      <CAnAccessBluetooth />
                    </Case>
                    <Case value="android-can_access_fine_location">
                      <CanAccessFineLocation />
                    </Case>
                    <Case value="android-can_send_sms">
                      <CanSendSms />
                    </Case>
                    <Case value="android-can_read_sms">
                      <CanReadSms />
                    </Case>
                    <Case value="android-can_call_number">
                      <CanCallNumber />
                    </Case>
                    <Case value="android-records_audio">
                      <RecordsAudio />
                    </Case>
                    <Case value="android-can_receive_mms">
                      <CanReceiveMms />
                    </Case>
                    <Case value="android-http_traffic">
                      <Harviewer urlFilter={this.filterOutWhitelistUrls} />
                    </Case>
                    <Case value="android-possible_foreign_connection">
                      <Harviewer />
                    </Case>
                    <Case value="android-highrisk_country_connection">
                      <HighRiskCountriesContactedDatatable platform="android" />
                    </Case>
                    <Case value="android-http_url_constant">
                      <ConstantsDatatable
                        typeFilter="URL"
                        valueFilter="http://"
                      />
                    </Case>
                    <Case value="android-command_execution">
                      <DopplerResult
                        impact="Command Execution"
                        ruleIds={['commexec-local', 'commexec-remote']}
                      />
                    </Case>
                    <Case value="android-indirect_factory_reset">
                      <DopplerResult
                        impact="Indirect Factory Reset"
                        ruleIds={[
                          'direct-factory-reset',
                          'factory-reset-broadcast',
                          'factory-reset-reboot-recovery',
                        ]}
                      />
                    </Case>
                    <Case value="android-logcat_leakage">
                      <DopplerResult
                        impact="Logcat Leakage"
                        ruleIds={[
                          'logcat-to-sdcard',
                          'logcat-to-shell-process',
                          'logcat-then-mv',
                          'logcat-then-java-move',
                          'logcat-then-write',
                        ]}
                      />
                    </Case>
                    <Case value="android-pii_leakage">
                      <DopplerResult impact="PII Leakage" ruleIds={['pii']} />
                    </Case>
                    <Case value="android-contains_library_cve_low">
                      <SbomVulnerabilitiesDataTable />
                    </Case>
                    <Case value="android-contains_library_cve_med">
                      <SbomVulnerabilitiesDataTable />
                    </Case>
                    <Case value="android-contains_library_cve_high">
                      <SbomVulnerabilitiesDataTable />
                    </Case>
                    <Case value="android-contains_library_cve_crit">
                      <SbomVulnerabilitiesDataTable />
                    </Case>
                    <Case value="android-can_access_microphone">
                      <RecordsAudio staticAnalysis />
                    </Case>
                    {/* iOS */}
                    {/* Security */}
                    <Case value="ios-hard_coded_key">
                      <CryptoOperationsDatatable onlyHardCoded />
                    </Case>
                    <Case value="ios-hard_coded_iv">
                      <CryptoOperationsDatatable />
                    </Case>
                    <Case value="ios-malware_detected">
                      <AvScanResults />
                    </Case>
                    <Case value="ios-executes_external_library">
                      <DynamicallyLoadedLibrariesDatatable />
                    </Case>
                    <Case value="ios-vulnerable_min_os_version">
                      <p />
                    </Case>
                    <Case value="ios-no_data_at_rest_encryption">
                      <FilesWrittenDataTable />
                    </Case>
                    {/* Privacy & Information Access */}
                    <Case value="ios-pii_exposure">
                      <SensitiveDataExposureDatatable platform="ios" />
                    </Case>
                    <Case value="ios-pii_exposure_low_risk">
                      <p>{issue.evidence_description}</p>
                    </Case>
                    <Case value="ios-gets_user_info">
                      <p>{issue.evidence_description}</p>
                    </Case>
                    <Case value="ios-executable_file_download">
                      <Harviewer onlyExecutableMimeTypes />
                    </Case>
                    <Case value="ios-uses_ad_network">
                      <AdNetworkEvidence platform="ios" />
                    </Case>
                    <Case value="ios-cloud_storage_integration">
                      <CloudStorageEvidence />
                    </Case>
                    <Case value="ios-links_with_social_network">
                      <SocialNetworkEvidence />
                    </Case>
                    <Case value="ios-can_access_calendar">
                      <AccessesCalendarEvidence />
                    </Case>
                    <Case value="ios-can_access_internet">
                      <Harviewer />
                    </Case>
                    <Case value="ios-has_in_app_purchases">
                      <SystemFrameworksDatatable
                        filter="storekit"
                        showFilter={false}
                      />
                    </Case>
                    <Case value="ios-no_ssl_pinning">
                      <SSLPinning detectFilter="no" />
                    </Case>
                    {/* Device Access */}
                    <Case value="ios-can_access_microphone">
                      <AccessMicrophoneEvidence />
                    </Case>
                    <Case value="ios-takes_picture">
                      <AccessCameraEvidence />
                    </Case>
                    <Case value="ios-can_interact_sms_mms">
                      <SystemFrameworksDatatable
                        filter="sms"
                        showFilter={false}
                      />
                    </Case>
                    <Case value="ios-can_interact_email">
                      <SystemFrameworksDatatable
                        filter="email"
                        showFilter={false}
                      />
                    </Case>
                    <Case value="ios-can_use_bluetooth">
                      <SystemFrameworksDatatable filter="bluetooth" />
                    </Case>
                    <Case value="ios-can_access_shared_libraries">
                      <AccessPhotosEvidence />
                    </Case>
                    <Case value="ios-can_access_address_book">
                      <AccessContactsEvidence />
                    </Case>
                    <Case value="ios-can_access_location">
                      <AccessLocationEvidence />
                    </Case>
                    <Case value="ios-possible_foreign_connection">
                      <Harviewer />
                    </Case>
                    <Case value="ios-highrisk_country_connection">
                      <HighRiskCountriesContactedDatatable platform="ios" />
                    </Case>
                    <Case value="ios-http_url_constant">
                      <UrlConstantsDatatable valueFilter="http://" />
                    </Case>
                    <Case value="ios-http_traffic">
                      <Harviewer urlFilter={this.filterOutWhitelistUrls} />
                    </Case>
                    <Case value="ios-missing_compile_protection">
                      <MissingCompileProtectionEvidence />
                    </Case>
                    <Case value="ios-keychain_security_accessible">
                      <KeychainSecurityAccessibleEvidence />
                    </Case>

                    {/* Default case, just show the evidence string */}
                    <Default>
                      <div />
                    </Default>
                  </Switch>
                )}
              </QCardBody>
            </QCard>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
}
export default withRouter(QReportFindingCard);
