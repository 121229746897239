import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { QButton } from '../Q-Components/QButton';
import { QModal } from '../Q-Components/QModal';
import { QModalActions } from '../Q-Components/QModalActions';
import { QModalContent } from '../Q-Components/QModalContent';
import { QModalTitle } from '../Q-Components/QModalTitle';
import i18n from '../../localization/i18n';

export const useConfirmationModal = (props = {}) => {
  const [isOpen = false, setIsOpen] = useState(() => false);
  const [confirmBody = '', setConfirmBody] = useState(() => props.confirmBody);
  const [confirmHeader = '', setConfirmHeader] = useState(
    () => props.confirmHeader,
  );
  const [confirm = () => {}, setConfirm] = useState(() => props.confirm);
  const [htmlBody = '', setHtmlBody] = useState(() => props.htmlBody);
  const [value = null, setValue] = useState(() => props.value);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const setModalContent = props => {
    setConfirmBody(() => props.confirmBody ?? confirmBody);
    setConfirmHeader(() => props.confirmHeader ?? confirmHeader);
    setConfirm(() => props.confirm ?? confirm);
    setHtmlBody(() => props.htmlBody ?? htmlBody);
    setValue(() => props.value ?? value);
  };

  return {
    toggle,
    isOpen,
    confirmHeader,
    confirmBody,
    htmlBody,
    value,
    confirm: () => {
      confirm();
      setIsOpen(false);
    },
    setConfirmBody,
    setConfirmHeader,
    setConfirm,
    setHtmlBody,
    setValue,
    setModalContent,
  };
};

const ConfirmationModal = props => {
  const {
    isOpen,
    toggle,
    confirmHeader,
    confirmBody,
    htmlBody,
    value = null,
    confirm,
    onlyConfirm = false,
  } = props;

  return (
    <QModal
      open={isOpen}
      isOpen={isOpen}
      toggle={toggle}
      title={
        <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
          <i className="far fa-exclamation-circle" />
          &nbsp;{confirmHeader}
        </QModalTitle>
      }
      content={
        <QModalContent>
          <p>{confirmBody}</p>
          {htmlBody}
        </QModalContent>
      }
      actions={
        <QModalActions>
          {!onlyConfirm && (
            <QButton
              variant="light"
              color="secondary"
              onClick={toggle}
              startIcon={<i className="far fa-times" />}
            >
              &nbsp;{i18n.t('Cancel')}
            </QButton>
          )}
          <QButton
            variant="filled"
            className="confirm-modal-confirm-btn"
            color="primary"
            onClick={() => confirm(value)}
            startIcon={<i className="far fa-check-circle" />}
          >
            &nbsp;{i18n.t('Confirm')}
          </QButton>
        </QModalActions>
      }
    >
      <QModalTitle toggle={toggle}>
        <i className="far fa-exclamation-circle" />
        &nbsp;{confirmHeader}
      </QModalTitle>
      <QModalContent>
        <p>{confirmBody}</p>
        {htmlBody}
      </QModalContent>
      <QModalActions>
        {!onlyConfirm && (
          <QButton color="secondary" onClick={toggle}>
            <i className="far fa-times" />
            &nbsp;{i18n.t('Cancel')}
          </QButton>
        )}
        <QButton
          className="confirm-modal-confirm-btn"
          color="primary"
          onClick={() => confirm(value)}
        >
          <i className="far fa-check-circle" />
          &nbsp;{i18n.t('Confirm')}
        </QButton>
      </QModalActions>
    </QModal>
  );
};

export default ConfirmationModal;
