import React, { Component } from 'react';
import { Map, TileLayer, Marker, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';

import i18n from '../../localization/i18n';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

class HostsContactedMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zoom: 1,
    };
  }

  render() {
    const { markers, showMap, mapCenter } = this.props;
    if (!showMap) return null;

    return (
      <div>
        {markers ? (
          Object.entries(markers).length === 0 ? (
            <span>{i18n.t('No connections found')}</span>
          ) : (
            showMap && (
              <Map
                className="leaflet-container"
                style={{ height: 300 }}
                center={mapCenter}
                zoom={this.state.zoom}
                maxZoom={18}
                worldCopyJump
              >
                <TileLayer
                  url={`https://api.maptiler.com/maps/basic/{z}/{x}/{y}.png?key=${process.env.REACT_APP_MAPTILER_API_KEY}`}
                  attribution='&copy; <a href="https://www.maptiler.com/copyright/">MapTiler</a> &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  tileSize={512}
                  zoomOffset={-1}
                />

                <MarkerClusterGroup>
                  {markers.map((marker, index) => (
                    <Marker
                      position={marker.latLong}
                      key={`${JSON.stringify(marker.latLong)}-${index}`}
                    >
                      <Tooltip direction="top">
                        <div>
                          <div>{marker.host}</div>
                          <div>{marker.country}</div>
                          <div>{marker.ip}</div>
                        </div>
                      </Tooltip>
                    </Marker>
                  ))}
                </MarkerClusterGroup>
              </Map>
            )
          )
        ) : (
          <span>{i18n.t('No latitude/longitude data')}</span>
        )}
      </div>
    );
  }
}

export default HostsContactedMap;
