import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Grid from '@mui/material/Grid';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { QCard } from '../components/Q-Components/QCard';
import { QCardHeader } from '../components/Q-Components/QCardHeader';
import { QCardBody } from '../components/Q-Components/QCardBody';
import { QButton } from '../components/Q-Components/QButton';
import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';
import { QLabel } from '../components/Q-Components/QLabel';
import { QAutocomplete } from '../components/Q-Components/QAutocomplete';
import { QInput } from '../components/Q-Components/QInput';
import { QSelect } from '../components/Q-Components/QSelect';
import Loading from '../components/Loading';
import AppsByDay from '../components/Analytics/AppsByDay';
import TopIssues from '../components/Analytics/TopIssues';
import TopCwes from '../components/Analytics/TopCwes';
import AnalyticsStatCards from '../components/Analytics/AnalyticsStatCards';
import ApiRequestsByDay from '../components/Analytics/ApiRequestsByDay';
import i18n from '../localization/i18n';
import { toastrHelper } from '../logic/toastrHelper';
import { IssuesByRisk } from '../components/Analytics/IssuesByRisk';
import { AnalysisSuccessRate } from '../components/Analytics/AnalysisSuccessRate';
import { invalidateAnalyticsCache } from '../store/slices/cache/analyticsCacheSlice';
import { fetchAnalyticsCache } from '../store/sliceHelpers/analyticsCacheSliceHelper';
import { fetchKaiGroups } from '../store/sliceHelpers/kaiSliceHelper';
import auth from '../logic/auth';
import UniqueAppsBySource from '../components/Analytics/UniqueAppsBySource';
import AppsByPlatform from '../components/Analytics/AppsByPlatform';
import { setPageTitle } from '../store/slices/emmAppSlice';
import { QDatePicker } from '../components/Q-Components/QDatePicker';

class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      analytics: null,
      currentDateFilter: { value: 'days-1', label: i18n.t('Last 24 hrs') },
      subGroupFilter: [],
      startDate: moment().subtract(1, 'days').toDate(),
      endDate: moment().toDate(),
      groupFilter: { value: 'all', label: 'All' },
      subGroupOptionsSelect: [],
      // We have the value as a string since it seems there's issues with ReactSelect
      // using Objects for values
      dateRangeOptions: [
        {
          value: 'days-1',
          label: i18n.t('Last 24 hrs'),
        },
        {
          value: 'days-7',
          label: i18n.t('Last 7 days'),
        },
        {
          value: 'days-30',
          label: i18n.t('Last 30 days'),
        },
        {
          value: 'years-1',
          label: i18n.t('Last year (365 days)'),
        },
        {
          value: 'all-0',
          label: i18n.t('All'),
        },
      ],
    };

    this.updateDateRange = this.updateDateRange.bind(this);
    this.updateAnalytics = this.updateAnalytics.bind(this);
    this.handleStartDateFilter = this.handleStartDateFilter.bind(this);
    this.handleEndDateFilter = this.handleEndDateFilter.bind(this);
    this.handleGroupFilter = this.handleGroupFilter.bind(this);
    this.processSubGroupFilter = this.processSubGroupFilter.bind(this);
    this.changeSubgroupFilter = this.changeSubgroupFilter.bind(this);
  }

  // Initialize the analytics data
  async componentWillMount() {
    if (this.props.admin) {
      this.props.fetchKaiGroups();
    } else {
      this.updateAnalytics();
    }

    const evaluatedSubgroupOptions = this.props.userSubGroups.map(subGroup => ({
      value: subGroup.id,
      label: subGroup.name,
    }));

    this.setState({ subGroupOptionsSelect: evaluatedSubgroupOptions });

    this.props.setPageTitle('Analytics');
  }

  componentWillUpdate(prevProps) {
    if (this.props.lang !== prevProps.lang) {
      window.location.reload(false);
    }
    if (this.props.admin !== prevProps.admin) {
      window.location.reload();
    }
  }

  // Handle an update to the date range filter option
  updateDateRange(event, newRange) {
    if (this.props.flags.mastV2) {
      const newOption = this.state.dateRangeOptions.find(
        option => option.value === newRange.props.value,
      );
      this.setState({ currentDateFilter: newOption }, () => {
        // After the state is updated, perform an update to the analytics data
        if (!this.props.admin) {
          this.updateAnalytics();
        }
      });
    } else {
      this.setState({ currentDateFilter: event }, () => {
        // After the state is updated, perform an update to the analytics data
        if (!this.props.admin) {
          this.updateAnalytics();
        }
      });
    }
  }

  changeSubgroupFilter(event) {
    const evaluatedSubgroups = [];
    if (this.props.flags.mastV2) {
      const {
        target: { value },
      } = event;

      for (let i = 0; i < value.length; i++) {
        if (typeof value[i] === 'object' && value[i] !== null) {
          evaluatedSubgroups.push(value[i]);
        } else {
          evaluatedSubgroups.push(
            this.state.subGroupOptionsSelect.filter(
              sub => sub.value === value[i],
            )[0],
          );
        }
      }

      this.setState({ subGroupFilter: evaluatedSubgroups }, () => {
        if (!this.props.admin) {
          this.updateAnalytics();
        }
      });
    } else {
      this.setState({ subGroupFilter: event }, () => {
        if (!this.props.admin) {
          this.updateAnalytics();
        }
      });
    }
  }

  async handleStartDateFilter(startDate) {
    this.setState({ startDate }, () => {
      if (!this.props.admin) {
        this.updateAnalytics();
      }
    });
  }

  async handleEndDateFilter(endDate) {
    this.setState({ endDate }, () => {
      if (!this.props.admin) {
        this.updateAnalytics();
      }
    });
  }

  async handleGroupFilter(groupFilter) {
    this.setState({ groupFilter }, () => {
      if (!this.props.admin) {
        this.updateAnalytics();
      }
    });
  }

  // For the MUI select to work we need to send subgroups as an array of subgroup values
  // Ex. subroupFilter = [{value: 'none', label: 'Non Associated'}, { value:1, label: "subgroup1" }]
  // should return  ['none', '1']
  processSubGroupFilter(subGroupFilter) {
    if (this.props.flags.mastV2) {
      if (Array.isArray(subGroupFilter)) {
        return subGroupFilter.map(subgroup => subgroup.value);
      }
      return [subGroupFilter.value];
    }
    return subGroupFilter;
  }

  getAnalyticsJson(group, startDate, endDate) {
    if (!startDate || !endDate) {
      return '';
    }

    // The timestamp returned from DatePicker will include the current time.
    // For example, if a user is checking this at 11 PM and wants to see a specific day it would instead use T23:00:000 for the start date.
    // We want to force this to include all scans for the dates selected and not ignore them if they were scanned earlier in the selected day.
    const parsedStartDate = startDate;
    parsedStartDate.setHours(0, 0, 0, 0);

    const parsedEndDate = endDate;
    parsedEndDate.setHours(23, 59, 59, 999);

    let analyticsJson = `${process.env.REACT_APP_API_BASE_URL}/adminV2/analytics?group=${group.value}&auth=basic`;
    analyticsJson = startDate
      ? `${analyticsJson}&startDate=${moment(parsedStartDate).toISOString()}`
      : analyticsJson;
    analyticsJson = endDate
      ? `${analyticsJson}&endDate=${moment(parsedEndDate).toISOString()}`
      : analyticsJson;
    return analyticsJson;
  }

  // Update the analytics data for the component
  async updateAnalytics(refresh = false) {
    const {
      startDate,
      endDate,
      groupFilter,
      currentDateFilter,
      subGroupFilter,
    } = this.state;
    const { admin } = this.props;
    this.setState({ isLoading: true });

    const type = currentDateFilter.value.split('-')[0];
    const range = currentDateFilter.value.split('-')[1];

    let response;
    try {
      if (admin) {
        response = await this.props.fetchAnalyticsCache('adminV2/analytics', {
          group: groupFilter ? groupFilter.value : null,
          startDate,
          endDate,
        });
      } else {
        response = await this.props.fetchAnalyticsCache('api/analytics', {
          type,
          range,
          subgroupIds: subGroupFilter
            ? subGroupFilter.map(entry => entry.value).join(',')
            : null,
          refresh,
        });
      }
      if (response.status !== 200) {
        toastrHelper.showErrorToast(
          i18n.t('Could not get analytics'),
          i18n.t('Error'),
          this.props.flags?.mastV2,
        );
        console.error(response.data);
      } else {
        this.setState({
          analytics: response.data,
        });
      }
    } catch (err) {
      console.error(err);
    }

    this.setState({
      isLoading: false,
    });
  }

  render() {
    const {
      isLoading,
      analytics,
      startDate,
      endDate,
      groupFilter,
      subGroupFilter,
      subGroupOptionsSelect,
    } = this.state;
    const { admin, kaiGroups } = this.props;

    const groupAdminGroups = kaiGroups;

    const groupsOptions = groupAdminGroups.map(group => ({
      value: group.id,
      label: group.name,
    }));
    groupsOptions.unshift({ value: 'all', label: 'All' });

    return (
      <div>
        {this.props.flags.mastV2 ? (
          <Grid container rowSpacing={2} columnSpacing={2} mb={4} mt={0} ml={0}>
            {!admin ? (
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={3}>
                  <QSelect
                    size="small"
                    options={this.state.dateRangeOptions}
                    value={this.state.currentDateFilter?.value || null}
                    onChange={(event, value) => {
                      this.updateDateRange(event, value);
                    }}
                    label="Date Range"
                    className={this.props.flags.mastV2 ? '' : 'z-index-10'}
                    selectIconProps={{ fontSize: 'medium' }}
                    formControlProps={{ sx: { width: '100%' } }}
                  />
                </Grid>
                {subGroupOptionsSelect.length > 0 && (
                  <Grid item xs={3}>
                    <QSelect
                      multiple
                      isMulti
                      options={subGroupOptionsSelect}
                      value={this.processSubGroupFilter(subGroupFilter)}
                      onChange={this.changeSubgroupFilter}
                      label="Subgroups"
                      formControlProps={{ sx: { width: '100%' } }}
                      selectIconProps={{ fontSize: 'medium' }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={6}
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <QButton
                    color="link"
                    size="sm"
                    startIcon={<i className="far fa-sync" />}
                    onClick={() => {
                      this.props.invalidateAnalyticsCache();
                      this.updateAnalytics(true);
                    }}
                    data-testid="test-refresh-analytics"
                    variant="ghost"
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={3}>
                    <QAutocomplete
                      options={groupsOptions}
                      defaultValue={groupsOptions[0]}
                      onChange={(event, value) => {
                        this.handleGroupFilter(value);
                      }}
                      disableClearable
                      popupIcon={<ExpandMoreIcon fontSize="medium" />}
                      renderInput={params => (
                        <QInput
                          {...params}
                          outlinedWithTitle
                          label="Groups"
                          variant="outlined"
                          style={{ textOverflow: 'ellipsis' }}
                          size="small"
                          InputProps={{
                            endAdornment: params.InputProps.endAdornment,
                            startAdornment: params.InputProps.startAdornment,
                            sx: {
                              '.MuiAutocomplete-endAdornment': {
                                position: 'absolute',
                              },
                            },
                            inputProps: params.inputProps,
                            ref: params.InputProps.ref,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={1.5} alignSelf="flex-end">
                    <QDatePicker
                      className="form-control"
                      label="Start Date"
                      date={startDate}
                      handleChangeDate={this.handleStartDateFilter}
                      sx={{ width: '100%' }}
                      inputProps={{ 'data-testid': 'test-start-date' }}
                    />
                  </Grid>
                  <Grid item xs={1.5} alignSelf="flex-end">
                    <QDatePicker
                      className="form-control"
                      label="End Date"
                      date={endDate}
                      handleChangeDate={this.handleEndDateFilter}
                      sx={{ width: '100%' }}
                      inputProps={{ 'data-testid': 'test-end-date' }}
                    />
                  </Grid>
                  <Grid item xs={1} alignSelf="flex-end">
                    <QButton
                      disabled={isLoading}
                      variant="filled"
                      onClick={() => this.updateAnalytics(true)}
                    >
                      <span>
                        {isLoading ? (
                          <i className="fas fa-spinner fa-spin" />
                        ) : (
                          'Query'
                        )}
                      </span>
                    </QButton>
                  </Grid>
                  <Grid
                    item
                    xs="5"
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <QButton
                      color="link"
                      size="sm"
                      startIcon={<i className="far fa-download" />}
                      href={this.getAnalyticsJson(
                        groupFilter,
                        startDate,
                        endDate,
                      )}
                      data-testid="test-download-kai-analytics"
                      target="_blank"
                      variant="ghost"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <QCard>
            <QCardHeader>
              <div className="d-flex justify-between">
                <strong>{i18n.t('Application Analysis Analytics')}</strong>{' '}
                <div className="d-flex justify-between">
                  {!admin && (
                    <QButton
                      color="link"
                      size="sm"
                      className="fas fa-sync pointer black-icon"
                      onClick={() => {
                        this.props.invalidateAnalyticsCache();
                        this.updateAnalytics(true);
                      }}
                      data-testid="test-refresh-analytics"
                    />
                  )}
                  {admin && (
                    <QButton
                      color="link"
                      size="sm"
                      className="fas fa-download black-icon"
                      href={this.getAnalyticsJson(
                        groupFilter,
                        startDate,
                        endDate,
                      )}
                      data-testid="test-download-kai-analytics"
                      target="_blank"
                    />
                  )}
                </div>
              </div>
            </QCardHeader>
            <QCardBody>
              <QRow>
                <QCol xs="5">
                  {!admin ? (
                    <QRow style={{ justifyContent: 'flex-end' }}>
                      <QCol xs="6">
                        <QLabel for="date-range-select">
                          <strong>{i18n.t('Date Range')}</strong>
                        </QLabel>

                        <QAutocomplete
                          id="date-range-select"
                          options={this.state.dateRangeOptions}
                          defaultValue={this.state.dateRangeOptions[0]}
                          onChange={option => this.updateDateRange(option)}
                          clearable={false}
                          disableClearable
                          renderInput={params => (
                            <QInput
                              {...params}
                              variant="standard"
                              style={{ textOverflow: 'ellipsis' }}
                            />
                          )}
                        />
                      </QCol>
                      {subGroupOptionsSelect.length > 0 && (
                        <QCol xs="6" id="">
                          <QLabel>
                            <strong>{i18n.t('Sub Groups')}</strong>
                          </QLabel>
                          <QSelect
                            id="column-sort-select"
                            onChange={this.changeSubgroupFilter}
                            value={subGroupFilter}
                            options={subGroupOptionsSelect}
                            label="Select Sub Groups"
                            isSearchable={false}
                            isMulti
                          />
                        </QCol>
                      )}
                    </QRow>
                  ) : (
                    <>
                      <QRow>
                        <QCol xs="3">
                          <QLabel>
                            <strong>{i18n.t('Groups')}</strong>
                          </QLabel>
                          <QAutocomplete
                            options={groupsOptions}
                            defaultValue={groupsOptions[0]}
                            onChange={group => this.handleGroupFilter(group)}
                            clearable={false}
                            disableClearable
                            renderInput={params => (
                              <QInput
                                {...params}
                                variant="standard"
                                style={{ textOverflow: 'ellipsis' }}
                              />
                            )}
                          />
                        </QCol>
                        <QCol xs="3">
                          <QLabel for="column-start-date-select">
                            <strong>Start Date</strong>
                          </QLabel>
                          <div>
                            <QDatePicker
                              className="form-control"
                              label="Start Date"
                              date={startDate}
                              handleChangeDate={this.handleStartDateFilter}
                              customInput={
                                <input
                                  data-testid="test-start-date"
                                  type="text"
                                />
                              }
                            />
                          </div>
                        </QCol>
                        <QCol xs="3">
                          <QLabel for="column-end-date-select">
                            <strong>End Date</strong>
                          </QLabel>
                          <QDatePicker
                            className="form-control"
                            label="End Date"
                            date={endDate}
                            handleChangeDate={this.handleEndDateFilter}
                            customInput={
                              <input data-testid="test-end-date" type="text" />
                            }
                          />
                        </QCol>
                      </QRow>
                      <br />
                      <QRow>
                        <QCol>
                          <QButton
                            disabled={isLoading}
                            variant="filled"
                            onClick={() => this.updateAnalytics(true)}
                          >
                            <span>
                              {isLoading ? (
                                <i className="fas fa-spinner fa-spin" />
                              ) : (
                                'Query'
                              )}
                            </span>
                          </QButton>
                        </QCol>
                      </QRow>
                    </>
                  )}
                </QCol>
              </QRow>
            </QCardBody>
          </QCard>
        )}

        {/* Analytics cards, make sure we have analytics from the network request before rendering to avoid errors */}
        {!analytics ? (
          isLoading ? (
            <Loading />
          ) : admin ? (
            'Please select and click query to display data'
          ) : (
            <Loading />
          )
        ) : (
          /*
            We need to have rowSpacing set to 0 here because of how we define the '.card' class in scss/core/_card.scss 
            We don't want to change any styling there to prevent breaking any layout for the v1 UI.
          */
          <Grid container rowSpacing={2} columnSpacing={2}>
            {/* Stat cards */}
            <AnalyticsStatCards
              averageAnalysisTime={analytics.averageAnalysisTime}
              totalUniqueAppsAnalyzed={analytics.totalUniqueAppsAnalyzed}
              totalUniqueIosApps={analytics.totalUniqueIosApps}
              totalUniqueAndroidApps={analytics.totalUniqueAndroidApps}
              totalStoreApps={analytics.totalStoreApps}
              totalBinaryApps={analytics.totalBinaryApps}
              medianQueueTime={analytics.medianQueueTime}
              totalUsagePercentage={analytics.totalUsagePercentage}
              isLoading={isLoading}
            />

            {/* Total number of analyzed apps */}
            <Grid item xs={4}>
              <AppsByPlatform
                header="Analyzed Apps by Platform"
                isLoading={isLoading}
                android={analytics.count.android}
                ios={analytics.count.ios}
              />
            </Grid>

            {/* Breakdown of store/binary analyzed apps */}
            <Grid item xs={4}>
              <AppsByPlatform
                header="Analyzed Store Apps by Platform"
                source="store"
                isLoading={isLoading}
                android={analytics.androidStoreApps}
                ios={analytics.iosStoreApps}
              />
            </Grid>
            <Grid item xs={4}>
              <AppsByPlatform
                header="Analyzed Binary Apps by Platform"
                source="binary"
                isLoading={isLoading}
                android={analytics.androidBinaryApps}
                ios={analytics.iosBinaryApps}
              />
            </Grid>

            {/* Breakdown of unique analyzed apps */}
            <Grid item xs={4}>
              <UniqueAppsBySource
                header="Unique Android Apps By Source"
                isLoading={isLoading}
                binaryCount={analytics.androidUniqueBinaryAppsAnalyzed}
                storeCount={analytics.androidUniqueStoreAppsAnalyzed}
              />
            </Grid>
            <Grid item xs={4}>
              <UniqueAppsBySource
                header="Unique iOS Apps By Source"
                isLoading={isLoading}
                binaryCount={analytics.iosUniqueBinaryAppsAnalyzed}
                storeCount={analytics.iosUniqueStoreAppsAnalyzed}
              />
            </Grid>

            <Grid item xs={4}>
              <AnalysisSuccessRate
                isLoading={isLoading}
                analysisSuccessRate={analytics.analysisSuccessRate}
              />
            </Grid>

            {/* Line chart of apps submitted by day */}
            <Grid item xs={12}>
              <AppsByDay
                isLoading={isLoading}
                appsByDate={analytics.appsByDate}
              />
            </Grid>

            {/* Line chart of api requests by day */}
            <Grid item xs={12}>
              <ApiRequestsByDay
                isLoading={isLoading}
                apiRequests={analytics.apiRequestsByDate}
              />
            </Grid>

            {/* HorizontalBar chart for top Android app issues */}
            <Grid item xs={6}>
              <TopIssues
                platform="android"
                header="Top Android Analysis Issues"
                issuesData={analytics.androidIssues}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={6}>
              <TopIssues
                platform="ios"
                header="Top iOS Analysis Issues"
                issuesData={analytics.iosIssues}
                isLoading={isLoading}
              />
            </Grid>

            {/* Store issues by risk */}
            <Grid item xs={4}>
              <IssuesByRisk
                appIssuesByRisk={analytics.androidStoreAppIssuesByRisk}
                header="Android Store App Issues by Risk"
                isLoading={isLoading}
                appType="store"
              />
            </Grid>
            <Grid item xs={4}>
              <IssuesByRisk
                appIssuesByRisk={analytics.iosStoreAppIssuesByRisk}
                header="iOS Store App Issues by Risk"
                isLoading={isLoading}
                appType="store"
              />
            </Grid>
            <Grid item xs={4}>
              <IssuesByRisk
                appIssuesByRisk={analytics.storeAppIssuesByRisk}
                header="All Store App Issues by Risk"
                isLoading={isLoading}
                appType="store"
              />
            </Grid>

            {/* Binary issues by risk */}
            <Grid item xs={4}>
              <IssuesByRisk
                appIssuesByRisk={analytics.androidBinaryAppIssuesByRisk}
                header="Android Binary App Issues by Risk"
                isLoading={isLoading}
                appType="binary"
              />
            </Grid>
            <Grid item xs={4}>
              <IssuesByRisk
                appIssuesByRisk={analytics.iosBinaryAppIssuesByRisk}
                header="iOS Binary App Issues by Risk"
                isLoading={isLoading}
                appType="binary"
              />
            </Grid>
            <Grid item xs={4}>
              <IssuesByRisk
                appIssuesByRisk={analytics.binaryAppIssuesByRisk}
                header="All Binary App Issues by Risk"
                isLoading={isLoading}
                appType="binary"
              />
            </Grid>

            <Grid item xs={6}>
              <TopIssues
                platform="android"
                header="Top Exploitable Android Store App Issues"
                issuesData={analytics.exploitableAndroidStoreAppIssues}
                isLoading={isLoading}
                appType="store"
              />
            </Grid>

            <Grid item xs={6}>
              <TopIssues
                platform="android"
                header="Top Exploitable Android Binary App Issues"
                issuesData={analytics.exploitableAndroidBinaryAppIssues}
                isLoading={isLoading}
                appType="binary"
              />
            </Grid>

            <Grid item xs={6}>
              <TopIssues
                platform="ios"
                header="Top Exploitable iOS Store App Issues"
                issuesData={analytics.exploitableIosStoreAppIssues}
                isLoading={isLoading}
                appType="store"
              />
            </Grid>

            <Grid item xs={6}>
              <TopIssues
                platform="ios"
                header="Top Exploitable iOS Binary App Issues"
                issuesData={analytics.exploitableIosBinaryAppIssues}
                isLoading={isLoading}
                appType="binary"
              />
            </Grid>

            <Grid item xs={6}>
              <TopCwes
                platform="android"
                header="Top Android Store App CWEs"
                cweData={analytics.androidStoreAppCwes}
                isLoading={isLoading}
                appType="store"
              />
            </Grid>

            <Grid item xs={6}>
              <TopCwes
                platform="android"
                header="Top Android Binary App CWEs"
                cweData={analytics.androidBinaryAppCwes}
                isLoading={isLoading}
                appType="binary"
              />
            </Grid>

            <Grid item xs={6}>
              <TopCwes
                platform="ios"
                header="Top iOS Store App CWEs"
                cweData={analytics.iosStoreAppCwes}
                isLoading={isLoading}
                appType="store"
              />
            </Grid>

            <Grid item xs={6}>
              <TopCwes
                platform="ios"
                header="Top iOS Binary App CWEs"
                cweData={analytics.iosBinaryAppCwes}
                isLoading={isLoading}
                appType="binary"
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ emmApp, kai }) => ({
  lang: emmApp.portalConfig.lang,
  userSubGroups: emmApp.userSubGroups,
  kaiGroups: kai.kaiGroups,
});
const mapDispatchToProps = {
  fetchAnalyticsCache,
  fetchKaiGroups,
  invalidateAnalyticsCache,
  setPageTitle,
};

const AnalyticsWithLDConsumer = withLDConsumer()(Analytics);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnalyticsWithLDConsumer);
