import React from 'react';
import { CardText } from 'reactstrap';

import { Issues } from './Issues';

export const V8_6 = ({ evidence }) => {
  const IsDetected = ({ detected }) => (
    <>
      {detected ? (
        <i className="far fa-check-circle success-icon" />
      ) : (
        <i className="far fa-times-circle danger-icon" />
      )}
    </>
  );

  return (
    <>
      <Issues evidence={evidence} issues={['NO_RASP_DETECTED']} />
      <hr />
      <CardText>
        <strong>App Protection: </strong>
        <IsDetected detected={evidence.appProtection} />
      </CardText>
    </>
  );
};
