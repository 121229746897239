import React from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QButton } from '../Q-Components/QButton';
import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import i18n from '../../localization/i18n';
import QDataTable from '../DataTable/QDataTable';
import DataTable from '../DataTable/DataTable';
import { DateTime } from '../DateTime';

import ApprovalApp from './ApprovalApp';

export default function AppApprovalTab({
  tabName,
  fields,
  data,
  actions,
  columnDisplayNamesMapping,
  isLoading,
}) {
  const isGroupAdmin = useSelector(
    ({ emmApp }) => emmApp.userAccess.group_admin,
  );
  const isKaiAdmin = useSelector(({ emmApp }) => emmApp.userAccess.kai_access);
  const showActions =
    isKaiAdmin || (process.env.REACT_APP_APPLIANCE !== 'cisa' && isGroupAdmin);
  const { mastV2 } = useFlags();

  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    {
      label: i18n.t('App'),
      component: ({ value }) => <ApprovalApp app={value} />,
      filterable: true,
      filterFunc: (filterStr, item) =>
        item.name?.toLowerCase().includes(filterStr.toLowerCase()) ||
        item.package?.toLowerCase().includes(filterStr.toLowerCase()),
    },
    ...(fields.length > 0 &&
      fields.map(field => ({
        label: i18n.t(columnDisplayNamesMapping[field]),
        component: ({ value: app }) => {
          if (field === 'createdAt' || field === 'updatedAt') {
            return <DateTime dateTime={app[field]} />;
          }
          if (app[field] === 'null') {
            return <p />;
          }
          return <p>{app[field]}</p>;
        },
      }))),
    ,
    showActions && actions
      ? {
          label: i18n.t('Actions'),
          component: ({ value: app }) => (
            <>
              <div>
                {actions.approve && (
                  <QButton
                    variant="outlined"
                    id="check-version"
                    color="success"
                    className="mr-1"
                    style={{
                      width: mastV2 ? 'unset' : '40px',
                      margin: '0 10px 0 0',
                    }}
                    onClick={() => {
                      actions.approve(app.id);
                    }}
                    data-testid="approve-app-button-test"
                  >
                    {mastV2 ? (
                      <strong>Approve</strong>
                    ) : (
                      <i className="fas fa-check" />
                    )}
                  </QButton>
                )}
                {actions.deny && (
                  <QButton
                    variant="outlined"
                    color="danger"
                    onClick={() => {
                      actions.deny(app.id);
                    }}
                    id="deny-app"
                    className="mr-1"
                    style={{ width: mastV2 ? 'unset' : '40px' }}
                    data-testid="deny-app-button-test"
                  >
                    {mastV2 ? (
                      <strong>Decline</strong>
                    ) : (
                      <i className="fas fa-times" />
                    )}
                  </QButton>
                )}
              </div>
            </>
          ),
        }
      : {},
  ];

  return (
    <>
      {mastV2 ? (
        <QDataTable
          showBottomPagination
          showPageSize
          data={data}
          columns={columns}
          rowsPerPage={10}
          isLoading={isLoading}
        />
      ) : (
        <QCard className="card-accent-primary">
          <QCardHeader>
            <div className="d-flex justify-between">
              <strong>{tabName}</strong>
            </div>
          </QCardHeader>
          <QCardBody>
            <DataTable
              data={data}
              columns={columns}
              rowsPerPage={10}
              isLoading={isLoading}
            />
          </QCardBody>
        </QCard>
      )}
    </>
  );
}
