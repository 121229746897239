import i18n from '../../localization/i18n';

export const orderByOptions = [
  {
    value: 'dateSubmitted',
    label: i18n.t('Date Submitted'),
    clearableValue: false,
  },
  {
    value: 'threatScore',
    label: i18n.t('Threat Score'),
    clearableValue: false,
  },
  {
    value: 'appPopularity',
    label: i18n.t('App Popularity'),
    clearableValue: false,
  },
];

export const pageSizeOptions = [
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
];

export const reviewStatusOptions = [
  {
    value: 'pending',
    label: i18n.t('Pending'),
    iconClass: 'far fa-ellipsis-h text-info',
  },
  {
    value: 'approved',
    label: i18n.t('Approved'),
    iconClass: 'far fa-check-circle text-success',
  },
  {
    value: 'archived',
    label: i18n.t('Archived'),
    iconClass: 'far fa-archive text-warning',
  },
];

export const platformOptions = [
  { value: 'both', label: i18n.t('Both'), clearableValue: false },
  { value: 'android', label: 'Android', clearableValue: false },
  { value: 'ios', label: 'iOS', clearableValue: false },
];

export const subGroupOptions = userSubGroups => {
  // Hide Non associated options if user is not part of a sub group
  if (Array.isArray(userSubGroups) && userSubGroups.length > 0) {
    return [
      { value: 'all', label: 'All' },
      { value: 'none', label: 'Non Associated' },
    ];
  }
  return [];
};

export const submissionTagOptions = [
  { value: 'all', label: 'All' },
  { value: 'none', label: 'Non Tagged' },
  { value: 'Manual Submission', label: 'Manual Submission' },
  { value: 'Manual - Binary Submission', label: 'Manual - Binary Submission' },
  {
    value: 'Manual - App Store Submission',
    label: 'Manual - App Store Submission',
  },
  { value: 'API Submission', label: 'API Submission' },
  { value: 'API - Binary Submission', label: 'API - Binary Submission' },
  { value: 'API - App Store Submission', label: 'API - App Store Submission' },
  { value: 'App Watchlist Submission', label: 'App Watchlist Submission' },
];

export const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'processing', label: 'Processing' },
  { value: 'complete', label: 'Complete' },
  { value: 'failed', label: 'Failed' },
];

export const testScriptFilterOptions = [
  { value: 'both', label: i18n.t('Both') },
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];
