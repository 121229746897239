import React from 'react';
import { QActionSelect as MuiActionSelect } from '@kw/quokka-ui';

const QActionSelect = ({
  actionOptions,
  label = 'Actions',
  width = '120px',
  selectProps = {},
}) => (
  <MuiActionSelect
    formControlProps={{
      className: 'mx-1 toggledown-primary',
      style: {
        paddingTop: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
      },
      sx: { width },
    }}
    className="mx-1"
    closedLabel={label}
    labelProps={{
      style: {
        transform: 'translate(16px, 6px) scale(1)',
      },
    }}
    options={actionOptions}
    selectProps={selectProps}
  />
);

export { QActionSelect };
