import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useParams, useHistory } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import ErrorHandler from '../components/ErrorHandler';
import LoginActivity from '../components/Settings/LoginActivity';
import TwoFactorAuth from '../components/Settings/TwoFactorAuth';
import APIKey from '../components/Settings/APIKey';
import MdmSideLoadedAppNotification from '../components/Settings/MdmSideLoadedAppNotification';
import AnalysisSettings from '../components/Settings/AnalysisSettings';
import AnalyticsSettings from '../components/Settings/AnalyticsSettings';
import MdmIntegrationCredentials from '../components/Settings/MdmIntegrationCredentials';
import GroupAdmin from '../components/Settings/GroupAdmin';
import LicenseUtilization from '../components/Settings/LicenseUtilization';
import AuditLogs from '../components/Settings/AuditLogs/AuditLogs';
import UserInfo from '../components/Settings/UserInfo';
import Report from '../components/Settings/Report';
import i18n from '../localization/i18n';
import { PDFReportConfig } from '../components/Settings/PDFReportConfig';
import { QTabContext } from '../components/Q-Components/QTabContext';
import { QTabList } from '../components/Q-Components/QTabList';
import { QTab } from '../components/Q-Components/QTab';
import { QTabLink } from '../components/Q-Components/QTabLink';
import { QTabContent } from '../components/Q-Components/QTabContent';
import { QTabPanel } from '../components/Q-Components/QTabPanel';
import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';
import { setPageTitle } from '../store/slices/emmAppSlice';

export const Settings = () => {
  const { tabName } = useParams();
  const [activeTabName, setActiveTabName] = useState(tabName);
  const history = useHistory();
  const { mastV2 } = useFlags();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Settings'));
  }, []);

  const { userAccess, license, userSubGroups } = useSelector(
    state => state.emmApp,
  );

  const tabs = [
    {
      key: 'security',
      title: () => (
        <span>
          {!mastV2 && <i className="fas fa-shield-check" />}{' '}
          {i18n.t('Security')}
        </span>
      ),
      tabContent: () => (
        <div className="animated fadeIn">
          <QRow columnGap="24px">
            <QCol flexGrow="4" flexShrink="4">
              <TwoFactorAuth />
            </QCol>
            <QCol flexGrow="4" flexShrink="4">
              <APIKey />
            </QCol>
          </QRow>
          <QRow sx={{ paddingTop: '24px' }}>
            <QCol flexGrow="8" flexShrink="8">
              <LoginActivity />
            </QCol>
          </QRow>
        </div>
      ),
    },
    {
      key: 'analysis',
      title: () => (
        <span>
          {!mastV2 && <i className="fas fa-mobile" />} {i18n.t('Analysis')}
        </span>
      ),
      tabContent: () => <AnalysisSettings />,
    },
    {
      key: 'analytics',
      title: () => (
        <span>
          {!mastV2 && <i className="fas fa-tachometer-alt" />}{' '}
          {i18n.t('Analytics')}
        </span>
      ),
      tabContent: () => <AnalyticsSettings />,
    },
  ];
  if (userAccess.group_admin) {
    tabs.push({
      key: 'reports',
      title: () => (
        <span>
          {!mastV2 && <i className="far fa-file-chart-line" />}{' '}
          {i18n.t('Report')}
        </span>
      ),
      tabContent: () => (
        <>
          <Report />
          <PDFReportConfig userId={userAccess.userId} />
        </>
      ),
    });
  }

  if (userAccess.mdm_integration) {
    tabs.push({
      key: 'mdm-integration',
      title: () => (
        <span>
          {!mastV2 && <i className="fas fa-screwdriver" />} MDM Integration
        </span>
      ),
      tabContent: () => (
        <QRow columnGap="36px">
          <QCol flex="1" xs="3">
            <MdmSideLoadedAppNotification />
          </QCol>
          <QCol flex="2" xs="6">
            <MdmIntegrationCredentials />
          </QCol>
        </QRow>
      ),
    });
  }

  if (userAccess.group_admin) {
    tabs.push({
      key: 'group-admin',
      title: () => (
        <span>{!mastV2 && <i className="far fa-users" />} Group Admin</span>
      ),
      tabContent: () => <GroupAdmin />,
    });
  }

  if (userAccess.group_admin) {
    tabs.push({
      key: 'audit-logs',
      title: () => (
        <span>
          {!mastV2 && <i className="far fa-clipboard-list" />} Audit Logs
        </span>
      ),
      tabContent: () => <AuditLogs />,
    });
  }

  if (license.endDate) {
    tabs.push({
      key: 'license',
      title: () => (
        <span>{!mastV2 && <i className="far fa-usd-circle" />}License</span>
      ),
      tabContent: () => <LicenseUtilization />,
    });
  }

  if (
    (userSubGroups && userSubGroups.length > 0) ||
    userAccess.can_invite_users
  ) {
    tabs.push({
      key: 'user-info',
      title: () => (
        <span>{!mastV2 && <i className="far fa-user" />} User Info</span>
      ),
      tabContent: () => (
        <UserInfo canInviteUsers={userAccess.can_invite_users} />
      ),
    });
  }

  return (
    <ErrorHandler>
      <QTabContext value={activeTabName}>
        <QTabList
          tabs
          onChange={(event, newTabName) => {
            setActiveTabName(newTabName);
          }}
        >
          {tabs.map(tab => (
            <QTab
              label={typeof tab.title === 'string' ? tab.title : tab.title()}
              key={tab.title}
              value={tab.key}
              onClick={() => {
                history.push(`/settings/${tab.key}`);
              }}
            >
              <QTabLink
                className={classnames({
                  active: tabName === tab.key,
                })}
                onClick={() => {
                  history.push(`/settings/${tab.key}`);
                }}
              >
                {typeof tab.title === 'string' ? tab.title : tab.title()}
              </QTabLink>
            </QTab>
          ))}
        </QTabList>

        <QTabContent activeTab={tabName}>
          {tabs.map(tab => (
            <QTabPanel
              key={tab.title}
              tabId={tab.key}
              value={tab.key}
              style={{ padding: 16 }}
              className="animated fadeIn"
            >
              {tab.tabContent()}
            </QTabPanel>
          ))}
        </QTabContent>
      </QTabContext>
    </ErrorHandler>
  );
};
