import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  FormGroup,
  Input,
} from 'reactstrap';
import { MultiSelect } from 'react-multi-select-component';
import Select from 'react-select';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { toastrHelper } from '../../logic/toastrHelper';

export const BatchChangeOwnershipModal = ({
  isOpen,
  toggle,
  analyzedAppIds,
  analyzedAppSubgroups,
  analyzedAppUserId,
  lastAnalyzedAppId,
  getApps,
}) => {
  const { userAccess, userSubGroups, groupAdminUsers } = useSelector(
    state => state.emmApp,
  );

  const [ownerGroups, setOwnerGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [applyToAllVersions, setApplyToAllVersions] = useState(false);
  const { mastV2 } = useFlags();

  const updateOne = async (analyzedAppId, newOwnerIds, newUserId) => {
    await axios.put(`analyzed-apps/${analyzedAppId}/subgroups`, {
      newOwnersGroupIds: newOwnerIds,
    });
    if (newUserId) {
      await axios.put(`analyzed-apps/${analyzedAppId}/submitter`, {
        newUserId,
      });
    }
  };

  const updateBatch = async (analyzedAppIds, newOwnerIds, newUserId) => {
    await axios.put(`analyzed-apps/batch/subgroups`, {
      subgroupIds: newOwnerIds,
      analyzedAppIds,
    });
    if (newUserId) {
      await axios.put(`analyzed-apps/batch/submitter`, {
        newUserId,
        analyzedAppIds,
      });
    }
  };

  const changeAppsOwner = async () => {
    try {
      let newOwnerIds = '';

      const newUserId = currentUser.value;
      for (let i = 0, len = ownerGroups.length; i < len; i++) {
        newOwnerIds += `${ownerGroups[i].value},`;
      }

      if (applyToAllVersions) {
        await updateBatch(analyzedAppIds, newOwnerIds, newUserId);
      } else {
        await updateOne(lastAnalyzedAppId, newOwnerIds, newUserId);
      }

      toastrHelper.showSuccessToast(
        i18n.t('Changed app ownership successfully'),
        null,
        mastV2,
      );
    } catch (err) {
      console.log('Error changing app ownership: ', err);
      toastrHelper.showErrorToast(
        i18n.t('Error changing app ownership'),
        null,
        mastV2,
      );
    }

    toggle();
    getApps();
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        const usersBuffer = groupAdminUsers.map(res => {
          if (analyzedAppUserId === res.id) {
            setCurrentUser({ value: res.id, label: res.email });
          }
          return { value: res.id, label: res.email };
        });

        setUsers(usersBuffer);
      } catch (err) {
        console.log('Error setting user:', err);
      }
    };

    if (userAccess.group_admin) {
      getUsers();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getOwnedGroups = async () => {
      if (analyzedAppSubgroups) {
        const subgroupIds = analyzedAppSubgroups.split(',');
        const ownerGroupsBuffer = [];
        subgroupIds.forEach(subgroup => {
          if (
            userSubGroups.findIndex(group => group.id === Number(subgroup)) >= 0
          ) {
            ownerGroupsBuffer.push({
              value: Number(subgroup),
              label:
                userSubGroups[
                  userSubGroups.findIndex(
                    group => group.id === Number(subgroup),
                  )
                ].name,
            });
          }
        });
        setOwnerGroups(ownerGroupsBuffer);
      }
    };

    if (userSubGroups.length > 0) {
      getOwnedGroups();
    }
    // eslint-disable-next-line
  }, [userSubGroups]);

  const isAdminValid =
    userAccess.group_admin && (ownerGroups.length !== 0 || currentUser.value);
  const isNonAdminValid = !userAccess.group_admin && ownerGroups.length !== 0;
  const isValid = isAdminValid || isNonAdminValid;

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>{i18n.t('Change App Ownership')}</ModalHeader>

      <ModalBody>
        <div>
          <Label>
            {i18n.t(
              "Which group would you like to reassign the app's ownership to?",
            )}
          </Label>
          <MultiSelect
            options={userSubGroups.map(group => ({
              value: group.id,
              label: group.name,
            }))}
            value={ownerGroups}
            onChange={setOwnerGroups}
            labelledBy="Select"
          />
        </div>
        {userAccess.group_admin && (
          <div>
            <Label>
              {i18n.t(
                "Which user would you like to reassign the app's ownership to?",
              )}
            </Label>
            <Select
              value={currentUser}
              options={users}
              onChange={option => setCurrentUser(option)}
            />
          </div>
        )}
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              onChange={e => setApplyToAllVersions(!!e.target.checked)}
            />{' '}
            Apply to all versions (if unchecked latest will be updated)
          </Label>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          <i className="fa fa-times" />
          &nbsp;{i18n.t('Cancel')}
        </Button>
        <Button
          className="confirm-modal-confirm-btn"
          color="primary"
          onClick={changeAppsOwner}
          disabled={!isValid}
        >
          <i className="far fa-check-circle" />
          &nbsp;{i18n.t('Confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
