import React from 'react';
import _ from 'lodash';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QTypography } from '../Q-Components/QTypography';

import AndroidAppInfoTable from './Android/AppInfoTable';
import IOSAppInfoTable from './iOS/AppInfoTable';

export function AppInfo({ results, platform, marketInfoExists }) {
  return (
    <div style={{ height: '100%' }}>
      <QCard
        className="card-accent-warning-2 app-info-section"
        style={{ height: '100%' }}
      >
        <QCardHeader
          title={<QTypography variant="h5Medium">App Information</QTypography>}
        >
          App Information
        </QCardHeader>
        <QCardBody>
          {_.isEmpty(results) ? (
            <i className="fas fa-spinner fa-spin" />
          ) : platform === 'android' ? (
            <AndroidAppInfoTable marketInfoExists={marketInfoExists} />
          ) : (
            <IOSAppInfoTable marketInfoExists={marketInfoExists} />
          )}
        </QCardBody>
      </QCard>
    </div>
  );
}
