/* eslint-disable import/no-unresolved */
import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Viewer } from '@microsoft/sarif-web-component';

export const SarifReport = () => {
  const doppler = useSelector(
    ({ appResults }) => appResults.results?.doppler ?? null,
  );

  if (!doppler || Array.isArray(doppler)) {
    return <div>Not available</div>;
  }

  // The clone deep here is to fix an issue the came up in the
  // SARIF view component. It throws an error when trying to add
  // a property to part of the SARIF results. From some light Googling
  // it seems that RTK uses Immer for any reducer that's created with
  // createSlice, so that would make sense.
  return <Viewer logs={[_.cloneDeep(doppler)]} />;
};
