import React, { useState } from 'react';
import axios from 'axios';
import { isRange } from 'range_check';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QTypography } from '../Q-Components/QTypography';
import { QButton } from '../Q-Components/QButton';
import { QInput } from '../Q-Components/QInput';
import { QIconButton } from '../Q-Components/QIconButton';
import util from '../../logic/util';
import { toastrHelper } from '../../logic/toastrHelper';
import { QListGroup } from '../Q-Components/QListGroup';
import { QListGroupItem } from '../Q-Components/QListGroupItem';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';

export const WhitelistIps = ({ getUserData, groupUser }) => {
  const [ip, setIp] = useState('');
  const { mastV2 } = useFlags();

  const addIpToWhitelist = async () => {
    let error = false;
    const ipArray = ip.replace(/\s+/g, '').split(',');

    ipArray.forEach(ipAddr => {
      if (ipAddr.includes('/')) {
        if (!isRange(ipAddr)) {
          toastrHelper.showErrorToast(
            `IP address is not a valid range: ${ipAddr}`,
            null,
            mastV2,
          );
          error = true;
        }
      } else {
        const { valid: ipValid, msg: ipValidMsg } = util.validateIp(ipAddr);
        if (!ipValid) {
          toastrHelper.showErrorToast(
            `IP address (${ipAddr}) not valid: ${ipValidMsg}`,
            null,
            mastV2,
          );
          error = true;
        }
      }
    });

    if (!error) {
      await axios.put(`group-admin/user/${groupUser.id}/whitelist-ips`, {
        ip,
      });
      setIp('');
      getUserData();
    }
  };

  const deleteIpFromWhitelist = async whitelistIpId => {
    await axios.delete(
      `group-admin/user/${groupUser.id}/whitelist-ips/${whitelistIpId}`,
    );
    getUserData();
  };

  return (
    <>
      <QTypography>
        <strong>API Key Allowlist IPs</strong>
      </QTypography>

      <QRow gap={1}>
        <QCol xs="6">
          <QTypography variant="caption">
            <small>
              You can provide either a single IP address or an IP address with a
              netmask such as <code>192.168.1.1/24</code>.
            </small>
          </QTypography>

          <QInput
            type="text"
            placeholder="IP address"
            data-testid="whitelist-ip-input"
            onChange={e => setIp(e.target.value)}
            value={ip}
          />

          <QButton
            sx={{ mt: 2 }}
            variant="filled"
            className="btn-primary mt-2"
            onClick={addIpToWhitelist}
            data-testid="whitelist-add-ip-button"
          >
            <i className="far fa-plus-circle mr-2" /> Add
          </QButton>
        </QCol>

        <QCol xs="6">
          <QListGroup>
            {groupUser.whitelistIps.map(ip => (
              <QListGroupItem
                key={ip.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '250px',
                }}
                divider
              >
                <QTypography>
                  <strong>IP:</strong> {ip.ip}
                </QTypography>

                <QIconButton
                  className="btn-warning"
                  size="sm"
                  onClick={() => deleteIpFromWhitelist(ip.id)}
                >
                  <i className="far fa-trash pointer" />
                </QIconButton>
              </QListGroupItem>
            ))}
          </QListGroup>
        </QCol>
      </QRow>
    </>
  );
};
