import React from 'react';
import moment from 'moment';

import AnalyzedAppInfo from '../AnalyzedApps/AnalyzedAppInfo';
import { DateTime } from '../DateTime';
import { ThreatScore } from '../ThreatScore';
import util from '../../logic/util';
import i18n from '../../localization/i18n';
import { QTableRow } from '../Q-Components/QTableRow';
import { QTableCell } from '../Q-Components/QTableCell';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QCheckbox } from '../Q-Components/QCheckbox';

/**
 * Format the app issues to the proper HTML
 * @param {*} issues
 */
const formatAppIssues = parsedIssues => {
  // Get a list of high risk items as configured by the user since we display
  // these by default in the summary section
  const issuesHtml = parsedIssues
    .filter(
      item =>
        item.found &&
        (item.risk === 'high' ||
          item.risk === 'critical' ||
          item.risk === 'medium'),
    )
    .map((issue, index) => {
      const issueInfo = util.formatAppIssue(issue.name[0]);
      // Check to see if an icon was found for the item
      // The util function will return just the issue string if no icon was found
      return (
        <div key={index} className="float-left ml-2">
          <i
            className={issueInfo.iconClass || 'fas fa-exclamation issue-icon'}
          />{' '}
          {i18n.t(issue.positive_finding_text)}
        </div>
      );
    })
    .filter(item => item);

  if (issuesHtml.length === 0) {
    return <span>{i18n.t('No high risk issues found')}</span>;
  }

  // Filter out any items that are blank in the array and then join then by ', '
  return issuesHtml;
};

export const AppVersion = ({ app, selectedApps, setSelectedApps }) => (
  <QTableRow>
    {/* App data */}
    <QTableCell>
      <QCheckbox
        type="checkbox"
        id={app.id}
        checked={selectedApps.includes(app.id)}
        onChange={() => {
          const tempSelectedApps = selectedApps;
          if (tempSelectedApps.includes(app.id)) {
            const indexToRemove = tempSelectedApps.indexOf(app.id);
            if (indexToRemove > -1) {
              tempSelectedApps.splice(indexToRemove, 1);
            }
          } else {
            tempSelectedApps.push(app.id);
          }
          setSelectedApps([...tempSelectedApps]);
        }}
      />
      <AnalyzedAppInfo app={app} />
    </QTableCell>

    {/* Analysis time */}
    <QTableCell className="text-center">
      {app.analysis_start && app.analysis_end ? (
        <span>
          <i className="fas fa-stopwatch mr-2" />
          <strong>
            {moment(app.analysis_end).diff(
              moment(app.analysis_start),
              'minutes',
            )}
          </strong>{' '}
          mins
        </span>
      ) : (
        <i className="fas fa-spinner fa-spin" />
      )}
    </QTableCell>

    {/* Date Submitted */}
    <QTableCell className="text-center">
      <DateTime dateTime={app.submitted_at} />
    </QTableCell>

    {/* Threat Score */}
    <QTableCell className="text-center">
      <ThreatScore
        threatScore={app.threat_score}
        status={app.farm_app.status}
      />
    </QTableCell>

    {/* High Risk Issues */}
    {app.farm_app.status !== 2 || !app.parsedIssues ? (
      <QTableCell className="text-center">
        <i className="fas fa-spinner fa-spin" />
      </QTableCell>
    ) : (
      <QTableCell>{formatAppIssues(app.parsedIssues)} </QTableCell>
    )}

    {/* Total App Issues */}
    {app.farm_app.status !== 2 ? (
      <QTableCell className="text-center">
        <i className="fas fa-spinner fa-spin" />
      </QTableCell>
    ) : (
      <QTableCell className="text-center">
        <QRow columnGap="5px">
          <QCol className="text-left">
            {i18n.t('Low: ')}
            <br />
            {i18n.t('Medium: ')}
            <br />
            {i18n.t('High: ')}
            <br />
            {i18n.t('Critical: ')}
            <br />
          </QCol>
          {app.parsedIssues && (
            <QCol>
              {app.lowIssueDifferential === 1 ? (
                <i className="far fa-long-arrow-up danger-icon" />
              ) : app.lowIssueDifferential === -1 ? (
                <i className="far fa-long-arrow-down success-icon" />
              ) : (
                <i className="far fa-minus-circle black-icon" />
              )}
              &nbsp;
              {
                app.parsedIssues.filter(
                  issue => issue.found && issue.risk === 'low',
                ).length
              }
              <br />
              {app.mediumIssueDifferential === 1 ? (
                <i className="far fa-long-arrow-up danger-icon" />
              ) : app.mediumIssueDifferential === -1 ? (
                <i className="far fa-long-arrow-down success-icon" />
              ) : (
                <i className="far fa-minus-circle black-icon" />
              )}
              &nbsp;
              {
                app.parsedIssues.filter(
                  issue => issue.found && issue.risk === 'medium',
                ).length
              }
              <br />
              {app.highIssueDifferential === 1 ? (
                <i className="far fa-long-arrow-up danger-icon" />
              ) : app.highIssueDifferential === -1 ? (
                <i className="far fa-long-arrow-down success-icon" />
              ) : (
                <i className="far fa-minus-circle black-icon" />
              )}
              &nbsp;
              {
                app.parsedIssues.filter(
                  issue => issue.found && issue.risk === 'high',
                ).length
              }
              <br />
              {app.criticalIssueDifferential === 1 ? (
                <i className="far fa-long-arrow-up danger-icon" />
              ) : app.criticalIssueDifferential === -1 ? (
                <i className="far fa-long-arrow-down success-icon" />
              ) : (
                <i className="far fa-minus-circle black-icon" />
              )}
              &nbsp;
              {
                app.parsedIssues.filter(
                  issue => issue.found && issue.risk === 'critical',
                ).length
              }
            </QCol>
          )}
        </QRow>
      </QTableCell>
    )}
  </QTableRow>
);
