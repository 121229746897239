import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import DataTable from '../DataTable/DataTable';
import i18n from '../../localization/i18n';
import { getCodeFromCountryName } from '../../logic/countryUtil';

class CountriesContactedList extends Component {
  getCountryCode(country) {
    if (country === 'Europe') {
      return 'eu';
    }
    const countryCode = getCodeFromCountryName(country);
    if (!countryCode) {
      return '';
    }

    return countryCode.toLowerCase();
  }

  render() {
    const { parsedAppIssues, platform } = this.props;

    const highRiskConnections = parsedAppIssues.find(
      issue =>
        issue.reportIdentifier === 'HIGHRISK_COUNTRY_CONNECTION' &&
        issue.found === true,
    );

    return !highRiskConnections ||
      !Array.isArray(highRiskConnections.evidence) ? (
      <span>{i18n.t('No parsed evidence found.')}</span>
    ) : (
      <div>
        <DataTable
          data={highRiskConnections.evidence}
          columns={[
            {
              keyName: platform === 'ios' ? 'uri' : 'domain',
              label: i18n.t('Domain'),
              sortable: true,
              filterable: true,
              width: '50%',
            },
            {
              keyName: 'country',
              label: i18n.t('Country'),
              component: ({ value }) => (
                <div>
                  <span className={`fi fi-${this.getCountryCode(value)}`} />{' '}
                  {value}
                </div>
              ),
              sortable: true,
              filterable: true,
              width: '50%',
            },
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  parsedAppIssues: appResults.parsedIssues,
});

export default withRouter(connect(mapStateToProps)(CountriesContactedList));
