import React from 'react';
import { Card } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QProfile as MuiProfile } from '@kw/quokka-ui';

const LDProfile = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiProfile {...props}>{children}</MuiProfile>
  ) : (
    <Card {...props}>{children}</Card>
  );
};
export const QProfile = withLDConsumer()(LDProfile);
