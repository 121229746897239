import axios from 'axios';

const baseUrl = 'adminV2';

const getKaiUsers = async config => {
  const users = await axios.get(`${baseUrl}/users`, config);
  return users.data;
};

const getKaiGroups = async () => {
  const groups = await axios.get(`${baseUrl}/groups`);
  return groups.data;
};

export default {
  getKaiUsers,
  getKaiGroups,
};
