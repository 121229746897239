import React from 'react';

import { AppApprovalStatusBadge } from './AppApporvalStatusBadge';
import { ThreatScoreBadge } from './ThreatScoreBadge';
import { QChip } from './Q-Components/QChip';
import { QUncontrolledTooltip } from './Q-Components/QUncontrolledTooltip';

export function ThreatScore({ threatScore, status, analysisApprovalStatus }) {
  const processingStatuses = [0, 1, 5]; // Not submitted, processing, and resubmitted

  if (['pending', 'denied'].includes(analysisApprovalStatus)) {
    return <AppApprovalStatusBadge status={analysisApprovalStatus} />;
  }

  if (processingStatuses.includes(status)) {
    return <i className="fas fa-spin fa-spinner" />;
  }

  // App analysis cancelled
  if (status === 30) {
    return (
      <QChip color="danger" pill variant="secondary" label="Cancelled">
        <i className="fas fa-window-close mr-1" />
        Cancelled
      </QChip>
    );
  }

  /* If the app is still processing, display the spinner.
            Otherwise, display proper badge color */
  if (status !== 2 && status !== 10) {
    return (
      <>
        <QChip
          color="danger"
          pill
          id="analysisErrorStatus"
          variant="secondary"
          label="Error"
        >
          Error
        </QChip>
        <QUncontrolledTooltip
          placement="right"
          target="analysisErrorStatus"
          title="Quokka has already been notified of the problem"
        />
      </>
    );
  }

  return <ThreatScoreBadge threatScore={threatScore} />;
}
