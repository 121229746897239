import axios from 'axios';

import util from '../../logic/util';
import { getCacheValue } from '../cacheHelpers/utils';
import { cacheFirmware } from '../slices/cache/firmwareCacheSlice';

const cache = 'firmware';
export const fetchFirmwareCache =
  (url, params = {}) =>
  async dispatch => {
    const key = util.paramsToUrl(url, params);

    const cacheValue = getCacheValue(key, cache);

    if (cacheValue) {
      return JSON.parse(cacheValue);
    }

    const data = await axios.get(url, { params });
    if (data.status < 400) {
      dispatch(cacheFirmware({ key, data: JSON.stringify(data) }));
    }
    return data;
  };
