import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import { connect } from 'react-redux';

import EditAppIssueModal from '../../../components/Modals/EditAppIssueModal';

import NiapSectionsData from './NiapSectionsData';

class NiapCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      showData: false,
      isOpenEditModal: false,
      appIssue: {},
    };

    this.toggleEditAppIssueModal = this.toggleEditAppIssueModal.bind(this);
  }

  toggleEditAppIssueModal() {
    this.setState({
      isOpenEditModal: !this.state.isOpenEditModal,
    });
  }

  render() {
    const { finding, filter, niapFindings, platform, niapIssues } = this.props;
    const { isOpen, showData, isOpenEditModal, appIssue } = this.state;

    if (!niapFindings) {
      return null;
    }

    const { result } = niapFindings[finding];

    const customAppIssue = niapIssues.find(
      issue => issue.issue.replace('niap_', '') === finding,
    );

    let level;
    if (result === 'pass') {
      level = 'success';
    } else if (niapFindings[finding].result === 'review') {
      level = 'warning';
    } else {
      level = 'danger';
    }

    return (
      <div>
        <EditAppIssueModal
          open={isOpenEditModal}
          toggle={this.toggleEditAppIssueModal}
          appIssue={appIssue}
          farmAppUuid={window.location.hash.split('/')[2]}
        />

        {filter === '' || filter === result ? (
          <Card className={`card-accent-${level}`} id={`niap-${finding}`}>
            <CardHeader>
              <div className="d-flex justify-between">
                <div
                  onClick={() => this.setState({ isOpen: !isOpen })}
                  className="pointer"
                  style={{ width: '95%' }}
                >
                  {finding}
                </div>
                <div>
                  {customAppIssue && (
                    <i
                      id={`niap-icon-${finding}`}
                      className="far fa-edit ml-2 pointer"
                      onClick={() => {
                        this.toggleEditAppIssueModal();
                        this.setState({ appIssue: customAppIssue });
                      }}
                    />
                  )}
                  {niapFindings[finding].flag && (
                    <span>
                      <i className="fas fa-flag ml-2" />
                    </span>
                  )}
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {niapFindings[finding].note && (
                <i>Note: {niapFindings[finding].note} </i>
              )}
              <p
                className={isOpen ? `text-${level}` : null}
                dangerouslySetInnerHTML={{
                  // When we have a custom result for the finding, the NIAP data may not have the message for
                  // the custom finding. So we fall back to the review text.
                  __html:
                    niapFindings[finding][result] ||
                    niapFindings[finding].review,
                }}
              />
              <Collapse isOpen={isOpen}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: niapFindings[finding].description,
                  }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: niapFindings[finding].activity,
                  }}
                />
                <strong>
                  Additional Info <i className="fas fa-caret-down" />
                </strong>
                <p
                  style={{
                    border: '2px solid #dedede',
                    borderRadius: '5px',
                    padding: '10px',
                    margin: '10px 0',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: niapFindings[finding].explanation,
                  }}
                />
                <p
                  className="text-center"
                  onClick={() => this.setState({ showData: !showData })}
                >
                  {' '}
                  <strong>
                    <i
                      className={
                        showData ? 'fas fa-caret-up' : 'fas fa-caret-down'
                      }
                    />{' '}
                    <span className="pointer">More Data</span>{' '}
                    <i
                      className={
                        showData ? 'fas fa-caret-up' : 'fas fa-caret-down'
                      }
                    />
                  </strong>{' '}
                </p>
                <hr />{' '}
                <Collapse isOpen={showData}>
                  <NiapSectionsData
                    niapFindings={niapFindings}
                    finding={finding}
                    platform={platform}
                  />
                </Collapse>
              </Collapse>
            </CardBody>
          </Card>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ appResults }) => ({
  niapIssues: appResults.niapAppIssues,
});

export default connect(mapStateToProps)(NiapCard);
