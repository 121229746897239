import React from 'react';
import moment from 'moment';

export const AnalysisTime = ({ analysisStart, analysisEnd, status }) => (
  <div>
    {analysisStart && analysisEnd ? (
      <span>
        <i className="fas fa-stopwatch mr-1" />
        <strong>
          {moment(analysisEnd).diff(moment(analysisStart), 'minutes')}
        </strong>{' '}
        mins
      </span>
    ) : status === 2 ? (
      <span>N/A</span>
    ) : (
      <i className="fas fa-spinner fa-spin" />
    )}
  </div>
);
