import React from 'react';
import { Link } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import { withLDConsumer, useFlags } from 'launchdarkly-react-client-sdk';

import { QUncontrolledButtonDropdown } from '../../Q-Components/QUncontrolledButtonDropdown';
import { QButton } from '../../Q-Components/QButton';
import { QDropdownSelectItem } from '../../Q-Components/QDropdownSelectItem';
import { QDropdownToggle } from '../../Q-Components/QDropdownToggle';
import { QDropdownMenu } from '../../Q-Components/QDropdownMenu';
import { QMenuButtonWithIcon } from '../../Q-Components/QMenuButtonWithIcon';
import { QActionSelect } from '../../Q-Components/QActionSelect';

export function KaiAppActions({ farmAppId }) {
  const handleFocus = event => {
    event.target.style.background = 'none';
  };
  const { mastV2 } = useFlags();

  return (
    <>
      {' '}
      {mastV2 ? (
        <QActionSelect
          actionOptions={[
            {
              value: 'details',
              label: 'Details',
              icon: <i className="fas fa-info-circle fa-lg" />,
              link: `/kai/farm-apps/${farmAppId}`,
            },
          ]}
        />
      ) : (
        <QUncontrolledButtonDropdown
          variant="filled"
          style={{
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
          className="mx-1"
          title="Actions"
          sx={{ width: '120px' }}
        >
          <QDropdownToggle
            caret
            className="toggledown-primary"
            size="sm"
            disableAnimation
            shrink={false}
            style={{
              transform: 'none',
              overflow: 'visible',
            }}
          >
            <QButton
              variant="outlined"
              endIcon={<ExpandMore />}
              className="button-toggledown-primary"
            >
              Actions
            </QButton>
          </QDropdownToggle>
          <QDropdownMenu
            variant="filled"
            IconComponent={null}
            value=""
            displayEmpty
            style={{
              background: 'none',
              paddingTop: '0px',
              minWidth: 'unset',
            }}
            renderValue={() => {}}
            onFocus={handleFocus}
          >
            {/* First item is always selected in MUI by default so this is to get rid of that behavior. */}
            <QDropdownSelectItem value="" style={{ display: 'none' }} />
            <Link to={`/kai/farm-apps/${farmAppId}`}>
              <QDropdownSelectItem title="Details">
                <QMenuButtonWithIcon
                  icon={<i className="fas fa-info-circle fa-lg" />}
                  label="Details"
                />
              </QDropdownSelectItem>
            </Link>
          </QDropdownMenu>
        </QUncontrolledButtonDropdown>
      )}
    </>
  );
}

export default withLDConsumer()(KaiAppActions);
