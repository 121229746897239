import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { toastrHelper } from '../../../logic/toastrHelper';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QInput } from '../../Q-Components/QInput';
import { QInputGroup } from '../../Q-Components/QInputGroup';
import { QInputGroupAddon } from '../../Q-Components/QInputGroupAddon';
import { QButton } from '../../Q-Components/QButton';
import { QListGroup } from '../../Q-Components/QListGroup';
import { QListGroupItem } from '../../Q-Components/QListGroupItem';
import { QIconButton } from '../../Q-Components/QIconButton';

const TrafficRegexFilter = () => {
  const [regexes, setRegexes] = useState([]);
  const { mastV2 } = useFlags();
  const [newRegex, setNewRegex] = useState('');

  const getTrafficRegexes = async () => {
    const response = await axios.get(
      'analysis-customization/traffic-regexes',
      {},
    );

    if (response.status !== 200) {
      toastrHelper.showErrorToast(
        'Error getting Traffic Regexes',
        null,
        mastV2,
      );
    }

    setRegexes(response.data);
  };

  const addRegexToTrafficRegexes = async () => {
    const response = await axios.post(
      'analysis-customization/traffic-regexes',
      {
        regex: newRegex,
      },
    );
    console.log(response);
    setNewRegex('');
    getTrafficRegexes();
  };

  const deleteRegexFromTrafficRegexes = async id => {
    console.log(id);
    const response = await axios.delete(
      `analysis-customization/traffic-regexes/${id}`,
    );
    console.log(response);
    getTrafficRegexes();
  };

  useEffect(() => {
    getTrafficRegexes();
  }, []);

  return (
    <QCard className="card-accent-warning-2">
      <QCardHeader title={<strong>Traffic Regex List</strong>}>
        <strong>Traffic Regex List</strong>
      </QCardHeader>
      <QCardBody>
        Regex:
        <QInputGroup>
          <QInput
            type="text"
            placeholder="Traffic Regex"
            onChange={e => setNewRegex(e.target.value)}
            value={newRegex}
          />
          <QInputGroupAddon addonType="append">
            <QButton
              variant="filled"
              color="primary"
              onClick={addRegexToTrafficRegexes}
              disabled={!newRegex}
            >
              Add
            </QButton>
          </QInputGroupAddon>
        </QInputGroup>
        <QListGroup className="mt-2">
          {regexes.map(regex => (
            <QListGroupItem
              key={regex.id}
              className="d-flex justify-between"
              secondaryAction={
                <QIconButton
                  style={{
                    color: 'unset',
                  }}
                >
                  <RemoveCircleOutlineIcon
                    fontSize="small"
                    onClick={() => deleteRegexFromTrafficRegexes(regex.id)}
                  />
                </QIconButton>
              }
            >
              <code>{regex.regex}</code>
              {!mastV2 && (
                <span className="pointer">
                  <i
                    className="far fa-times-circle danger-icon"
                    onClick={() => deleteRegexFromTrafficRegexes(regex.id)}
                  />
                </span>
              )}
            </QListGroupItem>
          ))}
        </QListGroup>
      </QCardBody>
    </QCard>
  );
};

export default TrafficRegexFilter;
