import React, { Component } from 'react';
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import axios from 'axios';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import util from '../logic/util';
import { toastrHelper } from '../logic/toastrHelper';
import i18n from '../localization/i18n';

class PasswordReset extends Component {
  constructor() {
    super();
    this.state = {
      newPassword: '',
      newPasswordDuplicate: '',
    };
  }

  async handlePasswordReset() {
    const { newPassword, newPasswordDuplicate } = this.state;
    // Check to see if passwords are equal
    if (newPassword !== newPasswordDuplicate) {
      toastrHelper.showErrorToast(
        i18n.t('Passwords do not match'),
        i18n.t('Error'),
        this.props.flags?.mastV2,
      );
      return;
    }

    // Get token from URL
    const token = util.getQueryVariable('token', this.props.location.search);
    if (!token) {
      toastrHelper.showErrorToast(
        i18n.t('No token provided'),
        i18n.t('Error'),
        this.props.flags?.mastV2,
      );
      return;
    }

    let response;
    try {
      response = await axios.post(`emm/forgot-password/${token}`, {
        newPassword: this.state.newPassword,
        newPasswordDuplicate: this.state.newPasswordDuplicate,
      });

      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          i18n.t('Successfully updated password'),
          i18n.t('Success'),
          this.props.flags?.mastV2,
        );
        this.props.history.push('/login');
      } else {
        toastrHelper.showErrorToast(
          `${i18n.t('Error updating password')}: ${response.data.msg}`,
          'Error',
          this.props.flags?.mastV2,
        );
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        `${i18n.t('Error updating password')}: ${err.response.data.msg}`,
        i18n.t('Error'),
        this.props.flags?.mastV2,
      );
    }
  }

  // Handles key press for the password field, tries to login on the enter key
  handleKeyPress(event) {
    if (event.charCode === 13) {
      event.preventDefault();
      this.handlePasswordReset();
    }
  }

  render() {
    return (
      <div>
        <div className="app flex-row align-items-center">
          <Container fluid>
            <Row id="login-header-row" className="justify-content-center">
              <div className="banner-logo" />
            </Row>
            {this.state.errorMsg && (
              <Row className="justify-content-center">
                <Col md="4">
                  <Alert color="danger">
                    <strong>{i18n.t('Error')}:&nbsp;</strong>
                    {this.state.errorMsg}
                  </Alert>
                </Col>
              </Row>
            )}

            <Row className="justify-content-center">
              <Col md="3">
                <Card className="p-4">
                  <CardBody className="card-body text-center">
                    <h3>{i18n.t('Password Reset')}</h3>
                    <InputGroup className="mb-3">
                      <InputGroupAddon
                        addonType="prepend"
                        className="justify-content-center"
                      >
                        <i className="icon-lock" />
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder={i18n.t('New Password')}
                        onChange={e =>
                          this.setState({ newPassword: e.target.value })
                        }
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <InputGroupAddon
                        addonType="prepend"
                        className="justify-content-center"
                      >
                        <i className="icon-lock" />
                      </InputGroupAddon>
                      <Input
                        type="password"
                        placeholder={i18n.t('Retype Password')}
                        onChange={e =>
                          this.setState({
                            newPasswordDuplicate: e.target.value,
                          })
                        }
                        onKeyPress={e => this.handleKeyPress(e)}
                      />
                    </InputGroup>
                    <Row>
                      <Col xs="12">
                        <Button
                          block
                          color="primary"
                          className="px-4"
                          onClick={() => this.handlePasswordReset()}
                        >
                          <i className="fas fa-lock" />
                          &nbsp;{i18n.t('Reset Password')}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Row>
                  <Col xs="12" className="text-center">
                    <strong>Quokka</strong> -{' '}
                    {i18n.t('Mobile Application Security Testing')}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(PasswordReset);
