import React from 'react';

import { QContainer } from '../Q-Components/QContainer';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';

export const FirmwareAppInfo = ({ app }) => {
  let farmAppName;

  try {
    farmAppName = decodeURIComponent(app.name);
  } catch (error) {
    farmAppName = app.name;
  }

  return (
    <QContainer fluid style={{ padding: '0px', background: 'none' }}>
      <QRow>
        <QCol xs="2" className="text-right">
          <QRow>
            <i className="fab fa-android fa-2x" />
          </QRow>
        </QCol>

        <QCol xs="10" className="text-left">
          <QRow>
            <strong>{farmAppName}</strong>
          </QRow>
          <QRow>
            <i>{app.package}</i>
          </QRow>
          <QRow>
            <i className="fab fa-android mt-1" />
            &nbsp;
            {app.version}
          </QRow>
        </QCol>
      </QRow>
    </QContainer>
  );
};
