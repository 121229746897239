import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router';
import { Card, CardHeader, CardBody } from 'reactstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Loading from '../Loading';

export const AppManifest = () => {
  const { farmAppId } = useParams();
  const [manifestCode, setManifestCode] = useState(null);

  const [showDetails, setShowDetails] = useState({
    value: false,
    label: 'Show More',
  });

  const toggleDetails = () => {
    setShowDetails({
      value: !showDetails.value,
      label: showDetails.value ? 'Show More' : 'Show Less',
    });
  };

  useEffect(() => {
    const getData = async () => {
      console.log('Getting manifest for farm app uuid:', farmAppId);
      try {
        const response = await axios.get(
          `api/results/manifest?uuid=${farmAppId}`,
        );

        if (response.status === 200) {
          setManifestCode(response.data);
        }
      } catch (err) {
        console.log('Error getting manifest for farm app: ', err);
      }
    };

    getData();
  }, [farmAppId]);

  if (!manifestCode) {
    return null;
  }

  return (
    <Card className="card-accent-primary">
      <CardHeader>App Manifest</CardHeader>
      <CardBody>
        {manifestCode ? (
          <>
            <SyntaxHighlighter language="xml" style={docco}>
              {showDetails.value
                ? manifestCode
                : `${manifestCode.substring(0, 500)}...`}
            </SyntaxHighlighter>

            <div
              className="text-center pointer bg-primary"
              onClick={() => toggleDetails()}
            >
              <strong>{showDetails.label}</strong>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </CardBody>
    </Card>
  );
};
