import React from 'react';
import { CardText } from 'reactstrap';

import { Issues } from './Issues';

export const V8_2 = ({ evidence }) => (
  <>
    <Issues evidence={evidence} issues={['DEBUGGABLE']} />
    <hr />
    <CardText>
      <strong>Debuggable: </strong>
      {evidence.isDebuggable ? (
        <i className="far fa-check-circle danger-icon" />
      ) : (
        <i className="far fa-times-circle success-icon" />
      )}
    </CardText>
  </>
);
