import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QChip } from './Q-Components/QChip';
import { QUncontrolledTooltip } from './Q-Components/QUncontrolledTooltip';

export const AppApprovalStatusBadge = ({ status }) => {
  const { mastV2 } = useFlags();

  const config = {
    pending: {
      color: 'warning',
      icon: 'fas fa-exclamation-circle',
      variant: 'primaryDark',
    },
    denied: {
      color: 'danger',
      icon: 'fas fa-times-circle',
      variant: 'secondary',
    },
  };

  const { color, icon, variant } = config[status];

  const AppApprovalChip = () => (
    <QChip
      variant={variant}
      color={color}
      pill
      id="appApprovalStatus"
      label={`Analysis approval ${status}`}
    >
      <i className={`${icon} mr-1`} />
      Analysis approval {status}
    </QChip>
  );

  return (
    <>
      {!mastV2 && <AppApprovalChip />}
      <QUncontrolledTooltip
        placement="right"
        target="appApprovalStatus"
        title=" Please contact your admin for assistance"
      >
        <span>
          <AppApprovalChip />
        </span>
      </QUncontrolledTooltip>
    </>
  );
};
