import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { QInputGroup } from '../../Q-Components/QInputGroup';
import { QInput } from '../../Q-Components/QInput';
import { QInputGroupAddon } from '../../Q-Components/QInputGroupAddon';
import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';
import { QButton } from '../../Q-Components/QButton';
import { QIconButton } from '../../Q-Components/QIconButton';

export default function EditableTextArea({
  isEditing,
  fieldName,
  value,
  oldValue,
  setValue,
  setIsEditing,
  currentItemId,
  onSave,
}) {
  const { mastV2 } = useFlags();
  return (
    <QInputGroup>
      <div style={{ display: 'flex', width: '100%' }}>
        {!mastV2 ? (
          <QInput
            disabled={!isEditing}
            value={value}
            type="textarea"
            onChange={e => setValue(e.target.value)}
            className="form-control"
          />
        ) : (
          <textarea
            className="form-control"
            onChange={e => {
              setValue(e.target.value);
            }}
            value={value}
            rows="3"
            style={{ borderRadius: '4px', borderColor: '#BDBDBD' }}
            data-testid="text-area-input"
            disabled={!isEditing}
          />
        )}
        <QInputGroupAddon addonType="append">
          <span>
            {isEditing ? (
              <QCol>
                <QRow sx={{ paddingBottom: '20px' }}>
                  {!mastV2 ? (
                    <QButton
                      disabled={
                        value === oldValue || value === '' || !currentItemId
                      }
                      className="btn-block"
                      color="primary"
                      onClick={() => {
                        onSave(fieldName);
                        setIsEditing(false);
                      }}
                    >
                      <i className="far fa-save" />
                    </QButton>
                  ) : (
                    <QIconButton
                      disabled={
                        value === oldValue || value === '' || !currentItemId
                      }
                      onClick={() => {
                        onSave(fieldName);
                        setIsEditing(false);
                      }}
                    >
                      <SaveOutlinedIcon fontSize="small" />
                    </QIconButton>
                  )}
                </QRow>
                <QRow>
                  {!mastV2 ? (
                    <QButton
                      className="btn-block"
                      onClick={() => {
                        setIsEditing(false);
                        setValue(oldValue);
                      }}
                    >
                      <i className="far fa-times" />
                    </QButton>
                  ) : (
                    <QIconButton
                      disabled={
                        value === oldValue || value === '' || !currentItemId
                      }
                      onClick={() => {
                        setIsEditing(false);
                        setValue(oldValue);
                      }}
                    >
                      <CloseOutlinedIcon fontSize="small" />
                    </QIconButton>
                  )}
                </QRow>
              </QCol>
            ) : (
              <QCol>
                <QRow>
                  {!mastV2 ? (
                    <QButton
                      className="btn-block"
                      disabled={!currentItemId}
                      onClick={() => setIsEditing(!isEditing)}
                    >
                      <i className="far fa-edit" />
                    </QButton>
                  ) : (
                    <QIconButton
                      onClick={() => setIsEditing(!isEditing)}
                      sx={{ paddingRight: '5px' }}
                      disabled={!currentItemId}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </QIconButton>
                  )}
                </QRow>
              </QCol>
            )}
          </span>
        </QInputGroupAddon>
      </div>
    </QInputGroup>
  );
}
