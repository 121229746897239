import React, { useState, useRef, useEffect } from 'react';
import { isRange } from 'range_check';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QRow } from '../../../Q-Components/QRow';
import { QCol } from '../../../Q-Components/QCol';
import { QButton } from '../../../Q-Components/QButton';
import { QInput } from '../../../Q-Components/QInput';
import util from '../../../../logic/util';
import { toastrHelper } from '../../../../logic/toastrHelper';
import { QTypography } from '../../../Q-Components/QTypography';
import { QIconButton } from '../../../Q-Components/QIconButton';
import { QListGroup } from '../../../Q-Components/QListGroup';
import { QListGroupItem } from '../../../Q-Components/QListGroupItem';

const LDDefaultWhitelistIps = ({
  defaultWhitelistIps = [],
  updateSubGroup,
}) => {
  const { mastV2 } = useFlags();

  const [newIp, setNewIp] = useState('');
  const ipInputRef = useRef(null);
  const [ips, setIps] = useState(defaultWhitelistIps || []);

  const addIpToWhitelist = async () => {
    let error = false;
    const ipArray = newIp.replace(/\s+/g, '').split(',');

    ipArray.forEach(ipAddr => {
      if (ipAddr.includes('/')) {
        if (!isRange(ipAddr)) {
          toastrHelper.showErrorToast(
            `IP address is not a valid range: ${ipAddr}`,
            null,
            mastV2,
          );
          error = true;
        }
      } else {
        const { valid: ipValid, msg: ipValidMsg } = util.validateIp(ipAddr);
        if (!ipValid) {
          toastrHelper.showErrorToast(
            `IP address (${ipAddr}) not valid: ${ipValidMsg}`,
            null,
            mastV2,
          );
          error = true;
        }
      }
    });

    if (error) {
      return;
    }

    const newIps = [...ips, ...ipArray];
    console.log('new ips:', newIps);
    setIps(newIps);

    await updateSubGroup({ defaultWhitelistIps: newIps });
    ipInputRef.current.focus();
    setNewIp('');
  };

  const deleteIpFromWhitelist = async whitelistIp => {
    const newIps = ips.filter(ip => ip !== whitelistIp);
    await updateSubGroup({ defaultWhitelistIps: newIps });
    setIps(newIps);
  };

  useEffect(() => {
    setIps(defaultWhitelistIps ?? []);
  }, [defaultWhitelistIps?.length > 0]);

  return (
    <>
      <strong>Default API Key Allowlist IPs</strong>
      <QRow columnGap="30px">
        <QCol xs="6">
          <div>
            <small>
              You can provide either a single IP address or an IP address with a
              netmask such as <code>192.168.1.1/24</code>.
            </small>
          </div>
          <QInput
            type="text"
            placeholder="IP address"
            onChange={e => setNewIp(e.target.value)}
            value={newIp}
            innerRef={ipInputRef}
            inputRef={ipInputRef}
          />
          <div className="flex justify-between mt-2">
            <QButton onClick={addIpToWhitelist} variant="filled">
              <span>
                <i className="far fa-plus" /> Add
              </span>
            </QButton>
          </div>
        </QCol>

        <QCol xs="6" flex="9">
          <QListGroup>
            {ips.map(ip => (
              <QListGroupItem
                key={ip}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '250px',
                }}
                divider
              >
                <QTypography>
                  <strong>IP:</strong> {ip}
                </QTypography>

                <QIconButton
                  className="btn-warning"
                  size="sm"
                  onClick={() => deleteIpFromWhitelist(ip)}
                >
                  <i className="far fa-trash pointer" />
                </QIconButton>
              </QListGroupItem>
            ))}
          </QListGroup>
        </QCol>
      </QRow>
    </>
  );
};

export const DefaultWhitelistIps = withLDConsumer()(LDDefaultWhitelistIps);
