import React from 'react';
import Grid from '@mui/material/Grid';

import StatCard from '../StatCard';
import i18n from '../../localization/i18n';

export default function AnalyticsStatCards({
  averageAnalysisTime,
  totalUniqueAppsAnalyzed,
  totalUniqueIosApps,
  totalUniqueAndroidApps,
  totalBinaryApps,
  totalStoreApps,
  medianQueueTime,
  totalUsagePercentage,
  isLoading,
}) {
  return (
    <Grid container item rowSpacing={2} columnSpacing={2}>
      <Grid item xs={3}>
        <StatCard
          isLoading={isLoading}
          icon="fas fa-th"
          leadText={totalUniqueAppsAnalyzed}
          subText={i18n.t('Unique Apps Analyzed')}
        />
      </Grid>
      <Grid item xs={3}>
        <StatCard
          isLoading={isLoading}
          icon="fab fa-android"
          leadText={totalUniqueAndroidApps}
          subText={i18n.t('Unique Android Apps')}
          bgClass="bg-android"
        />
      </Grid>
      <Grid item xs={3}>
        <StatCard
          isLoading={isLoading}
          icon="fab fa-apple"
          leadText={totalUniqueIosApps}
          subText={i18n.t('Unique iOS Apps')}
          bgClass="bg-ios"
          lowercase
        />
      </Grid>

      <Grid item xs={3}>
        <StatCard
          isLoading={isLoading}
          icon="far fa-store"
          leadText={totalStoreApps}
          subText="Total Store Apps"
        />
      </Grid>
      <Grid item xs={3}>
        <StatCard
          isLoading={isLoading}
          icon="far fa-box"
          leadText={totalBinaryApps}
          subText="Total Binary Apps"
        />
      </Grid>

      <Grid item xs={3}>
        <StatCard
          isLoading={isLoading}
          icon="far fa-clock"
          leadText={
            averageAnalysisTime
              ? `${averageAnalysisTime} ${i18n.t('minutes')}`
              : 'N/A'
          }
          subText={i18n.t('Average Analysis Time')}
        />
      </Grid>
      <Grid item xs={3}>
        <StatCard
          isLoading={isLoading}
          icon="far fa-pause"
          leadText={
            medianQueueTime
              ? `${medianQueueTime.toFixed(2)} ${i18n.t('minutes')}`
              : 'N/A'
          }
          subText={i18n.t('Median Queue Time')}
        />
      </Grid>
      <Grid item xs={3}>
        <StatCard
          isLoading={isLoading}
          icon="far fa-chart-pie-alt"
          leadText={
            totalUsagePercentage && totalUsagePercentage >= 0
              ? `${totalUsagePercentage}%`
              : 'N/A'
          }
          subText={i18n.t('Total Resource Usage')}
        />
      </Grid>
    </Grid>
  );
}
