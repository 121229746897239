import React, { useState, useRef } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../localization/i18n';
import { toastrHelper } from '../logic/toastrHelper';
import { QModal } from '../components/Q-Components/QModal';
import { QModalTitle } from '../components/Q-Components/QModalTitle';
import { QModalContent } from '../components/Q-Components/QModalContent';
import { QCard } from '../components/Q-Components/QCard';
import { QCardHeader } from '../components/Q-Components/QCardHeader';
import { QTypography } from '../components/Q-Components/QTypography';
import { QCardBody } from '../components/Q-Components/QCardBody';
import { QButton } from '../components/Q-Components/QButton';
import { QInputGroup } from '../components/Q-Components/QInputGroup';
import { QInputGroupAddon } from '../components/Q-Components/QInputGroupAddon';
import { QInput } from '../components/Q-Components/QInput';
import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';
import { QLabel } from '../components/Q-Components/QLabel';
import { QSubmitButton } from '../components/AppSubmit/QSubmitButton';
import { QLinearProgress } from '../components/Q-Components/QLinearProgress';

const SubmitFirmware = ({ userAccess, handleSubmissionFinished }) => {
  const [fileIsValid, setFileIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [firmwareName, setFirmwareName] = useState('');
  const [fileName, setFileName] = useState('');

  const { mastV2 } = useFlags();

  const fileRef = useRef();

  const clearFileInput = () => {
    fileRef.current.value = '';
  };

  const processFirmwareNameChange = async event => {
    await setFirmwareName(event.target.value);
  };

  /**
   * Process the event when the user selects a file to submit
   *
   * @param {any} event
   * @memberof BinarySubmit
   */
  const processFileChange = async event => {
    setFileIsValid(false);
    const file = event.target.files[0];

    // Verify that the app chosen has a valid file extension
    if (!file.name.endsWith('.zip') && !file.name.endsWith('.gz')) {
      toastrHelper.showErrorToast(
        i18n.t('Make sure to select a valid file.'),
        i18n.t('File type error'),
        mastV2,
      );
      clearFileInput();
      return;
    }

    // Verify that the user has access to the provided platform
    if (!userAccess.firmware_analysis) {
      toastrHelper.showErrorToast(
        i18n.t('You do not have access to perform firmware analysis.'),
        i18n.t('File type error'),
        mastV2,
      );
      clearFileInput();
      return;
    }

    setFileName(file.name);
    setFileIsValid(true);
  };

  const submitFirmware = async () => {
    setLoading(true);

    toastrHelper.showInfoToast(
      i18n.t('Submitting firmware...'),
      i18n.t('Info'),
      mastV2,
    );
    const firmwareFile = document.getElementById('firmware-file-input')
      .files[0];

    console.log('Firmware file:', firmwareFile);

    // Create the form data to be uploaded
    const formData = new FormData();
    formData.append('file', firmwareFile);
    formData.append('name', firmwareName);

    // Try to upload the firmware
    let response;
    try {
      response = await axios.post(
        `${process.env.REACT_APP_FIRMWARE_SERVICE_API}/scans/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total,
            );
            setProgress(percentCompleted);
          },
        },
      );

      if (response.status === 200) {
        // We successfully submitted the firmware
        toastrHelper.showSuccessToast(
          i18n.t('Firmware upload complete'),
          i18n.t('Success'),
          mastV2,
        );
        handleSubmissionFinished();
      } else if (response.status === 400) {
        toastrHelper.showErrorToast(
          `${i18n.t('Error submitting firmware image')}:<br/>${
            response.data.msg
          }`,
          i18n.t('Error'),
          mastV2,
        );
      } else {
        toastrHelper.showErrorToast(
          i18n.t('Error submitting firmware'),
          i18n.t('Error'),
          mastV2,
        );
      }
    } catch (err) {
      console.error(err);
      toastrHelper.showErrorToast(
        i18n.t('Error uploading file'),
        i18n.t('Error'),
        mastV2,
      );
    }

    fileRef.current.value = '';
    setFileIsValid(false);
    setFirmwareName('');
    setLoading(false);
  };

  return (
    <div className="animated fadeIn">
      <QModal
        isOpen={loading}
        open={loading}
        title={
          <QModalTitle>
            <QTypography variant="h3Regular">
              {i18n.t('Uploading Firmware')}
            </QTypography>
          </QModalTitle>
        }
        content={
          <QModalContent>
            <i className="fas fa-spin fa-spinner fa-2x mt-3 mb-2" />
            <QLinearProgress
              bar
              animated
              value={progress}
              variant="determinate"
            />
            {progress} %
          </QModalContent>
        }
      >
        <QModalContent className="text-center">
          <h3>{i18n.t('Uploading Firmware')}</h3>
          <i className="fas fa-spin fa-spinner fa-2x mt-3 mb-2" />
          <QLinearProgress bar animated value={progress}>
            {progress} %
          </QLinearProgress>
        </QModalContent>
      </QModal>
      <QRow>
        <QCol xs="4">
          <QCard className="card-accent-purple">
            <QCardHeader
              title={
                <QTypography variant="h4Medium" color="#000000">
                  <strong>{i18n.t('Firmware Upload')}</strong>
                </QTypography>
              }
            >
              <strong>{i18n.t('Firmware Upload')}</strong>
            </QCardHeader>
            <QCardBody>
              <QLabel for="firmware-name-input">
                <strong>{i18n.t('Firmware Name')}</strong>
              </QLabel>
              <QInputGroup className="mb-3">
                <QInput
                  type="text"
                  value={firmwareName}
                  onChange={e => processFirmwareNameChange(e)}
                  id="firmware-name-input"
                />
              </QInputGroup>
              {mastV2 ? (
                <QRow columnGap="8px" sx={{ mt: 3, width: 732 }}>
                  <QCol>
                    <QButton
                      variant="filled"
                      onClick={() => {
                        document.getElementById('firmware-file-input').click();
                      }}
                      style={{
                        color: '#000000',
                        backgroundColor: '#E0E0E0',
                        fontSize: '18px',
                        height: '100%',
                      }}
                    >
                      {i18n.t('Choose File')}
                    </QButton>
                  </QCol>
                  <QCol flex="4">
                    <QInput
                      inputProps={{
                        type: 'file',
                        accept: '.zip,.gz',
                        name: 'app',
                        'data-testid': 'binary-input',
                      }}
                      onChange={e => processFileChange(e)}
                      inputRef={fileRef}
                      id="firmware-file-input"
                      style={{ display: 'none' }}
                    />
                    <QInput
                      placeholder="No file chosen"
                      value={fileName}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </QCol>
                  <QCol>
                    <QSubmitButton
                      variant="filled"
                      type="button"
                      className="btn-purple btn-submit-app"
                      disabled={loading || !fileIsValid || !firmwareName}
                      startIcon={
                        <i
                          className={`far ${
                            loading ? 'fa-spin fa-spinner' : 'fa-upload'
                          }`}
                        />
                      }
                      content={i18n.t('Submit Image')}
                      onClick={submitFirmware}
                      sx={{ height: '100%' }}
                    />
                  </QCol>
                </QRow>
              ) : (
                <QInputGroup className="mb-3">
                  <QInput
                    type="file"
                    data-testid="firmware-input"
                    className="form-control"
                    id="firmware-file-input"
                    accept=".zip,.gz"
                    name="firmware-image"
                    onChange={e => processFileChange(e)}
                    innerRef={fileRef}
                  />
                  <QInputGroupAddon addonType="append">
                    <QSubmitButton
                      variant="filled"
                      type="button"
                      className="btn-purple btn-submit-app"
                      disabled={loading || !fileIsValid || !firmwareName}
                      startIcon={
                        <i
                          className={`far ${
                            loading ? 'fa-spin fa-spinner' : 'fa-upload'
                          }`}
                        />
                      }
                      content={i18n.t('Submit Image')}
                      onClick={submitFirmware}
                      sx={{ height: '100%' }}
                    />
                  </QInputGroupAddon>
                </QInputGroup>
              )}
            </QCardBody>
          </QCard>
        </QCol>
      </QRow>
    </div>
  );
};

const mapStateToProps = state => ({
  userAccess: state.emmApp.userAccess,
});

export default connect(mapStateToProps)(SubmitFirmware);
