import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import AnalyzedApp from '../components/AnalyzedApps/AnalyzedApp';
import DataTable from '../components/DataTable/DataTable';
import { QCard } from '../components/Q-Components/QCard';
import { QCardHeader } from '../components/Q-Components/QCardHeader';
import { QCardBody } from '../components/Q-Components/QCardBody';
import { QTable } from '../components/Q-Components/QTable';
import { QTableBody } from '../components/Q-Components/QTableBody';
import { QTableHead } from '../components/Q-Components/QTableHead';
import { QTableRow } from '../components/Q-Components/QTableRow';
import { QTableCell } from '../components/Q-Components/QTableCell';
import { QTypography } from '../components/Q-Components/QTypography';
import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';

const SideLoadedApps = ({ apps }) => {
  const sideloadedApps = apps.filter(app => !app.analyzed_app);
  return (
    <>
      <QRow>
        <h6>
          <QTypography variant="h6">
            Non-market and Side-loaded Applications
          </QTypography>
        </h6>
      </QRow>

      {sideloadedApps.length === 0 ? (
        <QRow>
          <QTypography>None found</QTypography>
        </QRow>
      ) : (
        <QRow>
          <QTable>
            <QTableHead>
              <QTableRow>
                <QTableCell headerCell>App</QTableCell>
                <QTableCell headerCell>Package</QTableCell>
                <QTableCell headerCell>Version</QTableCell>
              </QTableRow>
            </QTableHead>
            <QTableBody>
              {sideloadedApps.map((app, index) => (
                <QTableRow key={index}>
                  <QTableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <i
                        className={`${
                          app.platform === 'android'
                            ? 'fab fa-android fa-2x'
                            : 'fab fa-apple fa-2x'
                        } mr-2`}
                      />
                      <QTypography>
                        <strong>{app.name}</strong>
                      </QTypography>
                    </div>
                  </QTableCell>
                  <QTableCell>
                    <QTypography>{app.package}</QTypography>
                  </QTableCell>
                  <QTableCell>
                    <QTypography>{app.version}</QTypography>
                  </QTableCell>
                </QTableRow>
              ))}
            </QTableBody>
          </QTable>
        </QRow>
      )}
    </>
  );
};

class MdmDeviceDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apps: [],
    };
  }

  async componentWillMount() {
    const { deviceUuid } = this.props.match.params;
    try {
      const response = await axios.get(`mdm-integration/devices/${deviceUuid}`);
      if (!response.status === 200) {
        throw new Error(`Bad status code:${response.status}`);
      }
      if (!response.data.apps) {
        throw new Error('Apps were not in response');
      }

      this.setState({ apps: response.data.apps });
    } catch (err) {
      console.error('Error getting apps for device:', err);
    }
  }

  render() {
    const styles = {
      title: {
        marginBottom: '0.2rem',
        fontStyle: 'italic',
      },
      value: {
        fontWeight: 'bold',
      },
    };

    const { mdmDeviceDetails } = this.props;
    const {
      deviceId,
      compliant,
      deviceName,
      lastSeen,
      enrollmentDate,
      userName,
    } = mdmDeviceDetails;

    const { apps } = this.state;

    return (
      <>
        <QCard className="card-accent-purple">
          <QCardHeader
            title={<QTypography variant="h4Medium">Device Details</QTypography>}
          >
            <strong>Device Details</strong>
          </QCardHeader>
          <QCardBody sx={{ padding: '1% 3% 1% 3%' }}>
            <QRow gap="3%">
              <QCol xs="1" width="5%">
                <i className="fas fa-mobile-android-alt fa-10x" />
              </QCol>
              <QCol xs="5" width="50%">
                <QTable>
                  <QTableBody>
                    <QTableRow>
                      <QTableCell>
                        <QRow style={styles.title}>
                          <QTypography>Device ID</QTypography>
                        </QRow>
                        <QRow style={styles.value}>
                          <QTypography variant="bold">
                            {deviceId ?? 'N/A'}
                          </QTypography>
                        </QRow>
                      </QTableCell>
                      <QTableCell>
                        <QRow style={styles.title}>
                          <QTypography>User</QTypography>
                        </QRow>
                        <QRow style={styles.value}>
                          <QTypography variant="bold">
                            {userName ?? 'N/A'}
                          </QTypography>
                        </QRow>
                      </QTableCell>
                    </QTableRow>
                    {/* Device name and compliance status */}
                    <QTableRow>
                      <QTableCell>
                        <QRow style={styles.title}>
                          <QTypography>Device Name</QTypography>
                        </QRow>
                        <QRow style={styles.value}>
                          <QTypography variant="bold">
                            {deviceName ?? 'N/A'}
                          </QTypography>
                        </QRow>
                      </QTableCell>
                      <QTableCell>
                        <QRow style={styles.title}>
                          <QTypography>Compliant</QTypography>
                        </QRow>
                        <QRow style={styles.value}>
                          <QTypography variant="bold">
                            {compliant ? (
                              <i className="fas fa-check-circle text-success" />
                            ) : (
                              <i className="fas fa-times-circle text-danger" />
                            )}
                          </QTypography>
                        </QRow>
                      </QTableCell>
                    </QTableRow>
                    {/* Last seen and enrollment date */}
                    <QTableRow>
                      <QTableCell>
                        <QRow style={styles.title}>
                          <QTypography>Last Seen</QTypography>
                        </QRow>
                        <QRow style={styles.value}>
                          <QTypography variant="bold">
                            {moment(lastSeen).format('L - LTS')}
                          </QTypography>
                        </QRow>
                      </QTableCell>
                      <QTableCell>
                        <QRow style={styles.title}>
                          <QTypography>Enrollment Date</QTypography>
                        </QRow>
                        <QRow style={styles.value}>
                          <QTypography variant="bold">
                            {moment(enrollmentDate).format('L - LTS')}
                          </QTypography>
                        </QRow>
                      </QTableCell>
                    </QTableRow>
                  </QTableBody>
                </QTable>
              </QCol>
              <QCol>
                <SideLoadedApps apps={apps} />
              </QCol>
            </QRow>
          </QCardBody>
        </QCard>

        {/* Apps installed on device */}
        <QCard className="card-accent-success" sx={{ marginTop: 5 }}>
          <QCardHeader
            title={
              <QTypography variant="h4Medium">
                Apps installed on device
              </QTypography>
            }
          >
            <strong>Apps installed on device</strong>
          </QCardHeader>
          <QCardBody sx={{ padding: '1% 3% 1% 3%' }}>
            <DataTable
              data={apps
                .map(app => {
                  if (app.analyzed_app) {
                    return {
                      ...app.analyzed_app,
                      developer: app.developer,
                      storePublished: app.store_published,
                    };
                  }
                  return null;
                })
                .filter(app => app)}
              rowComponent={({ row: app }) => (
                <AnalyzedApp isMdmApp app={app} />
              )}
              columns={[
                {
                  label: 'App',
                  keyName: 'name',
                  filterable: true,
                  ...(!this.props.flags.mastV2 && { className: 'text-center' }),
                  filterFunc: (filterStr, item) => {
                    if (!filterStr) {
                      return true;
                    }

                    filterStr = filterStr.toLowerCase();
                    const pckg = item.farm_app.package.toLowerCase();
                    const name = item.farm_app.name.toLowerCase();
                    const developer = item.developer
                      ? item.developer.toLowerCase()
                      : '';
                    if (
                      pckg.includes(filterStr) ||
                      name.includes(filterStr) ||
                      developer.includes(filterStr)
                    ) {
                      return true;
                    }
                    return false;
                  },
                },
                {
                  label: 'Devices Installed On',
                  ...(!this.props.flags.mastV2 && { className: 'text-center' }),
                  keyName: 'devices_installed_on',
                  sortable: true,
                },
                {
                  label: 'Date Submitted',
                  ...(!this.props.flags.mastV2 && { className: 'text-center' }),
                  sortFunc: (a, b) => {
                    const result = moment(a.submitted_at).diff(
                      moment(b.submitted_at),
                    );
                    if (result < 0) return -1;
                    if (result > 0) return 1;
                    return 0;
                  },
                  keyName: 'date_submitted',
                  sortable: false,
                },
                {
                  label: 'Threat Score',
                  ...(!this.props.flags.mastV2 && { className: 'text-center' }),
                  keyName: 'threat_score',
                  sortable: true,
                },
                {
                  label: 'Summary Results',
                  ...(!this.props.flags.mastV2 && { className: 'text-center' }),
                  keyName: 'summary',
                },
                {
                  label: 'Reports',
                  ...(!this.props.flags.mastV2 && { className: 'text-center' }),
                  keyName: 'reports',
                },
              ]}
            />
          </QCardBody>
        </QCard>
      </>
    );
  }
}

const mapStateToProps = state => ({
  mdmDeviceDetails: state.emmApp.mdmDeviceDetails,
});

export default connect(mapStateToProps)(withLDConsumer()(MdmDeviceDetails));
