import React from 'react';
import { ListGroup } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QProfileMenu as MuiProfileMenu } from '@kw/quokka-ui';

const LDProfileMenu = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiProfileMenu {...props}>{children}</MuiProfileMenu>
  ) : (
    <ListGroup {...props}>{children}</ListGroup>
  );
};
export const QProfileMenu = withLDConsumer()(LDProfileMenu);
