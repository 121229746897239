import React, { Component } from 'react';
import { connect } from 'react-redux';

import DocumentationCard from '../components/DocumentationCard';
import i18n from '../localization/i18n';
import util from '../logic/util';
import { QCol } from '../components/Q-Components/QCol';
import { QRow } from '../components/Q-Components/QRow';
import { setPageTitle } from '../store/slices/emmAppSlice';

class DocumentationContainer extends Component {
  async componentWillMount() {
    this.props.setPageTitle('Documentation');
  }

  render() {
    return (
      <div className="animated fadeIn">
        <QRow columnGap="24px">
          <QCol xs="2">
            <DocumentationCard
              bg="bg-success"
              icon="fal fa-tachometer-alt"
              title="Portal"
              subtext={i18n.t(
                `Documentation for the ${
                  util.brands[process.env.REACT_APP_APPLIANCE]
                } analysis portal as well as the application analysis`,
              )}
              docType="portal"
            />
          </QCol>
          {process.env.REACT_APP_APPLIANCE === 'default' && (
            <QCol xs="2">
              <DocumentationCard
                bg="bg-warning-2"
                icon="fal fa-cogs"
                title="REST API"
                subtext={i18n.t('Documentation for the Quokka REST API')}
                docType="api"
              />
            </QCol>
          )}
        </QRow>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.emmApp.portalConfig.lang,
});

const mapDispatchToProps = {
  setPageTitle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocumentationContainer);
