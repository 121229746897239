import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../localization/i18n';
import StoreUrlSubmit from '../components/AppSubmit/StoreUrlSubmit';
import CustomCredentials from '../components/AppSubmit/CustomCredentials';
import StoreSearch from '../components/AppSubmit/StoreSearch';
import BulkSubmit from '../components/AppSubmit/BulkSubmit';
import BinarySubmit from '../components/AppSubmit/BinarySubmit';
import { QCard } from '../components/Q-Components/QCard';
import { QCardHeader } from '../components/Q-Components/QCardHeader';
import { QCardBody } from '../components/Q-Components/QCardBody';
import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';
import { QTabContext } from '../components/Q-Components/QTabContext';
import { QTabList } from '../components/Q-Components/QTabList';
import { QTab } from '../components/Q-Components/QTab';
import { QTabLink } from '../components/Q-Components/QTabLink';
import { QLabel } from '../components/Q-Components/QLabel';
import { QTabContent } from '../components/Q-Components/QTabContent';
import { QTabPanel } from '../components/Q-Components/QTabPanel';
import { setPageTitle } from '../store/slices/emmAppSlice';

function SubmitApp() {
  const [activeSubmitTab, setActiveSubmitTab] = useState('1');
  const { mastV2 } = useFlags();
  const userAccess = useSelector(state => state.emmApp.userAccess);
  const isTrialUser = useSelector(state => state.emmApp.isTrialUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle('Submit App'));
  }, []);

  const handleChange = (event, newTab) => {
    setActiveSubmitTab(newTab);
  };

  const SubmitAppCard = ({ children, ...props }) =>
    mastV2 ? children : <QCard {...props}>{children}</QCard>;
  const SubmitAppCardHeader = ({ children, ...props }) =>
    mastV2 ? children : <QCardHeader {...props}>{children}</QCardHeader>;
  const SubmitAppCardBody = ({ children, ...props }) =>
    mastV2 ? children : <QCardBody {...props}>{children}</QCardBody>;

  return (
    <div className="animated fadeIn app-submit">
      <br />
      <SubmitAppCard className="card-accent-info">
        <SubmitAppCardHeader>
          {mastV2 ? null : <strong>{i18n.t('AnalysisMenu')}</strong>}
        </SubmitAppCardHeader>

        <SubmitAppCardBody>
          <QRow>
            <QCol
              flex="1"
              xs="6"
              style={
                mastV2
                  ? {
                      backgroundColor: '#FAFAFA',
                      padding: '24px',
                    }
                  : {}
              }
            >
              <QTabContext value={activeSubmitTab}>
                <QTabList tabs onChange={handleChange}>
                  <QTab
                    cid="binary-submit-link"
                    className="binary-submit-label"
                    label={i18n.t('Binary Upload')}
                    value="1"
                  >
                    <QTabLink
                      id="binary-submit-link"
                      className={classnames({
                        active: activeSubmitTab === '1',
                      })}
                      onClick={() => setActiveSubmitTab('1')}
                    >
                      <QLabel>
                        <i className="fas fa-upload" />{' '}
                        {i18n.t('Binary Upload')}
                      </QLabel>
                    </QTabLink>
                  </QTab>
                  <QTab
                    cid="store-link-submit-link"
                    className="store-link-submit-label"
                    label={i18n.t('Store Link')}
                    value="2"
                  >
                    <QTabLink
                      id="store-link-submit-link"
                      className={classnames({
                        active: activeSubmitTab === '2',
                      })}
                      onClick={() => setActiveSubmitTab('2')}
                    >
                      <QLabel>
                        <i className="fal fa-store" /> {i18n.t('Store Link')}
                      </QLabel>
                    </QTabLink>
                  </QTab>
                  <QTab
                    cid="bulk-apps-submit-link"
                    className="bulk-apps-submit-label"
                    label={i18n.t('Bulk Submit')}
                    value="3"
                  >
                    <QTabLink
                      id="bulk-apps-submit-link"
                      disabled={isTrialUser}
                      className={classnames({
                        active: activeSubmitTab === '3',
                      })}
                      onClick={() => setActiveSubmitTab('3')}
                    >
                      <QLabel>
                        <i className="fal fa-list-ul" /> {i18n.t('Bulk Submit')}
                      </QLabel>
                    </QTabLink>
                  </QTab>
                </QTabList>

                <QTabContent activeTab={activeSubmitTab}>
                  <QTabPanel
                    tabId="1"
                    value="1"
                    className="animated fadeIn"
                    id="binary-submit-tab"
                  >
                    <QRow columnGap="36px">
                      <QCol flex="8 8 732px">
                        <BinarySubmit />
                      </QCol>
                      {mastV2 && userAccess.custom_credentials && (
                        <QCol flexGrow="4" flexShrink="4">
                          <CustomCredentials />
                        </QCol>
                      )}
                    </QRow>
                  </QTabPanel>

                  <QTabPanel
                    tabId="2"
                    value="2"
                    className="animated fadeIn"
                    id="store-link-submit-tab"
                  >
                    <QRow columnGap="36px">
                      <QCol flex="8 8 732px">
                        <StoreUrlSubmit />
                      </QCol>
                      {mastV2 && userAccess.custom_credentials && (
                        <QCol flexGrow="4" flexShrink="4">
                          <CustomCredentials />
                        </QCol>
                      )}
                    </QRow>
                  </QTabPanel>

                  <QTabPanel
                    tabId="3"
                    value="3"
                    className="animated fadeIn"
                    id="bulk-apps-submit-tab"
                  >
                    <QRow columnGap="36px">
                      <QCol flex="8 8 732px">
                        <BulkSubmit />
                      </QCol>
                      {mastV2 && userAccess.custom_credentials && (
                        <QCol flexGrow="4" flexShrink="4">
                          <CustomCredentials />
                        </QCol>
                      )}
                    </QRow>
                  </QTabPanel>
                </QTabContent>
              </QTabContext>
            </QCol>

            {!mastV2 && userAccess.custom_credentials && (
              <QCol xs="2">
                <CustomCredentials />
              </QCol>
            )}
          </QRow>

          <QRow>
            <QCol flex="1">
              {mastV2 ? <div style={{ height: '60px' }} /> : null}
              <StoreSearch />
            </QCol>
          </QRow>
        </SubmitAppCardBody>
      </SubmitAppCard>
    </div>
  );
}

export default SubmitApp;
