import React from 'react';
import { ListGroupItem } from 'reactstrap';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QProfileItem as MuiProfileItem } from '@kw/quokka-ui';

const LDProfileItem = ({ children, ...props }) => {
  const { mastV2 } = useFlags();
  return mastV2 ? (
    <MuiProfileItem {...props}>{children}</MuiProfileItem>
  ) : (
    <ListGroupItem {...props}>{children}</ListGroupItem>
  );
};
export const QProfileItem = withLDConsumer()(LDProfileItem);
