import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useLatest } from 'react-use';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { toastrHelper } from '../../../logic/toastrHelper';
import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';
import { QListGroup } from '../../Q-Components/QListGroup';
import { QListGroupItem } from '../../Q-Components/QListGroupItem';
import { QDropdownToggle } from '../../Q-Components/QDropdownToggle';
import { QDropdownMenu } from '../../Q-Components/QDropdownMenu';
import { QDropdownSelectItem } from '../../Q-Components/QDropdownSelectItem';
import { QUncontrolledDropdown } from '../../Q-Components/QUncontrolledDropdown';
import { QActionSelect } from '../../Q-Components/QActionSelect';

export const saveAssignSubGroups = async (
  users,
  assignedSubGroups,
  mastV2 = false,
) => {
  const getAssignedSubgroupIdsOfType = action =>
    assignedSubGroups
      .filter(subGroup => subGroup.action === action)
      .flatMap(subGroup => [subGroup.id, ','])
      .join('');

  try {
    await axios.put(`group-admin/bulk-edit-sub-group-assignments`, {
      users: users.map(user => ({ id: user.value })),
      assignedSubGroups: getAssignedSubgroupIdsOfType('assign'),
      unassignedSubGroups: getAssignedSubgroupIdsOfType('unassign'),
    });

    toastrHelper.showSuccessToast(
      `Successfully updated users' sub group assignments`,
      'Success',
      mastV2,
    );
  } catch (err) {
    toastrHelper.showErrorToast(
      `Error updating users: ${err.response.data.message}`,
      'Error',
      mastV2,
    );
  }
};
export const useAssignSubGroups = () => {
  const [selectedAssignedSubGroups, setSelectedSubGroups] = useState([]);
  const latestAssignedSubGroups = useLatest(selectedAssignedSubGroups);
  const notSame = id => group => group.id !== id;
  const addAssignment = (id, name, action) => {
    setSelectedSubGroups([
      ...selectedAssignedSubGroups.filter(notSame(id)),
      { id, name, action },
    ]);
  };
  const removeAssignment = id => {
    setSelectedSubGroups(selectedAssignedSubGroups.filter(notSame(id)));
  };
  const resetSubGroups = () => setSelectedSubGroups([]);
  // retrieves latest value, for when we need to use value in callback
  const get = () => latestAssignedSubGroups.current;

  return {
    selectedAssignedSubGroups,
    addAssignment,
    removeAssignment,
    get,
    resetSubGroups,
  };
};

export const AssignSubGroups = props => {
  const {
    subGroups,
    selectedAssignedSubGroups,
    setConfirmationModal,
    removeAssignment,
    addAssignment,
  } = props;

  const listHeader = {
    assign: 'Assign',
    unassign: 'Unassign',
  };

  const { mastV2 } = useFlags();

  const hasAction = actionType =>
    selectedAssignedSubGroups.find(
      subGroups => subGroups.action === actionType,
    );

  const assignMappedSubGroups = subGroups.map(({ id, name }) => ({
    value: name,
    label: name,
    onClick: () => addAssignment(id, name, 'assign'),
  }));

  const unassignMappedSubGroups = subGroups.map(({ id, name }) => ({
    value: name,
    label: name,
    onClick: () => addAssignment(id, name, 'unassign'),
  }));

  useEffect(() => {
    setConfirmationModal();
  }, []);

  return (
    <>
      <QRow
        className="justify-content-center"
        justifyContent="center"
        alignItems="center"
      >
        <h5>Assign Sub Groups</h5>
      </QRow>
      <hr />
      <QCol style={{ width: '90%' }} className="ml-auto mr-auto">
        <br />
        {selectedAssignedSubGroups.length > 0 ? (
          ['assign', 'unassign'].filter(hasAction).map(actionType => {
            const subGroupsOfActionType = selectedAssignedSubGroups.filter(
              subGroup => subGroup.action === actionType,
            );

            return (
              <>
                <small className="text-muted text-uppercase font-weight-bold">
                  {listHeader[actionType]}
                  <br />
                </small>

                <QListGroup>
                  {subGroupsOfActionType.map(({ name, id }) => (
                    <QListGroupItem
                      key={id}
                      divider
                      className="flex justify-content-between align-items-center"
                    >
                      {name}
                      <i
                        onClick={() => removeAssignment(id)}
                        className="fal fa-minus-circle pointer"
                        style={{ color: 'red' }}
                      />
                    </QListGroupItem>
                  ))}
                </QListGroup>
              </>
            );
          })
        ) : (
          <>
            <i> No sub groups selected</i>
            <br />
          </>
        )}
        {mastV2 ? (
          <>
            <QActionSelect
              actionOptions={assignMappedSubGroups}
              label="Assign"
              width="140px"
            />
            <QActionSelect
              actionOptions={unassignMappedSubGroups}
              label="Unassign"
              width="140px"
            />
          </>
        ) : (
          <QRow className="mt-3">
            <QUncontrolledDropdown>
              <QDropdownToggle caret>Assign</QDropdownToggle>
              <QDropdownMenu>
                {subGroups.map(({ id, name }) => (
                  <QDropdownSelectItem
                    onClick={() => addAssignment(id, name, 'assign')}
                  >
                    {name}
                  </QDropdownSelectItem>
                ))}
              </QDropdownMenu>
            </QUncontrolledDropdown>
            &nbsp;&nbsp;&nbsp;
            <QUncontrolledDropdown>
              <QDropdownToggle caret>Unassign</QDropdownToggle>
              <QDropdownMenu>
                {subGroups.map(({ id, name }) => (
                  <QDropdownSelectItem
                    onClick={() => addAssignment(id, name, 'unassign')}
                  >
                    {name}
                  </QDropdownSelectItem>
                ))}
              </QDropdownMenu>
            </QUncontrolledDropdown>
          </QRow>
        )}
      </QCol>
    </>
  );
};
