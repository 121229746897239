import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Collapse } from 'reactstrap';

export default class M6ios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      collapseButton: 'up',
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    this.setState({
      isOpen: !this.state.isOpen,
      collapseButton: this.state.collapseButton === 'up' ? 'down' : 'up',
    });
  }

  render() {
    const { owasp, owaspIssues } = this.props;
    const { isOpen, collapseButton } = this.state;
    return (
      <div>
        <Card
          className={
            owaspIssues.length === 0
              ? 'card-accent-success'
              : 'card-accent-danger'
          }
        >
          <CardHeader onClick={this.toggleCollapse} className="pointer">
            <Row>
              <Col className="text-left">{owasp}</Col>
              <Col className="text-right">
                <i className={`fas fa-lg fa-angle-${collapseButton}`} />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody>
              <Row>
                <Col xs="6">
                  <div>
                    This category covers misuse of a platform feature or failure
                    to use platform security controls. It might include Android
                    intents, platform permissions, misuse of TouchID, the
                    Keychain, or some other security control that is part of the
                    mobile operating system.
                  </div>
                  <div>
                    <a
                      href="https://owasp.org/www-project-mobile-top-10/2016-risks/m6-insecure-authorization"
                      style={{ display: 'table-cell' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      <em>More Info</em>{' '}
                    </a>
                  </div>
                </Col>
                {owaspIssues.length !== 0 ? (
                  <Col>
                    <strong>High level issues found: </strong>
                    <div>
                      {owaspIssues.map(issue => (
                        <div>
                          <i className="fas fa-exclamation-triangle warning-icon" />{' '}
                          {issue}
                        </div>
                      ))}
                    </div>
                  </Col>
                ) : null}
              </Row>
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}
