import React from 'react';
import { Label } from 'reactstrap';

import i18n from '../localization/i18n';

import { QDropdownWrapper } from './Q-Components/QDropdown';
import { QSelect } from './Q-Components/QSelect';
import HelpButton from './HelpButton';
import { platformOptions } from './AnalyzedApps/AnalyzedAppsFilterOptions';

const PlatformSelect = ({
  platformFilter,
  handlePlatformChange,
  optionFilter = null,
  hideLabel = false,
  mastV2 = false,
  withIconV2 = false,
}) => {
  const formatOptionLabel = ({ value, label }) => (
    <div
      style={
        mastV2 && withIconV2
          ? { display: 'flex', columnGap: '8px', alignItems: 'center' }
          : {}
      }
    >
      {value === 'both' && (
        <>
          <i className="fab fa-apple" />/<i className="fab fa-android" />{' '}
          <span>{label}</span>
        </>
      )}
      {value === 'ios' && (
        <>
          <i className="fab fa-apple" /> <span>{label}</span>
        </>
      )}
      {value === 'android' && (
        <>
          <i className="fab fa-android" /> <span>{label}</span>
        </>
      )}
    </div>
  );

  const filteredPlatformOptions = !optionFilter
    ? platformOptions
    : platformOptions.filter(optionFilter);

  return (
    <div>
      {!hideLabel && !mastV2 && (
        <Label for="platform-select" style={{ paddingTop: '5px' }}>
          <strong>{i18n.t('Platform(s)')}: </strong>
          <HelpButton helpItem="appPlatforms" />
        </Label>
      )}
      {/* Platform Select without icon i.e. on Analyzed Apps page */}
      {!withIconV2 && (
        <QSelect
          size="small"
          options={filteredPlatformOptions}
          value={platformFilter}
          onChange={handlePlatformChange}
          label="Platform(s)"
          selectIconProps={{ fontSize: 'medium' }}
          formControlProps={{ sx: { width: '100%' } }}
        />
      )}
      {/* Platform Select with icon i.e. on Submit App page */}
      {mastV2 && withIconV2 && (
        <QDropdownWrapper
          options={filteredPlatformOptions}
          selectedOption={platformFilter}
          onChange={handlePlatformChange}
          closedLabel={formatOptionLabel(platformFilter)}
          variant="outlined"
          formControlProps={{
            style: {
              width: '100%',
              paddingLeft: '0px',
              paddingRight: '0px',
              marginBottom: '8px',
            },
          }}
          labelProps={{
            disableAnimation: true,
            shrink: false,
            columnGap: '10px',
          }}
          selectProps={{
            style: { paddingTop: '0px', paddingBottom: '4.5px' },
          }}
          id="platform-select"
        />
      )}
    </div>
  );
};

export default PlatformSelect;
