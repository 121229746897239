import React from 'react';
import { Pie } from 'react-chartjs-2';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QCard } from '../Q-Components/QCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QDoughnut } from '../Q-Components/QDoughnut';

const LDAnalysisSuccessRate = ({ analysisSuccessRate, isLoading }) => {
  const { mastV2 } = useFlags();

  const successColor = mastV2 ? '#008675' : '#28a745';
  const failureColor = mastV2 ? '#F44336' : '#dc3545';

  const chartData = {
    labels: ['Success', 'Failure'],
    datasets: [
      {
        data: [analysisSuccessRate.successes, analysisSuccessRate.fails],
        backgroundColor: [successColor, failureColor],
      },
    ],
  };

  return (
    <QCard className="card-accent-secondary">
      <QCardHeader
        title="Analysis Success Rate"
        action={
          <span>{isLoading && <i className="fas fa-spinner fa-spin" />}</span>
        }
      >
        <div className="d-flex justify-between">
          <strong>Analysis Success Rate</strong>

          <span>{isLoading && <i className="fas fa-spinner fa-spin" />}</span>
        </div>
      </QCardHeader>
      <QCardBody>
        {mastV2 ? (
          <QDoughnut
            data={chartData}
            height={300}
            options={{
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  display: context =>
                    context.dataset.data[context.dataIndex] >= 1,
                  color: 'white',
                },
                title: {
                  display: true,
                  text: 'Number of analysis successes compared to failures',
                  position: 'bottom',
                  font: {
                    weight: 'normal',
                    style: 'italic',
                  },
                },
              },
            }}
          />
        ) : (
          <Pie
            data={chartData}
            height={300}
            options={{
              maintainAspectRatio: false,
              plugins: {
                datalabels: {
                  display: context =>
                    context.dataset.data[context.dataIndex] >= 1,
                  color: 'white',
                },
                title: {
                  display: true,
                  text: 'Number of analysis successes compared to failures',
                  position: 'bottom',
                  font: {
                    weight: 'normal',
                    style: 'italic',
                  },
                },
              },
            }}
          />
        )}
      </QCardBody>
    </QCard>
  );
};

export const AnalysisSuccessRate = withLDConsumer()(LDAnalysisSuccessRate);
