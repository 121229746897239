import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import i18n from '../../localization/i18n';
import { QToggleButtonGroup } from '../Q-Components/QButtonGroup';
import { QToggleButton } from '../Q-Components/QToggleButton';
import { QBadge } from '../Q-Components/QBadge';
import { QCard } from '../Q-Components/QCard';
import { QCardBody } from '../Q-Components/QCardBody';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QTypography } from '../Q-Components/QTypography';
import { QButton } from '../Q-Components/QButton';
import { QSwitch } from '../Q-Components/QSwitch';
import { QContainer } from '../Q-Components/QContainer';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QTable } from '../Q-Components/QTable';
import { QTableRow } from '../Q-Components/QTableRow';
import { QTableBody } from '../Q-Components/QTableBody';
import { QTableCell } from '../Q-Components/QTableCell';
import { QCollapse } from '../Q-Components/QCollapse';

const riskItemList = {
  low: {
    label: 'Low',
    color: 'success',
    variant: 'blue',
    muiColor: 'rgb(33, 150, 243)',
  },
  medium: {
    label: 'Medium',
    color: 'warning',
    variant: 'orange',
    muiColor: 'rgb(255, 152, 0)',
  },
  high: {
    label: 'High',
    color: 'danger',
    variant: 'red',
    muiColor: 'rgb(244, 67, 54)',
  },
  critical: {
    label: 'Critical',
    color: 'critical',
    variant: 'maroon',
    muiColor: 'rgb(90, -0, 0)',
  },
};

const ThreatScoreSection = ({
  toggleThreatItemEnabled,
  cardColor,
  headerText,
  toggle,
  collapseButtonClass,
  blockOpen,
  threatItems,
  changeThreatItemWeight,
  canCustomizeThreatScore,
}) => {
  const { mastV2 } = useFlags();
  return (
    <div>
      <QCard
        className={`card-accent-${cardColor}`}
        style={mastV2 ? { padding: '0px' } : {}}
      >
        <QCardHeader
          onClick={toggle}
          className="pointer"
          style={
            mastV2
              ? {
                  padding: '6px',
                  background: 'none',
                  backgroundColor: '#EEEEEE',
                }
              : {}
          }
          title={
            <QContainer
              fluid
              style={
                mastV2
                  ? { padding: '0px', background: 'none', minWidth: '100%' }
                  : {}
              }
            >
              <QRow className="w-100">
                <QCol className="text-left" flex="1" xs="2">
                  <QTypography
                    variant="bodyBold"
                    style={{ paddingLeft: '24px' }}
                  >
                    {i18n.t(`${headerText}`)}
                  </QTypography>
                </QCol>
                <QCol
                  className="text-right"
                  xs="2"
                  style={{ paddingRight: '24px' }}
                >
                  <QTypography variant="bodyRegular">
                    <i className={`fas ${collapseButtonClass}`} />
                  </QTypography>
                </QCol>
              </QRow>
            </QContainer>
          }
        >
          <QRow className="w-100">
            <QCol className="text-left">
              <strong>{i18n.t(`${headerText}`)}</strong>
            </QCol>
            <QCol className="text-right">
              <i className={`fas fa-lg ${collapseButtonClass}`} />
            </QCol>
          </QRow>
        </QCardHeader>
        <QCollapse isOpen={blockOpen} in={blockOpen}>
          <QCardBody>
            <QTable size="sm">
              <QTableBody>
                {threatItems.map((item, index) =>
                  canCustomizeThreatScore ? (
                    <QTableRow key={index}>
                      {/* Item name */}
                      <QTableCell
                        sx={{ padding: '8px' }}
                        className={`text ${
                          item.enabled ? '' : 'text-disabled'
                        }`}
                      >
                        <QTypography variant="bodyBold">
                          {item.risk !== item.default_risk ? `* ` : ``}
                        </QTypography>
                        <QTypography variant="bodyRegular">
                          {i18n.t(item.positive_finding_text)}
                        </QTypography>
                      </QTableCell>

                      {/* Threat item weight  */}
                      <QTableCell sx={{ padding: '8px' }}>
                        <QToggleButtonGroup value={item.risk}>
                          <QToggleButton
                            value="low"
                            className={`threat-item-weight-btn ${
                              item.risk === 'low' && item.enabled
                                ? 'active'
                                : 'disabled'
                            }`}
                            disabled={!item.enabled}
                            color="success"
                            selectedColor={riskItemList[item.risk].muiColor}
                            sx={
                              !item.enabled || item.risk !== 'low'
                                ? { border: 'none' }
                                : {}
                            }
                            size="sm"
                            onClick={() =>
                              changeThreatItemWeight(
                                item.id,
                                item.enabled,
                                'low',
                              )
                            }
                          >
                            {i18n.t('Low')}
                          </QToggleButton>{' '}
                          <QToggleButton
                            value="medium"
                            className={`threat-item-weight-btn ${
                              item.risk === 'medium' && item.enabled
                                ? 'active'
                                : 'disabled'
                            }`}
                            selectedColor={riskItemList[item.risk].muiColor}
                            sx={
                              item.risk === 'medium' && item.enabled
                                ? {}
                                : { border: 'none' }
                            }
                            disabled={!item.enabled}
                            color="warning"
                            size="sm"
                            onClick={() =>
                              changeThreatItemWeight(
                                item.id,
                                item.enabled,
                                'medium',
                              )
                            }
                          >
                            {i18n.t('Medium')}
                          </QToggleButton>{' '}
                          <QToggleButton
                            value="high"
                            className={`threat-item-weight-btn ${
                              item.risk === 'high' && item.enabled
                                ? 'active'
                                : 'disabled'
                            }`}
                            disabled={!item.enabled}
                            color="danger"
                            size="sm"
                            selectedColor={riskItemList[item.risk].muiColor}
                            sx={
                              item.risk === 'high' && item.enabled
                                ? {}
                                : { border: 'none' }
                            }
                            onClick={() =>
                              changeThreatItemWeight(
                                item.id,
                                item.enabled,
                                'high',
                              )
                            }
                          >
                            {i18n.t('High')}
                          </QToggleButton>
                          <QToggleButton
                            value="critical"
                            className={`threat-item-weight-btn ${
                              item.risk === 'critical' && item.enabled
                                ? 'active'
                                : 'disabled'
                            }`}
                            sx={
                              item.risk === 'critical' && item.enabled
                                ? {}
                                : { border: 'none' }
                            }
                            disabled={!item.enabled}
                            color="critical"
                            size="sm"
                            selectedColor={riskItemList[item.risk].muiColor}
                            onClick={() =>
                              changeThreatItemWeight(
                                item.id,
                                item.enabled,
                                'critical',
                              )
                            }
                          >
                            {i18n.t('Critical')}
                          </QToggleButton>
                        </QToggleButtonGroup>
                      </QTableCell>
                      <QTableCell sx={{ padding: '8px' }}>
                        {!mastV2 ? (
                          <QButton
                            className={`threat-item-enabled-btn ${
                              item.enabled ? 'active' : 'disabled'
                            }`}
                            size="sm"
                            onClick={() =>
                              toggleThreatItemEnabled(
                                item.id,
                                item.enabled,
                                item.risk,
                              )
                            }
                          >
                            {item.enabled
                              ? i18n.t('Enabled')
                              : i18n.t('Disabled')}
                          </QButton>
                        ) : (
                          <QSwitch
                            onChange={() =>
                              toggleThreatItemEnabled(
                                item.id,
                                item.enabled,
                                item.risk,
                              )
                            }
                            checked={item.enabled}
                          />
                        )}
                      </QTableCell>
                    </QTableRow>
                  ) : (
                    <QTableRow key={index}>
                      {/* Item name */}
                      <QTableCell
                        sx={{ padding: '8px' }}
                        className={`text-left ${
                          item.enabled ? '' : 'text-disabled'
                        }`}
                      >
                        <strong>
                          {`${
                            item.risk !== item.default_risk ? `*` : ``
                          } ${i18n.t(item.positive_finding_text)}`}
                        </strong>
                      </QTableCell>

                      {/* Risk item status badge  */}
                      <QTableCell sx={{ padding: '8px' }}>
                        {item.enabled ? (
                          <QBadge
                            style={{ fontSize: 13, width: 75 }}
                            color={riskItemList[item.risk].color}
                            label={riskItemList[item.risk].label}
                            variant={riskItemList[item.risk].variant}
                          />
                        ) : (
                          <QBadge
                            style={{ fontSize: 13, width: 75 }}
                            color="dark"
                            label="Disabled"
                          />
                        )}
                      </QTableCell>
                    </QTableRow>
                  ),
                )}
              </QTableBody>
            </QTable>
          </QCardBody>
        </QCollapse>
      </QCard>
    </div>
  );
};

export default ThreatScoreSection;
