import React from 'react';
import { Button } from 'reactstrap';

import { getAssets } from '../assets/assets';

import { styles } from './JoyTourConfig';

function getLabel(index, totalSteps) {
  if (index === 0) {
    return 'Start';
  }
  if (index + 1 !== totalSteps) {
    return 'Next';
  }
  return 'Done';
}

function Header({ index, step, closeProps, totalSteps, optionalStyle }) {
  return (
    <div style={styles.tooltipHeader}>
      <img
        src={getAssets[process.env.REACT_APP_APPLIANCE ?? 'default'].logo.fav}
        alt="Logo"
        style={styles.tooltipLogo}
      />
      {step.title && <div style={styles.tooltipTitle}>{step.title}</div>}
      <div style={{ ...styles.tooltipSteps, ...optionalStyle?.tooltipSteps }}>
        {index + 1} / {totalSteps}
      </div>
      <Button
        {...closeProps}
        size="sm"
        className="btn-sm"
        id="close-tooltip-btn"
        style={styles.buttonClose}
      >
        <i className="far fa-times" style={styles.closeLabel} />
      </Button>
    </div>
  );
}

export default function Tooltip({
  continuous,
  index,
  step,
  backProps,
  skipProps,
  primaryProps,
  tooltipProps,
  totalSteps,
  reportButton,
  optionalStyle,
}) {
  return (
    <div
      {...tooltipProps}
      style={{ ...styles.tooltip, ...optionalStyle?.tooltip }}
    >
      <Header
        index={index}
        step={step}
        closeProps={skipProps}
        totalSteps={totalSteps}
        optionalStyle={optionalStyle}
      />

      <div style={styles.tooltipContent}>{step.content}</div>
      <div style={styles.tooltipFooter}>
        {reportButton}
        {continuous && (
          <Button
            style={
              step.styles?.buttonNext
                ? step.styles?.buttonNext
                : styles.buttonNext
            }
            {...primaryProps}
          >
            {getLabel(index, totalSteps)}
          </Button>
        )}
        {index > 0 && (
          <Button style={styles.buttonBack} {...backProps}>
            Back
          </Button>
        )}
      </div>
    </div>
  );
}
