import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: 1,
  pageSize: { value: 10, label: '10' },
  platformFilter: { value: 'both', label: 'Both' },
  subGroupFilter: { value: 'all', label: 'All' },
  sortBy: { value: 'dateSubmitted', label: 'Date Submitted' },
  // Used for the tag next to the search query, only set when a query happens
  currentSearchQuery: '',
  searchQuery: '',
  reviewStatusFilter: {
    value: 'pending',
    label: 'Pending',
    iconClass: 'far fa-ellipsis-h text-info',
  },
};

export { initialState };

export const summarizedAppsSlice = createSlice({
  name: 'summarizedApps',
  initialState,
  reducers: {
    changePage: (state, action) => ({
      ...state,
      page: action.payload,
    }),
    changePageSize: (state, action) => ({
      ...state,
      pageSize: action.payload,
    }),
    changePlatformFilter: (state, action) => ({
      ...state,
      platformFilter: action.payload,
    }),
    changeSubGroupFilter: (state, action) => ({
      ...state,
      subGroupFilter: action.payload,
    }),
    changeSortByColumn: (state, action) => ({
      ...state,
      sortBy: action.payload,
    }),
    changeCurrentSearchQuery: (state, action) => ({
      ...state,
      currentSearchQuery: action.payload,
    }),
    changeSearchQuery: (state, action) => ({
      ...state,
      searchQuery: action.payload,
    }),
    changeReviewStatusFilter: (state, action) => ({
      ...state,
      reviewStatusFilter: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  changePage,
  changePageSize,
  changePlatformFilter,
  changeSubGroupFilter,
  changeSortByColumn,
  changeCurrentSearchQuery,
  changeSearchQuery,
  changeReviewStatusFilter,
} = summarizedAppsSlice.actions;

export default summarizedAppsSlice.reducer;
