import React, { useState, useEffect } from 'react';
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import auth from '../logic/auth';
import util from '../logic/util';
import { toastrHelper } from '../logic/toastrHelper';
import { getAssets } from '../assets/assets';

export const KryptowireSsoLogin = ({ serviceProvider }) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const { mastV2 } = useFlags();

  const redirectUrl = util.getQueryVariable(
    'redirectUrl',
    `?${window.location.href.split('?')[1]}`,
  );

  const getSpToken = async () => {
    // If we have a token try to get a JWT for the particular service provider
    try {
      const token = localStorage.getItem('token');

      const response = await axios.get('auth/jwt', {
        params: { serviceProvider },
        headers: { Authorization: token },
      });
      const spToken = response.data.token;

      window.location.replace(`${redirectUrl}?jwt=${spToken}`);
    } catch (err) {
      console.log('Error getting SP token:', err);
      setLoading(false);
    }
  };

  // When the component loads check to see if there is a token already saved in local storage from a previous authentication
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setLoading(false);
      return;
    }

    getSpToken();
    // eslint-disable-next-line
  }, [serviceProvider, redirectUrl]);

  // Attempt to login to the portal
  const handleLogin = async () => {
    // Reset the error message so that it 'pops' back up if there's another error
    setErrorMsg('');
    setLoading(true);

    // Check to make sure the input is valid (valid email and password is present)
    if (!util.isValidEmail(email)) {
      toastrHelper.showWarningToast(
        'Please enter a valid email',
        'Warning',
        mastV2,
      );
      return;
    }
    if (!password) {
      toastrHelper.showWarningToast(
        'Please enter a password',
        'Warning',
        mastV2,
      );
      return;
    }

    try {
      const response = await axios.post('emm/login', {
        email,
        password,
        token,
      });

      console.log('Response:', response);
      if (response.status === 401 || response.status === 400) {
        setPassword('');
        setErrorMsg(response.data.msg);
      } else {
        console.log('Successful login');
        // Store the auth token in local storage
        localStorage.setItem('token', `Bearer ${response.data.token}`);
        auth.user.authenticated = true;

        // Set the global axios header for authorization
        axios.defaults.headers.common.Authorization =
          localStorage.getItem('token');

        getSpToken();
      }
    } catch (err) {
      console.error(err);
      if (err.response.status === 401) {
        setErrorMsg(err.response.data.msg);
      } else {
        toastrHelper.showErrorToast(
          'Error with server while logging in',
          null,
          mastV2,
        );
      }
      console.error('Error with login request');
      console.error(err.response);
    }
  };

  // Handles key press for the password field, tries to login on the enter key
  const handleKeyPress = event => {
    if (event.charCode === 13) {
      event.preventDefault();

      handleLogin();
    }
  };

  return (
    <div>
      <div className="app flex-row align-items-center">
        <Container fluid>
          <Row id="login-header-row" className="justify-content-center">
            <img
              src={
                getAssets[process.env.REACT_APP_APPLIANCE ?? 'default'].logo
                  .banner
              }
              alt="Logo"
            />
          </Row>
          {errorMsg && (
            <Row className="justify-content-center">
              <Col md="4">
                <Alert color="danger">
                  <strong>Error:&nbsp;</strong>
                  {errorMsg}
                </Alert>
              </Col>
            </Row>
          )}

          <Row className="justify-content-center">
            <Col md="3">
              <Card className="p-4">
                <CardBody className="card-body text-center">
                  <h3>Quokka SSO Login</h3>
                  <p className="text-muted">Log in to your account</p>

                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <span className="input-group-text">
                        <i
                          className={
                            loading ? 'far fa-spin fa-spinner' : 'icon-user'
                          }
                        />
                      </span>
                    </InputGroupAddon>
                    <Input
                      autoFocus
                      disabled={loading}
                      type="text"
                      className="user-email-input"
                      placeholder="Email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      onKeyPress={e => handleKeyPress(e)}
                    />
                  </InputGroup>

                  <div className="animated fadeIn">
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <i
                            className={
                              loading ? 'far fa-spin fa-spinner' : 'icon-lock'
                            }
                          />
                        </span>
                      </InputGroupAddon>
                      <Input
                        autoFocus
                        disabled={loading}
                        type="password"
                        className="user-password-input"
                        placeholder="Password"
                        value={password}
                        onKeyPress={e => handleKeyPress(e)}
                        onChange={e => setPassword(e.target.value)}
                      />
                    </InputGroup>

                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <span className="input-group-text">
                          <i
                            className={
                              loading ? 'far fa-spin fa-spinner' : 'icon-clock'
                            }
                          />
                        </span>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        disabled={loading}
                        className="user-token-input"
                        placeholder="Token"
                        value={token}
                        onKeyPress={e => handleKeyPress(e)}
                        onChange={e => setToken(e.target.value)}
                      />
                    </InputGroup>
                    <i>
                      <small>
                        * Only required if two factor authentication enabled
                      </small>
                    </i>
                  </div>

                  <Row className="mt-3">
                    <Col xs="12">
                      <Button
                        block
                        color="primary"
                        className="px-4 user-login-btn"
                        onClick={() => handleLogin()}
                      >
                        <i className="fas fa-sign-in" />
                        &nbsp;Login
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row>
                <Col xs="12" className="text-center">
                  <strong>Kryptowire</strong> - Mobile Application Security
                  Testing
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
