import React from 'react';
import { useSelector } from 'react-redux';
import { ListGroupItem, ListGroup } from 'reactstrap';

export const Issues = ({ evidence = {}, issues = [] }) => {
  const reportItems =
    useSelector(({ appResults }) => appResults.reportItems) || [];

  const passedItems = reportItems.filter(reportItem =>
    issues.includes(reportItem.reportIdentifier),
  );

  return (
    <ListGroup>
      {evidence.failedFindings
        ? evidence.failedFindings.map(finding => (
            <ListGroupItem key={finding.reportItem.id}>
              <span>
                <i
                  className={`fas fa-exclamation-triangle mr-2 ${
                    finding.reportItem.risk === 'critical'
                      ? 'critical-icon'
                      : finding.reportItem.risk === 'high'
                      ? 'danger-icon'
                      : 'warning-icon'
                  }`}
                />
                {finding.reportItem.positive_finding_text}
              </span>
            </ListGroupItem>
          ))
        : passedItems.map(reportItem => (
            <ListGroupItem key={reportItem.id}>
              <span>
                <i className="fas fa-check-circle success-icon mr-2" />
                {reportItem.negative_finding_text}
              </span>
            </ListGroupItem>
          ))}
    </ListGroup>
  );
};

export const IsDetected = ({ detected }) => (
  <>
    {detected ? (
      <i className="far fa-check-circle success-icon" />
    ) : (
      <i className="far fa-times-circle danger-icon" />
    )}
  </>
);
