import React from 'react';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import filesize from 'filesize';
import { useSelector } from 'react-redux';

import androidReportUtil from '../../logic/androidReportUtil';
import iosReportUtil from '../../logic/iosReportUtil';

import { AppMetadata } from './Malicious/AppMetadata';

export const AppSummary = ({ app }) => {
  const { appiumScript } = useFlags();
  const { results } = useSelector(state => state.appResults);
  const { customized_eid: customizedEid } = useSelector(
    state => state.emmApp.userConfig,
  );

  const marketInfo = results.market || results.analysis.store_info;

  const { external_id: externalId, submissionTag } = app;
  const {
    name,
    package: pckg,
    platform,
    version,
    icon_large_url: iconUrl,
    store_url: appUrl,
    submitted_at: submittedAt,
    script_name: scriptName,
  } = app.farm_app;

  let fileSize;
  let sha1;
  let md5;

  if (platform === 'android') {
    ({ SHA1: sha1, MD5: md5 } = androidReportUtil.getHashes(results));
    fileSize = `${androidReportUtil.getFileSize(results).MB} MB`;
  } else {
    ({ SHA1: sha1, MD5: md5 } = iosReportUtil.getHashes(results));
    fileSize = marketInfo.size ? filesize(marketInfo.size) : 'N/A';
  }

  return (
    <AppMetadata
      appUrl={appUrl}
      iconUrl={iconUrl}
      name={name}
      pckg={pckg}
      platform={platform}
      version={version}
      submissionTag={submissionTag}
      otherAttributes={[
        {
          label: 'Submitted On',
          value: moment(submittedAt).format('L-LTS'),
          defaultView: true,
        },
        {
          label: customizedEid || 'EID',
          value: externalId || 'N/A',
          defaultView: true,
        },
        { label: 'SHA1', value: sha1 },
        { label: 'MD5', value: md5 },
        { label: 'File size', value: fileSize },
        { label: 'Test Script', value: appiumScript && scriptName },
      ]}
    />
  );
};
