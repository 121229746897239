import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Container, Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { QTypography } from '@kw/quokka-ui';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { SubmissionTagBadge } from '../SubmissionTagBadge';
import { QChip } from '../Q-Components/QChip';

function AnalyzedAppInfo({
  app,
  userAccess,
  completedOrPostProcessingStatuses,
  kai = false,
}) {
  const { mastV2 } = useFlags();
  const customizedEid = useSelector(
    state => state.emmApp.userConfig.customized_eid,
  );

  let farmAppName;

  try {
    farmAppName = decodeURIComponent(app.farm_app.name);
  } catch (error) {
    farmAppName = app.farm_app.name;
  }

  return mastV2 ? (
    <Grid container spacing={2} className="analyzed-app-info-link">
      <Grid item>
        {app.farm_app.icon_small_url || app.farm_app.iconSmallUrl ? (
          <a
            href={app.farm_app.store_url || app.farm_app.storeUrl}
            target="_blank"
          >
            <img
              src={app.farm_app.icon_small_url || app.farm_app.iconSmallUrl}
              height="20px"
              alt="App Icon"
            />
          </a>
        ) : app.farm_app.icon_base64 ? (
          <img
            alt="Icon"
            src={`data:image/png;base64,${app.farm_app.icon_base64}`}
            height="20px"
          />
        ) : app.farm_app.platform === 'android' ? (
          <i className="fab fa-android fa-2x" />
        ) : (
          <i className="fab fa-apple fa-2x" />
        )}
      </Grid>

      <Grid item>
        <Link
          to={
            kai
              ? `/kai/farm-apps/${app.farm_app.id}`
              : userAccess?.can_view_reports &&
                completedOrPostProcessingStatuses.includes(app.farm_app.status)
              ? `/${app.farm_app.platform}-report/${
                  app.farm_app.uuid ? app.farm_app.uuid : app.farm_app.id
                }/${app.id}`
              : '#'
          }
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Grid item>
            <QTypography variant="h6Regular" color="black">
              {farmAppName}
            </QTypography>
          </Grid>
          <Grid item>
            <QTypography variant="h6Medium" color="#757575">
              {app.farm_app.package}
            </QTypography>
          </Grid>
          <Grid item>
            <QTypography variant="h6Medium">
              {app.farm_app.platform === 'android' ? (
                <i
                  className="fab fa-android mt-1"
                  style={{ color: '#244C5A' }}
                />
              ) : (
                <i className="fab fa-apple mt-1" style={{ color: '#244C5A' }} />
              )}
              &nbsp;
              {app.farm_app.version}
            </QTypography>
          </Grid>
          {app.external_id ||
            (app.externalId && (
              <Grid item>
                <QTypography variant="h6Medium" color="#757575">
                  {customizedEid || 'EID'}: {app.external_id || app.externalId}
                </QTypography>
              </Grid>
            ))}

          <Grid item>
            <SubmissionTagBadge submissionTag={app.submissionTag} />
          </Grid>
          <Grid container spacing="4px">
            {app.labels?.map(item => (
              <Grid item>
                <QChip
                  color="secondary"
                  label={_.startCase(item.label)}
                  style={{ marginTop: '4px' }}
                >
                  {_.startCase(item.label)}
                </QChip>
              </Grid>
            ))}
          </Grid>
        </Link>
      </Grid>
    </Grid>
  ) : (
    <Container fluid>
      <Row className="analyzed-app-info-link">
        <Col xs="2" className="text-right">
          <Row>
            {app.farm_app.icon_small_url || app.farm_app.iconSmallUrl ? (
              <a
                href={app.farm_app.store_url || app.farm_app.storeUrl}
                target="_blank"
              >
                <img
                  src={app.farm_app.icon_small_url || app.farm_app.iconSmallUrl}
                  height="30px"
                  alt="App Icon"
                />
              </a>
            ) : app.farm_app.icon_base64 ? (
              <img
                alt="Icon"
                src={`data:image/png;base64,${app.farm_app.icon_base64}`}
                height="30px"
              />
            ) : app.farm_app.platform === 'android' ? (
              <i className="fab fa-android fa-2x" />
            ) : (
              <i className="fab fa-apple fa-2x" />
            )}
          </Row>
        </Col>
        <Col xs="10" className="text-left">
          <Link
            to={
              userAccess?.can_view_reports &&
              completedOrPostProcessingStatuses.includes(app.farm_app.status)
                ? `/${app.farm_app.platform}-report/${
                    app.farm_app.uuid ? app.farm_app.uuid : app.farm_app.id
                  }/${app.id}`
                : '#'
            }
            style={{ textDecoration: 'none', color: 'inherit' }}
            className="analyzed-app-info-link"
          >
            <Row>
              <strong>{farmAppName}</strong>
            </Row>
            <Row>
              <i>{app.farm_app.package}</i>
            </Row>
            <Row>
              {app.farm_app.platform === 'android' ? (
                <i className="fab fa-android mt-1" />
              ) : (
                <i className="fab fa-apple mt-1" />
              )}
              &nbsp;
              {app.farm_app.version}
            </Row>
            {app.external_id ||
              (app.externalId && (
                <Row>
                  <span>
                    <strong>{customizedEid || 'EID'}:</strong>{' '}
                    {app.external_id || app.externalId}
                  </span>
                </Row>
              ))}

            <Row xs="2">
              <SubmissionTagBadge submissionTag={app.submissionTag} />
            </Row>
            {app.labels?.map(item => (
              <Row xs="2">
                <QChip
                  color="secondary"
                  label={_.startCase(item.label)}
                  style={{ marginTop: '4px' }}
                >
                  {_.startCase(item.label)}
                </QChip>
              </Row>
            ))}
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default withLDConsumer()(AnalyzedAppInfo);
