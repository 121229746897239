import React, { Component } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Collapse } from 'reactstrap';

import StaticAnalysisFunctionsDatatable from '../StaticAnalysisFunctionsDatatable';
import CryptoOperationsDatatable from '../CryptoOperationsDatatable';
import HashOperationsDatatable from '../HashOperationsDatatable';
import TabsView from '../../../TabsView';

export default class M5ios extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      collapseButton: 'up',
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    this.setState({
      isOpen: !this.state.isOpen,
      collapseButton: this.state.collapseButton === 'up' ? 'down' : 'up',
    });
  }

  render() {
    const { owasp, owaspIssues } = this.props;
    const { isOpen, collapseButton } = this.state;

    const tabs = [
      {
        title: 'Static Analysis Functions',
        tabContent: () => (
          <Row>
            <Col>
              <StaticAnalysisFunctionsDatatable />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Crypto Operations',
        tabContent: () => (
          <Row>
            <Col>
              <CryptoOperationsDatatable />
            </Col>
          </Row>
        ),
      },
      {
        title: 'Hash Operations',
        tabContent: () => (
          <Row>
            <Col>
              <HashOperationsDatatable />
            </Col>
          </Row>
        ),
      },
    ];

    return (
      <div>
        <Card
          className={
            owaspIssues.length === 0
              ? 'card-accent-success'
              : 'card-accent-danger'
          }
        >
          <CardHeader onClick={this.toggleCollapse} className="pointer">
            <Row>
              <Col className="text-left">{owasp}</Col>
              <Col className="text-right">
                <i className={`fas fa-lg fa-angle-${collapseButton}`} />
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <CardBody>
              <Row>
                <Col xs="6">
                  <div>
                    Threat agents that exploit authorization vulnerabilities
                    typically do so through automated attacks that use available
                    or custom-built tools.
                  </div>
                  <div>
                    <a
                      href="https://owasp.org/www-project-mobile-top-10/2016-risks/m5-insufficient-cryptography"
                      style={{ display: 'table-cell' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {' '}
                      <em>More Info</em>{' '}
                    </a>
                  </div>
                </Col>
                {owaspIssues.length !== 0 ? (
                  <Col>
                    <strong>High level issues found: </strong>
                    <div>
                      {owaspIssues.map(issue => (
                        <div>
                          <i className="fas fa-exclamation-triangle warning-icon" />{' '}
                          {issue}
                        </div>
                      ))}
                    </div>
                  </Col>
                ) : null}
              </Row>
              <hr />
              <strong>Additional Analysis Information:</strong>
              <TabsView tabs={tabs} />
            </CardBody>
          </Collapse>
        </Card>
      </div>
    );
  }
}
