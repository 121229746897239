import React, { Component } from 'react';
import { Table } from 'reactstrap';
import moment from 'moment';
import classNames from 'classnames';
import axios from 'axios';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { QRow } from '../components/Q-Components/QRow';
import { QCol } from '../components/Q-Components/QCol';
import { QCard } from '../components/Q-Components/QCard';
import { QCardBody } from '../components/Q-Components/QCardBody';
import {
  setActiveUserNotification,
  deleteUserNotification,
  setPageTitle,
} from '../store/slices/emmAppSlice';
import ConfirmationModal from '../components/Modals/ConfirmationModal';
import i18n from '../localization/i18n';

const StatusIcon = ({ status }) =>
  status === 'unread' ? (
    <i
      style={{ fontSize: '0.7em', marginRight: '20px' }}
      className="fas fa-circle text-danger"
    />
  ) : null;

const NotificationEntry = ({
  notification,
  selectedNotificationUuid,
  selectNotification,
  deleteNotification,
}) => (
  <tr
    style={{ transition: 'background-color 0.5s ease' }}
    className={classNames({
      'bg-gray-200': selectedNotificationUuid === notification.uuid,
    })}
  >
    <td
      onClick={() => selectNotification(notification)}
      className="pointer"
      data-testid={`select-notification-${notification.uuid}`}
    >
      <StatusIcon status={notification.status} />
      <span>
        <strong>{notification.user_notification.title}</strong>
        <br />
        <i>{moment(notification.created_at).format('L - LTS')}</i>
      </span>
    </td>
    <td className="text-center">
      <i
        className="fas fa-trash pointer"
        onClick={() => deleteNotification(notification)}
      />
    </td>
  </tr>
);

const NotificationMessage = ({ selectedNotificationUuid, body }) => (
  <QCard>
    <QCardBody>
      {!selectedNotificationUuid ? (
        i18n.t('Please select a notification')
      ) : (
        <div dangerouslySetInnerHTML={{ __html: body }} />
      )}
    </QCardBody>
  </QCard>
);

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNotificationUuid: '',
      selectedBody: '',
      showConfirmationModal: false,
      notificationToDelete: null,
    };

    this.selectNotification = this.selectNotification.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
  }

  async componentWillMount() {
    this.props.setPageTitle('Notifications');
  }

  async selectNotification(selectedNotification) {
    // Tell the server that the notification has been read
    await axios.get(`notifications/read/${selectedNotification.uuid}`);

    // Update things locally to show that the notification has bene read

    this.props.setActiveUserNotification(selectedNotification);
    this.setState({
      selectedNotificationUuid: selectedNotification.uuid,
      selectedBody: selectedNotification.user_notification.body_html,
    });
  }

  async deleteNotification(notificationToDelete) {
    // Tell the server to delete the notification
    await axios.delete(`notifications/${notificationToDelete.uuid}`);

    this.props.deleteUserNotification(notificationToDelete);
    this.setState({
      selectedBody: '',
      selectedNotificationUuid: '',
      showConfirmationModal: false,
    });
  }

  render() {
    const { userNotifications } = this.props;
    const {
      selectedNotificationUuid,
      selectedBody,
      showConfirmationModal,
      notificationToDelete,
    } = this.state;

    return (
      <div className="animated fadeIn">
        <ConfirmationModal
          toggle={() =>
            this.setState({ showConfirmationModal: !showConfirmationModal })
          }
          isOpen={showConfirmationModal}
          confirmHeader={i18n.t('Delete notification')}
          confirmBody={i18n.t(
            'Please confirm that you want to delete this notification.',
          )}
          confirm={() => this.deleteNotification(notificationToDelete)}
        />
        <QRow columnGap="30px">
          <QCol xs="3">
            <QCard>
              <QCardBody>
                {this.props.flags.mastv2 ? (
                  <List>
                    {userNotifications
                      .slice()
                      .sort((a, b) => {
                        if (moment(a).isAfter(b)) return 1;
                        if (moment(a).isBefore(b)) return -1;
                        return 0;
                      })
                      .map(notification => (
                        <ListItem
                          key={notification.uuid}
                          disablePadding
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="comments"
                              onClick={() => {
                                this.setState({
                                  notificationToDelete: notification,
                                  showConfirmationModal: true,
                                });
                              }}
                            >
                              <i className="far fa-trash pointer" />
                            </IconButton>
                          }
                          data-testid={`select-notification-${notification.uuid}`}
                        >
                          <ListItemButton
                            onClick={() =>
                              this.selectNotification(notification)
                            }
                            selected={
                              selectedNotificationUuid === notification.uuid
                            }
                          >
                            <StatusIcon status={notification.status} />
                            <span>
                              <strong>
                                {notification.user_notification.title}
                              </strong>
                              <br />
                              <i>
                                {moment(notification.created_at).format(
                                  'L - LTS',
                                )}
                              </i>
                            </span>
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                ) : (
                  <Table>
                    <tbody>
                      {userNotifications
                        .slice()
                        .sort((a, b) => {
                          if (moment(a).isAfter(b)) return 1;
                          if (moment(a).isBefore(b)) return -1;
                          return 0;
                        })
                        .map(notification => (
                          <NotificationEntry
                            key={notification.uuid}
                            notification={notification}
                            selectedNotificationUuid={selectedNotificationUuid}
                            selectNotification={this.selectNotification}
                            deleteNotification={() => {
                              this.setState({
                                notificationToDelete: notification,
                                showConfirmationModal: true,
                              });
                            }}
                          />
                        ))}
                    </tbody>
                  </Table>
                )}
              </QCardBody>
            </QCard>
          </QCol>
          <QCol>
            <NotificationMessage
              body={selectedBody}
              selectedNotificationUuid={selectedNotificationUuid}
            />
          </QCol>
        </QRow>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userNotifications: state.emmApp.userNotifications,
  lang: state.emmApp.portalConfig.lang,
});

const mapDispatchToProps = {
  deleteUserNotification,
  setActiveUserNotification,
  setPageTitle,
};

const NotificationsWithLDConsumer = withLDConsumer()(Notifications);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsWithLDConsumer);
