/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QButtonGroup } from '../../Q-Components/QButtonGroup';
import { QAutocomplete } from '../../Q-Components/QAutocomplete';
import { QInput } from '../../Q-Components/QInput';
import { QRow } from '../../Q-Components/QRow';
import { QCol } from '../../Q-Components/QCol';
import { QLabel } from '../../Q-Components/QLabel';
import { QSwitchControlLabel } from '../../Q-Components/QSwitchControlLabel';
import { QSwitch } from '../../Q-Components/QSwitch';
import { QTypography } from '../../Q-Components/QTypography';
import i18n from '../../../localization/i18n';
import { EditableInput } from '../../EditableInput';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { fetchGroupAdminGroup } from '../../../store/sliceHelpers/emmAppSliceHelper';
import { fetchKaiGroups } from '../../../store/sliceHelpers/kaiSliceHelper';
import { invalidateAnalyzedAppsCache } from '../../../store/slices/cache/analyzedAppsCacheSlice';
import {
  countriesDataOptions,
  getCountryNameFromCode,
} from '../../../logic/countryUtil';
import { toastrHelper } from '../../../logic/toastrHelper';

import { NewGroup } from './NewGroup';

export const GroupConfig = ({ kaiAccess = false }) => {
  const [group, setGroup] = useState();
  const [groupInputValue, setGroupInputValue] = useState('');
  const [countryInputValue, setCountryInputValue] = useState('');
  const [showConfigConfirm, setShowConfigConfirm] = useState(false);
  const [attributes, setAttributes] = useState(null);
  const { mastV2, appiumScript } = useFlags();

  const dispatch = useDispatch();

  const groupAdminGroups = useSelector(state =>
    kaiAccess ? state.kai.kaiGroups : state.emmApp.groupAdminGroup,
  );

  const groupsOptions = groupAdminGroups.map(group => ({
    value: group.id,
    label: group.name,
    ...group,
  }));

  useEffect(() => {
    setGroup(groupsOptions[0]);
  }, [groupsOptions.length > 0]);

  const getGroupConfig = async () => {
    try {
      const groups = kaiAccess
        ? await dispatch(fetchKaiGroups())
        : await dispatch(fetchGroupAdminGroup());

      if (group) {
        setGroup({
          value: group.id,
          label: group.name,
          ...groups.find(adminGroup => adminGroup.id === group.value),
        });
      }
    } catch (err) {
      console.log('Error getting group config:', err);
    }
  };

  const updateGroupConfig = async attributes => {
    try {
      const filteredAttributes = _.omit(attributes, ['label', 'value']);

      await axios.put('group-admin/config', {
        ...filteredAttributes,
        groupId: group.id,
      });

      getGroupConfig();
      dispatch(invalidateAnalyzedAppsCache());
      toastrHelper.showSuccessToast(
        i18n.t('Group config was updated successfully.'),
        null,
        mastV2,
      );
    } catch (err) {
      console.log('Error updating group config:', err);
      toastrHelper.showWarningToast(
        'Failed to update group config',
        null,
        mastV2,
      );
    }
  };

  const createNewGroup = async name => {
    try {
      const response = await axios.post(`adminV2/group`, { groupName: name });

      if (response.status === 200) {
        toastrHelper.showSuccessToast(
          i18n.t('New group was created.'),
          null,
          mastV2,
        );
      } else {
        toastrHelper.showWarningToast(
          `${i18n.t('Error creating group')}: ${
            response.data.message || response.data.msg
          }`,
          null,
          mastV2,
        );
      }
    } catch (err) {
      toastrHelper.showWarningToast(
        `${i18n.t('Error creating group')}: ${
          err.response.data.message || err.response.data.msg
        }`,
        null,
        mastV2,
      );
    } finally {
      dispatch(fetchKaiGroups());
    }
  };

  const configConfirmation = async attributes => {
    setShowConfigConfirm(true);
    setAttributes(attributes);
  };

  useEffect(() => {
    getGroupConfig();
  }, []);

  return (
    <QCard className="card-accent-primary">
      {attributes && (
        <ConfirmationModal
          isOpen={showConfigConfirm}
          toggle={() => setShowConfigConfirm(!showConfigConfirm)}
          confirmHeader="Update Group Config"
          confirmBody={`Are you sure to update ${attributes.label} permission for group?`}
          confirm={() => {
            setShowConfigConfirm(false);
            updateGroupConfig(attributes);
          }}
        />
      )}
      <QCardHeader
        disableTypography
        title={
          <QTypography variant="h4Bold" color="#000000">
            Group Configuration
          </QTypography>
        }
        action={
          kaiAccess && (
            <QButtonGroup
              style={{
                width: '350px',
              }}
            >
              <NewGroup createNewGroup={createNewGroup} />
              <QAutocomplete
                data-testid="kai-group-list"
                value={group || null}
                onChange={(event, newGroup) => {
                  setGroup(newGroup);
                }}
                inputValue={groupInputValue}
                onInputChange={(event, newGroupInputValue) => {
                  setGroupInputValue(newGroupInputValue);
                }}
                options={groupsOptions}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                disableClearable
                renderInput={params => (
                  <QInput
                    {...params}
                    outlinedWithTitle
                    size="small"
                    variant="outlined"
                    style={{ textOverflow: 'ellipsis', width: '200px' }}
                    InputProps={{
                      endAdornment: params.InputProps.endAdornment,
                      sx: {
                        '.MuiAutocomplete-endAdornment': {
                          position: 'absolute',
                        },
                      },
                      inputProps: params.inputProps,
                      ref: params.InputProps.ref,
                    }}
                  />
                )}
                style={{
                  width: '200px',
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      wordWrap: 'break-word',
                      display: 'block',
                    }}
                  >
                    {option.label}
                  </li>
                )}
                popupIcon={<ExpandMoreIcon htmlColor="#000000" />}
                clearIcon={<ExpandLessIcon htmlColor="#000000" />}
              />
            </QButtonGroup>
          )
        }
        className="flex justify-between align-center"
      >
        <strong>Group Configuration</strong>
        <div className="flex justify-between align-center">
          {kaiAccess && (
            <>
              <NewGroup createNewGroup={createNewGroup} />
              <Select
                options={groupsOptions}
                value={group}
                onChange={newGroup => {
                  setGroup(newGroup);
                }}
                styles={{
                  container: base => ({
                    ...base,
                    width: '200px',
                  }),
                }}
              />
            </>
          )}
        </div>
      </QCardHeader>

      <QCardBody>
        {group && (
          <QRow columnGap="30px">
            <QCol flex="1" xs="5">
              {mastV2 ? null : (
                <QLabel htmlFor="group-name-input">
                  <strong>Name:</strong>
                </QLabel>
              )}
              <EditableInput
                label={
                  <QTypography variant="h5Regular" color="#000000">
                    Name:
                  </QTypography>
                }
                id="group-name-input"
                initValue={group ? group.name : ''}
                onSave={newName => updateGroupConfig({ name: newName })}
              />

              {kaiAccess && (
                <>
                  <div>
                    <strong>ID: </strong>
                    {group.id}
                  </div>
                  <div>
                    <strong>Created: </strong>
                    {moment(group.createdAt).format('L - LTS')}
                  </div>

                  <div>
                    <strong>Updated: </strong>
                    {moment(group.updatedAt).format('L - LTS')}
                  </div>
                </>
              )}

              <div style={{ height: 20 }} />

              {kaiAccess && (
                <>
                  <QSwitchControlLabel
                    label={
                      <strong>
                        Approval required for store app submission
                      </strong>
                    }
                    control={
                      <QSwitch
                        label="Approval required for store app submission"
                        value={group.storeAppApprovalRequired}
                        checked={group.storeAppApprovalRequired}
                        onChange={() =>
                          configConfirmation({
                            label: 'Approval required for store app submission',
                            storeAppApprovalRequired:
                              !group.storeAppApprovalRequired,
                          })
                        }
                      />
                    }
                  >
                    <QSwitch
                      label="Approval required for store app submission"
                      value={group.storeAppApprovalRequired}
                      onChange={() =>
                        configConfirmation({
                          label: 'Approval required for store app submission',
                          storeAppApprovalRequired:
                            !group.storeAppApprovalRequired,
                        })
                      }
                    />
                  </QSwitchControlLabel>
                  <p>
                    Users will be forced to request for approval when submitting
                    a store app if the app has not yet been added to the
                    approved submission list
                  </p>

                  <QSwitchControlLabel
                    data-testid="test-group-config-binaryAppApprovalRequired-switch"
                    label={
                      <strong>
                        Approval required for binary app submission
                      </strong>
                    }
                    control={
                      <QSwitch
                        label="Approval required for binary app submission"
                        value={group.binaryAppApprovalRequired}
                        checked={group.binaryAppApprovalRequired}
                        onChange={() =>
                          configConfirmation({
                            label:
                              'Approval required for binary app submission',
                            binaryAppApprovalRequired:
                              !group.binaryAppApprovalRequired,
                          })
                        }
                      />
                    }
                  >
                    <QSwitch
                      datatestid="test-group-config-binaryAppApprovalRequired-switch"
                      label="Approval required for binary app submission"
                      value={group.binaryAppApprovalRequired}
                      onChange={() =>
                        configConfirmation({
                          label: 'Approval required for binary app submission',
                          binaryAppApprovalRequired:
                            !group.binaryAppApprovalRequired,
                        })
                      }
                    />
                  </QSwitchControlLabel>
                  <p>
                    Users will be forced to request for approval when submitting
                    a binary app if the app has not yet been added to the
                    approved submission list
                  </p>
                </>
              )}

              <QSwitchControlLabel
                label={<strong>Subgroup required for UI app submission</strong>}
                data-testid="test-group-config-switch"
                control={
                  <QSwitch
                    label="Subgroup required for UI app submission"
                    value={group.submitRequiredSubgroupUi}
                    checked={group.submitRequiredSubgroupUi}
                    onChange={() =>
                      configConfirmation({
                        label: 'Subgroup required for UI app submission',
                        submitRequiredSubgroupUi:
                          !group.submitRequiredSubgroupUi,
                      })
                    }
                  />
                }
              >
                <QSwitch
                  datatestid="test-group-config-switch"
                  label="Subgroup required for UI app submission"
                  value={group.submitRequiredSubgroupUi}
                  onChange={() =>
                    configConfirmation({
                      label: 'Subgroup required for UI app submission',
                      submitRequiredSubgroupUi: !group.submitRequiredSubgroupUi,
                    })
                  }
                />
              </QSwitchControlLabel>
              <p>
                Users will be forced to select one or more sub-groups when
                submitting an application through Portal UI
              </p>
              <QSwitchControlLabel
                label={
                  <strong>Subgroup required for API app submission</strong>
                }
                control={
                  <QSwitch
                    label="Subgroup required for API app submission"
                    value={group.submitRequiredSubgroupApi}
                    checked={group.submitRequiredSubgroupApi}
                    onChange={() =>
                      configConfirmation({
                        label: 'Subgroup required for API app submission',
                        submitRequiredSubgroupApi:
                          !group.submitRequiredSubgroupApi,
                      })
                    }
                  />
                }
              >
                <QSwitch
                  label="Subgroup required for API app submission"
                  value={group.submitRequiredSubgroupApi}
                  checked={group.submitRequiredSubgroupApi}
                  onChange={() =>
                    configConfirmation({
                      label: 'Subgroup required for API app submission',
                      submitRequiredSubgroupApi:
                        !group.submitRequiredSubgroupApi,
                    })
                  }
                />
              </QSwitchControlLabel>
              <p>
                Users will be forced to specify one or more sub-groups when
                submitting an application through API
              </p>
              <QSwitchControlLabel
                label={
                  <strong>
                    Subgroup assigned by default for app submission
                  </strong>
                }
                control={
                  <QSwitch
                    label="Subgroup assigned by default for app submission"
                    value={group.submitDefaultSubgroup}
                    checked={group.submitDefaultSubgroup}
                    onChange={() =>
                      configConfirmation({
                        label:
                          'Subgroup assigned by default for app submission',
                        submitDefaultSubgroup: !group.submitDefaultSubgroup,
                      })
                    }
                  />
                }
              >
                <QSwitch
                  label="Subgroup assigned by default for app submission"
                  value={group.submitDefaultSubgroup}
                  checked={group.submitDefaultSubgroup}
                  onChange={() =>
                    configConfirmation({
                      label: 'Subgroup assigned by default for app submission',
                      submitDefaultSubgroup: !group.submitDefaultSubgroup,
                    })
                  }
                />
              </QSwitchControlLabel>
              <p>
                Any app submission that is submitted without any sub groups
                selected will be assigned to all the sub groups a user belongs
                to
              </p>
              <QSwitchControlLabel
                label={<strong>Subgroup required for access</strong>}
                control={
                  <QSwitch
                    label="Subgroup required for access"
                    value={group.subgroupRequired}
                    checked={group.subgroupRequired}
                    onChange={() =>
                      configConfirmation({
                        label: 'Subgroup required for access',
                        subgroupRequired: !group.subgroupRequired,
                      })
                    }
                  />
                }
              >
                <QSwitch
                  label="Subgroup required for access"
                  value={group.subgroupRequired}
                  checked={group.subgroupRequired}
                  onChange={() =>
                    configConfirmation({
                      label: 'Subgroup required for access',
                      subgroupRequired: !group.subgroupRequired,
                    })
                  }
                />
              </QSwitchControlLabel>
              <p>
                Users with no subgroup assigned to them will have no access to
                apps from other groups.
              </p>

              <QSwitchControlLabel
                label={
                  <strong>Note required for changing review status</strong>
                }
                control={
                  <QSwitch
                    label="Note required for changing review status"
                    value={group.noteRequiredReviewStatus}
                    checked={group.noteRequiredReviewStatus}
                    onChange={() => {
                      configConfirmation({
                        label: 'Note required for changing review status',
                        noteRequiredReviewStatus:
                          !group.noteRequiredReviewStatus,
                      });
                    }}
                  />
                }
              >
                <QSwitch
                  label="Note required for changing review status"
                  value={group.noteRequiredReviewStatus}
                  checked={group.noteRequiredReviewStatus}
                  onChange={() => {
                    configConfirmation({
                      label: 'Note required for changing review status',
                      noteRequiredReviewStatus: !group.noteRequiredReviewStatus,
                    });
                  }}
                />
              </QSwitchControlLabel>
              <p>
                Users will be forced to enter a note when changing app or issue
                review status
              </p>

              <QSwitchControlLabel
                label={
                  <strong>App analysis added to watchlist by default</strong>
                }
                data-testid="test-group-config-submitDefaultWatchlist-switch"
                control={
                  <QSwitch
                    label="App analysis added to watchlist by default"
                    value={group.submitDefaultWatchlist}
                    checked={group.submitDefaultWatchlist}
                    onChange={() => {
                      configConfirmation({
                        label: 'App analysis added to watchlist by default',
                        submitDefaultWatchlist: !group.submitDefaultWatchlist,
                      });
                    }}
                  />
                }
              >
                <QSwitch
                  datatestid="test-group-config-submitDefaultWatchlist-switch"
                  label="App analysis added to watchlist by default"
                  value={group.submitDefaultWatchlist}
                  checked={group.submitDefaultWatchlist}
                  onChange={() => {
                    configConfirmation({
                      label: 'App analysis added to watchlist by default',
                      submitDefaultWatchlist: !group.submitDefaultWatchlist,
                    });
                  }}
                />
              </QSwitchControlLabel>
              <p>All app analysis will be automatically added to a watchlist</p>
              <QSwitchControlLabel
                label={<strong>View Submitting User by default</strong>}
                data-testid="test-group-config-viewSubmittingUser-switch"
                control={
                  <QSwitch
                    label="View Submitting User by default"
                    value={group.viewSubmittingUser}
                    checked={group.viewSubmittingUser}
                    onChange={() => {
                      configConfirmation({
                        label: 'View Submitting User by default',
                        viewSubmittingUser: !group.viewSubmittingUser,
                      });
                    }}
                  />
                }
              >
                <QSwitch
                  datatestid="test-group-config-viewSubmittingUser-switch"
                  label="View Submitting User by default"
                  value={group.viewSubmittingUser}
                  checked={group.viewSubmittingUser}
                  onChange={() => {
                    configConfirmation({
                      label: 'View Submitting User by default',
                      viewSubmittingUser: !group.viewSubmittingUser,
                    });
                  }}
                />
              </QSwitchControlLabel>
              <p>
                Users can add a new Column to analyzed apps table to show who
                submitted the apps
              </p>
              {appiumScript && (
                <>
                  <QSwitchControlLabel
                    data-testid="test-group-config-testScriptSubmission-switch"
                    label={<strong>Test Script Submission</strong>}
                    control={
                      <QSwitch
                        label="Test Script Submission"
                        value={group.testScriptSubmission}
                        checked={group.testScriptSubmission}
                        onChange={() =>
                          configConfirmation({
                            label: 'Test Script Submission',
                            testScriptSubmission: !group.testScriptSubmission,
                          })
                        }
                      />
                    }
                  >
                    <QSwitch
                      datatestid="test-group-config-testScriptSubmission-switch"
                      label="Test Script Submission"
                      value={group.testScriptSubmission}
                      onChange={() =>
                        configConfirmation({
                          label: 'Test Script Submission',
                          testScriptSubmission: !group.testScriptSubmission,
                        })
                      }
                    />
                  </QSwitchControlLabel>
                  <p>
                    Users will be able to add test scripts to binary app
                    submissions
                  </p>
                </>
              )}
            </QCol>
            {mastV2 ? (
              <QCol flex="1" xs="5">
                <QTypography variant="h5Bold" color="#000000">
                  Customization
                </QTypography>

                <br />
                <br />

                <QTypography variant="h5Regular" color="#000000">
                  This section allows an admin to customize the portal&apos;s
                  layout
                </QTypography>

                <hr />

                <EditableInput
                  label={
                    <QTypography variant="h5Regular" color="#000000">
                      External ID String:
                    </QTypography>
                  }
                  id="group-customizedEid-input"
                  initValue={group.customizedEid ?? 'EID'}
                  onSave={newEid =>
                    updateGroupConfig({ customizedEid: newEid })
                  }
                />

                <br />

                <QTypography variant="h5Regular" color="#000000">
                  Home country:
                </QTypography>

                <QAutocomplete
                  data-testid="home-country-list"
                  noOptionsText="nothing found"
                  value={
                    group && group.homeCountryCode
                      ? getCountryNameFromCode(group.homeCountryCode)
                      : ''
                  }
                  onChange={(event, newCountry) => {
                    updateGroupConfig({
                      homeCountryCode: newCountry.value,
                    });
                  }}
                  inputValue={countryInputValue}
                  onInputChange={(event, newCountryInput) => {
                    setCountryInputValue(newCountryInput);
                  }}
                  options={countriesDataOptions}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value
                  }
                  disableClearable
                  renderInput={params => (
                    <QInput
                      {...params}
                      outlinedWithTitle
                      size="small"
                      variant="outlined"
                      style={{ textOverflow: 'ellipsis' }}
                      InputProps={{
                        endAdornment: params.InputProps.endAdornment,
                        sx: {
                          '.MuiAutocomplete-endAdornment': {
                            position: 'absolute',
                          },
                        },
                        inputProps: params.inputProps,
                        ref: params.InputProps.ref,
                      }}
                    />
                  )}
                  popupIcon={<ExpandMoreIcon htmlColor="#000000" />}
                  clearIcon={<ExpandLessIcon htmlColor="#000000" />}
                />
              </QCol>
            ) : (
              <QCol xs="5">
                <span>
                  <i className="fas fa-tools mr-2" />
                  <strong>Customization</strong>
                </span>
                <p>
                  <i>
                    This section allows an admin to customize the portal&apos;s
                    layout
                  </i>
                </p>
                <hr />
                <div className="d-flex flex-row">
                  <QCol xs="4">
                    <div className="p-2">
                      <strong>External ID String:</strong>
                    </div>
                  </QCol>
                  <QCol xs="6">
                    <EditableInput
                      id="group-customizedEid-input"
                      initValue={group.customizedEid ?? 'EID'}
                      onSave={newEid =>
                        updateGroupConfig({ customizedEid: newEid })
                      }
                    />
                  </QCol>
                </div>
                <div style={{ height: 20 }} />
                <div className="d-flex flex-row">
                  <QCol xs="4">
                    <div className="p-2">
                      <strong>Home Country:</strong>
                    </div>
                  </QCol>
                  <QCol xs="6">
                    <Select
                      noOptionsMessage={() => 'nothing found'}
                      value={{
                        value: group.homeCountry,
                        label: group.homeCountryCode
                          ? getCountryNameFromCode(group.homeCountryCode)
                          : '',
                      }}
                      onChange={newCountry =>
                        updateGroupConfig({
                          homeCountryCode: newCountry.value,
                        })
                      }
                      options={countriesDataOptions}
                    />
                  </QCol>
                </div>
              </QCol>
            )}
          </QRow>
        )}
      </QCardBody>
    </QCard>
  );
};
