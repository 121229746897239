import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QTypography } from '../../Q-Components/QTypography';
import { QSelect } from '../../Q-Components/QSelect';
import i18n from '../../../localization/i18n';
import { toastrHelper } from '../../../logic/toastrHelper';
import { QListGroup } from '../../Q-Components/QListGroup';
import { QListGroupItem } from '../../Q-Components/QListGroupItem';
import { QUncontrolledTooltip } from '../../Q-Components/QUncontrolledTooltip';

export default function SubGroups({
  groupUser,
  getUserData,
  kaiAccess = false,
}) {
  const { mastV2 } = useFlags();
  const [group, setGroup] = useState({
    value: groupUser.userGroup?.id,
    label: groupUser.userGroup?.name,
  });

  // Pull list of groups from redux
  const groupAdminGroups = useSelector(state =>
    kaiAccess ? state.kai.kaiGroups : state.emmApp.groupAdminGroup,
  );

  const groupsOptions = groupAdminGroups.map(group => ({
    value: group.id,
    label: group.name,
  }));

  const subGroupsOptions = _.flatten(
    groupAdminGroups
      .filter(adminGroup => adminGroup.id === group?.value)
      .map(adminGroup =>
        (adminGroup.sub_groups || adminGroup.subGroups).map(subGroup => ({
          value: subGroup.id,
          label: subGroup.name,
        })),
      ),
  );

  const addNewSubGroup = async subGroupSelectionId => {
    console.log(
      'Adding new sub group:',
      subGroupSelectionId,
      'for user:',
      groupUser.id,
    );

    const subGroupMappings = [
      {
        subGroupId: subGroupSelectionId,
        userId: groupUser.id,
      },
      ...groupUser.subGroupMappings,
    ];

    try {
      await axios.put(`group-admin/user/${groupUser.id}/sub-groups`, {
        subGroupMappings,
      });

      toastrHelper.showSuccessToast(
        i18n.t('Successfully added sub-groups'),
        null,
        mastV2,
      );
    } catch (err) {
      toastrHelper.showWarningToast(
        i18n.t('Error adding sub-groups'),
        null,
        mastV2,
      );
    }

    getUserData();
  };

  const removeSubGroupMapping = async subGroupMappingToRemoveId => {
    console.log('Removing sub group mapping id:', subGroupMappingToRemoveId);

    const subGroupMappings = groupUser.subGroupMappings.filter(
      mapping => mapping.id !== subGroupMappingToRemoveId,
    );
    try {
      await axios.put(`group-admin/user/${groupUser.id}/sub-groups`, {
        subGroupMappings,
      });

      toastrHelper.showSuccessToast(
        i18n.t('Successfully removed sub-groups'),
        null,
        mastV2,
      );
    } catch (err) {
      toastrHelper.showWarningToast(
        i18n.t('Error removing sub-groups'),
        null,
        mastV2,
      );
    }

    getUserData();
  };

  const setDefaultSettings = async (name, subGroupId) => {
    try {
      await axios.put(
        `group-admin//user/${groupUser.id}/sub-group-default-settings`,
        { subGroupId },
      );

      toastrHelper.showSuccessToast(
        `Successfully apply default settings of sub-group ${name} for ${groupUser.email}`,
        null,
        mastV2,
      );
    } catch (err) {
      toastrHelper.showErrorToast(
        `Error apply default settings of sub-group ${name} for ${groupUser.email}`,
        null,
        mastV2,
      );
      console.log(err);
    }

    getUserData();
  };
  const ownSubGroupIds = groupUser.subGroupMappings.map(
    subGroupMapping => subGroupMapping.subGroupId,
  );

  // Filter out subgroup that user already enrolled
  const filterOwnSubGroupOptions = subGroupsOptions.filter(
    ({ value }) => !ownSubGroupIds.includes(value),
  );

  const handleChangeGroupOption = (event, newGroup) => {
    if (mastV2) {
      const newOption = groupsOptions.find(
        option => option.value === newGroup.props.value,
      );

      setGroup(newOption);
    } else {
      setGroup(event);
    }
  };

  const handleChangeSubGroupOption = (event, newSubGroup) => {
    let newOption;

    if (mastV2) {
      newOption = newSubGroup.props.value;
    } else {
      newOption = event.value;
    }

    addNewSubGroup(newOption);
  };

  return (
    <>
      <QTypography>
        <strong>Group</strong>
      </QTypography>

      <QSelect
        formControlProps={{ sx: { width: '100%' } }}
        options={groupsOptions}
        value={!mastV2 ? group : groupsOptions.length ? group?.value : ''}
        selectProps={{
          disabled: true,
        }}
        isDisabled
        onChange={handleChangeGroupOption}
      />

      <hr />

      <QTypography>
        <strong>Sub Groups:</strong>
      </QTypography>
      {groupUser.subGroupMappings.length > 0 ? (
        <QListGroup>
          {groupUser.subGroupMappings.map(mapping => (
            <QListGroupItem
              key={mapping.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              divider
            >
              {mapping.subGroup.name}
              <div>
                <QUncontrolledTooltip
                  target="default-reset-icon"
                  placement="top"
                  title="Reset user configuration to sub group defaults"
                />
                <i
                  id="default-reset-icon"
                  className="fal fa-sync-alt pointer mr-3"
                  onClick={() =>
                    setDefaultSettings(
                      mapping.subGroup.name,
                      mapping.subGroup.id,
                    )
                  }
                />
                <QUncontrolledTooltip
                  target="delete-subgroup-icon"
                  placement="top"
                  title="Delete sub group"
                />

                <i
                  id="delete-subgroup-icon"
                  className="far fa-times-circle pointer"
                  onClick={() => removeSubGroupMapping(mapping.id)}
                />
              </div>
            </QListGroupItem>
          ))}
        </QListGroup>
      ) : (
        <div>
          <QTypography>No sub groups assigned</QTypography>
        </div>
      )}

      <QTypography>
        <strong>Add Sub Group</strong>
      </QTypography>

      <QSelect
        formControlProps={{ sx: { width: '100%' } }}
        options={filterOwnSubGroupOptions}
        onChange={handleChangeSubGroupOption}
      />
    </>
  );
}
