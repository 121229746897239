import React, { Component } from 'react';
import classnames from 'classnames';
import { Row, Col, Card, CardBody, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import androidReportUtil from '../../logic/androidReportUtil';
import iosReportUtil from '../../logic/iosReportUtil';
import i18n from '../../localization/i18n';
import AppReports from '../AnalyzedApps/AppReports';

import AndroidReportTabs from './Android/ReportTabs';
import IosReportTabs from './iOS/ReportTabs';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      app: { farm_app: {} },
    };
  }

  componentDidMount() {
    this.getApp();
  }

  async getApp() {
    const { analyzedAppId } = this.props;
    if (analyzedAppId) {
      const response = await axios.get(`/emm/app/${analyzedAppId}`);
      this.setState({ app: response.data });
    }
  }

  render() {
    const { platform, marketInfo, threatScore, results, userConfig } =
      this.props;

    const { customized_eid: customizedEid } = userConfig;

    let appUrl;
    let iconUrl;
    if (marketInfo) {
      if (platform === 'android') {
        ({ app_url: appUrl, pkg_ico_url: iconUrl } = marketInfo);
      } else {
        ({ url: appUrl, pkg_ico_url60: iconUrl } = marketInfo);
      }
    }

    let name;
    let pckg;
    let version;
    if (platform === 'android') {
      name = androidReportUtil.getAppName(results);
      pckg = androidReportUtil.getAppPackageName(results);
      version = androidReportUtil.getVersionName(results);
    } else {
      name = iosReportUtil.getAppName(results);
      pckg = iosReportUtil.getAppPackageName(results);
      version = iosReportUtil.getVersionName(results);
    }
    const { app } = this.state;
    const { appiumScript } = this.props.flags;

    return (
      <Card
        className={classnames('report-header-card', 'mb-3', {
          'card-accent-android': platform === 'android',
          'card-accent-ios': platform === 'ios',
        })}
      >
        <CardBody style={{ padding: 0 }}>
          <Row style={{ padding: 16, margin: 0 }}>
            <Col>
              <div className="report-header-app-section">
                <div className="report-header-app-inner">
                  {iconUrl ? (
                    <a href={appUrl} target={appUrl}>
                      <img
                        alt="logo"
                        src={iconUrl}
                        height="90px"
                        width="90px"
                      />
                    </a>
                  ) : platform === 'android' ? (
                    <i className="fab fa-android fa-4x" />
                  ) : (
                    <i className="fab fa-apple fa-4x" />
                  )}
                </div>
                <div className="report-header-app-inner">
                  <p className="report-header-text report-header-app-name">
                    <strong>{name}</strong>
                  </p>
                  <p className="report-header-text report-header-app-info">
                    <i>{pckg}</i>
                  </p>
                  <p className="report-header-text report-header-app-info">
                    {version}
                  </p>
                  {results.external_id && (
                    <p className="report-header-text report-header-app-info">
                      <strong>{customizedEid || 'EID'}:</strong>{' '}
                      {results.external_id}
                    </p>
                  )}
                  {results.submitted_at && (
                    <span>
                      <strong>Submitted At: </strong>
                      {moment(results.submitted_at).format('L-LTS')}
                    </span>
                  )}
                  {appiumScript && app.script_name && (
                    <p className="report-header-text">
                      <span>
                        <strong>{i18n.t('Test Script')}: </strong>
                        {app.script_name}
                      </span>
                    </p>
                  )}
                  {results.submissionTag && (
                    <p>
                      <Badge color="primary">{results.submissionTag} </Badge>
                    </p>
                  )}
                </div>
              </div>
            </Col>
            <Col className="report-header-threat-score-title text-right">
              <div className="mr-4">
                <p className="report-header-text report-header-threat-score-title bold">
                  {i18n.t('Threat Score')}
                </p>
                <div style={{ display: 'inline-flex' }}>
                  {app.id && (
                    <div style={{ marginRight: '50px' }}>
                      <AppReports app={app} />
                    </div>
                  )}
                  {threatScore > 75.0 ? (
                    <Badge
                      color="danger"
                      className="report-header-threat-score"
                    >
                      {threatScore}
                    </Badge>
                  ) : threatScore > 35.0 ? (
                    <Badge
                      color="warning"
                      className="report-header-threat-score"
                    >
                      {threatScore}
                    </Badge>
                  ) : (
                    <Badge color="info" className="report-header-threat-score">
                      {threatScore}
                    </Badge>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ margin: 0 }}>
            {platform === 'android' ? <AndroidReportTabs /> : <IosReportTabs />}
          </Row>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = ({ appResults, emmApp }) => ({
  results: appResults.results,
  simpleReportView: emmApp.simpleReportView,
  userAccess: emmApp.userAccess,
  lang: emmApp.portalConfig.lang,
  userConfig: emmApp.userConfig,
});

export default connect(mapStateToProps)(withLDConsumer()(Header));
