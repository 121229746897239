import React, { useEffect, useState } from 'react';
import { Label, InputGroup, InputGroupAddon } from 'reactstrap';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import axios from 'axios';
import _ from 'lodash';

import TestScriptFilterSelect from '../TestScriptFilterSelect';
import PlatformSelect from '../PlatformSelect';
import HelpButton from '../HelpButton';
import AsyncTablePagination from '../AsyncTablePagination';
import ConfirmationModal from '../Modals/ConfirmationModal';
import Loading from '../Loading';
import i18n from '../../localization/i18n';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import {
  changeSearchQuery,
  changeSortByColumn,
  changePlatformFilter,
  changePageSize,
  changeSubGroupFilter,
  changeStartDate,
  changeEndDate,
  changeReviewStatusFilter,
  changePage,
  changeSubmissionTagFilter,
  changeStatusFilter,
  changeTestScriptFilter,
  changeLabelsFilter,
} from '../../store/slices/analyzedAppsSlice';
import { QConditionalCard } from '../Q-Components/QConditionalCard';
import { QCardHeader } from '../Q-Components/QCardHeader';
import { QCardBody } from '../Q-Components/QCardBody';
import { QContainer } from '../Q-Components/QContainer';
import { QRow } from '../Q-Components/QRow';
import { QCol } from '../Q-Components/QCol';
import { QInput } from '../Q-Components/QInput';
import { QDropdownSelectItem } from '../Q-Components/QDropdownSelectItem';
import { QButton } from '../Q-Components/QButton';
import { QTable } from '../Q-Components/QTable';
import { QTableHead } from '../Q-Components/QTableHead';
import { QTableRow } from '../Q-Components/QTableRow';
import { QTableBody } from '../Q-Components/QTableBody';
import { QTableCell } from '../Q-Components/QTableCell';
import { QUncontrolledDropdown } from '../Q-Components/QUncontrolledDropdown';
import { QDropdownMenu } from '../Q-Components/QDropdownMenu';
import { QDropdownToggle } from '../Q-Components/QDropdownToggle';
import { QDownloadButton } from '../Q-Components/QDownloadButton';
import { QSelect } from '../Q-Components/QSelect';
import { fetchGroupAdminGroup } from '../../store/sliceHelpers/emmAppSliceHelper';
import { useLocalStorage } from '../../store/localStorage';
import { QDatePicker } from '../Q-Components/QDatePicker';
import { QSearchBar } from '../Q-Components/QSearchBar';

import ReviewStatusFilter from './ReviewStatusFilter';
import AnalyzedApp from './AnalyzedApp';
import {
  orderByOptions,
  pageSizeOptions,
  reviewStatusOptions,
  platformOptions,
  subGroupOptions,
  submissionTagOptions,
  statusOptions,
  testScriptFilterOptions,
} from './AnalyzedAppsFilterOptions';

export const AnalyzedAppsTable = ({
  getApps,
  removeApp,
  showConfirmModal,
  toggleConfirmModal,
  handleKeyPress,
  handleTextChange,
  removeSearchQuery,
  changeStatusOption,
  changeSortOption,
  handlePageSizeChange,
  handleSubGroupFilter,
  handlePlatformChange,
  pagination,
  loading,
  data,
  filters,
  resubmitApp,
  showResubmitConfirmModal,
  toggleResubmitConfirmModal,
  toggleSettingsModal,
  columnSettings,
  handleStartDate,
  handleEndDate,
  handleSubmissionTagFilter,
  downloadCsv,
  handleTestScriptFilterChange,
  handleLabelsFilterChange,
}) => {
  const {
    page,
    pageSize,
    subGroupFilter,
    platformFilter,
    sortBy,
    currentSearchQuery,
    searchQuery,
    startDate,
    endDate,
    submissionTagFilter,
    statusFilter,
    testScriptFilter,
    labelsFilter,
  } = filters;

  const { userSubGroups } = useSelector(state => state.emmApp);
  const { mastV2, appiumScript } = useFlags();

  const location = useLocation();
  const dispatch = useDispatch();

  const tempSortByV2 = sortBy?.value || null;
  const tempSortBy = { ...sortBy };
  tempSortBy.label = i18n.t(tempSortBy.label);

  const tempStatusV2 = statusFilter?.value || null;
  const tempStatus = { ...statusFilter };
  tempStatus.label = i18n.t(tempStatus.label);

  const tempPlatformFilterV2 = platformFilter?.value || null;
  const tempPlatformFilter = { ...platformFilter };
  tempPlatformFilter.label = i18n.t(tempPlatformFilter.label);

  const tempTestScriptFilterV2 = testScriptFilter?.value || null;
  const tempTestScriptFilter = { ...testScriptFilter };
  tempTestScriptFilter.label = i18n.t(tempTestScriptFilter.label);

  const [testScriptPermission, setTestScriptPermission] = useLocalStorage(
    'testScriptPermission',
    false,
  );

  const groupAdminGroups = useSelector(state => state.emmApp.groupAdminGroup);

  const [labelOptions, setLabelOptions] = useState([
    { value: 'all', label: 'All' },
  ]);

  const getLabels = async () => {
    try {
      const response = await axios.get(`api/labels/get-all-labels`);
      if (response?.status === 200) {
        setLabelOptions([
          { value: 'all', label: 'All' },
          ...response.data?.labels?.map(item => {
            const label = { value: item.label, label: _.startCase(item.label) };
            return label;
          }),
        ]);
      }
    } catch (err) {
      console.log('Error getting labels for group:', err);
    }
  };

  useEffect(() => {
    dispatch(fetchGroupAdminGroup());
    getLabels();
  }, []);

  useEffect(() => {
    if (groupAdminGroups.length > 0) {
      setTestScriptPermission(groupAdminGroups[0].testScriptSubmission);
    }
  }, [groupAdminGroups]);

  const changeStatus = (event, newStatus) => {
    if (mastV2) {
      const newOption = statusOptions.find(
        option => option.value === newStatus.props.value,
      );
      changeStatusOption(newOption);
    } else {
      changeStatusOption(event);
    }
  };

  const changeSortBy = (event, newOrderBy) => {
    if (mastV2) {
      const newOption = orderByOptions.find(
        option => option.value === newOrderBy.props.value,
      );
      changeSortOption(newOption);
    } else {
      changeSortOption(event);
    }
  };

  const changePlatform = (event, newPlatform) => {
    if (mastV2) {
      const newOption =
        typeof newPlatform.props.value === 'string'
          ? platformOptions.find(
              option => option.value === newPlatform.props.value,
            )
          : newPlatform.props.value;
      handlePlatformChange(newOption);
    } else {
      handlePlatformChange(event);
    }
  };

  const changeTestScriptFilterOption = (event, newTestScriptFilter) => {
    if (mastV2) {
      const newOption = testScriptFilterOptions.find(
        option => option.value === newTestScriptFilter.props.value,
      );
      handleTestScriptFilterChange(newOption);
    } else {
      handleTestScriptFilterChange(event);
    }
  };

  const changePageSizeWrapper = event => {
    handlePageSizeChange(event);
  };

  const subGroupOptionsSelect = subGroupOptions(userSubGroups);
  userSubGroups.forEach(subGroup => {
    subGroupOptionsSelect.push({
      value: subGroup.id,
      label: subGroup.name,
    });
  });

  // For the MUI select to work we need to send subgroups as an array of subgroup values
  // Ex. subroupFilter = [{value: 'none', label: 'Non Associated'}, { value:1, label: "subgroup1" }]
  // should return  ['none', '1']
  const processSubGroupFilter = subGroupFilter => {
    if (mastV2) {
      if (Array.isArray(subGroupFilter)) {
        return subGroupFilter.map(subgroup => subgroup.value);
      }
      return [subGroupFilter.value];
    }
    return subGroupFilter;
  };

  const processSubmissionTagFilter = submissionTagFilter => {
    if (mastV2) {
      if (Array.isArray(submissionTagFilter)) {
        return submissionTagFilter.map(tag => tag.value);
      }
      return [submissionTagFilter.value];
    }
    return submissionTagFilter;
  };

  const processLabelsFilter = labelsFilter => {
    if (mastV2) {
      if (Array.isArray(labelsFilter)) {
        return labelsFilter.map(label => label.value);
      }
      return [labelsFilter.value];
    }
    return labelsFilter;
  };

  // MUI select returns sugroups as  ['none', '1'] and this should be converted to subroups objects
  const changeSubgroupFilterWrapper = event => {
    const evaluatedSubgroups = [];
    if (mastV2) {
      const {
        target: { value },
      } = event;

      if (
        value.length > 1 &&
        (value[value.length - 1] === 'all' ||
          value[value.length - 1].value === 'all')
      ) {
        handleSubGroupFilter([]);
        return;
      }

      for (let i = 0; i < value.length; i++) {
        if (typeof value[i] === 'object' && value[i] !== null) {
          evaluatedSubgroups.push(value[i]);
        } else {
          evaluatedSubgroups.push(
            subGroupOptionsSelect.filter(sub => sub.value === value[i])[0],
          );
        }
      }
      handleSubGroupFilter(evaluatedSubgroups);
    } else {
      handleSubGroupFilter(event);
    }
  };

  const changeSubmissionTagFilterWrapper = event => {
    if (mastV2) {
      const {
        target: { value },
      } = event;

      if (
        value.length > 1 &&
        (value[value.length - 1] === 'all' ||
          value[value.length - 1].value === 'all')
      ) {
        handleSubmissionTagFilter([]);
        return;
      }

      handleSubmissionTagFilter(
        submissionTagOptions.filter(option => value.includes(option.value)),
      );
    } else {
      handleSubmissionTagFilter(event);
    }
  };

  const changeLabelsFilterWrapper = event => {
    if (mastV2) {
      const {
        target: { value },
      } = event;

      if (
        value.length > 1 &&
        (value[value.length - 1] === 'all' ||
          value[value.length - 1].value === 'all')
      ) {
        handleLabelsFilterChange([]);
        return;
      }

      handleLabelsFilterChange(
        labelOptions.filter(option => value.includes(option.value)),
      );
    } else {
      handleLabelsFilterChange(event);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const paramList = [
      'search',
      'sortColumn',
      'platform',
      'pageSize',
      'reviewStatus',
      'subGroupId',
      'startDate',
      'endDate',
      'page',
      'submissionTags',
      'status',
      'withTestScript',
      'labels',
    ];

    const [
      searchParam,
      sortColumnParam,
      platformParam,
      pageSizeParam,
      reviewStatusParam,
      subGroupIdParam,
      startDateParam,
      endDateParam,
      pageParam,
      submissionTagParam,
      statusParam,
      withTestScriptParam,
      labelsParam,
    ] = paramList.map(key => queryParams.get(key));

    if (searchParam) {
      dispatch(changeSearchQuery(searchParam));
    }

    if (sortColumnParam) {
      dispatch(
        changeSortByColumn(
          orderByOptions.find(option => option.value === sortColumnParam),
        ),
      );
    }

    if (pageSizeParam) {
      dispatch(
        changePageSize(
          pageSizeOptions.find(
            option => option.value === Number(pageSizeParam),
          ),
        ),
      );
    }

    if (reviewStatusParam) {
      dispatch(
        changeReviewStatusFilter(
          reviewStatusOptions.find(
            option => option.value === reviewStatusParam,
          ),
        ),
      );
    }

    if (platformParam) {
      dispatch(
        changePlatformFilter(
          platformOptions.find(option => option.value === platformParam),
        ),
      );
    }

    if (withTestScriptParam) {
      dispatch(
        changeTestScriptFilter(
          testScriptFilterOptions.find(
            option => option.value === withTestScriptParam,
          ),
        ),
      );
    }

    if (subGroupIdParam && subGroupIdParam !== 'all') {
      const values = subGroupIdParam.split(',');
      dispatch(
        changeSubGroupFilter(
          subGroupOptionsSelect.filter(option => values.includes(option.value)),
        ),
      );
    }

    if (startDateParam) {
      dispatch(changeStartDate(moment(startDateParam).toDate()));
    }

    if (endDateParam) {
      dispatch(changeEndDate(moment(endDateParam).toDate()));
    }

    if (pageParam) {
      dispatch(changePage(Number(pageParam)));
    }

    if (submissionTagParam && submissionTagParam !== 'all') {
      const values = submissionTagParam.split(',');

      dispatch(
        changeSubmissionTagFilter(
          submissionTagOptions.filter(option =>
            values.includes(option.value.toString()),
          ),
        ),
      );
    }

    if (labelsParam && labelsParam !== 'all') {
      const values = labelsParam.split(',');

      dispatch(
        changeLabelsFilter(
          labelOptions.filter(option =>
            values.includes(option.value.toString()),
          ),
        ),
      );
    }

    if (statusParam) {
      dispatch(
        changeStatusFilter(
          statusOptions.find(option => option.value === statusParam),
        ),
      );
    }

    getApps(Number(pageParam ?? 1));
  }, []);

  /* istanbul ignore next */
  return (
    <div className={mastV2 ? '' : 'animated fadeIn'}>
      <ConfirmationModal
        isOpen={showConfirmModal}
        toggle={toggleConfirmModal}
        confirm={removeApp}
        confirmHeader={i18n.t('Application Removal')}
        confirmBody={i18n.t(
          'Are you sure you want to remove this application? If this application is on your watchlist and is the most recent version of that app, it will also be removed from your watchlist.',
        )}
      />

      <ConfirmationModal
        isOpen={showResubmitConfirmModal}
        toggle={toggleResubmitConfirmModal}
        confirm={resubmitApp}
        confirmHeader={i18n.t('Application Resubmission')}
        confirmBody={i18n.t(
          'Are you sure you want to resubmit this application for analysis?',
        )}
      />

      <QConditionalCard className="card-accent-info" id="analyzed-apps-card">
        {!mastV2 && (
          <QCardHeader className="analyzed-apps-card-header">
            <div className="d-flex justify-between">
              <div>
                <strong>{i18n.t('Analyzed Applications')}</strong>{' '}
                <HelpButton helpItem="analyzedApps" />
              </div>

              <div className="d-flex justify-between">
                <QButton
                  color="link"
                  size="sm"
                  className="fas fa-cog fa-lg pointer black-icon"
                  onClick={() => toggleSettingsModal()}
                />
                <QButton
                  color="link"
                  size="sm"
                  className="fas fa-sync-alt pointer black-icon"
                  onClick={() => {
                    dispatch(invalidateAnalyzedAppsCache());
                    getApps(1);
                  }}
                />

                <QUncontrolledDropdown>
                  <QDropdownToggle size="sm" color="link">
                    <i className="fas fa-download black-icon" />
                  </QDropdownToggle>
                  <QDropdownMenu>
                    <QDropdownSelectItem
                      onClick={async () => downloadCsv(false)}
                    >
                      <i className="fas fa-file-excel pointer success-icon" />
                      CSV
                    </QDropdownSelectItem>
                    <QDropdownSelectItem
                      onClick={async () => downloadCsv(true)}
                    >
                      <i className="fas fa-file-archive warning-icon" /> ZIP
                    </QDropdownSelectItem>
                  </QDropdownMenu>
                </QUncontrolledDropdown>
              </div>
            </div>
          </QCardHeader>
        )}
        {mastV2 ? (
          <QContainer
            style={{
              padding: '0px',
              background: 'none',
              minHeight: '120px',
              minWidth: '100%',
            }}
          >
            <QRow
              sx={{
                mb: '12px',
                mt: '24px',
                ml: '12px',
                justifyContent: 'space-between',
              }}
            >
              {/* Filtering options */}
              <QCol sx={{ width: '60%' }}>
                <QRow columnGap="16px">
                  {/* Column to sort by */}
                  <QCol sx={{ width: '20%' }}>
                    <QSelect
                      size="small"
                      options={orderByOptions}
                      value={tempSortByV2}
                      onChange={changeSortBy}
                      label="Sort by"
                      selectIconProps={{ fontSize: 'medium' }}
                      formControlProps={{ sx: { width: '100%' } }}
                    />
                  </QCol>

                  {/* Platform options */}
                  <QCol sx={{ width: '20%' }}>
                    <PlatformSelect
                      handlePlatformChange={changePlatform}
                      platformFilter={tempPlatformFilterV2}
                      mastV2={mastV2}
                    />
                  </QCol>

                  {/* Review status options */}
                  <QCol sx={{ width: '20%' }}>
                    <ReviewStatusFilter getApps={getApps} />
                  </QCol>
                  {/* Submission Methods */}
                  <QCol sx={{ width: '20%' }}>
                    <QSelect
                      multiple
                      isMulti
                      options={submissionTagOptions}
                      value={processSubmissionTagFilter(submissionTagFilter)}
                      onChange={changeSubmissionTagFilterWrapper}
                      label="Submission Methods"
                      formControlProps={{ sx: { width: '100%' } }}
                      selectIconProps={{ fontSize: 'medium' }}
                    />
                  </QCol>
                </QRow>
              </QCol>
              <QCol>
                <QRow columnGap="16px">
                  {/* Start Date */}
                  <QCol sx={{ width: '30%' }}>
                    <QRow sx={{ flexDirection: 'column' }}>
                      <QDatePicker
                        date={startDate}
                        handleChangeDate={handleStartDate}
                        label="Start Date"
                        sx={{ width: '100%' }}
                      />
                    </QRow>
                  </QCol>
                  {/* End Date */}
                  <QCol sx={{ width: '30%' }}>
                    <QRow sx={{ flexDirection: 'column' }}>
                      <QDatePicker
                        date={endDate}
                        handleChangeDate={handleEndDate}
                        label="End Date"
                        sx={{ width: '100%' }}
                      />
                    </QRow>
                  </QCol>
                  {/* Settings and options */}
                  <QCol sx={{ width: '30%' }}>
                    <div
                      className="d-flex justify-between"
                      style={{ paddingTop: '6px' }}
                    >
                      <div
                        className="d-flex justify-between"
                        style={{ columnGap: '10px' }}
                      >
                        <QButton
                          variant="outlined"
                          startIcon={
                            <i
                              className="fas fa-cog fa-lg pointer"
                              style={{ fontSize: '20px', fontWeight: '400' }}
                            />
                          }
                          onClick={() => toggleSettingsModal()}
                          style={{
                            border: 'none',
                            background: 'none',
                            padding: '0px',
                            minWidth: 'unset',
                          }}
                        />
                        <QButton
                          variant="outlined"
                          size="sm"
                          startIcon={
                            <i
                              className="fas fa-sync-alt pointer"
                              style={{ fontSize: '20px', fontWeight: '400' }}
                            />
                          }
                          onClick={() => {
                            dispatch(invalidateAnalyzedAppsCache());
                            getApps(1);
                          }}
                          style={{
                            border: 'none',
                            background: 'none',
                            padding: '0px',
                            minWidth: 'unset',
                          }}
                        />
                        <QDownloadButton
                          options={[
                            {
                              value: 'csv',
                              label: 'CSV',
                              icon: (
                                <i className="fas fa-file-excel pointer success-icon" />
                              ),
                              onClick: async () => downloadCsv(false),
                            },
                            {
                              value: 'zip',
                              label: 'ZIP',
                              icon: (
                                <i className="fas fa-file-archive warning-icon" />
                              ),
                              onClick: async () => downloadCsv(true),
                            },
                          ]}
                        />
                      </div>
                    </div>
                  </QCol>
                </QRow>
              </QCol>
            </QRow>
            <QRow sx={{ mb: '12px', ml: '12px' }}>
              <QCol sx={{ width: '60%' }}>
                <QRow columnGap="16px">
                  {/* App Status */}
                  <QCol sx={{ width: '20%' }}>
                    <QSelect
                      size="small"
                      options={statusOptions}
                      value={tempStatusV2}
                      onChange={changeStatus}
                      label="Status"
                      formControlProps={{ sx: { width: '100%' } }}
                      selectIconProps={{ fontSize: 'medium' }}
                    />
                  </QCol>
                  {/* Test Script Options */}
                  {appiumScript && testScriptPermission && (
                    <QCol
                      data-testid="test-script-filter-options"
                      sx={{ width: '20%' }}
                    >
                      <TestScriptFilterSelect
                        handleTestScriptFilterOptionChange={
                          changeTestScriptFilterOption
                        }
                        testScriptFilter={tempTestScriptFilterV2}
                        mastV2={mastV2}
                      />
                    </QCol>
                  )}
                  {/* Sub group filter */}
                  {subGroupOptionsSelect.length > 0 && (
                    <QCol sx={{ width: '20%' }}>
                      <QSelect
                        multiple
                        isMulti
                        options={subGroupOptionsSelect}
                        value={processSubGroupFilter(subGroupFilter)}
                        onChange={changeSubgroupFilterWrapper}
                        label="Subgroups"
                        formControlProps={{ sx: { width: '100%' } }}
                        selectIconProps={{ fontSize: 'medium' }}
                      />
                    </QCol>
                  )}
                  {/* Labels when mastV2 is on  */}
                  <QCol sx={{ width: '20%' }}>
                    <QSelect
                      multiple
                      options={labelOptions}
                      value={processLabelsFilter(labelsFilter)}
                      onChange={changeLabelsFilterWrapper}
                      label="Labels"
                      formControlProps={{ sx: { width: '100%' } }}
                      selectIconProps={{ fontSize: 'medium' }}
                    />
                  </QCol>
                </QRow>
              </QCol>
            </QRow>
            <QRow sx={{ mb: '12px', ml: '12px', mr: '12px' }}>
              <QCol sx={{ width: '100%' }}>
                <QSearchBar
                  searchValue={searchQuery}
                  onChange={handleTextChange}
                  onRemove={removeSearchQuery}
                  onKeyPress={handleKeyPress}
                />
              </QCol>
            </QRow>
          </QContainer>
        ) : (
          <QContainer fluid>
            <QRow className="analyzed-apps-filter-options">
              {/* Filtering options */}
              {/* Column to sort by */}
              <QCol xs="2">
                <Label for="column-sort-select" style={{ paddingTop: '5px' }}>
                  <strong>{i18n.t('Sort by')}:</strong>
                </Label>
                <QSelect
                  size="small"
                  options={orderByOptions}
                  value={tempSortBy}
                  onChange={changeSortBy}
                  label="Sort by"
                  id="column-sort-select"
                />
              </QCol>
              {/* Platform options */}
              <QCol xs="2" id="platform-filter-options">
                <PlatformSelect
                  handlePlatformChange={changePlatform}
                  platformFilter={tempPlatformFilter}
                  mastV2={mastV2}
                />
              </QCol>
              {/* Review status options */}
              <QCol xs="2" id="">
                <ReviewStatusFilter getApps={getApps} />
              </QCol>
              {/* Sub group filter */}
              {/* Submission Methods */}
              <QCol xs="3">
                <Label style={{ paddingTop: '5px' }}>
                  <strong>{i18n.t('Submission Methods')}</strong>
                </Label>
                <QSelect
                  multiple
                  isMulti
                  options={submissionTagOptions}
                  value={processSubmissionTagFilter(submissionTagFilter)}
                  onChange={changeSubmissionTagFilterWrapper}
                  label="Submission Methods"
                  formControlProps={{ sx: { width: '100%' } }}
                  selectIconProps={{ fontSize: 'medium' }}
                  className="z-index-10"
                />
              </QCol>
              {/* Start Date */}
              <QCol xs="1">
                <Label
                  for="column-start-date-select"
                  style={{ paddingTop: '5px' }}
                >
                  <strong>Start Date:</strong>
                </Label>
                <div>
                  <QDatePicker
                    className="form-control"
                    date={startDate}
                    handleChangeDate={handleStartDate}
                    label="Start Date"
                  />
                </div>
              </QCol>
              {/* End Date */}
              <QCol xs="1">
                <Label
                  for="column-end-date-select"
                  style={{ paddingTop: '5px' }}
                >
                  <strong>End Date:</strong>
                </Label>
                <div>
                  <QDatePicker
                    className="form-control"
                    date={endDate}
                    handleChangeDate={handleEndDate}
                    label="End Date"
                  />
                </div>
              </QCol>
            </QRow>
            <QRow className="analyzed-apps-filter-options">
              {/* App Status */}
              <QCol xs="2">
                <Label for="column-status-select" style={{ paddingTop: '5px' }}>
                  <strong>{i18n.t('Status')}:</strong>
                </Label>
                <QSelect
                  options={statusOptions}
                  value={tempStatus}
                  onChange={changeStatus}
                  clearable={false}
                  isSearchable={false}
                />
              </QCol>
              {appiumScript && testScriptPermission && (
                <QCol
                  xs="2"
                  id="test-script-filter-options"
                  data-testid="test-script-filter-options"
                >
                  <TestScriptFilterSelect
                    handleTestScriptFilterOptionChange={
                      changeTestScriptFilterOption
                    }
                    testScriptFilter={tempTestScriptFilter}
                  />
                </QCol>
              )}
              {subGroupOptionsSelect.length > 0 && (
                <QCol xs="2" id="">
                  <Label style={{ paddingTop: '5px' }}>
                    <strong>{i18n.t('Sub Groups')}</strong>
                  </Label>
                  <QSelect
                    multiple
                    isMulti
                    options={subGroupOptionsSelect}
                    value={processSubGroupFilter(subGroupFilter)}
                    onChange={changeSubgroupFilterWrapper}
                    label="Subgroups"
                    className="z-index-10"
                  />
                </QCol>
              )}
              {/* Labels */}
              <QCol xs="2">
                <Label style={{ paddingTop: '5px' }}>
                  <strong>{i18n.t('Labels')}</strong>
                </Label>
                <QSelect
                  isMulti
                  options={labelOptions}
                  value={processLabelsFilter(labelsFilter)}
                  onChange={changeLabelsFilterWrapper}
                />
              </QCol>
              {/* Apps to show per page - v1 ONLY */}
              <QCol xs="2">
                <Label for="apps-per-page-select" style={{ paddingTop: '5px' }}>
                  <strong>{i18n.t('Apps per page')}:</strong>
                </Label>
                <QSelect
                  size="small"
                  options={pageSizeOptions}
                  value={pageSize}
                  onChange={changePageSizeWrapper}
                  label="Apps per page"
                  id="apps-per-page-select"
                />
              </QCol>
            </QRow>
            <QRow className="analyzed-apps-search-bar">
              {/* Search query */}
              <QCol xs="3">
                <Label for="app-query-input" style={{ paddingTop: '5px' }}>
                  <strong>{i18n.t('Search')}</strong>
                  <HelpButton helpItem="appSearch" />
                </Label>
                <InputGroup>
                  <QInput
                    type="text"
                    id="app-query-input"
                    onKeyPress={e => handleKeyPress(e)}
                    onChange={handleTextChange}
                    placeholder={i18n.t('Search Apps')}
                    value={searchQuery}
                  />
                  {currentSearchQuery && (
                    <InputGroupAddon
                      className="pointer input-group-warning"
                      onClick={removeSearchQuery}
                      addonType="append"
                    >
                      <span className="input-group-text">
                        <i className="fas fa-times" />
                      </span>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </QCol>
            </QRow>
          </QContainer>
        )}
        <QCardBody style={mastV2 ? { width: '100%' } : {}}>
          <div
            className={
              mastV2 ? '' : 'analyzed-apps-table-pagination d-flex justify-end'
            }
          >
            {/* Table pagination */}
            <AsyncTablePagination
              pagination={pagination}
              page={page}
              getPage={getApps}
              showRowsPerPage
              currentRows={data.length}
              handlePageSizeChange={handlePageSizeChange}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
            />
          </div>
          {/* If we're loading, display the table */}
          {loading ? (
            <Loading />
          ) : (
            <div>
              <QTable className="fixed-table">
                <QTableHead>
                  <QTableRow>
                    <QTableCell width="17%" className="text-left">
                      {i18n.t('App')}&nbsp;
                      <HelpButton helpItem="appColumn" />
                    </QTableCell>
                    {subGroupOptionsSelect.length > 0 &&
                      columnSettings.subGroupsCol && (
                        <QTableCell width="15%" className="text-left">
                          Sub Groups&nbsp;
                        </QTableCell>
                      )}
                    {columnSettings.analysisTimeCol && (
                      <QTableCell width="15%" className="text-left">
                        Analysis Time&nbsp;
                      </QTableCell>
                    )}
                    {columnSettings.dateCol && (
                      <QTableCell width="15%" className="text-left">
                        {i18n.t('Date Submitted')}&nbsp;
                        <HelpButton helpItem="dateSubmitted" />
                      </QTableCell>
                    )}
                    {columnSettings.submittingUserCol && (
                      <QTableCell width="16%" className="text-left">
                        {i18n.t('Submitted By')}&nbsp;
                        <HelpButton helpItem="submittedBy" />
                      </QTableCell>
                    )}
                    {columnSettings.scoreCol && (
                      <QTableCell width="8%" className="text-left">
                        {i18n.t('Threat Score')}&nbsp;
                        <HelpButton helpItem="threatScore" />
                      </QTableCell>
                    )}
                    {columnSettings.findingsCol && (
                      <QTableCell width="27%" className="text-left">
                        {i18n.t('Important Risk Findings')}&nbsp;
                        <HelpButton helpItem="summaryResults" />
                      </QTableCell>
                    )}
                    <QTableCell width="26%" className="text-center">
                      {i18n.t('Reports/Actions')}&nbsp;
                      <HelpButton helpItem="reportColumn" />
                    </QTableCell>
                  </QTableRow>
                </QTableHead>
                <QTableBody>
                  {data.map((app, index) => (
                    <AnalyzedApp
                      app={app}
                      key={index}
                      getApps={getApps}
                      resubmitApp={toggleResubmitConfirmModal}
                      removeApp={toggleConfirmModal}
                      userHasSubGroup={subGroupOptionsSelect.length > 0}
                      labelOptions={labelOptions.filter(
                        label => label.value !== 'all',
                      )}
                      pageNum={page}
                    />
                  ))}
                </QTableBody>
              </QTable>
              <div className={mastV2 ? '' : 'd-flex justify-end'}>
                <AsyncTablePagination
                  pagination={pagination}
                  page={page}
                  getPage={getApps}
                  showRowsPerPage
                  currentRows={data.length}
                  handlePageSizeChange={handlePageSizeChange}
                  pageSize={pageSize}
                  pageSizeOptions={pageSizeOptions}
                />
              </div>
            </div>
          )}
        </QCardBody>
      </QConditionalCard>
    </div>
  );
};

const mapStateToProps = ({ analyzedApps, emmApp }) => ({
  filters: analyzedApps,
  lang: emmApp.portalConfig.lang,
  columnSettings: emmApp.columnSettings,
});

export default connect(mapStateToProps)(withLDConsumer()(AnalyzedAppsTable));
