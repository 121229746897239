import React from 'react';
import { useFlags, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { QTableRow as MuiTableRow } from '@kw/quokka-ui';

const LDTableRow = ({ children, hoverDisabled, ...props }) => {
  const { mastV2 } = useFlags();
  const defaultBackgroundColor = '#fafafa';

  return mastV2 ? (
    hoverDisabled ? (
      <MuiTableRow
        {...props}
        sx={{
          '&:hover': {
            backgroundColor: defaultBackgroundColor,
            cursor: 'unset',
          },
          ...props.sx,
        }}
      >
        {children}
      </MuiTableRow>
    ) : (
      <MuiTableRow {...props}>{children}</MuiTableRow>
    )
  ) : (
    <tr {...props}>{children}</tr>
  );
};
export const QTableRow = withLDConsumer()(LDTableRow);
