import React, { useState, useEffect } from 'react';
import axios from 'axios';

import AppApprovalTab from './AppApprovalTab';

export default function ApprovedAppsTab({ isActive, group }) {
  const [appsList, setAppsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchApprovedApps() {
    try {
      setIsLoading(true);
      const appApprovalUrl = group
        ? `/adminV2/app-approval/apps/${group.id}`
        : `/app-approval/apps`;
      const responseForApproved = await axios.get(
        `${appApprovalUrl}?status=approved`,
      );
      const responseForPreApproved = await axios.get(
        `${appApprovalUrl}?status=preApproved`,
      );
      const data = responseForPreApproved.data.concat(responseForApproved.data);

      setAppsList(
        data.map(app => ({
          developer: app.storeInfo?.developer ?? app.farmApp?.developer,
          createdAt: app.createdAt,
          requestedBy: app.requestedBy,
          status: app.status,
          platform: app.platform,
          package: app.package,
          name: app.storeInfo?.name ?? app.farmApp?.name,
          iconUrl: app.storeInfo?.iconUrl ?? app.farmApp?.iconSmallUrl,
          iconBase64: app.farmApp?.iconBase64,
          updatedAt: app.updatedAt,
          lastUpdatedBy: app.lastUpdatedBy,
        })),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isActive) {
      fetchApprovedApps();
    }
  }, [isActive, group]);

  const fieldNames = {
    developer: 'Developer',
    createdAt: 'Submitted On',
    requestedBy: 'Submitted By',
    lastUpdatedBy: 'Approved By',
    updatedAt: 'Approved On',
  };
  const fields = [
    'developer',
    'createdAt',
    'requestedBy',
    'updatedAt',
    'lastUpdatedBy',
  ];
  return (
    <AppApprovalTab
      tabName="Approved Apps"
      fields={fields}
      data={appsList}
      columnDisplayNamesMapping={fieldNames}
      isLoading={isLoading}
    />
  );
}
