import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { QAutocomplete } from '../../Q-Components/QAutocomplete';
import { QCard } from '../../Q-Components/QCard';
import { QCardHeader } from '../../Q-Components/QCardHeader';
import { QCardBody } from '../../Q-Components/QCardBody';
import { QInput } from '../../Q-Components/QInput';
import { QTypography } from '../../Q-Components/QTypography';
import { fetchGroupAdminGroup } from '../../../store/sliceHelpers/emmAppSliceHelper';
import { fetchKaiGroups } from '../../../store/sliceHelpers/kaiSliceHelper';
import AppAnalysisApprovals from '../../AppApprovals/AppAnalysisApprovals';

export default function AppApprovals({ kaiAccess = false }) {
  const [group, setGroup] = useState();
  const [groupInputValue, setGroupInputValue] = useState('');
  const groupAdminGroups = useSelector(state =>
    kaiAccess ? state.kai.kaiGroups : state.emmApp.groupAdminGroup,
  );
  const dispatch = useDispatch();

  const groupsOptions = groupAdminGroups.map(group => ({
    value: group.id,
    label: group.name,
    ...group,
  }));

  useEffect(() => {
    setGroup(groupsOptions[0]);
  }, [groupsOptions.length > 0]);

  const getGroupConfig = async () => {
    try {
      const groups = kaiAccess
        ? await dispatch(fetchKaiGroups())
        : await dispatch(fetchGroupAdminGroup());

      if (group) {
        setGroup({
          value: group.id,
          label: group.name,
          ...groups.find(adminGroup => adminGroup.id === group.value),
        });
      }
    } catch (err) {
      console.log('Error getting group config:', err);
    }
  };

  useEffect(() => {
    getGroupConfig();
  }, []);

  return (
    <>
      <QCard className="card-accent-primary">
        <QCardHeader
          className="flex justify-between align-center"
          title={
            <QTypography variant="h4Bold" color="#000000">
              App Approvals
            </QTypography>
          }
          action={
            kaiAccess && (
              <QAutocomplete
                data-testid="kai-group-list"
                value={group || null}
                onChange={(event, newGroup) => {
                  setGroup(newGroup);
                }}
                inputValue={groupInputValue}
                onInputChange={(event, newGroupInputValue) => {
                  setGroupInputValue(newGroupInputValue);
                }}
                options={groupsOptions}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                disableClearable
                renderInput={params => (
                  <QInput
                    {...params}
                    size="small"
                    variant="outlined"
                    outlinedWithTitle
                    style={{ textOverflow: 'ellipsis', width: '200px' }}
                    InputProps={{
                      endAdornment: params.InputProps.endAdornment,
                      sx: {
                        '.MuiAutocomplete-endAdornment': {
                          position: 'absolute',
                        },
                      },
                      inputProps: params.inputProps,
                      ref: params.InputProps.ref,
                    }}
                  />
                )}
                style={{
                  width: '200px',
                }}
                renderOption={(props, option) => (
                  <li
                    {...props}
                    style={{
                      wordWrap: 'break-word',
                      display: 'block',
                    }}
                  >
                    {option.label}
                  </li>
                )}
                popupIcon={<ExpandMoreIcon htmlColor="#000000" />}
                clearIcon={<ExpandLessIcon htmlColor="#000000" />}
              />
            )
          }
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span>
              <strong>App Approvals</strong>
            </span>
          </div>
          {kaiAccess && (
            <Select
              options={groupsOptions}
              value={group}
              onChange={newGroup => {
                setGroup(newGroup);
              }}
              styles={{
                container: base => ({
                  ...base,
                  width: '200px',
                }),
              }}
            />
          )}
        </QCardHeader>
        <QCardBody>
          {group && <AppAnalysisApprovals appApprovalEnabled group={group} />}
        </QCardBody>
      </QCard>
    </>
  );
}
