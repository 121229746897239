// This file contains api operations for groupAdmin

import axios from 'axios';

const baseUrl = 'group-admin';

const getGroupAdminUsers = async config => {
  const users = await axios.get(`${baseUrl}/users`, config);
  return users.data;
};

const getGroupAdminSubGroups = async () => {
  const subGroups = await axios.get(`${baseUrl}/sub-groups`);
  return subGroups.data;
};

const getGroupAdminGroup = async () => {
  const group = await axios.get(`${baseUrl}/config`);

  return [group.data];
};

export default {
  getGroupAdminUsers,
  getGroupAdminSubGroups,
  getGroupAdminGroup,
};
