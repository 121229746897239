import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { toastrHelper } from '../../logic/toastrHelper';

import AppApprovalTab from './AppApprovalTab';

export default function PendingApprovalAppsTab({ isActive, group }) {
  const [appsList, setAppsList] = useState([]);
  const appApprovalRequestUrl = group
    ? `/adminV2/app-approval/request/${group.id}`
    : '/app-approval/request';
  const { mastV2 } = useFlags();
  const [isLoading, setIsLoading] = useState(false);

  async function fetchPendingApps() {
    try {
      setIsLoading(true);
      const appApprovalUrl = group
        ? `/adminV2/app-approval/apps/${group.id}`
        : `/app-approval/apps`;
      const response = await axios.get(`${appApprovalUrl}?status=pending`);

      setAppsList(
        response.data.map(app => ({
          id: app.id,
          developer: app.storeInfo?.developer ?? app.farmApp?.developer,
          createdAt: app.createdAt,
          requestedBy: app.requestedBy,
          status: app.status,
          platform: app.platform,
          package: app.package,
          name: app.storeInfo?.name ?? app.farmApp?.name,
          iconUrl: app.storeInfo?.iconUrl ?? app.farmApp?.iconSmallUrl,
        })),
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  async function approveApp(appId) {
    try {
      await axios.put(appApprovalRequestUrl, {
        requestId: appId,
        status: 'approved',
      });

      toastrHelper.showSuccessToast(
        `App was approved successfully`,
        'Success',
        mastV2,
      );
    } catch (err) {
      toastrHelper.showErrorToast(
        `Error approving app: ${err.response.data.message}`,
        'Error',
        mastV2,
      );
    } finally {
      fetchPendingApps();
    }
  }

  async function denyApp(appId) {
    try {
      const response = await axios.put(appApprovalRequestUrl, {
        requestId: appId,
        status: 'denied',
      });
      if (response.status === 200) {
        fetchPendingApps();
        toastrHelper.showSuccessToast(
          `App denied successfully`,
          'Success',
          mastV2,
        );
      } else {
        toastrHelper.showErrorToast(`Failed to deny app`, 'Error', mastV2);
      }
    } catch (err) {
      toastrHelper.showErrorToast(
        `Error approving app: ${err.response.data.msg}`,
        'Error',
        mastV2,
      );
    }
  }

  useEffect(() => {
    if (isActive) {
      fetchPendingApps();
    }
  }, [isActive, group]);

  const fields = ['developer', 'createdAt', 'requestedBy'];
  const fieldNames = {
    developer: 'Developer',
    createdAt: 'Submitted On',
    requestedBy: 'Submitted By',
  };
  const actions = { approve: approveApp, deny: denyApp };
  return (
    <AppApprovalTab
      tabName="Pending Approval Apps"
      fields={fields}
      data={appsList}
      actions={actions}
      columnDisplayNamesMapping={fieldNames}
      isLoading={isLoading}
    />
  );
}
