import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { QModal } from '../Q-Components/QModal';
import { QModalTitle } from '../Q-Components/QModalTitle';
import { QModalContent } from '../Q-Components/QModalContent';
import { QModalActions } from '../Q-Components/QModalActions';
import { QButton } from '../Q-Components/QButton';
import { QTypography } from '../Q-Components/QTypography';
import { invalidateAnalyzedAppsCache } from '../../store/slices/cache/analyzedAppsCacheSlice';
import { toastrHelper } from '../../logic/toastrHelper';

export default function CheckAppVersionModal({
  isOpen,
  toggle,
  appData,
  getWatchlistApps,
}) {
  const [latestVersion, setLatestVersion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const page = useSelector(state => state.emmApp.watchlistPage);
  const dispatch = useDispatch();
  const { mastV2 } = useFlags();

  useEffect(() => {
    setLatestVersion(null);
  }, [appData]);

  async function checkAppVersion() {
    setIsLoading(true);
    try {
      const { data } = await axios.put(`/api/check-app-version/${appData.id}`);

      setLatestVersion(data.latest_version);
      dispatch(invalidateAnalyzedAppsCache());
      toastrHelper.showSuccessToast(data.msg, 'Success', mastV2);
    } catch (err) {
      toastrHelper.showErrorToast(
        'Error checking app version',
        'Error',
        mastV2,
      );
    } finally {
      setIsLoading(false);
      getWatchlistApps(page);
    }
  }

  return (
    <QModal
      open={isOpen}
      isOpen={isOpen}
      toggle={toggle}
      title={
        <QModalTitle onClose={toggle} closeIcon={<CloseIcon />}>
          <QTypography variant="h5Regular">
            <i className="far fa-check-circle mr-2" />
            Check New Version for {appData.name}
          </QTypography>
        </QModalTitle>
      }
      content={
        <QModalContent>
          <p>
            <QTypography variant="bodyRegular">
              <i>Check for new version and resubmit the new one if found</i>
            </QTypography>
          </p>
          <p>
            <QTypography variant="bodyRegular">
              <i className="fas fa-code-branch mr-2" />
              Current version: {appData.latestVersion}
            </QTypography>
          </p>
          {latestVersion && (
            <p>
              <QTypography variant="bodyRegular">
                <i className="fas fa-code-branch mr-2" />
                Latest version: {latestVersion}
              </QTypography>
            </p>
          )}
        </QModalContent>
      }
      actions={
        <QModalActions>
          <QButton variant="light" color="secondary" onClick={toggle}>
            <QTypography variant="bodyRegular">
              <i className="far fa-times" />
              &nbsp;Cancel
            </QTypography>
          </QButton>
          <QButton
            variant="filled"
            color="primary"
            onClick={checkAppVersion}
            disabled={isLoading}
          >
            <QTypography variant="bodyRegular" color="#FFFFFF">
              <i className="far fa-check-circle" />
              &nbsp;
              {isLoading ? (
                <i className="fas fa-spin fa-spinner" />
              ) : (
                <span>Check</span>
              )}
            </QTypography>
          </QButton>
        </QModalActions>
      }
    >
      <QModalTitle>
        <QTypography variant="h5Regular">
          <i className="far fa-check-circle mr-2" />
          Check New Version for {appData.name}
        </QTypography>
      </QModalTitle>
      <QModalContent>
        <p>
          <QTypography variant="bodyRegular">
            <i>Check for new version and resubmit the new one if found</i>
          </QTypography>
        </p>
        <p>
          <QTypography variant="bodyRegular">
            <i className="fas fa-code-branch mr-2" />
            Current version: {appData.latestVersion}
          </QTypography>
        </p>
        {latestVersion && (
          <p>
            <QTypography variant="bodyRegular">
              <i className="fas fa-code-branch mr-2" />
              Latest version: {latestVersion}
            </QTypography>
          </p>
        )}
      </QModalContent>
      <QModalActions>
        <QButton onClick={toggle}>Cancel</QButton>
        <QButton color="success" onClick={checkAppVersion} disabled={isLoading}>
          <QTypography variant="bodyRegular" color="#FFFFFF">
            {isLoading ? (
              <i className="fas fa-spin fa-spinner" />
            ) : (
              <span>Check</span>
            )}
          </QTypography>
        </QButton>
      </QModalActions>
    </QModal>
  );
}
